import { AxiosRequestConfig } from 'axios/index'
import axios from 'axios'
import { RequestResponse } from '@/interface/request'
import { RESPONSE_CODE } from '@/interface/constants'

async function callService<D>(
  actionName: string,
  params?: any,
  method: string = 'POST',
  options?: AxiosRequestConfig
): Promise<RequestResponse<D>> {
  return new Promise(async (resolve, reject) => {
    try {
      const queryData: any = {}
      let _params
      if (Array.isArray(params)) {
        _params = params
      } else {
        _params = {
          ...params
        }
      }
      method = method.toUpperCase()
      if (method === 'GET') queryData.params = _params
      if (method === 'POST' || method === 'DELETE' || method === 'PUT')
        queryData.data = _params
      const res: any = await axios(actionName, {
        ...queryData,
        method,
        ...options
      })
      if (res) {
        const { code = RESPONSE_CODE.FAIL } = res.data || {}
        if (code !== RESPONSE_CODE.FAIL) {
          resolve(res.data)
        } else {
          reject(res.data)
        }
      } else {
        reject(res)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export { callService }

export default {
  key1000000: `Submitted successfully`,
  key1000001: `Permission denied`,
  key1000002: `Interface error, please check the interface error in the console.`,
  key1000003: `Hint`,
  key1000004: `Go to buy`,
  key1000005: `Cancel`,
  key1000006: `No relevant user information`,
  key1000007: `Please fill in the warranty number`,
  key1000008: `Activated successfully`,
  key1000009: `The warranty card number has already been used`,
  key1000010: `The warranty card number does not exist`,
  key1000011: `Select product`,
  key1000012: `Please enter the product name.`,
  key1000013: `Search`,
  key1000014: `Number of good products:`,
  key1000015: `Number of defective products in stock:`,
  key1000016: `Save`,
  key1000017: `Please select products.`,
  key1000018: `Select assembly/bundled product`,
  key1000019: `Sales statistics`,
  key1000020: `Platform`,
  key1000021: `Sales`,
  key1000022: `The information you filled is not complete`,
  key1000023: `Inventory change Record`,
  key1000024: `Date`,
  key1000025: `Staff`,
  key1000026: `Type of change`,
  key1000027: `Related Order No.`,
  key1000028: `Qualified product inventory changes`,
  key1000029: `Unqualified product inventory changes`,
  key1000030: `In-transit inventory changes`,
  key1000031: `Changes in inventory awaiting shipment`,
  key1000032: `Export`,
  key1000033: `Close`,
  key1000034: `Importing and exporting tasks`,
  key1000035: `Close`,
  key1000036: `Close`,
  key1000037: `The upload task is running, you can also close the window, the upload task will be executed in the background until the end, you can also go to`,
  key1000038: `View the results of the import task.`,
  key1000039: `Import initialized inventory`,
  key1000040: `Procurement`,
  key1000041: `Allocate inventory`,
  key1000042: `Back to "New order"`,
  key1000043: `Ship`,
  key1000044: `Inventory count`,
  key1000045: `Import shipped orders`,
  key1000046: `Initialize`,
  key1000047: `Customer returns`,
  key1000048: `Inventory change order`,
  key1000049: `Transfer order`,
  key1000050: `Assembly order`,
  key1000051: `Disassembly order of Assembly & Bundled product`,
  key1000052: `Return to Supplier`,
  key1000053: `Create Return to Supplier`,
  key1000054: `The Return to Supplier has been updated and been fulfilled.`,
  key1000055: `Delete Return to Supplier`,
  key1000056: `The order has been cancelled.`,
  key1000057: `Order has been refunded`,
  key1000058: `Receipt`,
  key1000059: `Delete purchase order`,
  key1000060: `Create purchase order`,
  key1000061: `Mandatory finish of purchase order`,
  key1000062: `Modify purchase order`,
  key1000063: `Return`,
  key1000064: `Uncollected products`,
  key1000065: `Whether to export`,
  key1000066: `Tips`,
  key1000067: `Confirm`,
  key1000068: `Export current inventory`,
  key1000069: `The export task is in progress. You can also close the window. The export task will execute itself in the background until it ends. You can go to`,
  key1000070: `View the results of the exporting task.`,
  key1000071: `Export failed. Do you want to download the file to check the failure reason?`,
  key1000072: `Export successfully`,
  key1000073: `Default`,
  key1000074: `Sync shipping address`,
  key1000075: `Select delivery address`,
  key1000076: `Please select an address`,
  key1000077: `You do not have permission to access this page.`,
  key1000078: `Back to homepage`,
  key1000079: `Back to previous page`,
  key1000080: `Quick fill`,
  key1000081: `Image is too large.`,
  key1000082: `Please enter`,
  key1000083: `User Info`,
  key1000084: `Settings`,
  key1000085: `Edit information`,
  key1000086: `Name`,
  key1000087: `Change Password`,
  key1000088: `Old password`,
  key1000089: `New password`,
  key1000090: `Confirm password`,
  key1000091: `中文繁體`,
  key1000092: `日本語`,
  key1000093: `Management center`,
  key1000094: `Account management`,
  key1000095: `Username`,
  key1000096: `Merchant number`,
  key1000097: `Copy`,
  key1000098: `Payment`,
  key1000099: `Value added resource package`,
  key1000100: `Default currency`,
  key1000101: `Theme settings`,
  key1000102: `Recommended color`,
  key1000103: `After-sales`,
  key1000104: `Document and Tools`,
  key1000105: `Product Help Document`,
  key1000106: `Log out`,
  key1000107: `Please enter password.`,
  key1000108: `Please enter the password again.`,
  key1000109: `The two passwords are not consistent!`,
  key1000110: `Old password cannot be empty.`,
  key1000111: `Username can not be empty.`,
  key1000112: `Successfully saved`,
  key1000113: `Successfully changed`,
  key1000114: `Old password is incorrect.`,
  key1000115: `Copy successfully`,
  key1000116: `Failed to copy`,
  key1000117: `Mark successful`,
  key1000118: `In station message mark read failed`,
  key1000119: `Unfold`,
  key1000120: `Hind`,
  key1000121: `notice`,
  key1000122: `Notice`,
  key1000123: `Message prompt`,
  key1000124: `View all`,
  key1000125: `Mark all read`,
  key1000126: `Menu management`,
  key1000127: `No data`,
  key1000128: `Procurement management`,
  key1000129: `Finished`,
  key1000130: `Quantity of goods to be received`,
  key1000131: `Delivery list`,
  key1000132: `Unfinished`,
  key1000133: `All`,
  key1000134: `day`,
  key1000135: `week`,
  key1000136: `year`,
  key1000137: `Order quantity`,
  key1000138: `Order amount`,
  key1000139: `Failed to query shop analysis statistics`,
  key1000140: `Warehouse management`,
  key1000141: `In counting`,
  key1000142: `The repair order is not closed`,
  key1000143: `Quantity of goods to be received in the repair order`,
  key1000144: `Not in counting`,
  key1000145: `Shop`,
  key1000146: `total`,
  key1000147: `Draft`,
  key1000148: `Publishing`,
  key1000149: `Online products`,
  key1000150: `Publish failed`,
  key1000151: `Total Product Listings`,
  key1000152: `Failed to query the store`,
  key1000153: `Store expiration notice`,
  key1000154: `See more`,
  key1000155: `(Remaining`,
  key1000156: `days`,
  key1000157: `Expiration)`,
  key1000158: `Category Name`,
  key1000159: `Order amount`,
  key1000160: `Number of orders`,
  key1000161: `Sales quantity`,
  key1000162: `Best selling category`,
  key1000163: `uncategorized`,
  key1000164: `Top 5`,
  key1000165: `Top 10`,
  key1000166: `Top 15`,
  key1000167: `Top 20`,
  key1000168: `Top 25`,
  key1000169: `Number of orders`,
  key1000170: `System announcement`,
  key1000171: `Product information`,
  key1000172: `Best selling products`,
  key1000173: `Publication of goods`,
  key1000174: `My products`,
  key1000175: `The platform's best-selling products`,
  key1000176: `Best selling products in the store`,
  key1000177: `Top 3`,
  key1000178: `After-sales service`,
  key1000179: `Replacement completed`,
  key1000180: `Replacing`,
  key1000181: `Repair completed`,
  key1000182: `In repiaring`,
  key1000183: `Return completed`,
  key1000184: `In progress`,
  key1000185: `Order Management`,
  key1000186: `Failed to update shipment information`,
  key1000187: `Failed to search for original orders`,
  key1000188: `Order not found`,
  key1000189: `The shipping method of the order has not been matched.`,
  key1000190: `Abnormal order status`,
  key1000191: `Unpaid order`,
  key1000192: `Insufficient inventory`,
  key1000193: `Inventory is locked.`,
  key1000194: `Products have not been matched.`,
  key1000195: `Cancelled orders`,
  key1000196: `Refunded order`,
  key1000197: `Blacklisted customers`,
  key1000198: `The warehouse does not match`,
  key1000199: `Query for consignment list failed`,
  key1000200: `Saving`,
  key1000201: `Failed to get user warehouse list`,
  key1000202: `Failed to query currency list`,
  key1000203: `Are you sure to delete?`,
  key1000204: `Confirm`,
  key1000205: `Please select the default currency of the system and enter the search`,
  key1000206: `Welcome to EasySellers`,
  key1000207: `For better experience, please set your system default currency`,
  key1000208: `(Note: system functional currency`,
  key1000209: `After setting, the currency will be selected by default for the statistics report in the system)`,
  key1000210: `remind`,
  key1000211: `Please select the default currency`,
  key1000212: `Save failed`,
  key1000213: `Query failed`,
  key1000214: `Please select or enter keywords to search`,
  key1000215: `Confirm`,
  key1000216: `Enter up to 50 characters`,
  key1000217: `Can not be empty`,
  key1000218: `The exported file can be downloaded in 7 days, and it is unable to download after the time.`,
  key1000219: `Task type`,
  key1000220: `Starting time`,
  key1000221: `Completion time`,
  key1000222: `Status`,
  key1000223: `Result`,
  key1000224: `Export results`,
  key1000225: `Processing`,
  key1000226: `Processing complete`,
  key1000227: `Failure`,
  key1000228: `Back`,
  key1000229: `Total:`,
  key1000230: `Success/Failure:`,
  key1000231: `Download`,
  key1000232: `Executing`,
  key1000233: `Execution completed`,
  key1000234: `Execution failed`,
  key1000235: `Product`,
  key1000236: `Customer`,
  key1000237: `Initialize inventory`,
  key1000238: `Purchase order`,
  key1000239: `Assembly & bundled products`,
  key1000240: `Historical orders`,
  key1000241: `Inventory count`,
  key1000242: `Replacement`,
  key1000243: `Repair`,
  key1000244: `Export`,
  key1000245: `Import`,
  key1000246: `The email is sent successfully, please go to E-mail to check the verification code.`,
  key1000247: `The email may appear in the "Spam folder", you can go to the "Spam folder" to confirm,`,
  key1000248: `If you do not receive it after 5 minutes, please click again to get the verification code`,
  key1000249: `Please input the correct email address`,
  key1000250: `Please enter the mobile phone number`,
  key1000251: `The SMS has been sent successfully, please check it on your mobile phone. If you do not receive it after 5 minutes, please click again to get the verification code`,
  key1000252: `Failed to send`,
  key1000253: `Verification code must be filled`,
  key1000254: `Wrong verification code`,
  key1000255: `The verification code has expired`,
  key1000256: `Merchant ID cannot be empty`,
  key1000257: `The customer does not exist`,
  key1000258: `Login failed`,
  key1000259: `Warranty Card Number`,
  key1000260: `Please enter the mold warranty card number`,
  key1000261: `Use or not`,
  key1000262: `Please choose whether to use`,
  key1000263: `Export time`,
  key1000264: `Add Warranty Card Numbers in Bulk`,
  key1000265: `Generation rules: prefix fixed 3-digit code + 10-digit random code (random English lowercase or numbers)`,
  key1000266: `Prefix`,
  key1000267: `Number of generated`,
  key1000268: `Export settings`,
  key1000269: `Part Name`,
  key1000270: `Model name`,
  key1000271: `Import warranty order`,
  key1000272: `/file/Import and export template of warranty card.xlsx`,
  key1000273: `Filter`,
  key1000274: `Reset`,
  key1000275: `Import and export`,
  key1000276: `Export selected`,
  key1000277: `Export all`,
  key1000278: `Generate by rules`,
  key1000279: `Set up a warranty card`,
  key1000280: `Bulk delete`,
  key1000281: `Unused`,
  key1000282: `Used`,
  key1000283: `Download the Excel sample data of the warranty card import template (`,
  key1000284: `Sample.xlsx`,
  key1000285: `The filled warranty card template is uploaded below.`,
  key1000286: `Drag the Excel file here, or`,
  key1000287: `Click to upload`,
  key1000288: `Only files with .xlsx format can be uploaded, and no more than 2MB.`,
  key1000289: `Warranty card number【`,
  key1000290: `】has existed`,
  key1000291: `】has been used`,
  key1000292: `Export warranty order`,
  key1000293: `The export task is running, you can also close the window, the export task will be executed in the background until the end, you can go to`,
  key1000294: `Processing date`,
  key1000295: `to`,
  key1000296: `Start date`,
  key1000297: `End date`,
  key1000298: `Category`,
  key1000299: `Please select a category.`,
  key1000300: `Keywords`,
  key1000301: `Please enter the keywords to search.`,
  key1000302: `Order No.`,
  key1000303: `Contact platform / Contact person`,
  key1000304: `Repair product/quantity`,
  key1000305: `Receiver`,
  key1000306: `Phone`,
  key1000307: `Address`,
  key1000308: `維修類別

`,
  key1000309: `Processing method`,
  key1000310: `Logistics cost (Coming/Back)`,
  key1000311: `Date of receipt`,
  key1000312: `Return date`,
  key1000313: `Notes`,
  key1000314: `Waybill number`,
  key1000315: `Operation`,
  key1000316: `Import returns`,
  key1000317: `/file/Import sample of Returns.xlsx`,
  key1000318: `Complete repair`,
  key1000319: `Shipment date:`,
  key1000320: `Ship date`,
  key1000321: `Waybill number:`,
  key1000322: `Export the data of repair products`,
  key1000323: `Export all`,
  key1000324: `Selected:`,
  key1000325: `pieces of data`,
  key1000326: `Edit`,
  key1000327: `Delete`,
  key1000328: `Download Excel sample material of importing returns (`,
  key1000329: `The filled return file is uploaded below.`,
  key1000330: `Please select the ship date.`,
  key1000331: `Please enter the waybill number.`,
  key1000332: `Past week`,
  key1000333: `Past month`,
  key1000334: `Past three months`,
  key1000335: `Successful operation`,
  key1000336: `The after-sales service is empty.`,
  key1000337: `Abnormal inventory interface`,
  key1000338: `Inventory shortage`,
  key1000339: `Import failed. Do you want to download the file to check the failure reason?`,
  key1000340: `Upload file size cannot exceed 2M.`,
  key1000341: `Please select a repair order.`,
  key1000342: `Export the repairs you have filtered out to an Excel file.`,
  key1000343: `Warehouse for receiving returns`,
  key1000344: `Returned products/quantity`,
  key1000345: `Warehouse for replacement and shipping`,
  key1000346: `Replacement product/quantity`,
  key1000347: `Replacement category`,
  key1000348: `Processing method`,
  key1000349: `Complete the replacement`,
  key1000350: `Export replacements`,
  key1000351: `Please select an replacement order.`,
  key1000352: `Export your selected replacements to an Excel file.`,
  key1000353: `Uncollected products/quantity`,
  key1000354: `Reason not taken`,
  key1000355: `Receiving Warehouse`,
  key1000356: `Bulk add`,
  key1000357: `Please select`,
  key1000358: `Export uncollected products`,
  key1000359: `Confirm to save the records of uncollected products, and the inventory will be replenished.`,
  key1000360: `Order No.:`,
  key1000361: `Reason for uncollected products:`,
  key1000362: `Warehouse for receiving products:`,
  key1000363: `Other`,
  key1000364: `<p>Order number:`,
  key1000365: `Order does not exist.`,
  key1000366: `The set of the order numbers queried is empty!`,
  key1000367: `Please fill in complete contents.`,
  key1000368: `Please select orders with uncollected products.`,
  key1000369: `Export the untaken items that you have filtered out to an Excel file.`,
  key1000370: `Contact platform:`,
  key1000371: `Contact person:`,
  key1000372: `Type of return:`,
  key1000373: `Processing method:`,
  key1000374: `Logistics freight type:`,
  key1000375: `Logistics cost (coming):`,
  key1000376: `Refund amount:`,
  key1000377: `Notes:`,
  key1000378: `Image`,
  key1000379: `Product No. / Product name / Specification`,
  key1000380: `Quantity`,
  key1000381: `Quantity returned`,
  key1000382: `Buyer undertakes`,
  key1000383: `To be borne by the seller`,
  key1000384: `Failed to obtain return details`,
  key1000385: `Error in getting data dictionary`,
  key1000386: `Type of repair:`,
  key1000387: `Logistics cost (return):`,
  key1000388: `Date of receipt:`,
  key1000389: `Receiver:`,
  key1000390: `Address 1:`,
  key1000391: `Address 2 / District:`,
  key1000392: `Location:`,
  key1000393: `Province / State:`,
  key1000394: `Country:`,
  key1000395: `Phone number:`,
  key1000396: `Number of repairs`,
  key1000397: `Type of replacement:`,
  key1000398: `Quantity of replacements`,
  key1000399: `Returned goods`,
  key1000400: `Replacement product`,
  key1000401: `Add products`,
  key1000402: `The warehouse for return receiving and the warehouse for replacement shipping cannot be empty.`,
  key1000403: `Returned products and replacement products cannot be empty.`,
  key1000404: `Type of return`,
  key1000405: `Receiving warehouse`,
  key1000406: `Logistics cost (coming)`,
  key1000407: `Refund amount`,
  key1000408: `Complete return`,
  key1000409: `Warehouse for receiving products:`,
  key1000410: `Export returns`,
  key1000411: `After completing the return, the inventory will be increased.`,
  key1000412: `Please select the date of receipt.`,
  key1000413: `Please choose a warehouse to receive products.`,
  key1000414: `Please select a return order.`,
  key1000415: `Export returns which were filtered out to an Excel file.`,
  key1000416: `Product No. / Product name / Specification`,
  key1000417: `30-day sales`,
  key1000418: `60-day sales`,
  key1000419: `Cumulative sales`,
  key1000420: `Procurement cycle`,
  key1000421: `Safety stock level`,
  key1000422: `Purchase quantity`,
  key1000423: `Purchase price`,
  key1000424: `Received Qualified products/Unqualified products`,
  key1000425: `Receipt quantity of qualified/ unqualified products this time`,
  key1000426: `Notes for unqualified products`,
  key1000427: `Supplier:`,
  key1000428: `Warehouse:`,
  key1000429: `Estimated arrival date:`,
  key1000430: `Purchase quantity:`,
  key1000431: `Total purchase quantity:`,
  key1000432: `Total purchase price:`,
  key1000433: `1688 total amount:`,
  key1000434: `Unreceived:`,
  key1000435: `Supplier`,
  key1000436: `Please enter keywords.`,
  key1000437: `Purchase date`,
  key1000438: `Fufillment warehouse`,
  key1000439: `1688 Is the purchase paid?`,
  key1000440: `1688 Order Status`,
  key1000441: `Purchase order type`,
  key1000442: `Purchase order No.`,
  key1000443: `Customized No.`,
  key1000444: `Warehouse`,
  key1000445: `Order date`,
  key1000446: `Estimated arrival date`,
  key1000447: `Number of product varieties`,
  key1000448: `Total number of products`,
  key1000449: `Quantity received`,
  key1000450: `1688 Transaction Status`,
  key1000451: `Amount`,
  key1000452: `Creator`,
  key1000453: `Purchase order Status`,
  key1000454: `Operator`,
  key1000455: `Finish time`,
  key1000456: `Export purchase order`,
  key1000457: `Logistics tracking`,
  key1000458: `Logistics information`,
  key1000459: `Custom filter`,
  key1000460: `Save`,
  key1000461: `Hide`,
  key1000462: `Delete`,
  key1000463: `Add purchase order`,
  key1000464: `Add 1688 Purchase Order`,
  key1000465: `Sync 1688 information`,
  key1000466: `1688 order payment`,
  key1000467: `Alipay`,
  key1000468: `Sincere E credit`,
  key1000469: `Cross-border treasure`,
  key1000470: `Purchase amount:`,
  key1000471: `1688 Amount:`,
  key1000472: `Transferred to "Awaiting receipt"`,
  key1000473: `Partial receipt (Unfinished)`,
  key1000474: `Receive`,
  key1000475: `Export Excel`,
  key1000476: `Force finish`,
  key1000477: `1688 place an order`,
  key1000478: `Logistics tracking`,
  key1000479: `The exporting task is being executed,`,
  key1000480: `You can also close the window,`,
  key1000481: `The exporting task will be executed in the background until the end,`,
  key1000482: `You can go to`,
  key1000483: `Logistics status:`,
  key1000484: `Logistics company:`,
  key1000485: `shipment number:`,
  key1000486: `Non-1688 orders,`,
  key1000487: `Do you want to ignore continue?`,
  key1000488: `Did you complete the payment?`,
  key1000489: `Paid`,
  key1000490: `Unpaid`,
  key1000491: `Waiting for buyers payment`,
  key1000492: `Waiting for seller to ship`,
  key1000493: `Waiting for the logistics company to pick up the parcel`,
  key1000494: `Waiting for buyer to sign`,
  key1000495: `Buyer has signed`,
  key1000496: `Received`,
  key1000497: `successful transaction`,
  key1000498: `Order canceled`,
  key1000499: `transaction terminated`,
  key1000500: `Awaiting receipt`,
  key1000501: `Failed to query category list`,
  key1000502: `Sync succeeded, please check later`,
  key1000503: `Sync failed`,
  key1000504: `1688 has placed an order successfully, please wait to check the result`,
  key1000505: `Save succeeded and failed`,
  key1000506: `Please select a purchase order to sync`,
  key1000507: `Select up to 30 1688 purchase orders to synchronize`,
  key1000508: `Please select 1688 order to synchronize`,
  key1000509: `Please enter a name to save.`,
  key1000510: `Payment completed, sync information`,
  key1000511: `Payment failed`,
  key1000512: `Please select the 1688 purchase order to be paid`,
  key1000513: `Select up to 30 1688 purchase orders for payment`,
  key1000514: `Please select 1688 order to pay`,
  key1000515: `The filter has not been saved.`,
  key1000516: `Successfully deleted`,
  key1000517: `Forcing to finish the purchase order will write off the unreceived inventory.`,
  key1000518: `Delete or not?`,
  key1000519: `Please select a purchase order.`,
  key1000520: `Export the purchase orders you have selected to an Excel file.`,
  key1000521: `Failed to export purchase order`,
  key1000522: `Query progress failed`,
  key1000523: `Products whose inventory is lower than the safety stock level will be displayed in the Procurement list. The recommended order quantity is calculated based on 30-day sales, 60-day sales and order cycle.`,
  key1000524: `Product name`,
  key1000525: `Preferred supplier / Procurement cycle`,
  key1000526: `Please select`,
  key1000527: `Alternative supplier 1 / Procurement cycle`,
  key1000528: `Alternative supplier 2 / Procurement cycle`,
  key1000529: `Please select the preferred manufacturer first`,
  key1000530: `The preferred supplier and the alternative supplier cannot be the same.`,
  key1000531: `Alternative supplier 2 cannot be the same as supplier 1.`,
  key1000532: `Please select an alternative manufacturer first`,
  key1000533: `Please enter content.`,
  key1000534: `Select date`,
  key1000535: `In transit inventory / inventory of existing good products`,
  key1000536: `Minimum purchase quantity`,
  key1000537: `Suggested purchase quantity`,
  key1000538: `Purchase unit price`,
  key1000539: `Currency`,
  key1000540: `Warehouse inventory`,
  key1000541: `In-transit inventory`,
  key1000542: `Existing qualified product inventory`,
  key1000543: `Estimated arrival date`,
  key1000544: `Add products`,
  key1000545: `View other warehouse inventory`,
  key1000546: `No data in other warehouses`,
  key1000547: `Failed to query inventory of other warehouses`,
  key1000548: `Failed to add item`,
  key1000549: `Failed to get PO details`,
  key1000550: `Please select a supplier.`,
  key1000551: `Please select a warehouse.`,
  key1000552: `The customized No. cannot be repeated.`,
  key1000553: `This list is your 1688 buyer account list, which is used to obtain 1688 manufacturers and generate 1688 orders and other functions`,
  key1000554: `account name`,
  key1000555: `1688 account name`,
  key1000556: `Expiration`,
  key1000557: `Authorization status`,
  key1000558: `Staff with permissions`,
  key1000559: `Add authorization`,
  key1000560: `Edit`,
  key1000561: `Reauthorize`,
  key1000562: `Invalid`,
  key1000563: `Valid`,
  key1000564: `Query 1688 authorization list failed`,
  key1000565: `Whether to reauthorize`,
  key1000566: `Failed to get 1688 Authorization URL`,
  key1000567: `Failed to query employee details`,
  key1000568: `Failed to add new employee`,
  key1000569: `Deactivate this account?`,
  key1000570: `Deactivated successfully`,
  key1000571: `Deactivation failed`,
  key1000572: `Authorization succeeded`,
  key1000573: `Service error`,
  key1000574: `Account or account code already exists`,
  key1000575: `The account does not exist.`,
  key1000576: `The account does not match`,
  key1000577: `Authorization failed`,
  key1000578: `Manage supplier files here`,
  key1000579: `Manufacturer type`,
  key1000580: `Supplier No.`,
  key1000581: `Supplier name`,
  key1000582: `Contact person`,
  key1000583: `Contact number`,
  key1000584: `Manufacturer URL`,
  key1000585: `Bulk delete`,
  key1000586: `Add`,
  key1000587: `Supplier No. cannot be empty.`,
  key1000588: `The supplier name cannot be empty.`,
  key1000589: `Add new supplier`,
  key1000590: `Not selected by default`,
  key1000591: `There is an unfinished purchase order.`,
  key1000592: `Supplier code cannot be repeated.`,
  key1000593: `Supplier names cannot be repeated.`,
  key1000594: `Edit supplier`,
  key1000595: `Please select at least one supplier.`,
  key1000596: `The manufacturer file cannot be restored after deletion, please decide carefully.`,
  key1000597: `Delete supplier`,
  key1000598: `No recalculation will be allowed within an hour.`,
  key1000599: `Existing/In-transit inventory`,
  key1000600: `Qualified product inventory`,
  key1000601: `Recalculate`,
  key1000602: `Change supplier`,
  key1000603: `Select transfer warehouse`,
  key1000604: `Generate purchase orders (selected)`,
  key1000605: `Generate purchase orders (All)`,
  key1000606: `Generate transfer order`,
  key1000607: `Inventory from other warehouses`,
  key1000608: `Calculating the procurement lists needs to count a large amount of data, which may take a long time. Please perform it with caution. Once recalculated, it will not be allowed to recalculate within one hour.`,
  key1000609: `Please select the items.`,
  key1000610: `The warehouse selected for generating the transfer order must be consistent`,
  key1000611: `Failed to get the warehouse list`,
  key1000612: `Failed to query warehouse goods`,
  key1000613: `Failed to generate the transfer order`,
  key1000614: `Query failed`,
  key1000615: `Recalculation failed`,
  key1000616: `Total`,
  key1000617: `Failed to query inventory details`,
  key1000618: `Save and authorize`,
  key1000619: `Edit account`,
  key1000620: `Account name cannot be empty`,
  key1000621: `Listing details`,
  key1000622: `Purchasing link`,
  key1000623: `Add related products`,
  key1000624: `Please add product`,
  key1000625: `Please enter purchase link`,
  key1000626: `This list is the association between your 1688 manufacturer's local products and 1688 platform products`,
  key1000627: `1688 related products`,
  key1000628: `Please enter sku`,
  key1000629: `Local product information`,
  key1000630: `1688 Product Information`,
  key1000631: `Modify association`,
  key1000632: `Cancel modification`,
  key1000633: `The product was not retrieved, please confirm whether the purchase link is correct`,
  key1000634: `Not recognized`,
  key1000635: `Not associated`,
  key1000636: `Associated`,
  key1000637: `Query manufacturer failed`,
  key1000638: `Failed to modify the association`,
  key1000639: `Failed to query purchase-related list`,
  key1000640: `Receipt quantity    Qualified/Unqualified products`,
  key1000641: `Receipt record`,
  key1000642: `Time of receipt`,
  key1000643: `Receipt quantity of qualified products`,
  key1000644: `Receipt quantity of unqualified products`,
  key1000645: `Notes for unqualified products`,
  key1000646: `Receiver`,
  key1000647: `Customized No.:`,
  key1000648: `Creator:`,
  key1000649: `1688 account`,
  key1000650: `Shipping address`,
  key1000651: `1688 order transaction method`,
  key1000652: `Procurement warehouse`,
  key1000653: `Estimated arrival date`,
  key1000654: `1688 buyer message`,
  key1000655: `Subtotal`,
  key1000656: `Save and place an order with 1688`,
  key1000657: `Add authorized account`,
  key1000658: `Refresh`,
  key1000659: `Large Market Ordinary Orders`,
  key1000660: `distribution order`,
  key1000661: `automatically choose`,
  key1000662: `Alipay Secured Transaction`,
  key1000663: `Store account cannot be empty`,
  key1000664: `Please select a purchasing warehouse`,
  key1000665: `Sync address succeeded`,
  key1000666: `Failed to query delivery address`,
  key1000667: `Please select a 1688 account`,
  key1000668: `1688 order successfully`,
  key1000669: `Add association`,
  key1000670: `Disassociate`,
  key1000671: `Failed to query 1688 product related information`,
  key1000672: `Whether to delete the binding association`,
  key1000673: `Unlinked successfully`,
  key1000674: `Unlink failed`,
  key1000675: `Please select the data to operate`,
  key1000676: `The new product association is successful`,
  key1000677: `Failed to add product association`,
  key1000678: `Modify product association successfully`,
  key1000679: `Failed to modify product association`,
  key1000680: `add parts`,
  key1000681: `preview`,
  key1000682: `Please enter the template name`,
  key1000683: `layout`,
  key1000684: `System notification`,
  key1000685: `Display the system's internal message notification`,
  key1000686: `Notify the store that is about to expire recently, you can click to renew`,
  key1000687: `sales analysis`,
  key1000688: `Platform/Store sales`,
  key1000689: `The sales data of the platform is counted by the platform or store as the dimension`,
  key1000690: `product analysis`,
  key1000691: `Listed products`,
  key1000692: `Display the total number of listed products in the system`,
  key1000693: `Best seller`,
  key1000694: `Displays the product or system item with the highest sales volume`,
  key1000695: `Best selling category`,
  key1000696: `Show Best SellersShow the top selling categories of my products`,
  key1000697: `pending matters`,
  key1000698: `Displays the number of orders that the order currently needs to process`,
  key1000699: `After-sales management`,
  key1000700: `Display the number of orders that need to be processed after sales`,
  key1000701: `Display the purchase orders that purchase currently needs to process`,
  key1000702: `Display the purchase orders that need to be processed by the current warehouse`,
  key1000703: `modify layout`,
  key1000704: `Store authorization`,
  key1000705: `After authorization, you can start to use EasySellers, enjoy fast publishing, order processing and many other functions!`,
  key1000706: `Add template`,
  key1000707: `default template`,
  key1000708: `Failed to query marketplace list`,
  key1000709: `Do you want to delete the current template?`,
  key1000710: `Failed to delete the template`,
  key1000711: `Part cannot be empty`,
  key1000712: `Modify template successfully`,
  key1000713: `Add template successfully`,
  key1000714: `Failed to modify template`,
  key1000715: `Failed to add template`,
  key1000716: `The current template has not been saved, do you want to give up saving the current template?`,
  key1000717: `Query template list failed`,
  key1000718: `Add up to 6 parts`,
  key1000719: `Template name cannot be empty`,
  key1000720: `Template name already exists`,
  key1000721: `Please enter the first code`,
  key1000722: `Please enter the suffix`,
  key1000723: `SKU obfuscation analysis function settings`,
  key1000724: `When publishing or synchronizing online products, automatically establish the matching relationship between the store SKU and the system inventory SKU according to the rules`,
  key1000725: `Only when the [Store SKU] is consistent with the [Inventory SKU], it will be automatically matched. After successful matching, it will not be matched by other interception rules`,
  key1000726: `Enable the custom SKU interception and matching function`,
  key1000727: `Start SKU of priority matching inventory`,
  key1000728: `When checked: inventory SKU matching is preferred. If it is matched, it will not be matched according to other rules. If it is not matched, it will be matched according to interception rules. If it is not matched again, it will be matched according to [Association between store SKU and inventory SKU]`,
  key1000729: `If it is not checked, it will be matched only according to the interception rules. After successful interception, it will be automatically matched. If it cannot be matched again, it will be matched according to [Association between Store SKU and Inventory SKU]`,
  key1000730: `Intercept by front and tail codes`,
  key1000731: `Intercept by digits`,
  key1000732: `Remove the first code`,
  key1000733: `remove suffix`,
  key1000734: `Remove the front X bit`,
  key1000735: `Remove the following X bits`,
  key1000736: `test case`,
  key1000737: `test`,
  key1000738: `Test Results:`,
  key1000739: `The test result is empty`,
  key1000740: `Failed to edit`,
  key1000741: `Query failed`,
  key1000742: `Role Name`,
  key1000743: `Please enter the role name.`,
  key1000744: `Authority`,
  key1000745: `Modules`,
  key1000746: `Select all`,
  key1000747: `Select all (`,
  key1000748: `Permission cannot be empty.`,
  key1000749: `Role name cannot be repeated.`,
  key1000750: `Receiver's mobile phone number`,
  key1000751: `Please enter the recipient's mobile number`,
  key1000752: `Send time`,
  key1000753: `Recipient area code`,
  key1000754: `Policy name`,
  key1000755: `SMS expenditure`,
  key1000756: `Number of SMS splits`,
  key1000757: `Last update time`,
  key1000758: `Resend`,
  key1000759: `failed`,
  key1000760: `successfully`,
  key1000761: `SMS Template`,
  key1000762: `Sending record`,
  key1000763: `Please select a text message to resend`,
  key1000764: `Resend operation succeeded`,
  key1000765: `Payment method`,
  key1000766: `Payment account number`,
  key1000767: `Service content`,
  key1000768: `Type`,
  key1000769: `Active purchase`,
  key1000770: `Automatic deduction`,
  key1000771: `Payment method used for purchase in easyseller`,
  key1000772: `Transaction list`,
  key1000773: `Message content`,
  key1000774: `Message type`,
  key1000775: `Station letter`,
  key1000776: `View details`,
  key1000777: `Error querying the list of messages in the station`,
  key1000778: `No related content found`,
  key1000779: `Release date:`,
  key1000780: `Failed to get the details of the in station message`,
  key1000781: `Recipient email`,
  key1000782: `Please enter the recipient email`,
  key1000783: `Email template name`,
  key1000784: `Reason for failure`,
  key1000785: `Email template`,
  key1000786: `Please select an email to resend`,
  key1000787: `Precedence`,
  key1000788: `Top`,
  key1000789: `Set to the bottom`,
  key1000790: `Drag and place`,
  key1000791: `Go up one level`,
  key1000792: `Go down one level`,
  key1000793: `Rule name`,
  key1000794: `Matched warehouse name`,
  key1000795: `Last modified time`,
  key1000796: `Match warehouse`,
  key1000797: `Add`,
  key1000798: `Optional conditions`,
  key1000799: `Platform shop`,
  key1000800: `Marketplace/Site/Shop`,
  key1000801: `country of destination`,
  key1000802: `Products in the order`,
  key1000803: `Order total amount`,
  key1000804: `Total weight of the order`,
  key1000805: `Buyers specify the shipping method`,
  key1000806: `Shipping warehouse`,
  key1000807: `Address contains keywords`,
  key1000808: `Customer service notes contain keywords`,
  key1000809: `Branch name`,
  key1000810: `Selected conditions`,
  key1000811: `Trigger action`,
  key1000812: `(The action takes effect when the above selected conditions are met)`,
  key1000813: `Please choose a shipping company`,
  key1000814: `Please choose the shipping method`,
  key1000815: `Please enter the rule name`,
  key1000816: `Add rule`,
  key1000817: `Failed to query the rule list`,
  key1000818: `Failed to check shipping method`,
  key1000819: `Whether to delete the rule`,
  key1000820: `Delete rule`,
  key1000821: `Delete rule successfully`,
  key1000822: `Failed to delete the rule`,
  key1000823: `The rule is saved successfully`,
  key1000824: `Failed to save rule`,
  key1000825: `Failed to query site list`,
  key1000826: `Failed to query list of the store`,
  key1000827: `Rule modification`,
  key1000828: `Failed to obtain rule details`,
  key1000829: `Change the sort order successfully`,
  key1000830: `Failed to sort the rule list`,
  key1000831: `Loading`,
  key1000832: `Trigger time`,
  key1000833: `The sending object is the mobile phone number in the customer information of the corresponding customer in the order.`,
  key1000834: `SMS template`,
  key1000835: `Phone area code`,
  key1000836: `Enable`,
  key1000837: `Disable`,
  key1000838: `Send SMS`,
  key1000839: `(Click to edit optional conditions)`,
  key1000840: `Does the order contain any product that requires a warranty`,
  key1000841: `(the same condition is “Or” relationship, while the different condition is "And" relation)`,
  key1000842: `When executing shipping of the order (shipped)`,
  key1000843: `When an offline order is imported and the status is "Shipped"`,
  key1000844: `During order replacement`,
  key1000845: `Update status succeeded`,
  key1000846: `Please enter the shipping account`,
  key1000847: `Match logistics channels`,
  key1000848: `Matching shipping company`,
  key1000849: `Match shipping method`,
  key1000850: `Shipping account`,
  key1000851: `Consignment method:`,
  key1000852: `The sending object is the Email in the customer information of the corresponding customer in the order`,
  key1000853: `Email template`,
  key1000854: `Send email`,
  key1000855: `Staff`,
  key1000856: `Restaurant name`,
  key1000857: `Watermark content`,
  key1000858: `Creation time`,
  key1000859: `Fixed price`,
  key1000860: `Auction`,
  key1000861: `Order No`,
  key1000862: `Selected stores`,
  key1000863: `SMS recharge method`,
  key1000864: `Recharge amount`,
  key1000865: `Custom Input`,
  key1000866: `Fixed area`,
  key1000867: `Number of SMS`,
  key1000868: `Package content`,
  key1000869: `Purchase duration`,
  key1000870: `Connect to your PayPal account and use it to pay your bills. You will be redirected to PayPal to add payment information`,
  key1000871: `Error prompt`,
  key1000872: `SMS amount recharge`,
  key1000873: `SMS recharge in fixed areas`,
  key1000874: `Custom`,
  key1000875: `Article`,
  key1000876: `month`,
  key1000877: `Calculating…`,
  key1000878: `Currently bound account:`,
  key1000879: `Pay immediately`,
  key1000880: `Cancel payment`,
  key1000881: `Return to platform list`,
  key1000882: `The selected data can be discounted`,
  key1000883: `Can`,
  key1000884: `cel`,
  key1000885: `Con`,
  key1000886: `firm`,
  key1000887: `The number of SMS messages can only be a multiple of 100`,
  key1000888: `fracture`,
  key1000889: `Failed to query the region of the SMS`,
  key1000890: `Payment order does not exist or has expired`,
  key1000891: `Failed to get payment order details`,
  key1000892: `Half a month`,
  key1000893: `Months`,
  key1000894: `1 quarter`,
  key1000895: `Failed to create payment, please try again`,
  key1000896: `Exit the current page`,
  key1000897: `Failed to obtain payment channel`,
  key1000898: `Illegal parameter`,
  key1000899: `This list shows the sales platforms supported by the system. Custom platforms can be added. Custom platforms start with 'C-'`,
  key1000900: `Set automatic renewal`,
  key1000901: `Bulk purchase`,
  key1000902: `Batch renewal`,
  key1000903: `授權`,
  key1000904: `Please select the renewal period`,
  key1000905: `Monthly renewal`,
  key1000906: `Annual renewal`,
  key1000907: `Failed to query the number of accounts in the platform list`,
  key1000908: `Failed to query store details`,
  key1000909: `Duplicate name`,
  key1000910: `Save failed`,
  key1000911: `Failed to get the store list`,
  key1000912: `Authorization is in progress and the page will jump soon`,
  key1000913: `Authorization failed, please try again later`,
  key1000914: `EBay authorization failed, please try again later`,
  key1000915: `After the platform shop is deleted, it cannot be restored. All orders for this shop will be deleted. Please make a careful decision.`,
  key1000916: `This store account is a free store account. After deletion, you will no longer enjoy the rights and interests of this free store. Please delete it carefully`,
  key1000917: `Delete shop`,
  key1000918: `Sync successfully`,
  key1000919: `Failed to synchronize coupan address`,
  key1000920: `Failed to get site list`,
  key1000921: `Account ID or Shop name already exists`,
  key1000922: `The shop already exists.`,
  key1000923: `Failed to modify the store`,
  key1000924: `Failed to add a store`,
  key1000925: `Query site failed`,
  key1000926: `Delete or not`,
  key1000927: `Remove platform`,
  key1000928: `There is a store under the platform, please delete it before proceeding`,
  key1000929: `Failed to delete platform`,
  key1000930: `Please select a store to purchase`,
  key1000931: `Failed to create payment order`,
  key1000932: `Failed to activate automatic renewal`,
  key1000933: `Are you sure to cancel automatic renewal? After cancellation, the service deadline will not be deducted`,
  key1000934: `Closed successfully!`,
  key1000935: `Failed to turn off automatic renewal`,
  key1000936: `Site`,
  key1000937: `Please select a site.`,
  key1000938: `Shipping method`,
  key1000939: `Please enter the email title`,
  key1000940: `Upload files`,
  key1000941: `Drag a word, pdf, excel or ppt file here, or`,
  key1000942: `Upload up to 5 attachments`,
  key1000943: `Modify Email template`,
  key1000944: `Add Email template`,
  key1000945: `Please enter body content...`,
  key1000946: `Please enter account ID.`,
  key1000947: `Please enter account number ID`,
  key1000948: `Please enter accessKey`,
  key1000949: `Please enter secretKey`,
  key1000950: `Please enter the Seller ApiKey`,
  key1000951: `Please enter User ID`,
  key1000952: `Please enter password.`,
  key1000953: `Platform`,
  key1000954: `Shop name`,
  key1000955: `Fill in the first code of the name of the shopline store function variable, such as: zhangsan. myshopline

The shop name of www.zhangsan.com is Zhangsan“`,
  key1000956: `Account ID`,
  key1000957: `Account ID`,
  key1000958: `Sales Area`,
  key1000959: `Site`,
  key1000960: `Shop LOGO`,
  key1000961: `Validity period`,
  key1000962: `Password`,
  key1000963: `East America`,
  key1000964: `Western America`,
  key1000965: `Europe`,
  key1000966: `Select files`,
  key1000967: `Please choose a platform.`,
  key1000968: `Please enter the shop name`,
  key1000969: `Add a shop`,
  key1000970: `Edit shop`,
  key1000971: `Please select the sales area`,
  key1000972: `This option cannot be empty`,
  key1000973: `The system has integrated`,
  key1000974: `Shipment tracking URL`,
  key1000975: `Customer code`,
  key1000976: `Key (Token)`,
  key1000977: `Parcel collection point`,
  key1000978: `Please enter the code.`,
  key1000979: `Sender name`,
  key1000980: `Sender's phone number`,
  key1000981: `Sender address`,
  key1000982: `Sender's city`,
  key1000983: `Sender's Province/Continent`,
  key1000984: `Sender country`,
  key1000985: `Shipping method Name`,
  key1000986: `The actual shipping method that you bind.`,
  key1000987: `COD currency and exchange rate`,
  key1000988: `Add a shipping method`,
  key1000989: `Code`,
  key1000990: `Name`,
  key1000991: `Add new account`,
  key1000992: `Add the shipping method of eBay to bind`,
  key1000993: `Site:`,
  key1000994: `Add a carrier`,
  key1000995: `Please enter the name of the shipping company`,
  key1000996: `Carrier`,
  key1000997: `API concatenation`,
  key1000998: `Not concatenated`,
  key1000999: `Carrier name`,
  key1001000: `Add account`,
  key1001001: `Relative USD exchange rate`,
  key1001002: `Name of shipping company:`,
  key1001003: `Delete current account`,
  key1001004: `Site cannot be empty.`,
  key1001005: `Shipping method cannot be empty.`,
  key1001006: `Code cannot be empty.`,
  key1001007: `Name cannot be empty.`,
  key1001008: `The shipping company account has already existed`,
  key1001009: `The name of the shipping company is illegal and cannot exist#`,
  key1001010: `The account name of the shipping company is illegal and cannot be saved#`,
  key1001011: `Successfully deleted`,
  key1001012: `Failed to delete`,
  key1001013: `Failed to modify status`,
  key1001014: `Failed to delete`,
  key1001015: `The account does not exist.`,
  key1001016: `Operation failed`,
  key1001017: `Failed to request Ebay site`,
  key1001018: `Interface query failed`,
  key1001019: `Edit carrier`,
  key1001020: `Duplicate carrier name`,
  key1001021: `Edit shipping method name`,
  key1001022: `Fail to revise`,
  key1001023: `Failed to obtain the warehouse list`,
  key1001024: `Repeated shipping method`,
  key1001025: `Query failed xx`,
  key1001026: `Whether to disable the shipping company`,
  key1001027: `Enabled successfully`,
  key1001028: `Failed to query the list of shipping companies`,
  key1001029: `Add role`,
  key1001030: `SMS signature`,
  key1001031: `API authorization status`,
  key1001032: `Marketing authorization status`,
  key1001033: `eBay managed payments`,
  key1001034: `Expiration time`,
  key1001035: `Free users`,
  key1001036: `Disabled`,
  key1001037: `Enabled`,
  key1001038: `Reauthorize`,
  key1001039: `Marketing authorization`,
  key1001040: `Synchronous Return Address`,
  key1001041: `Sync logistics and payment methods`,
  key1001042: `Cancel automatic renewal`,
  key1001043: `Please enter the branch name`,
  key1001044: `Customer service notes contain keywords:`,
  key1001045: `The name of the branch is`,
  key1001046: `The shipping method specified by customers is`,
  key1001047: `Yes`,
  key1001048: `No`,
  key1001049: `Does it contain any product that requires a warranty`,
  key1001050: `The keywords of the address key are`,
  key1001051: `The order amount is`,
  key1001052: `Currency is`,
  key1001053: `The total order weight is`,
  key1001054: `The shipping warehouse is`,
  key1001055: `The marketplace is`,
  key1001056: `The site is`,
  key1001057: `The shop is`,
  key1001058: `The country of destination is`,
  key1001059: `Platform/Channel Name`,
  key1001060: `Please enter a custom platform/channel name`,
  key1001061: `Add custom platform`,
  key1001062: `Please enter the address keywords, separated by English characters`,
  key1001063: `You can only add up to five address keywords`,
  key1001064: `6/F, Building C1, Nanshan Zhiyuan, Nanshan District, Shenzhen, Guangdong Province`,
  key1001065: `Description`,
  key1001066: `Quantity`,
  key1001067: `VAT rate`,
  key1001068: `Unit price`,
  key1001069: `contain`,
  key1001070: `VAT`,
  key1001071: `801-Hera Classic Series Eggshell White Noodles`,
  key1001072: `Total price`,
  key1001073: `Thank you for your order`,
  key1001074: `Address 1`,
  key1001075: `Please enter address 1`,
  key1001076: `Please enter the address`,
  key1001077: `Seller information`,
  key1001078: `Please enter company name`,
  key1001079: `Address 2`,
  key1001080: `Please enter address 2`,
  key1001081: `City`,
  key1001082: `Please enter the city`,
  key1001083: `Region/Country`,
  key1001084: `Please enter region/country`,
  key1001085: `Tax No`,
  key1001086: `Please enter the tax number`,
  key1001087: `Mobile number`,
  key1001088: `Please enter your mobile number`,
  key1001089: `Fax`,
  key1001090: `Please enter a fax`,
  key1001091: `Mailbox`,
  key1001092: `Please enter an email.`,
  key1001093: `supplier`,
  key1001094: `Please enter supplier`,
  key1001095: `Supplier address`,
  key1001096: `Please enter the supplier address`,
  key1001097: `Contact number:`,
  key1001098: `Invoice date`,
  key1001099: `Invoice No`,
  key1001100: `Invoice amount`,
  key1001101: `Customer information`,
  key1001102: `Contact number:`,
  key1001103: `Sender information`,
  key1001104: `Order information`,
  key1001105: `Order date:`,
  key1001106: `Invoice details`,
  key1001107: `(excluding VAT)`,
  key1001108: `(including VAT)`,
  key1001109: `Total price of single item`,
  key1001110: `Total invoice amount`,
  key1001111: `Total VAT`,
  key1001112: `SF Express`,
  key1001113: `ABC Store`,
  key1001114: `Title`,
  key1001115: `Total product price`,
  key1001116: `Please enter comments`,
  key1001117: `Invoice No.:`,
  key1001118: `Invoice date:`,
  key1001119: `Order No.:`,
  key1001120: `Ordering time:`,
  key1001121: `Company name:`,
  key1001122: `Company address:`,
  key1001123: `Tax No.:`,
  key1001124: `Mobile number:`,
  key1001125: `Fax:`,
  key1001126: `Email:`,
  key1001127: `Mailing address`,
  key1001128: `Buyer's name:`,
  key1001129: `Shipping address:`,
  key1001130: `Tel:`,
  key1001131: `Subtotal of goods:`,
  key1001132: `Subtotal after tax:`,
  key1001133: `Discount amount:`,
  key1001134: `Shipping fee:`,
  key1001135: `Order amount:`,
  key1001136: `Invoice remarks:`,
  key1001137: `Please enter the keywords of customer service notes, separated by English characters`,
  key1001138: `Clear`,
  key1001139: `The minimum order amount is not filled in, and the total order amount data will be cleared`,
  key1001140: `The maximum order amount is not filled in, and the total order amount data will be cleared`,
  key1001141: `The currency is not selected, and the total order amount data will be cleared`,
  key1001142: `The maximum order amount cannot be less than the minimum, and the total order amount data will be cleared`,
  key1001143: `Total order weight`,
  key1001144: `Total order weight (KG)`,
  key1001145: `The minimum total weight of the order is not filled in, and the total weight data of the order will be cleared`,
  key1001146: `The maximum total weight of the order is not filled in, and the total weight data of the order will be cleared`,
  key1001147: `The maximum value of the total order weight cannot be less than the minimum value, and the total weight data of the order will be cleared`,
  key1001148: `Operator`,
  key1001149: `The corresponding [Store name] in the order`,
  key1001150: `The corresponding [Customer name] in the order`,
  key1001151: `The corresponding [Order No.] in the order`,
  key1001152: `The corresponding [Product name] in the order`,
  key1001153: `Warranty Inquiry URL`,
  key1001154: `The following exchange rates are all against the US dollar, and the real-time exchange rate is the latest exchange rate of the day.`,
  key1001155: `Currency name`,
  key1001156: `Currency code`,
  key1001157: `Official exchange rate`,
  key1001158: `Exchange rate`,
  key1001159: `Exchange rate type`,
  key1001160: `Last update time`,
  key1001161: `Set exchange rate`,
  key1001162: `Fixed exchange rate`,
  key1001163: `Real Time Exchange Rates`,
  key1001164: `Exchange rate cannot be empty`,
  key1001165: `Failed to get employee list`,
  key1001166: `Please follow the prompts`,
  key1001167: `Authorization successful`,
  key1001168: `Service error`,
  key1001169: `Account or account code already exists`,
  key1001170: `Account does not exist`,
  key1001171: `Account number mismatch`,
  key1001172: `Authorization failed`,
  key1001173: `Parameter error`,
  key1001174: `Manage staff accounts, staff roles and permissions for each role here.`,
  key1001175: `Staff`,
  key1001176: `role`,
  key1001177: `Staff Name`,
  key1001178: `Role`,
  key1001179: `Deactivate/enable`,
  key1001180: `Role name`,
  key1001181: `Associated sub-account`,
  key1001182: `Administrator`,
  key1001183: `Disable`,
  key1001184: `Please fill in the correct email address.`,
  key1001185: `Failed to modify employee`,
  key1001186: `Get role list`,
  key1001187: `Add staff`,
  key1001188: `Edit role`,
  key1001189: `Edit staff`,
  key1001190: `Disable?`,
  key1001191: `Failed to deactivate employee`,
  key1001192: `Enable failed`,
  key1001193: `The role is in use and cannot be deleted.`,
  key1001194: `Failed to delete role`,
  key1001195: `Template type`,
  key1001196: `Login verification code`,
  key1001197: `Marketing`,
  key1001198: `Verify`,
  key1001199: `Warranty E-mail template`,
  key1001200: `After the authentication email is deleted, the email verification code cannot be sent, please delete it carefully!`,
  key1001201: `Whether to delete this template`,
  key1001202: `Delete template`,
  key1001203: `Template deleted successfully`,
  key1001204: `Watermark type`,
  key1001205: `My watermark`,
  key1001206: `Watermark layout`,
  key1001207: `Size adjustment`,
  key1001208: `Font type`,
  key1001209: `Font size`,
  key1001210: `Font color`,
  key1001211: `Watermark spacing`,
  key1001212: `Rotate watermark`,
  key1001213: `Transparency`,
  key1001214: `Watermark position`,
  key1001215: `Template information`,
  key1001216: `Picture watermark`,
  key1001217: `Text watermark`,
  key1001218: `Custom`,
  key1001219: `Tiled`,
  key1001220: `Bold`,
  key1001221: `Preview`,
  key1001222: `Microsoft Accor black`,
  key1001223: `Fangsong font`,
  key1001224: `Song typeface`,
  key1001225: `Boldface`,
  key1001226: `Italics`,
  key1001227: `Youyuan Font`,
  key1001228: `Stretch (border)`,
  key1001229: `Buy again`,
  key1001230: `Expiration time:`,
  key1001231: `Query error`,
  key1001232: `Set up your warehouse here and the warehouse used for shipment.`,
  key1001233: `Warehouse`,
  key1001234: `Disable/enable warehouse`,
  key1001235: `Province / State`,
  key1001236: `Country / Region`,
  key1001237: `Postal code`,
  key1001238: `Sender`,
  key1001239: `Warehouse name cannot be empty.`,
  key1001240: `Are you sure you want to disable this warehouse? Warehouses with orders, purchase orders and inventories cannot be disabled`,
  key1001241: `Are you sure you want to enable the warehouse? After enabling, you can select to the warehouse`,
  key1001242: `Warehouse in use, disabling failed`,
  key1001243: `Warehouses with orders, purchase orders or inventory cannot be deleted, please decide carefully.`,
  key1001244: `Delete warehouse`,
  key1001245: `The warehouse is in use and cannot be deleted.`,
  key1001246: `Change warehouse`,
  key1001247: `Warehouse name already exists.`,
  key1001248: `Add warehouse`,
  key1001249: `Template name:`,
  key1001250: `Language`,
  key1001251: `Consumption tax (VAT)`,
  key1001252: `Consumption tax rate`,
  key1001253: `Generate invoice number`,
  key1001254: `Invoice prefix`,
  key1001255: `Template configuration`,
  key1001256: `Formwork size:`,
  key1001257: `Buyer information`,
  key1001258: `Buyer's name`,
  key1001259: `Invoice information`,
  key1001260: `Order time`,
  key1001261: `Barcode`,
  key1001262: `logistics company`,
  key1001263: `Consignment cost`,
  key1001264: `Discount amount`,
  key1001265: `Invoice remarks`,
  key1001266: `Invoice type 1`,
  key1001267: `Invoice type 2`,
  key1001268: `Invoice type 3`,
  key1001269: `Invoice type 4`,
  key1001270: `No consumption tax`,
  key1001271: `Goods including consumption tax`,
  key1001272: `Goods do not include consumption tax`,
  key1001273: `Payment time`,
  key1001274: `Printing time`,
  key1001275: `The exported file can be downloaded in 7 days, and it is unable to download after the time.`,
  key1001276: `Please select a task type`,
  key1001277: `Please enter the URLs (use the Enter key to wrap a line, and up to 10 can be scraped at a time).`,
  key1001278: `Source URL`,
  key1001279: `Product title`,
  key1001280: `Title`,
  key1001281: `Sales price`,
  key1001282: `Platform`,
  key1001283: `Scrape date`,
  key1001284: `Scrape by link`,
  key1001285: `Support eBay`,
  key1001286: `Supported platform`,
  key1001287: `Download plugin`,
  key1001288: `Is the link from Shopify?`,
  key1001289: `Start to scrape`,
  key1001290: `Start scraping`,
  key1001291: `Claim`,
  key1001292: `products have been migrated successfully, please go to the draft box to view`,
  key1001293: `data were successfully collected`,
  key1001294: `In claiming`,
  key1001295: `In claiming`,
  key1001296: `Scraped information is empty.`,
  key1001297: `Supports up to 10 links, please delete`,
  key1001298: `eBay products data cannot be scraped with other platform's together`,
  key1001299: `In scraping data...`,
  key1001300: `Please install the All Way Collection plug-in to collect!`,
  key1001301: `Click to view the installation tutorial`,
  key1001302: `Please select the scraped data to delete`,
  key1001303: `Whether to delete scraped data`,
  key1001304: `Delete scraped data`,
  key1001305: `Active`,
  key1001306: `Archive`,
  key1001307: `Active`,
  key1001308: `Ended`,
  key1001309: `Deleted`,
  key1001310: `Active`,
  key1001311: `Inactive`,
  key1001312: `The products are being migrated, please go to the draft box to view`,
  key1001313: `Please choose a value`,
  key1001314: `Original price`,
  key1001315: `Inventory`,
  key1001316: `Migrate`,
  key1001317: `Please select a site`,
  key1001318: `Choose a shop`,
  key1001319: `Select all stores`,
  key1001320: `Do you recommend motors category first`,
  key1001321: `Copying to eBay, you can close the window and it will proceed in the background. Progress:`,
  key1001322: `Product migration`,
  key1001323: `Please select a shop.`,
  key1001324: `Please enter the keywords to search.`,
  key1001325: `類目映射規則`,
  key1001326: `Attribute replacement rules`,
  key1001327: `Task center`,
  key1001328: `Bulk migrate`,
  key1001329: `-規格名稱`,
  key1001330: `-規格值類型`,
  key1001331: `-類目屬性`,
  key1001332: `數據加載中...`,
  key1001333: `建議類目選擇`,
  key1001334: `映射平台屬性名稱`,
  key1001335: `映射平台規格值類型`,
  key1001336: `系統匹配中`,
  key1001337: `系統匹配已完成`,
  key1001338: `映射平台：`,
  key1001339: `映射平台站點：`,
  key1001340: `建議類目：`,
  key1001341: `應用`,
  key1001342: `Selected:`,
  key1001343: `商品規格映射：`,
  key1001344: `系統建議匹配`,
  key1001345: `匹配狀態：`,
  key1001346: `已匹配規格：`,
  key1001347: `已匹配規格值：`,
  key1001348: `添加屬性值`,
  key1001349: `商品屬性映射：`,
  key1001350: `匹配狀態:`,
  key1001351: `已匹配屬性：`,
  key1001352: `已匹配屬性值：`,
  key1001353: `Add custom attributes`,
  key1001354: `新增映射`,
  key1001355: `Update completed`,
  key1001356: `Successfully added`,
  key1001357: `修改映射`,
  key1001358: `已映射`,
  key1001359: `(已映射)`,
  key1001360: `文本框`,
  key1001361: `單選可定義值`,
  key1001362: `單選`,
  key1001363: `多選`,
  key1001364: `多選可定義值`,
  key1001365: `Property Name`,
  key1001366: `請輸入屬性名稱`,
  key1001367: `屬性值`,
  key1001368: `Please enter the attribute value.`,
  key1001369: `前後不能有空格`,
  key1001370: `新增自定義屬性`,
  key1001371: `新增自定義屬性值`,
  key1001372: `屬性名稱重複`,
  key1001373: `系統檢測到重複屬性值，已自動去重`,
  key1001374: `屬性值重複`,
  key1001375: `系统檢測到重複屬性值，已自動去重`,
  key1001376: `Multi-Variation`,
  key1001377: `输入关键字过滤`,
  key1001378: `映射平台`,
  key1001379: `映射平台站點`,
  key1001380: `映射平台類目`,
  key1001381: `原平台類目`,
  key1001382: `(已選)`,
  key1001383: `平台類目映射`,
  key1001384: `Failed to get Lazada category data`,
  key1001385: `請選擇要刪除的映射`,
  key1001386: `確認刪除？刪除後系統將清除類目的映射關系。`,
  key1001387: `刪除提示`,
  key1001388: `获取类目映射列表失败`,
  key1001389: `Please enter attributes.`,
  key1001390: `Set up mapping`,
  key1001391: `Item attributes:`,
  key1001392: `Custom attributes`,
  key1001393: `Show all`,
  key1001394: `Please fill in the Attribute value`,
  key1001395: `You have not selected the mapping attribute.`,
  key1001396: `There is no same value. It cannot be mapped.`,
  key1001397: `Please select attributes.`,
  key1001398: `Please select attributes (support custom attributes).`,
  key1001399: `Please select attributes (support selecting multiple selections).`,
  key1001400: `Please select attributes (support multiple selections and entering custom attributes).`,
  key1001401: `The attribute you added already exists.`,
  key1001402: `Save as a compatible list`,
  key1001403: `Template name`,
  key1001404: `Save failed, the same name exists`,
  key1001405: `Excluded shipping locations`,
  key1001406: `More`,
  key1001407: `Custom attributes`,
  key1001408: `Attribute name`,
  key1001409: `Attribute value`,
  key1001410: `Please enter the attribute name.`,
  key1001411: `Apply component templates`,
  key1001412: `Apply`,
  key1001413: `Text translation`,
  key1001414: `Source language`,
  key1001415: `Automatic`,
  key1001416: `Packing`,
  key1001417: `Russian`,
  key1001418: `Spanish`,
  key1001419: `French`,
  key1001420: `Portuguese`,
  key1001421: `German`,
  key1001422: `Italian`,
  key1001423: `Simplified Chinese`,
  key1001424: `Thai`,
  key1001425: `Arabic`,
  key1001426: `Japanese`,
  key1001427: `Vietnamese`,
  key1001428: `Indonesian`,
  key1001429: `Malay`,
  key1001430: `Please select the target language`,
  key1001431: `The content of the translation still exists.`,
  key1001432: `Upload picture URL`,
  key1001433: `Please enter the picture URLs, please use "Enter" to wrap when there are multiple picture URLs`,
  key1001434: `Picture URL is empty`,
  key1001435: `Picture grouping`,
  key1001436: `Picture name.xxx`,
  key1001437: `Level 1`,
  key1001438: `Level 2 1-1`,
  key1001439: `Level 3 1-1-1`,
  key1001440: `Level 2`,
  key1001441: `Level 2 2-1`,
  key1001442: `Level 3 2-1-1`,
  key1001443: `Level 2 2-2`,
  key1001444: `Level 3 2-2-1`,
  key1001445: `Level 3`,
  key1001446: `Level 2  3-1`,
  key1001447: `Level 3 3-1-1`,
  key1001448: `Level 2 3-2`,
  key1001449: `Level 3 3-2-1`,
  key1001450: `Select`,
  key1001451: `Please tick the data to be deleted first`,
  key1001452: `Deleting`,
  key1001453: `Failed to pass the content verification`,
  key1001454: `Saved successfully, the item location list is`,
  key1001455: `Sync policy`,
  key1001456: `Data is being synced, please check later`,
  key1001457: `Confirm to save changes`,
  key1001458: `Modifying the content of the template will simultaneously change the policy content of all online products that have used the policy`,
  key1001459: `Please select the number of days for domestic refunds and returns`,
  key1001460: `Please choose one to bear the  domestic refund and return costs`,
  key1001461: `Please select the number of days for international refunds and returns`,
  key1001462: `Please choose one to bear the international refund and return costs`,
  key1001463: `Saved successfully, it will jump to the return policy list`,
  key1001464: `Saved successfully, it will jump to the return policy list`,
  key1001465: `Offering a minimum 30-day return policy helps your listings qualify for Top Rated Seller Benefits.`,
  key1001466: `Return policy`,
  key1001467: `After receiving the item, your buyer should contact you within`,
  key1001468: `Return shipping will be paid by`,
  key1001469: `Domestic returns accepted`,
  key1001470: `International returns accepted`,
  key1001471: `Country or region`,
  key1001472: `Item location`,
  key1001473: `Ship to`,
  key1001474: `Please enter the shipping area`,
  key1001475: `Domestic shipping`,
  key1001476: `International`,
  key1001477: `Please choose logistics`,
  key1001478: `Shipping fee`,
  key1001479: `Shipping fee cannot be empty`,
  key1001480: `Shipping fee for one more`,
  key1001481: `Shipping fee for one more can not be empty`,
  key1001482: `Free shipping`,
  key1001483: `Delete current logistics`,
  key1001484: `The shipping fee for one more cannot be higher than the shipping fee`,
  key1001485: `Save as`,
  key1001486: `Please select at least one payment method`,
  key1001487: `Electronic payment method`,
  key1001488: `Your email address of PayPAl account (used for receiving payments)`,
  key1001489: `Other payment methods`,
  key1001490: `Payment policy`,
  key1001491: `If the managed payments of the current shop has been opened, please go to`,
  key1001492: `Shop authorization management`,
  key1001493: `, refresh the status of managed payments`,
  key1001494: `Receiving account`,
  key1001495: `Requires immediate payment when buyers use Buy It Now`,
  key1001496: `Merchant credit card`,
  key1001497: `Please select the handling time`,
  key1001498: `The handling fee cannot be empty`,
  key1001499: `Offering same-day or 1-day handling time helps your listing qualify for Top Rated Seller Benefits.`,
  key1001500: `Domestic shipping`,
  key1001501: `Handling time`,
  key1001502: `Handling cost - Calculated shipping only`,
  key1001503: `Domestic shipping fee table`,
  key1001504: `International shipping`,
  key1001505: `Providing international transport may make your product information visible on other eBay sites and available to international buyers`,
  key1001506: `Additional ship to locations - buyers contact for costs`,
  key1001507: `International shipping fee table`,
  key1001508: `Zones not covered`,
  key1001509: `Shipping policy`,
  key1001510: `Add domestic shipping`,
  key1001511: `Sync`,
  key1001512: `Use global shipping plan`,
  key1001513: `Add international shipping`,
  key1001514: `Average: the same fee per buyer`,
  key1001515: `Domestic shipping type`,
  key1001516: `Calculation: Fees vary based on buyer location`,
  key1001517: `International logistics type`,
  key1001518: `Non-delivery: local pickup only`,
  key1001519: `Shipping: Large items over 150 pounds`,
  key1001520: `Please select a shop first`,
  key1001521: `Please select a site first`,
  key1001522: `The shipping fee table is being synchronized`,
  key1001523: `Shipping fee table synced successfully`,
  key1001524: `Store cannot be empty`,
  key1001525: `Policy description`,
  key1001526: `Set as default template`,
  key1001527: `Basic`,
  key1001528: `Not ship to:`,
  key1001529: `Cancel all`,
  key1001530: `Domestic:`,
  key1001531: `International:`,
  key1001532: `[Hide all countries/regions]`,
  key1001533: `Other:`,
  key1001534: `You must select at least one payment method`,
  key1001535: `After ticking PayPal, you must fill in the email address of the payment account`,
  key1001536: `Saved successfully, it will jump to the payment policy list`,
  key1001537: `Saved successfully, it will jump to the payment policy list`,
  key1001538: `Saved successfully, it will jump to the shipping policy list`,
  key1001539: `Saved successfully, it will jump to the shipping policy list`,
  key1001540: `From：`,
  key1001541: `Store & Category`,
  key1001542: `Item specifics`,
  key1001543: `Variation information`,
  key1001544: `Picture information`,
  key1001545: `Selling details`,
  key1001546: `Business policies`,
  key1001547: `Shipping details`,
  key1001548: `Descriptions`,
  key1001549: `Data processing`,
  key1001550: `No relevant data found`,
  key1001551: `Please select a category first`,
  key1001552: `Setting succeeded`,
  key1001553: `Failed to add watermark`,
  key1001554: `Please add a product image first`,
  key1001555: `Revising`,
  key1001556: `Type`,
  key1001557: `Key words`,
  key1001558: `Please enter fuzzy search`,
  key1001559: `Starting price`,
  key1001560: `Fixed price`,
  key1001561: `Source URL`,
  key1001562: `Synchronize products`,
  key1001563: `Sync all products`,
  key1001564: `Sync selected products`,
  key1001565: `Add watermarks`,
  key1001566: `Publish date:`,
  key1001567: `Last modified:`,
  key1001568: `Please select the product to add watermark`,
  key1001569: `Please select the products to migrate.`,
  key1001570: `Please select the product to be synchronized`,
  key1001571: `Data is being synchronized, please check later`,
  key1001572: `Shop:`,
  key1001573: `Module:`,
  key1001574: `Shipping discount:`,
  key1001575: `Domestic shipping`,
  key1001576: `Shipping fee`,
  key1001577: `Shipping fee for one more`,
  key1001578: `Other payment:`,
  key1001579: `Notes:`,
  key1001580: `Accepted`,
  key1001581: `Within`,
  key1001582: `Shipping cost paid by`,
  key1001583: `Refund option`,
  key1001584: `International:`,
  key1001585: `Description:`,
  key1001586: `Logistics`,
  key1001587: `Remove module`,
  key1001588: `Use the standard shipping discount settings of eBay account (Flat`,
  key1001589: `Use eBay account to promote shipping discount settings (Promotional`,
  key1001590: `Add domestic shipping`,
  key1001591: `Add`,
  key1001592: `Buyer requirements`,
  key1001593: `The following customers are not allowed to buy my products.`,
  key1001594: `The shipping address is outside my shipping range.`,
  key1001595: `Have received`,
  key1001596: `cases of abandonment, in the past`,
  key1001597: `In the past 10 days, the number of bids or purchases of products has reached the limit I set`,
  key1001598: `This restriction is only available when the customer's credit index is equal to or lower than`,
  key1001599: `Paypal:`,
  key1001600: `Return policy`,
  key1001601: `Domestic:`,
  key1001602: `Please select a shipping discount`,
  key1001603: `Please choose domestic shipping`,
  key1001604: `Please choose international logistics`,
  key1001605: `Please choose shipTo`,
  key1001606: `Please select the MSRP`,
  key1001607: `Please select the limit`,
  key1001608: `Please select an indicator`,
  key1001609: `Please select a payment account.`,
  key1001610: `Please select a return mode.`,
  key1001611: `Please select the returns within option.`,
  key1001612: `Please select returns shipping cost paid by`,
  key1001613: `Please select the refund option`,
  key1001614: `Shipping policy`,
  key1001615: `Buyer requirements`,
  key1001616: `Return policy`,
  key1001617: `Exclude ship to locations`,
  key1001618: `View compatibility list`,
  key1001619: `#`,
  key1001620: `View`,
  key1001621: `Template name:`,
  key1001622: `Site:`,
  key1001623: `English`,
  key1001624: `Text translation is charged by [character], and your current amount of text translation is`,
  key1001625: `, please go to`,
  key1001626: `[Value added resource package]`,
  key1001627: `Purchase`,
  key1001628: `The number of available resources is insufficient. Please go to`,
  key1001629: `Translation failed:`,
  key1001630: `Successfully translated`,
  key1001631: `Packing info`,
  key1001632: `Select an item location template`,
  key1001633: `Packing rule`,
  key1001634: `Type of packing`,
  key1001635: `Irregular package`,
  key1001636: `Package size`,
  key1001637: `Package weight`,
  key1001638: `Select a template`,
  key1001639: `Create template`,
  key1001640: `Please select a shop and site first`,
  key1001641: `Please select the item location template`,
  key1001642: `The operation failed. The data of the selected template is incomplete, please select another template.`,
  key1001643: `Subtitle ($6.00)`,
  key1001644: `Form of sale`,
  key1001645: `Fixed price type`,
  key1001646: `Product condition`,
  key1001647: `Product condition description`,
  key1001648: `Translate`,
  key1001649: `When the form of sale is an auction or a single product with fixed price, it is the SKU of the product.`,
  key1001650: `When the form of sale is an entity, it is the parent SKU of the product, which can be used as a prefix for generating variation SKUs`,
  key1001651: `Fixed price`,
  key1001652: `Auction`,
  key1001653: `Single product`,
  key1001654: `Variations`,
  key1001655: `Shipping policy`,
  key1001656: `Business policies`,
  key1001657: `Loading`,
  key1001658: `Create`,
  key1001659: `Select all sites`,
  key1001660: `Select all`,
  key1001661: `Picture translation`,
  key1001662: `Picture translation result`,
  key1001663: `Click for the larger image`,
  key1001664: `After selecting the pictures, click "Confirm" to set`,
  key1001665: `Simplified Chinese`,
  key1001666: `English`,
  key1001667: `Pictures selected`,
  key1001668: `Translated picture`,
  key1001669: `Original picture`,
  key1001670: `Conversion in progress:`,
  key1001671: `Translate`,
  key1001672: `Confirm to modify`,
  key1001673: `Image translation is charged by [times], and the current amount of image translation is`,
  key1001674: `Please select the pictures to be translated`,
  key1001675: `Stock Quantity:`,
  key1001676: `Bulk Inventory Modification`,
  key1001677: `Please select the target platform attribute`,
  key1001678: `Required`,
  key1001679: `Attribute replacement`,
  key1001680: `Original platform attribute`,
  key1001681: `Original platform`,
  key1001682: `Target platform category`,
  key1001683: `Target platform attribute`,
  key1001684: `Recommended`,
  key1001685: `Buyers often search for these attributes`,
  key1001686: `Additional`,
  key1001687: `Buyers may also be interested in these`,
  key1001688: `There is an attribute with the same name, please re-enter`,
  key1001689: `The attribute name cannot be empty`,
  key1001690: `Failed to query the attribute list corresponding to the migration`,
  key1001691: `Please select a category`,
  key1001692: `Saved the mapping list successfully`,
  key1001693: `Failed to save the mapping list`,
  key1001694: `data loading`,
  key1001695: `Please enter a recommended category name`,
  key1001696: `Recommended category`,
  key1001697: `Recommended category`,
  key1001698: `One-click translation`,
  key1001699: `Save to draft box`,
  key1001700: `Publish`,
  key1001701: `Update listing`,
  key1001702: `Use default photos`,
  key1001703: `The attribute picture cannot be empty`,
  key1001704: `Product picture`,
  key1001705: `It requires that at least one side of the picture is larger than 500 pixels. The first picture is the main picture, and you can drag the picture to adjust the order`,
  key1001706: `Variation picture`,
  key1001707: `At least one side should be larger than 500 pixels`,
  key1001708: `Online picture URL`,
  key1001709: `Local Upload`,
  key1001710: `Picture editing`,
  key1001711: `Size of the picture`,
  key1001712: `White background`,
  key1001713: `Picture type`,
  key1001714: `For the current column, you can upload up to`,
  key1001715: `pictures, you can upload`,
  key1001716: `pictures`,
  key1001717: `Upload failed`,
  key1001718: `Pictures of this type cannot be uploaded for more than 12`,
  key1001719: `Pictures only support JPG, JPEG, PNG, WEBP format!`,
  key1001720: `The picture size cannot exceed 5MB!`,
  key1001721: `In picture processing`,
  key1001722: `SKU cannot be empty.`,
  key1001723: `The length cannot exceed 50 characters`,
  key1001724: `Price(`,
  key1001725: `Price cannot be empty`,
  key1001726: `Quantity cannot be empty`,
  key1001727: `Variation attributes`,
  key1001728: `Custom attribute name`,
  key1001729: `Please select a variation attribute`,
  key1001730: `Variation combination`,
  key1001731: `Bulk generate SKU`,
  key1001732: `Bulk generate price`,
  key1001733: `Bulk enter prices`,
  key1001734: `Bulk generate quantity`,
  key1001735: `Bulk enter inventory`,
  key1001736: `Please complete the variation combination`,
  key1001737: `Please enter a custom attribute name`,
  key1001738: `Attribute values of variations ​​cannot exceed 30`,
  key1001739: `Please enter the image width`,
  key1001740: `Existing picture size`,
  key1001741: `Please enter the size to be modified`,
  key1001742: `Bulk set up size`,
  key1001743: `Custom size`,
  key1001744: `Please select the picture to be resized`,
  key1001745: `Attribute name, such as Year`,
  key1001746: `Attribute value, e.g. 2018`,
  key1001747: `Replace with`,
  key1001748: `Result of white background pictures`,
  key1001749: `White background picture`,
  key1001750: `Convert white background`,
  key1001751: `Please select the pictures to be converted into white background`,
  key1001752: `Please enter the starting price`,
  key1001753: `Please enter a number`,
  key1001754: `Please enter inventory.`,
  key1001755: `Duration`,
  key1001756: `Break-even price`,
  key1001757: `Price`,
  key1001758: `Quantity`,
  key1001759: `Accept buyer's bargaining`,
  key1001760: `Private listing`,
  key1001761: `Sale tax`,
  key1001762: `VAT tax rate`,
  key1001763: `Bulk sale`,
  key1001764: `Wholesale quantity`,
  key1001765: `Turn on`,
  key1001766: `Automatically accept greater than`,
  key1001767: `Automatically reject less than`,
  key1001768: `Allow buyers to remain anonymous to other eBay users`,
  key1001769: `Also apply to shipping and handling costs`,
  key1001770: `Product category`,
  key1001771: `Please enter the category name.`,
  key1001772: `Update current category`,
  key1001773: `Recommended category`,
  key1001774: `To publish`,
  key1001775: `Failed to publish`,
  key1001776: `Publishing`,
  key1001777: `Draft NO.`,
  key1001778: `Modify`,
  key1001779: `Add products`,
  key1001780: `Bulk publish`,
  key1001781: `Bulk translation`,
  key1001782: `Bulk modify quantity`,
  key1001783: `Created:`,
  key1001784: `Publish`,
  key1001785: `Please select drafts to publish`,
  key1001786: `Processing, please check the result later`,
  key1001787: `Please select a draft to translate`,
  key1001788: `Please select a draft to edit inventory`,
  key1001789: `Please select a country or region`,
  key1001790: `Please upload product pictures`,
  key1001791: `Please enter a product title`,
  key1001792: `Title length cannot exceed 255 characters`,
  key1001793: `Please enter description information`,
  key1001794: `Please enter the item title`,
  key1001795: `Please select a product category attribute`,
  key1001796: `Please enter the brand`,
  key1001797: `Brand length cannot exceed 100 characters`,
  key1001798: `Please select the warehouse location`,
  key1001799: `Please select express company`,
  key1001800: `Please select the delivery method`,
  key1001801: `Please select the type of delivery freight`,
  key1001802: `Please select the place of return/exchange`,
  key1001803: `Please enter the initial delivery fee (one way)`,
  key1001804: `Please enter the return freight (one way)`,
  key1001805: `Please enter the title.`,
  key1001806: `Title length cannot exceed 80 characters`,
  key1001807: `The length of the subtitle cannot exceed 50 characters`,
  key1001808: `Please enter SKU.`,
  key1001809: `SKU length cannot exceed 50 characters`,
  key1001810: `Please choose the form of sale`,
  key1001811: `Please select the item status`,
  key1001812: `Please select a product category`,
  key1001813: `Please select the number of days of publication`,
  key1001814: `Please select a country or region`,
  key1001815: `Please select a payment policy`,
  key1001816: `Please select a shipping policy`,
  key1001817: `Please select a return policy`,
  key1001818: `Please select product type`,
  key1001819: `Choose at least one mode of logistics`,
  key1001820: `Please upload variant pictures`,
  key1001821: `Please complete the wholesale price`,
  key1001822: `Please enter packing weight`,
  key1001823: `Please enter the attributes of the original marketplace or the target marketplace`,
  key1001824: `1. Attribute replacement rules are automatically applied during products migration 2. Modifying or deleting rules does not affect products that have already migrated`,
  key1001825: `Target platform`,
  key1001826: `Failed to load list, please try again`,
  key1001827: `Failed to delete, please try again`,
  key1001828: `Publish`,
  key1001829: `Please select products to batch publish`,
  key1001830: `Whether to batch release selected products`,
  key1001831: `The operation is successful, the data is being processed, please wait for the query`,
  key1001832: `Please select a product to delete`,
  key1001833: `Whether to delete draft data`,
  key1001834: `Sync products`,
  key1001835: `Sync selected`,
  key1001836: `Sync all`,
  key1001837: `Delist`,
  key1001838: `on the shelf`,
  key1001839: `The operation is successful, and the data is being synchronized in the background. You can view the results in the task center.`,
  key1001840: `Please select products to sync`,
  key1001841: `Whether to delete published online data`,
  key1001842: `Please select a product to remove`,
  key1001843: `Whether to remove the current data`,
  key1001844: `The operation was successful, please wait to check the data`,
  key1001845: `Please select publication data`,
  key1001846: `Whether to re-list the current data`,
  key1001847: `Whether to remove the current listing data`,
  key1001848: `Removed successfully`,
  key1001849: `Category attribute`,
  key1001850: `This is the main image of your product page. You can upload multiple images at the same time. 
Maximum 8 images. Dimensions are between 330x330 and 5000x5000. File sizes do not exceed 5mb.
You can adjust the order by dragging pictures`,
  key1001851: `Up to 8 images, minimum 330px`,
  key1001852: `The operation has been submitted successfully, please publish online on Lazada to view the submission results`,
  key1001853: `Failed to get Lazada details data`,
  key1001854: `long description`,
  key1001855: `show more`,
  key1001856: `product description`,
  key1001857: `Highlight description`,
  key1001858: `shrink`,
  key1001859: `Please enter the Video URL`,
  key1001860: `Source URL`,
  key1001861: `Please enter the source URL`,
  key1001862: `Warranty type`,
  key1001863: `Please select a warranty type`,
  key1001864: `Warranty period`,
  key1001865: `Warranty period`,
  key1001866: `Picture size must not exceed`,
  key1001867: `This category of uploaded pictures cannot exceed 8`,
  key1001868: `Toggle input of different package sizes and weights`,
  key1001869: `Please fill in the packing weight`,
  key1001870: `Package size (L*W*H)`,
  key1001871: `Please fill in the complete packaging information`,
  key1001872: `Selected category:`,
  key1001873: `Weight cannot be empty and cannot be 0`,
  key1001874: `Weight cannot be empty`,
  key1001875: `length cannot be empty and cannot be 0`,
  key1001876: `length cannot be empty`,
  key1001877: `width cannot be empty and cannot be 0`,
  key1001878: `width cannot be empty`,
  key1001879: `height cannot be empty and cannot be 0`,
  key1001880: `height cannot be empty`,
  key1001881: `SellerSKU cannot be empty`,
  key1001882: `Length cannot exceed 200 characters`,
  key1001883: `selling price (`,
  key1001884: `Selling price cannot be empty`,
  key1001885: `Price after discount(`,
  key1001886: `Package Weight (KG)`,
  key1001887: `Package size (length*width*height CM)`,
  key1001888: `The sale price of the promotional product. The ratio of sale price to price must be between 0.20 and 1.00`,
  key1001889: `product properties`,
  key1001890: `Product portfolio`,
  key1001891: `Please complete the product portfolio`,
  key1001892: `Add`,
  key1001893: `Bulk sales price`,
  key1001894: `Enter selling prices in bulk`,
  key1001895: `Generate discounted prices in bulk`,
  key1001896: `Enter discounted prices in bulk`,
  key1001897: `re-upload`,
  key1001898: `Promotion date`,
  key1001899: `delete the discounted price`,
  key1001900: `The data are repeated`,
  key1001901: `Variant attribute values cannot exceed 50`,
  key1001902: `Please set the price first`,
  key1001903: `Please set a discounted price`,
  key1001904: `The range of the discounted price is between 0*2 - 1 of the price`,
  key1001905: `Please set the event promotion date`,
  key1001906: `The price cannot be 0`,
  key1001907: `Only add watermark to the first image`,
  key1001908: `Add watermark to all product drawings`,
  key1001909: `Failed to query the watermark template`,
  key1001910: `wrong reason`,
  key1001911: `See why`,
  key1001912: `Price`,
  key1001913: `Price after discount`,
  key1001914: `Note: The following exchange rates are all against the US dollar, and the real-time exchange rate is the latest exchange rate of the day.`,
  key1001915: `Currency`,
  key1001916: `Fixed exchange rate`,
  key1001917: `Real Time Exchange Rates`,
  key1001918: `Modify`,
  key1001919: `Multiple pictures can be uploaded at the same time. Up to 200 images. The recommended upload resolution is 800 * 800/1024 * 1024px. Picture with size no more than 1MB. You can adjust the order by dragging pictures`,
  key1001920: `Do not track inventory`,
  key1001921: `Zero inventory placement is not allowed`,
  key1001922: `Allow zero inventory order`,
  key1001923: `Search Engine Optimization (SEO)`,
  key1001924: `The operation was submitted successfully. Please view the submission results online in Shopify`,
  key1001925: `Failed to get shopify details data`,
  key1001926: `Failed to query collection list`,
  key1001927: `Classification`,
  key1001928: `Please enter classification`,
  key1001929: `Please enter the manufacturer`,
  key1001930: `Label`,
  key1001931: `Please enter labels separated by English characters`,
  key1001932: `Product Collection`,
  key1001933: `Please enter the product collection name`,
  key1001934: `The suggested sales price cannot be empty`,
  key1001935: `Inventory at least 1`,
  key1001936: `Customs code`,
  key1001937: `Original price（`,
  key1001938: `*Suggested sales price（`,
  key1001939: `No transportation required`,
  key1001940: `*Available inventory`,
  key1001941: `Weight (KG)`,
  key1001942: `Tax collection required`,
  key1001943: `Inventory strategy`,
  key1001944: `Variation attribute values cannot exceed 250`,
  key1001945: `The same name exists`,
  key1001946: `Cannot be empty`,
  key1001947: `If it is blank, the platform will be generated by the product title`,
  key1001948: `Visit website`,
  key1001949: `If it is empty, the platform will be generated from the product description`,
  key1001950: `Collection name`,
  key1001951: `Custom Collection`,
  key1001952: `Custom collection not published`,
  key1001953: `Image set`,
  key1001954: `Include Collection`,
  key1001955: `Original field collection`,
  key1001956: `Please fill in the set name`,
  key1001957: `Collection saved successfully`,
  key1001958: `Failed to save collection`,
  key1001959: `Please enter the URL (only https is supported)`,
  key1001960: `Please enter the correct picture address`,
  key1001961: `Unpublished`,
  key1001962: `Published`,
  key1001963: `Synchronize Collection`,
  key1001964: `Failed to query collection list`,
  key1001965: `Delete this item collection`,
  key1001966: `Synchronizing`,
  key1001967: `Please select the collection to synchronize`,
  key1001968: `Classification description`,
  key1001969: `Please fill in the classification name`,
  key1001970: `Classification saved successfully`,
  key1001971: `Failed to save classification`,
  key1001972: `Please fill in the variation information`,
  key1001973: `The operation was submitted successfully. Please view the submission results online in shopline`,
  key1001974: `Failed to query classification list`,
  key1001975: `Failed to obtain shopline details data`,
  key1001976: `Product Summary`,
  key1001977: `Brand`,
  key1001978: `Please enter product classification`,
  key1001979: `Overbought strategy`,
  key1001980: `Not allowed`,
  key1001981: `Allow`,
  key1001982: `Shipment or not`,
  key1001983: `Inventory tracking`,
  key1001984: `Classification number`,
  key1001985: `Synchronous classification`,
  key1001986: `Delete this classification`,
  key1001987: `Please select the classification to synchronize`,
  key1001988: `Campaign name`,
  key1001989: `Activity time`,
  key1001990: `Select End Date`,
  key1001991: `When the discount activity starts.`,
  key1001992: `The start time must be 1 hour later than the current time`,
  key1001993: `The end time must be 1 hour later than the start time, and the discount period must be less than 180 days.`,
  key1001994: `Please enter campaign name.`,
  key1001995: `Please enter the activity date`,
  key1001996: `The start time must be at least 1 hour later than the current time`,
  key1001997: `Next week`,
  key1001998: `Next month`,
  key1001999: `The next three months`,
  key1002000: `Failed to query discount activity details`,
  key1002001: `Specification No.`,
  key1002002: `Edit Activity`,
  key1002003: `Edit Active Product`,
  key1002004: `Delete Activity`,
  key1002005: `End Activity`,
  key1002006: `Sync Activity`,
  key1002007: `New Activity`,
  key1002008: `Sync All`,
  key1002009: `Start time:`,
  key1002010: `End time:`,
  key1002011: `Synchronization Progress`,
  key1002012: `Coming soon`,
  key1002013: `In progress`,
  key1002014: `Expired`,
  key1002015: `Please select activity data`,
  key1002016: `Whether to end this activity`,
  key1002017: `Whether to delete this activity`,
  key1002018: `Data is updated every 10 minutes`,
  key1002019: `Topping mode`,
  key1002020: `Turn automatic top setting on/off`,
  key1002021: `Auto top selling goods`,
  key1002022: `Specify as scheduled`,
  key1002023: `Automatic renewal upon expiration`,
  key1002024: `Please enter the specification number`,
  key1002025: `Online merchandise`,
  key1002026: `Sold out`,
  key1002027: `Banned`,
  key1002028: `Video information`,
  key1002029: `No more than 9 sheets, the first one being the cover sheet. File sizes do not exceed 5mb.
You can adjust the order by dragging pictures to a minimum of 500*500 pixels`,
  key1002030: `Operational submission was successful, please view submission results online in Shopee`,
  key1002031: `Synchronization was successful, please refresh the page later to view`,
  key1002032: `Failed to get Shopee details data`,
  key1002033: `Video information`,
  key1002034: `Size cannot exceed`,
  key1002035: `Only MP4 format is supported!`,
  key1002036: `Product Status`,
  key1002037: `Brand new`,
  key1002038: `second-hand`,
  key1002039: `Do you purchase in advance?`,
  key1002040: `To avoid publishing failures, be sure to confirm that the corresponding logistics have been opened in the back of the store`,
  key1002041: `No (delivery within 3 days required)`,
  key1002042: `I need it`,
  key1002043: `Days of shipment (5-10 days can be filled in)`,
  key1002044: `Select All`,
  key1002045: `SKU (Specification No.)`,
  key1002046: `Wholesale price`,
  key1002047: `Minimum`,
  key1002048: `Maximum`,
  key1002049: `Product price`,
  key1002050: `Source website:`,
  key1002051: `Product Name/Product ID`,
  key1002052: `Product name:`,
  key1002053: `Product ID:`,
  key1002054: `Inventory:`,
  key1002055: `Currently, there are no top goods`,
  key1002056: `Remove Item`,
  key1002057: `Current top goods`,
  key1002058: `Listing`,
  key1002059: `Remaining time of topping`,
  key1002060: `Top setting`,
  key1002061: `Top setting mode:`,
  key1002062: `Auto cycle top selling goods`,
  key1002063: `Top as planned`,
  key1002064: `1. Automatic renewal of current goods to be topped: automatically renew the goods to be topped after the top is opened and expired;`,
  key1002065: `2. Auto top selling products: after being opened, the system will automatically circle the top selling 5 products;`,
  key1002066: `3. Set the top according to the plan: after opening, set the top according to the planned time period and commodities`,
  key1002067: `Each top setting period`,
  key1002068: `To be topped`,
  key1002069: `New commodities to be placed`,
  key1002070: `You can only add 5 top products at most,`,
  key1002071: `Currently, you can add`,
  key1002072: `Items`,
  key1002073: `Finished`,
  key1002074: `The time period cannot be repeated`,
  key1002075: `Each time period is fixed for 4 hours, please select again`,
  key1002076: `Add up to 6 time periods`,
  key1002077: `Whether to cancel this setting`,
  key1002078: `Please add top goods`,
  key1002079: `The scheduled time is not set correctly`,
  key1002080: `Please enter the product name`,
  key1002081: `Product ID`,
  key1002082: `Please enter the product ID`,
  key1002083: `Discount price`,
  key1002084: `Purchase restrictions`,
  key1002085: `Enable/Disable`,
  key1002086: `Commodities participating in the event`,
  key1002087: `Whether to delete goods`,
  key1002088: `Delete products`,
  key1002089: `Failed to delete order import template`,
  key1002090: `Successfully added`,
  key1002091: `Other`,
  key1002092: `The operation was submitted successfully. Please view the submission results online on Amazon`,
  key1002093: `Failed to get Amazon category data`,
  key1002094: `Failed to get site`,
  key1002095: `Failed to obtain Amazon template`,
  key1002096: `Failed to get Amazon attribute group`,
  key1002097: `Failed to get Amazon properties`,
  key1002098: `Failed to get Amazon details data`,
  key1002099: `Image upload failed`,
  key1002100: `Add multiple attributes`,
  key1002101: `Please enter the main image URL`,
  key1002102: `Please enter another picture URL`,
  key1002103: `Please enter the Swatch image URL`,
  key1002104: `Product type`,
  key1002105: `Please enter key to search`,
  key1002106: `Star rating`,
  key1002107: `Review template`,
  key1002108: `When the star level is on, find a review template`,
  key1002109: `At present, automatic review only supports Lazada and Shopee platforms, and other platforms will be opened in succession`,
  key1002110: `Attributes`,
  key1002111: `Send Today`,
  key1002112: `Review Settings`,
  key1002113: `Review record`,
  key1002114: `Closed`,
  key1002115: `Whether to delete the current template.`,
  key1002116: `User name`,
  key1002117: `Comments`,
  key1002118: `Comment time`,
  key1002119: `Review content`,
  key1002120: `Review time`,
  key1002121: `{value} star`,
  key1002122: `Copy to eBay`,
  key1002123: `Malaysia`,
  key1002124: `Thailand`,
  key1002125: `Vietnam`,
  key1002126: `Philippines`,
  key1002127: `Singapore`,
  key1002128: `Indonesian`,
  key1002129: `No data selected`,
  key1002130: `Registered trade name`,
  key1002131: `Please enter a registered trade name`,
  key1002132: `Seller's commodity number`,
  key1002133: `Please enter the seller's item number`,
  key1002134: `Registered Commodity ID`,
  key1002135: `Please enter the registered merchandise ID`,
  key1002136: `Temporary Preservation`,
  key1002137: `Waiting for approval`,
  key1002138: `Approval completed`,
  key1002139: `Partial approval`,
  key1002140: `Failed to Approve`,
  key1002141: `Merchandise Deleted`,
  key1002142: `Key Information`,
  key1002143: `Distribution Information`,
  key1002144: `Return/exchange`,
  key1002145: `Announcement Information`,
  key1002146: `The first picture is a main one, each picture can not exceed 10M, minimum 100PX or more, total no more than 10`,
  key1002147: `Failed to get Coupang Category data`,
  key1002148: `Request for bulletin list failed`,
  key1002149: `When changing the image type, the relevant data is cleared, please make sure to switch`,
  key1002150: `When changing the attribute type, the relevant data is cleared, please decide whether to switch or not`,
  key1002151: `Query Out of Library List Failed`,
  key1002152: `Operational submission was successful, please view the submission results online in Coupang`,
  key1002153: `Please enter the brand, leave blank if no brand or homemade`,
  key1002154: `Manufacturer`,
  key1002155: `Make sure there is no brand on the right side of your home made merchandise.`,
  key1002156: `Parallel Inlet`,
  key1002157: `Minor Purchase`,
  key1002158: `Do you buy on behalf of overseas customers?`,
  key1002159: `Non-parallel inlet`,
  key1002160: `Yes`,
  key1002161: `May not`,
  key1002162: `Is PCC required (Personal Customs Code)`,
  key1002163: `Maximum per capita purchase`,
  key1002164: `Set the maximum quantity that can be purchased within a specific period of time.`,
  key1002165: `Not set`,
  key1002166: `individual`,
  key1002167: `Sales Period`,
  key1002168: `Registration Sales Time`,
  key1002169: `Surcharge Tax`,
  key1002170: `Please choose whether to add VAT to the products you sell.`,
  key1002171: `Taxation`,
  key1002172: `Duty free`,
  key1002173: `Last 3 days`,
  key1002174: `Last 5 days`,
  key1002175: `Last 7 days`,
  key1002176: `Last 15 days`,
  key1002177: `Last 30 days`,
  key1002178: `Last 60 days`,
  key1002179: `Last 90 days`,
  key1002180: `Last 120 days`,
  key1002181: `Announcement Categories`,
  key1002182: `Inform Name`,
  key1002183: `details`,
  key1002184: `Refer to Commodity Detailed Interface`,
  key1002185: `See Product Details page`,
  key1002186: `Product name`,
  key1002187: `The name of the item shown on the actual sales page. Changes may be made according to Coupang's standards.`,
  key1002188: `Register trade name`,
  key1002189: `The name of the item used on the order is not visible to the customer. Set your name to be manageable.`,
  key1002190: `Options`,
  key1002191: `Picture upload`,
  key1002192: `Web Pictures`,
  key1002193: `Basic registration`,
  key1002194: `Register according to options`,
  key1002195: `Individual commodities`,
  key1002196: `Upload no more than 10 images of this type`,
  key1002197: `Exit warehouse`,
  key1002198: `Distribution in Jizhou/remote areas or not`,
  key1002199: `Express Company`,
  key1002200: `Distribution Method`,
  key1002201: `Bundle Distribution`,
  key1002202: `Distribution freight type`,
  key1002203: `Date required for warehouse release (days)`,
  key1002204: `Date required for warehouse release`,
  key1002205: `Please enter the time it takes for the customer to place an order and leave the warehouse.`,
  key1002206: `- Distribution method: 1-7 days for normal distribution and fresh refrigeration`,
  key1002207: `- Distribution method: 1-20 days when order is made, purchased on behalf, installed and distributed, and directly communicated to the seller`,
  key1002208: `Enter by Purchase Option`,
  key1002209: `Bulk set up`,
  key1002210: `General Distribution`,
  key1002211: `Fresh freezing`,
  key1002212: `Customized`,
  key1002213: `Purchase on behalf`,
  key1002214: `Installation Distribution or Direct Delivery to Seller`,
  key1002215: `Free Distribution`,
  key1002216: `Pay Distribution`,
  key1002217: `Freight to Pay`,
  key1002218: `Specified condition package`,
  key1002219: `Please select the data first`,
  key1002220: `news`,
  key1002221: `Batch setting date required for warehouse release`,
  key1002222: `Please enter a number of days`,
  key1002223: `Incorrect date required for warehouse release, minimum 1 day max. 30 days`,
  key1002224: `Sales status`,
  key1002225: `Selling price`,
  key1002226: `Inventory quantity`,
  key1002227: `Out of stock`,
  key1002228: `Registered Commodity ID:`,
  key1002229: `Saled`,
  key1002230: `Not sold`,
  key1002231: `Content`,
  key1002232: `Content Editing`,
  key1002233: `Basic Input`,
  key1002234: `Input by SKU`,
  key1002235: `Have filled in content`,
  key1002236: `Not yet filled in`,
  key1002237: `Return/Exchange Location`,
  key1002238: `Initial Distribution Fee (One Way)`,
  key1002239: `Return freight (one way)`,
  key1002240: `When the goods are returned for customer reasons, the return/distribution fee is 0 KRW and the total amount of the initial distribution fee plus the return distribution fee is KRW.`,
  key1002241: `Seller's commodity code/SKU`,
  key1002242: `Please enter the original price of the item, which is the price before the coupang discount is applied.`,
  key1002243: `Model`,
  key1002244: `Bar code`,
  key1002245: `Multiple Attributes`,
  key1002246: `Single Attribute`,
  key1002247: `Multiple Attribute Commodities`,
  key1002248: `Individual Attribute Commodities`,
  key1002249: `Please refine multiple attributes`,
  key1002250: `Improve Individual Attribute Commodities`,
  key1002251: `Original Price (`,
  key1002252: `Multi-attribute merchandise registration`,
  key1002253: `Single Attribute Merchandise Registration`,
  key1002254: `Please add variant attributes`,
  key1002255: `Batch Generated Original Price`,
  key1002256: `Batch input original price`,
  key1002257: `Batch Generated Model`,
  key1002258: `Batch generation of barcodes`,
  key1002259: `Please enter the actual price sold in Coupang.`,
  key1002260: `(At least 10 won organizations)`,
  key1002261: `Prices may not be changed during the promotion period and may be changed after the end of the promotion period.`,
  key1002262: `Enter the quantity of stock available for sale.`,
  key1002263: `Outages (from 0 to 99999) have a negative impact on customer experience. Enter an adequate inventory.`,
  key1002264: `Template content`,
  key1002265: `Add Element`,
  key1002266: `Text content`,
  key1002267: `typeface`,
  key1002268: `font size`,
  key1002269: `Font Color`,
  key1002270: `Bold or not`,
  key1002271: `Upload pictures`,
  key1002272: `Watermark Layout`,
  key1002273: `zoom`,
  key1002274: `rotate`,
  key1002275: `picture`,
  key1002276: `watermark`,
  key1002277: `Empty Template`,
  key1002278: `Replace background image`,
  key1002279: `It is recommended to select pictures that conform to the size of your product. It is recommended to use 800 * 800/1000 * 1000/1500 * 1500`,
  key1002280: `Whether to empty the panel`,
  key1002281: `Switching type will reset the panel. Continue switching`,
  key1002282: `Microsoft Yahei`,
  key1002283: `Please enter the name of the watermark template`,
  key1002284: `Please add a watermark`,
  key1002285: `Custom mode only supports 1 watermark image`,
  key1002286: `Failed to add watermark template`,
  key1002287: `Time filtering`,
  key1002288: `Number of customers`,
  key1002289: `Number of payment orders`,
  key1002290: `Total payment amount`,
  key1002291: `Number of payment customers`,
  key1002292: `Number of refund orders`,
  key1002293: `Total refund amount`,
  key1002294: `Number of refunded customers`,
  key1002295: `Number of customers`,
  key1002296: `Failed to query store analysis data`,
  key1002297: `Order profit analysis`,
  key1002298: `Store profit analysis`,
  key1002299: `Commodity profit analysis`,
  key1002300: `date`,
  key1002301: `Order quantity`,
  key1002302: `Refund quantity`,
  key1002303: `Total purchase quantity`,
  key1002304: `Sales Issue`,
  key1002305: `Available inventory`,
  key1002306: `Defective product`,
  key1002307: `Failed to query commodity inventory analysis statistics data`,
  key1002308: `Purchase time`,
  key1002309: `Purchase Order No`,
  key1002310: `Procurement personnel`,
  key1002311: `Purchase quantity`,
  key1002312: `Product type and quantity`,
  key1002313: `Total purchase amount`,
  key1002314: `Number of good products in storage`,
  key1002315: `Number of defective products in storage`,
  key1002316: `Total warehousing amount`,
  key1002317: `Opening inventory`,
  key1002318: `Closing inventory`,
  key1002319: `Issue`,
  key1002320: `Issue Summary`,
  key1002321: `Exchange and delivery`,
  key1002322: `Goods transfer and issue`,
  key1002323: `Storage`,
  key1002324: `Receipt Summary`,
  key1002325: `Purchase Receipt`,
  key1002326: `Return receipt`,
  key1002327: `Stock transfer and warehousing`,
  key1002328: `Replacement warehousing`,
  key1002329: `Best selling latitude`,
  key1002330: `Best seller ranking`,
  key1002331: `Best selling type`,
  key1002332: `Product SKU`,
  key1002333: `Total sales`,
  key1002334: `Total cost of sales`,
  key1002335: `Channel charges`,
  key1002336: `Total commodity income`,
  key1002337: `Average profit`,
  key1002338: `Profit`,
  key1002339: `Gross profit margin`,
  key1002340: `Refund rate`,
  key1002341: `Sales commission`,
  key1002342: `Transaction commission`,
  key1002343: `Sales of goods`,
  key1002344: `Total cost of goods`,
  key1002345: `EBay cross-customs transaction fee:`,
  key1002346: `Ebay custody payment tax:`,
  key1002347: `Transaction fee:`,
  key1002348: `Service fee:`,
  key1002349: `Tariff:`,
  key1002350: `Commodity VAT:`,
  key1002351: `Logistics expenses`,
  key1002352: `Logistics cost = shipping cost + return logistics cost`,
  key1002353: `Refund logistics expenses must be borne by the seller when the logistics freight type is selected`,
  key1002354: `Calculate to refund amount`,
  key1002355: `Failed to query store profit data`,
  key1002356: `Number of sales orders`,
  key1002357: `Number of goods sold`,
  key1002358: `Number of goods refunded`,
  key1002359: `Total order revenue`,
  key1002360: `Order revenue`,
  key1002361: `Cost of goods`,
  key1002362: `Failed to query order profit data`,
  key1002363: `Payment sales volume`,
  key1002364: `Payment amount`,
  key1002365: `Refund sales`,
  key1002366: `First order time:`,
  key1002367: `Last order time:`,
  key1002368: `Statistical type`,
  key1002369: `Account number`,
  key1002370: `Payment time`,
  key1002371: `Country`,
  key1002372: `Summary of the last 7 days`,
  key1002373: `Summary of the last 30 days`,
  key1002374: `Sales`,
  key1002375: `Purchase order quantity`,
  key1002376: `Total loans`,
  key1002377: `Number of SKUs`,
  key1002378: `Receipt quantity`,
  key1002379: `Yield`,
  key1002380: `Average delivery date (days)`,
  key1002381: `Please enter ASKU/FNSKU`,
  key1002382: `Snapshot time`,
  key1002383: `Quantity of goods charged in 6-12 months`,
  key1002384: `Charge for 6-12 months`,
  key1002385: `Quantity of goods charged for more than 12 months`,
  key1002386: `Charge for more than 12 months`,
  key1002387: `Individual commodity volume`,
  key1002388: `Light commodities`,
  key1002389: `Charging month`,
  key1002390: `Select month`,
  key1002391: `Warehouse No`,
  key1002392: `Long side`,
  key1002393: `Middle edge`,
  key1002394: `Short side`,
  key1002395: `Weight`,
  key1002396: `Volume`,
  key1002397: `Product standard`,
  key1002398: `Inventory`,
  key1002399: `Amount to be removed`,
  key1002400: `Total volume`,
  key1002401: `Estimated storage cost`,
  key1002402: `Please enter the order number/transaction number/ASIN/MSKU/FNSKU`,
  key1002403: `Compensation No`,
  key1002404: `Reason`,
  key1002405: `Product title`,
  key1002406: `Status`,
  key1002407: `Amount of compensation for each piece`,
  key1002408: `Total amount`,
  key1002409: `Amount of compensation (cash)`,
  key1002410: `Compensation quantity (inventory)`,
  key1002411: `Amount of compensation (total)`,
  key1002412: `Original compensation number`,
  key1002413: `Compensation type`,
  key1002414: `Please enter transaction number/MSKU/FNSKU`,
  key1002415: `Ship date`,
  key1002416: `Transaction No`,
  key1002417: `Operation Center`,
  key1002418: `Inventory attribute`,
  key1002419: `Country`,
  key1002420: `Failed to query customer analysis data`,
  key1002421: `Here are the products`,
  key1002422: `in your Shopline store. you need to establish a mapping relationship between the Product No. of Shopline and the products you actually ship.`,
  key1002423: `Please select a type.`,
  key1002424: `All`,
  key1002425: `Gift`,
  key1002426: `Add purchases`,
  key1002427: `All sites`,
  key1002428: `Product ID / Product No.`,
  key1002429: `Product type`,
  key1002430: `Multiple variations`,
  key1002431: `Operation`,
  key1002432: `Set product category`,
  key1002433: `Bulk update`,
  key1002434: `Connect`,
  key1002435: `The product category must be a sub-category.`,
  key1002436: `Assembly/bundled product No.`,
  key1002437: `Please enter the Assembly/Bundled product No.`,
  key1002438: `Assembly/bundled product name`,
  key1002439: `Please enter the name of the assembly or bundled product.`,
  key1002440: `Pre-assembled`,
  key1002441: `Declared product name`,
  key1002442: `Declared value`,
  key1002443: `Size`,
  key1002444: `Length`,
  key1002445: `Width`,
  key1002446: `Height`,
  key1002447: `Product No.`,
  key1002448: `Product No.`,
  key1002449: `Please enter SKU.`,
  key1002450: `Gram (g)`,
  key1002451: `Centimeter (CM)`,
  key1002452: `Included component products/stand-alone products`,
  key1002453: `Add component products/stand-alone products`,
  key1002454: `Assembly/bundled product No. cannot be empty.`,
  key1002455: `Assembly/bundled product name cannot be empty.`,
  key1002456: `Component products/stand-alone products cannot be empty.`,
  key1002457: `The number of component products/stand-alone products must be greater than 1.`,
  key1002458: `Bound products with inventory records cannot be converted to non-pre-assembled.`,
  key1002459: `The products bound are required.`,
  key1002460: `Product No. already exists.`,
  key1002461: `The product does not exist.`,
  key1002462: `The product does not exist.`,
  key1002463: `Please enter the article number or product name`,
  key1002464: `Actual commodity name`,
  key1002465: `Please enter the actual product name`,
  key1002466: `Actual products included`,
  key1002467: `Import Assembly & Bundled product`,
  key1002468: `/file/Import sample of Assembly & Bundled product.xlsx`,
  key1002469: `Import and export`,
  key1002470: `Export Assembly & Bundled product`,
  key1002471: `Download the Excel sample material for importing Assembly & Bundled products (`,
  key1002472: `The written file of Assembly & Bundled product is uploaded below, and the product number that is the same as the current product archive will be overwritten.`,
  key1002473: `Import products`,
  key1002474: `The upload task is being executed. You can also close the window. The upload task will be executed by itself in the background until the end. You can also go to`,
  key1002475: ` View the results of the import task.`,
  key1002476: `Please select an order.`,
  key1002477: `Export the selected assembly/bundled products to an Excel file, and you can use it to modify and import again to update the file of the Assembly & Bundled product.`,
  key1002478: `Failed to export the combination`,
  key1002479: `Please select products first.`,
  key1002480: `Once the assembly/bundled product is deleted, it will be permanently invisible.`,
  key1002481: `Delete assembly/bundled products`,
  key1002482: `Failed to delete the combination`,
  key1002483: `Failed to query the list of combinations`,
  key1002484: `Partial import successful`,
  key1002485: `Import Successful`,
  key1002486: `Outbound Failure`,
  key1002487: `Successful remittance`,
  key1002488: `Whether there are multiple specifications`,
  key1002489: `Product SPU`,
  key1002490: `Please enter product SPU`,
  key1002491: `English name`,
  key1002492: `Warranty or not`,
  key1002493: `Warranty period (days)`,
  key1002494: `Product picture`,
  key1002495: `Basic information`,
  key1002496: `Main image`,
  key1002497: `Specification information`,
  key1002498: `Add new specifications`,
  key1002499: `Auto generate`,
  key1002500: `Automatically generate SKU rules`,
  key1002501: `Multi-specification automatic generation will take SPU + specification name as the value`,
  key1002502: `For example:`,
  key1002503: `SPU value is GT,`,
  key1002504: `The specification value is red and large, and then the SKU value is GT-Red-Large`,
  key1002505: `Indicative cost price`,
  key1002506: `Size (cm)`,
  key1002507: `Call setting (manufacturer - minimum purchase quantity - call cycle)`,
  key1002508: `Preferred supplier`,
  key1002509: `Alternative supplier 1`,
  key1002510: `Alternative supplier 2`,
  key1002511: `Please enter the product No.`,
  key1002512: `Please select a product category.`,
  key1002513: `Please add the table data first`,
  key1002514: `Please enter the preferred manufacturer`,
  key1002515: `Please fill in the complete product information`,
  key1002516: `Please fill in the specification information`,
  key1002517: `SKU cannot be empty`,
  key1002518: `Specification has not been saved.`,
  key1002519: `Failed to save products`,
  key1002520: `The same specification name exists.`,
  key1002521: `Please fill in the specification name.`,
  key1002522: `Switching specifications will clear the added product data. Do you want to continue switching?`,
  key1002523: `Failed to query product details`,
  key1002524: `Category name cannot be empty.`,
  key1002525: `There are products under the category, so it cannot be deleted.`,
  key1002526: `Level 3  3-2-2`,
  key1002527: `Connecting with “My product” is to enable the system to identify the specific product to be shipped after the order is generated, and to facilitate the system to synchronize the online inventory of Shopline.`,
  key1002528: `Add my products to connect`,
  key1002529: `SKU does not exist.`,
  key1002530: `The same SKU exists.`,
  key1002531: `Imported successfully`,
  key1002532: `Specification`,
  key1002533: `value`,
  key1002534: `/file/Product import sample.xlsx`,
  key1002535: `Bulk operation`,
  key1002536: `Set category`,
  key1002537: `Export products`,
  key1002538: `Download Excel sample material for importing products (`,
  key1002539: `The filled product file is uploaded below, and the product file with the same product No. as the current product file will be updated.`,
  key1002540: `No specifications`,
  key1002541: `After the product is deleted, it will be permanently invisible, and the historical orders will also show that the product has been deleted.`,
  key1002542: `Export the products you selected to an Excel file, and you can use them to modify and import again to update the product file.`,
  key1002543: `Available inventory`,
  key1002544: `Assembled inventory`,
  key1002545: `Unqualified product inventory`,
  key1002546: `Failed to acquire inventory`,
  key1002547: `Original warehouse`,
  key1002548: `Destination warehouse`,
  key1002549: `Please enter content.`,
  key1002550: `Transfer quantity of qualified product inventory`,
  key1002551: `Please enter the transfer quantity of qualified product inventory.`,
  key1002552: `Transfer quantity of unqualified product inventory`,
  key1002553: `Please enter the inventory transfer quantity of unqualified products.`,
  key1002554: `Please select the original warehouse.`,
  key1002555: `Please select the destination warehouse.`,
  key1002556: `Please select the original warehouse.`,
  key1002557: `Please select products.`,
  key1002558: `The transfer quantity of both qualified products and the unqualified products is 0.`,
  key1002559: `Failed to save the stock transfer order`,
  key1002560: `Qualified product inventory changes`,
  key1002561: `Unqualified product inventory changes`,
  key1002562: `Inventory change order No.:`,
  key1002563: `Reasons:`,
  key1002564: `All warehouses`,
  key1002565: `The system calculates the virtual inventory of the assembly/bundled product based on the existing inventory of the raw materials of the assembly/bundled product.`,
  key1002566: `Select a warehouse`,
  key1002567: `Non-pre-assembled`,
  key1002568: `Product No. / Product Name`,
  key1002569: `Assembled quantity`,
  key1002570: `Existing unqualified products inventory`,
  key1002571: `/file/Import and export sample of product inventory.xlsx`,
  key1002572: `Component product inventory/ Stand-alone product inventory`,
  key1002573: `Please enter the notes of unqualified products.`,
  key1002574: `Export inventory`,
  key1002575: `Download Excel sample material for importing initial inventory (`,
  key1002576: `The initialized inventory file is uploaded below. Once the product is distributed, the initialization of inventory will no longer be allowed, and the system will automatically skip it.`,
  key1002577: `Clear`,
  key1002578: `Export the current inventory of all products to an Excel file.`,
  key1002579: `The exporting task is being executed, please wait`,
  key1002580: `A transfer order is used to transfer the inventory from one warehouse to another warehouse.`,
  key1002581: `Transfer order number`,
  key1002582: `Please use English characters and spaces to separate`,
  key1002583: `Creation date`,
  key1002584: `Transfer order No.`,
  key1002585: `Import warehouse transfer order`,
  key1002586: `/File/Transfer Doc Import Template.xlsx`,
  key1002587: `Export custom`,
  key1002588: `Download the Excel sample data of the import warehouse transfer order（`,
  key1002589: `Export transfer order`,
  key1002590: `Failed to export the warehouse transfer order`,
  key1002591: `Please select the transfer orders to be exported`,
  key1002592: `Export transfer orders you selected to an Excel file.`,
  key1002593: `Export all transfer orders to an Excel file.`,
  key1002594: `Export custom`,
  key1002595: `Please enter the data to be exported`,
  key1002596: `Original warehouse:`,
  key1002597: `Destination warehouse:`,
  key1002598: `Inventory awaiting shipment`,
  key1002599: `Inventory count results (Qualified/Unqualified products)`,
  key1002600: `Inventory count results`,
  key1002601: `Inventory count record`,
  key1002602: `Count Date`,
  key1002603: `Inventory quantity before inventory count (qualified and unqualified products)`,
  key1002604: `Inventory quantity after inventory count (qualified and unqualified products)`,
  key1002605: `Import inventory count sheet`,
  key1002606: `/file/Import sample of inventory results.xlsx`,
  key1002607: `Enter inventory count results`,
  key1002608: `Start counting (Selected)`,
  key1002609: `Start counting (All)`,
  key1002610: `Export inventory count sheet (selected)`,
  key1002611: `Export inventory count sheet (All)`,
  key1002612: `Completely overwrite the notes of unqualified products inventory`,
  key1002613: `Waiting for input`,
  key1002614: `Download Excel sample material of  importing customer files (`,
  key1002615: `Export all the products that are in inventory counting to an Excel file, you can modify the count results and re-import to save it in the system.`,
  key1002616: `Download Excel sample material of importing inventory count results`,
  key1002617: `The filled file of the inventory count result is uploaded below, and the current inventory will be modified after the result is successfully imported.`,
  key1002618: `After the inventory count, the inventory will be locked and not allowed to be distributed to the order. It will be unlocked after the count results are entered and the inventory count is completed.`,
  key1002619: `Please select the inventory count sheet to be exported.`,
  key1002620: `Export inventory count sheet`,
  key1002621: `Number of split orders`,
  key1002622: `Assembly quantity`,
  key1002623: `Increase inventory`,
  key1002624: `Required inventory`,
  key1002625: `Existing stocks`,
  key1002626: `Split order number`,
  key1002627: `Assembly order No.`,
  key1002628: `Required raw material inventory`,
  key1002629: `Please enter a note`,
  key1002630: `Receiving warehouse:`,
  key1002631: `Number of returns`,
  key1002632: `Please enter a note.`,
  key1002633: `Receipt quantity this time`,
  key1002634: `Return to Supplier No.:`,
  key1002635: `Return time:`,
  key1002636: `Purchase order No.:`,
  key1002637: `Number of returns:`,
  key1002638: `Total number of returns:`,
  key1002639: `Unreceived:`,
  key1002640: `The assembly is used to convert the inventory of component products/stand-alone products into the inventory of assembly/bundled products to be pre-assembled.`,
  key1002641: `Assembly order`,
  key1002642: `Split order`,
  key1002643: `The inventory of raw material product will increase after split.`,
  key1002644: `Add Assembly & Bundled product`,
  key1002645: `Assembly`,
  key1002646: `Add split orders`,
  key1002647: `Please select the warehouse first.`,
  key1002648: `No assembly/bundled product selected`,
  key1002649: `Insufficient inventory`,
  key1002650: `"Return to Supplier" is used to return the unqualified products to the supplier for repair.`,
  key1002651: `Return to Supplier No.`,
  key1002652: `Status`,
  key1002653: `Export Return to Supplier`,
  key1002654: `Mark as "Refund"`,
  key1002655: `Exporting task`,
  key1002656: `Returning`,
  key1002657: `Returned`,
  key1002658: `Refund completed`,
  key1002659: `Partial receipt`,
  key1002660: `Products in the Return to Supplier will not be returned back to the warehouse, and the supplier will directly refund.`,
  key1002661: `All status`,
  key1002662: `Are you sure to force finish it? The inventory will not be replenished after the operation.`,
  key1002663: `Parameter abnormal`,
  key1002664: `In inventory counting`,
  key1002665: `Export the Return to Supplier you have selected to an Excel file.`,
  key1002666: `Please enter content.`,
  key1002667: `Purchase order error`,
  key1002668: `Please select a warehouse.`,
  key1002669: `The number of returns cannot be 0.`,
  key1002670: `Purchase order does not exist.`,
  key1002671: `The purchase order is in unreceived status.`,
  key1002672: `Out of stock`,
  key1002673: `Set inventory synchronization time`,
  key1002674: `Automatic synchronization`,
  key1002675: `Synchronization time`,
  key1002676: `Select time`,
  key1002677: `By time`,
  key1002678: `By Interval`,
  key1002679: `every other`,
  key1002680: `Every minute`,
  key1002681: `Number of online publications`,
  key1002682: `Store/itemId`,
  key1002683: `Online inventory`,
  key1002684: `Anti oversold setting`,
  key1002685: `Anti oversold`,
  key1002686: `Enable Platform`,
  key1002687: `Set stock alert value`,
  key1002688: `When the SKU inventory is lower than the alert value, only keep the SKU on the center line of the corresponding synchronous store`,
  key1002689: `The inventory of the store with the best sales within days, and the inventory of other stores is synchronized to 0`,
  key1002690: `Print space label`,
  key1002691: `Bar Code Size`,
  key1002692: `Location Size`,
  key1002693: `Bar Code Location`,
  key1002694: `Location of goods`,
  key1002695: `Location`,
  key1002696: `Above the shipping space`,
  key1002697: `Below the shipping space`,
  key1002698: `Be at the left side`,
  key1002699: `Centered`,
  key1002700: `Be at the right`,
  key1002701: `Number`,
  key1002702: `Batch settings`,
  key1002703: `Print Preview`,
  key1002704: `Failed to get shipping position bar code`,
  key1002705: `Please fill in the quantity`,
  key1002706: `Inventory SKU`,
  key1002707: `Inventory SKU cannot be empty`,
  key1002708: `New Association`,
  key1002709: `Store SKU`,
  key1002710: `Partial matching succeeded`,
  key1002711: `non-existent`,
  key1002712: `Matching failed`,
  key1002713: `Failed to add`,
  key1002714: `Return date`,
  key1002715: `Customer/Country`,
  key1002716: `Number of returns`,
  key1002717: `Notes`,
  key1002718: `Please enter the reason.`,
  key1002719: `Please select a warehouse.`,
  key1002720: `The inventory changes of both qualified products and the unqualified products are 0.`,
  key1002721: `Insufficient inventory, unable to deduct`,
  key1002722: `The products are locked and the operation failed.`,
  key1002723: `For the existing inventory of the products in the warehouse, you can initialize the inventory when you first use the system. Once the inventory is distributed, it will not be allowed to initialize again.`,
  key1002724: `Location`,
  key1002725: `Please enter slot`,
  key1002726: `Empty space`,
  key1002727: `Please enter the slot number`,
  key1002728: `Inventory unit price`,
  key1002729: `Total inventory price`,
  key1002730: `Clear data`,
  key1002731: `Notes for inventory`,
  key1002732: `Please enter inventory notes.`,
  key1002733: `Batch clearance`,
  key1002734: `Show all products`,
  key1002735: `Display the total value of inventory in the warehouse`,
  key1002736: `Display the total value of qualified inventory`,
  key1002737: `Display the total value of in-transit inventory`,
  key1002738: `Total value of inventory in the warehouse`,
  key1002739: `total value of qualified inventory`,
  key1002740: `Total value of in-transit inventory`,
  key1002741: `Download the Excel sample material for importing product inventory (`,
  key1002742: `The data for products that are no longer in this warehouse can be cleared. The product inventory of those data must all be 0.`,
  key1002743: `No item with a slot number exists in the selected data`,
  key1002744: `Failed to modify shipping position`,
  key1002745: `Failed to query the total value of products`,
  key1002746: `Failed to update inventory details`,
  key1002747: `Be aware of inventory failures`,
  key1002748: `Failed to query inventory details`,
  key1002749: `Export of Current Inventory Failed`,
  key1002750: `Failed to add comments`,
  key1002751: `Delete note failed`,
  key1002752: `Failed to get defective comments`,
  key1002753: `Commodity Inventory Query Failed`,
  key1002754: `Initialization Inventory Failed`,
  key1002755: `SKU&Qty`,
  key1002756: `Store SKU associated inventory SKU import`,
  key1002757: `/File/Store SKU Associated Inventory SKU Import Template. xlsx`,
  key1002758: `Import Association`,
  key1002759: `Download the SKU import template associated with the store SKU（`,
  key1002760: `Import succeeded, please check later`,
  key1002761: `Delete current content?`,
  key1002762: `The inventory change order is used to adjust the quantity of the existing qualified product inventory and the existing unqualified product inventory.`,
  key1002763: `Inventory change order No.`,
  key1002764: `Platform Enabled`,
  key1002765: `Anti oversold status`,
  key1002766: `Product name/SKU`,
  key1002767: `Inventory quantity of good products`,
  key1002768: `Warning value`,
  key1002769: `Number of online publications`,
  key1002770: `Inventory Warning（`,
  key1002771: `Set anti oversold`,
  key1002772: `Set Selected`,
  key1002773: `Set All`,
  key1002774: `Manually associate inventory SKU`,
  key1002775: `Set inventory synchronization time`,
  key1002776: `Enabled`,
  key1002777: `Not enabled`,
  key1002778: `Failed to query the anti oversold list`,
  key1002779: `Delete this data`,
  key1002780: `Delete selected data`,
  key1002781: `Failed to get listing online list`,
  key1002782: `Please enter the order number, SKU or product name`,
  key1002783: `Operation log`,
  key1002784: `Product/Quantity`,
  key1002785: `Allocate inventory (selected)`,
  key1002786: `Allocate inventory (All)`,
  key1002787: `Back to "New order"`,
  key1002788: `(out of stock)`,
  key1002789: `Product is empty.`,
  key1002790: `The system automatically deducts the inventory according to the sequence of the order. Orders with sufficient inventory will be transferred to "Available for shipment", and orders with insufficient inventory will be transferred to the out of stock.`,
  key1002791: `Shipping status`,
  key1002792: `Print documents`,
  key1002793: `Picking method`,
  key1002794: `Print historical date`,
  key1002795: `Enter keywords for filtering`,
  key1002796: `Amount/payment method`,
  key1002797: `Shipping method/Waybill number`,
  key1002798: `Waybill number`,
  key1002799: `Reason for order failure of consignment`,
  key1002800: `Order time`,
  key1002801: `Inward consignment No`,
  key1002802: `/File/ custom logistics channel logistics doc No. import template xlsx`,
  key1002803: `Unsynchronized`,
  key1002804: `Processing`,
  key1002805: `Available for shipment`,
  key1002806: `Cancel sync`,
  key1002807: `Print pick list`,
  key1002808: `Print sheet`,
  key1002809: `Pick to order`,
  key1002810: `Collective Picking`,
  key1002811: `Logistics face list not obtained`,
  key1002812: `Reprint the picking list`,
  key1002813: `Reprint face sheet`,
  key1002814: `Import consignment No`,
  key1002815: `Shipping order`,
  key1002816: `Change to "Shipped"`,
  key1002817: `Change selected orders`,
  key1002818: `Change all`,
  key1002819: `Scan shipment`,
  key1002820: `Back to "Available for shipment"`,
  key1002821: `Skip order`,
  key1002822: `Get the logistics face list`,
  key1002823: `Reprint`,
  key1002824: `Processing error`,
  key1002825: `Download the excel sample data of the custom logistics channel logistics doc No. import template（`,
  key1002826: `The filled in custom logistics channel logistics doc No. import template is uploaded below.`,
  key1002827: `(Custom Channel)`,
  key1002828: `Outward consignment No`,
  key1002829: `To order`,
  key1002830: `In order`,
  key1002831: `Order placed successfully`,
  key1002832: `Order failure`,
  key1002833: `To Pick`,
  key1002834: `List to be printed`,
  key1002835: `To Execute Shipment`,
  key1002836: `Print selected`,
  key1002837: `Print all`,
  key1002838: `Batch printing`,
  key1002839: `Scan order printing`,
  key1002840: `Scan SKU print`,
  key1002841: `Please enter the scanning content`,
  key1002842: `Failed to print face sheet`,
  key1002843: `Please select the order first`,
  key1002844: `Please select picking list`,
  key1002845: `Your order can be changed to "Shipped" when it is ready to be delivered to the shipping company.`,
  key1002846: `Change to "Shipping"`,
  key1002847: `Failed to change shipment`,
  key1002848: `Failed to print summary picking list`,
  key1002849: `Failed to generate picking list`,
  key1002850: `Modification of Logistics Order Number Failed`,
  key1002851: `Synchronous Shipping Status Failed`,
  key1002852: `Query list in shipment failed`,
  key1002853: `Skip order failed`,
  key1002854: `Query warehouse list failed`,
  key1002855: `Get face list successfully`,
  key1002856: `Failed to obtain the logistics face order`,
  key1002857: `Failed to get quantity in shipment`,
  key1002858: `Identification Number`,
  key1002859: `Please enter / scan the order number or logistics number`,
  key1002860: `Shipping method`,
  key1002861: `Shipping status`,
  key1002862: `Scan Successful Auto Shipping`,
  key1002863: `Execute Shipping`,
  key1002864: `Number of scanned orders:`,
  key1002865: `Number of orders shipped:`,
  key1002866: `Shipped`,
  key1002867: `Unshipped`,
  key1002868: `Order number or logistics number does not exist`,
  key1002869: `Order number or logistics number not included in shipment`,
  key1002870: `Scan shipment failure`,
  key1002871: `Shipment successfully executed`,
  key1002872: `Failed to execute delivery`,
  key1002873: `Warehouse/consignment note No`,
  key1002874: `Import historical orders`,
  key1002875: `/file/Order import sample.xlsx`,
  key1002876: `/file/Momo importing template-Home delivery.xlsx`,
  key1002877: `/file/Momo importing template-Convenience store pickup.xlsx`,
  key1002878: `/file/LINE Cool coupon import order template.xlsx`,
  key1002879: `/file/RS-order template.xlsx`,
  key1002880: `Import standard templates`,
  key1002881: `Import Momo Home Delivery Template`,
  key1002882: `Import Momo Convenience store pickup template`,
  key1002883: `Import LINE cool coupon template`,
  key1002884: `Import RS order template`,
  key1002885: `Download the Excel sample of the imported order`,
  key1002886: `Standard template example.xlsx`,
  key1002887: `Momo Import Template-Home Delivery.xlsx`,
  key1002888: `Momo importing template-Convenience store pickup.xlsx`,
  key1002889: `LINE cool coupon template.xlsx`,
  key1002890: `RS-order template.xlsx`,
  key1002891: `The filled order file is uploaded below, and the order No. that is the same as the one in the current order file library will be skipped.`,
  key1002892: `The upload task is running, you can also close the window, the upload task will be executed in the background until the end, you can also go to`,
  key1002893: `Failed to query the shipped list`,
  key1002894: `Mark as paid`,
  key1002895: `Back to "Checking"`,
  key1002896: `Mark the order as paid and check other aspects.`,
  key1002897: `Offline payment`,
  key1002898: `Mark as paid successfully`,
  key1002899: `The system automatically imports online orders on the platform in series. After confirming, the window will be closed and the system will automatically import in the background.`,
  key1002900: `Synchronize online orders`,
  key1002901: `The order will no longer be fulfilled after cancellation.`,
  key1002902: `Cancel orders`,
  key1002903: `請輸入訂單號,若不填，系統將自動生成`,
  key1002904: `修改運費`,
  key1002905: `修改訂單總額`,
  key1002906: `訂購人`,
  key1002907: `聯繫電話`,
  key1002908: `訂購人E-mail`,
  key1002909: `省/洲`,
  key1002910: `Actual shipping method`,
  key1002911: `Waybill number:`,
  key1002912: `Order notes`,
  key1002913: `請輸入商品名稱/SPU/SKU`,
  key1002914: `新增手工訂單`,
  key1002915: `Order information`,
  key1002916: `Payment`,
  key1002917: `產品金額：`,
  key1002918: `運費：`,
  key1002919: `Total amount:`,
  key1002920: `Customer information`,
  key1002921: `Delivery information`,
  key1002922: `提交`,
  key1002923: `添加本頁`,
  key1002924: `已添加`,
  key1002925: `請輸入收件人`,
  key1002926: `請輸入收件人聯繫電話`,
  key1002927: `Please select a country/region`,
  key1002928: `請輸入省/洲`,
  key1002929: `Picking list`,
  key1002930: `Please enter / scan SKU`,
  key1002931: `Quantity to be issued`,
  key1002932: `Scanned quantity`,
  key1002933: `Missing quantity`,
  key1002934: `Sheet printing status`,
  key1002935: `Picking Order No.:`,
  key1002936: `End this sort`,
  key1002937: `Cancel this sort`,
  key1002938: `Total orders:`,
  key1002939: `Number of orders printed:`,
  key1002940: `No related orders found`,
  key1002941: `SKU has been sorted`,
  key1002942: `SKU does not exist in the current picking list`,
  key1002943: `Picking Order No. does not exist`,
  key1002944: `Scan successful`,
  key1002945: `The current picking list has been picked`,
  key1002946: `Query successful`,
  key1002947: `End this sort?`,
  key1002948: `End sorting succeeded`,
  key1002949: `End sorting failed`,
  key1002950: `Cancel this sort?`,
  key1002951: `Cancel sorting succeeded`,
  key1002952: `Cancel sorting failed`,
  key1002953: `The picking list in progress is queried successfully`,
  key1002954: `After sales number`,
  key1002955: `Province / State`,
  key1002956: `Phone number`,
  key1002957: `Notes:`,
  key1002958: `Delivery deadline:`,
  key1002959: `Quantity returned`,
  key1002960: `Repair`,
  key1002961: `Platform of the order:`,
  key1002962: `Payment date:`,
  key1002963: `Order date:`,
  key1002964: `Shopline Branch Name:`,
  key1002965: `Customer message`,
  key1002966: `Product details`,
  key1002967: `Replacement`,
  key1002968: `Current qualified product inventory:`,
  key1002969: `Add a product`,
  key1002970: `Products in original order`,
  key1002971: `It did not be matched by any real product.`,
  key1002972: `Return and exchange information`,
  key1002973: `Product cost:`,
  key1002974: `Delivery cost:`,
  key1002975: `Tax:`,
  key1002976: `Adjusted amount:`,
  key1002977: `Discount coupon:`,
  key1002978: `Discount:`,
  key1002979: `Payment method:`,
  key1002980: `Seller's actual receipt:`,
  key1002981: `Actually paid by customer:`,
  key1002982: `Orderer:`,
  key1002983: `Customer's Email:`,
  key1002984: `Fill in the waybill number`,
  key1002985: `Shipping warehouse:`,
  key1002986: `Shipping method:`,
  key1002987: `Actual shipping method:`,
  key1002988: `Notes:`,
  key1002989: `Delivery period:`,
  key1002990: `The inventory will be replenished to the warehouse after confirming that it has not been picked up.`,
  key1002991: `No relevant data`,
  key1002992: `Arrived`,
  key1002993: `Picked up`,
  key1002994: `Delivery deadline has passed`,
  key1002995: `Preparing`,
  key1002996: `Pending`,
  key1002997: `Return completed`,
  key1002998: `The return store is closed`,
  key1002999: `Delivered`,
  key1003000: `Shop closed`,
  key1003001: `The receiver cannot be empty.`,
  key1003002: `Please choose the actual shipping method.`,
  key1003003: `Address 1 cannot be empty.`,
  key1003004: `Phone number cannot be empty.`,
  key1003005: `Please select a country.`,
  key1003006: `The replacement item is empty.`,
  key1003007: `Return & Replacement`,
  key1003008: `None`,
  key1003009: `Category`,
  key1003010: `Method`,
  key1003011: `Please enter other`,
  key1003012: `Please select the reason.`,
  key1003013: `The reason for uncollected products cannot be empty.`,
  key1003014: `Fill in the waybill number`,
  key1003015: `The sku of assembly/bundled product is abnormal and cannot be added.`,
  key1003016: `Product cannot be empty.`,
  key1003017: `Failed to cancel the order`,
  key1003018: `Customer phone`,
  key1003019: `Shopline Branch Name`,
  key1003020: `Please enter / scan the order number and click or enter to confirm`,
  key1003021: `Mark printed directly after printing successfully`,
  key1003022: `Print the picking list together`,
  key1003023: `When the face list is in PDF format, the picking list cannot be printed together`,
  key1003024: `Print`,
  key1003025: `Mark printed`,
  key1003026: `Print result confirmation`,
  key1003027: `The order sheet has been generated. If it has been printed, please click the mark printed`,
  key1003028: `Print settings`,
  key1003029: `Scan print sheet`,
  key1003030: `Ship To Address`,
  key1003031: `Operation record`,
  key1003032: `Operator`,
  key1003033: `Operation time`,
  key1003034: `close`,
  key1003035: `Failed to query operation log`,
  key1003036: `Print the pick list`,
  key1003037: `The pick list is a list for you to pick up the products in the warehouse.`,
  key1003038: `Page-by-order printing`,
  key1003039: `Shop products`,
  key1003040: `Sales channels`,
  key1003041: `Order multi-SKU import method`,
  key1003042: `When the order number is repeated`,
  key1003043: `System field`,
  key1003044: `Excel file import headers`,
  key1003045: `Please enter the column headers of the Excel file`,
  key1003046: `Available range`,
  key1003047: `Import settings`,
  key1003048: `Each line`,
  key1003049: `Multiple columns in the same row`,
  key1003050: `When selecting a pipeline import with the same row and multiple columns, if the title column name filled in is SKU,`,
  key1003051: `Then the second SKU is called SKU1 by default, and the third SKU2 is deduced accordingly.`,
  key1003052: `Therefore, users need to adjust the column names in the corresponding excel to avoid import failure`,
  key1003053: `Ignore`,
  key1003054: `Cover`,
  key1003055: `Template Field Mapping`,
  key1003056: `Map`,
  key1003057: `Please enter the corresponding column title in the Excel file in this column,`,
  key1003058: `If you don't enter it, you will not be able to match the corresponding field.`,
  key1003059: `Edit order import template`,
  key1003060: `Fixed value`,
  key1003061: `Leave blank`,
  key1003062: `Order currency`,
  key1003063: `Postage`,
  key1003064: `The number of goods`,
  key1003065: `Product sku`,
  key1003066: `Number of Products`,
  key1003067: `Commodity price`,
  key1003068: `Customer Name`,
  key1003069: `Customer gender`,
  key1003070: `Customer birthday`,
  key1003071: `Customer email`,
  key1003072: `Customer shipping address 1`,
  key1003073: `Customer shipping address 2`,
  key1003074: `Customer country`,
  key1003075: `Customer status`,
  key1003076: `Customer city`,
  key1003077: `Customer zip code`,
  key1003078: `Actual shipping method`,
  key1003079: `Tracking Number`,
  key1003080: `Delivery time`,
  key1003081: `Specify the mailing method`,
  key1003082: `The recipient's phone`,
  key1003083: `Recipient address 1`,
  key1003084: `Recipient address 2`,
  key1003085: `Recipient province`,
  key1003086: `Recipient country`,
  key1003087: `Recipient postcode`,
  key1003088: `Recipient city`,
  key1003089: `Add order import template`,
  key1003090: `Please enter the completed content`,
  key1003091: `Picking Order No`,
  key1003092: `SKU number`,
  key1003093: `Printer`,
  key1003094: `Batch picking`,
  key1003095: `Actions performed after import`,
  key1003096: `Select the excel file to import the order into`,
  key1003097: `Set as shipped`,
  key1003098: `Trigger warranty notification if warranty item is included`,
  key1003099: `Only .xlsx, .xls files can be uploaded, and no more than 10MB`,
  key1003100: `Start import`,
  key1003101: `Size cannot exceed 10MB!`,
  key1003102: `Failed to query platform account`,
  key1003103: `Failed to get list of all order import templates`,
  key1003104: `The import operation is successful, you can view the processing progress in the task center`,
  key1003105: `The upload file size cannot exceed 10M`,
  key1003106: `Please select a template`,
  key1003107: `Please select a sales channel`,
  key1003108: `Back to check`,
  key1003109: `Import orders by template`,
  key1003110: `Failed to query order import template list`,
  key1003111: `Whether to delete the order import template`,
  key1003112: `Delete order import template`,
  key1003113: `Delete rule order import template successful`,
  key1003114: `Failed to query order import template details`,
  key1003115: `Print invoices`,
  key1003116: `Shippable`,
  key1003117: `Notice`,
  key1003118: `Recheck`,
  key1003119: `Recheck selected orders`,
  key1003120: `Recheck all`,
  key1003121: `Mark as paid (selected)`,
  key1003122: `Mark as paid (All)`,
  key1003123: `添加手工訂單`,
  key1003124: `展示更多(隱藏`,
  key1003125: `條)`,
  key1003126: `The system automatically imports online orders on the platform in series. After confirming, the window will be closed and the system will automatically import in the background.`,
  key1003127: `Number of historical orders`,
  key1003128: `Total historical consumption`,
  key1003129: `Last order date`,
  key1003130: `Gender`,
  key1003131: `Birthday`,
  key1003132: `Select birthday`,
  key1003133: `Please select or enter a customer label.`,
  key1003134: `Warranty card number`,
  key1003135: `Associated order`,
  key1003136: `Warranty period`,
  key1003137: `Warranty start date`,
  key1003138: `Warranty status`,
  key1003139: `Order status`,
  key1003140: `Marketing campaign Name`,
  key1003141: `Start date`,
  key1003142: `End date`,
  key1003143: `Marketing method`,
  key1003144: `Orders`,
  key1003145: `Sales`,
  key1003146: `Inventory allocation finished`,
  key1003147: `Shipping`,
  key1003148: `Cancelled`,
  key1003149: `Basic file`,
  key1003150: `Male`,
  key1003151: `Female`,
  key1003152: `Weight`,
  key1003153: `Set`,
  key1003154: `Warranty code`,
  key1003155: `Activate the warranty`,
  key1003156: `Replaced`,
  key1003157: `Product name:`,
  key1003158: `SKU:`,
  key1003159: `Specification:`,
  key1003160: `Failed`,
  key1003161: `Under warranty`,
  key1003162: `The warranty period has expired`,
  key1003163: `Not yet activate`,
  key1003164: `Marketing records`,
  key1003165: `Fill in the warranty number`,
  key1003166: `Activation succeeded`,
  key1003167: `The phone number already exists.`,
  key1003168: `Customer mailbox already exists`,
  key1003169: `Customer label is empty.`,
  key1003170: `Customer email and mobile phone number are empty.`,
  key1003171: `Email content:`,
  key1003172: `SMS content:`,
  key1003173: `Campaign name:`,
  key1003174: `Start date:`,
  key1003175: `Marketing method:`,
  key1003176: `Customer requirements:`,
  key1003177: `Number of customers covered:`,
  key1003178: `Orders:`,
  key1003179: `Sales:`,
  key1003180: `Conversion rate:`,
  key1003181: `Delivery status`,
  key1003182: `Email`,
  key1003183: `SMS`,
  key1003184: `have`,
  key1003185: `Within 7 days`,
  key1003186: `Within 15 days`,
  key1003187: `Within 30 days`,
  key1003188: `Within 60 days`,
  key1003189: `60 days ago`,
  key1003190: `in the past 7 days is over`,
  key1003191: `in the past 7 days is less than`,
  key1003192: `More than 15 days`,
  key1003193: `in the past 15 days is less than`,
  key1003194: `in the past 30 days is over`,
  key1003195: `in the past 30 days is less than`,
  key1003196: `in the past 60 days is over`,
  key1003197: `in the past 60 days is less than`,
  key1003198: `of all orders is more than`,
  key1003199: `of all orders is less than`,
  key1003200: `Leave phone number`,
  key1003201: `Phone number`,
  key1003202: `Customer location`,
  key1003203: `Have repurchased?`,
  key1003204: `Total consumption amount`,
  key1003205: `Contact information`,
  key1003206: `Please enter the amount.`,
  key1003207: `E-mail`,
  key1003208: `Customer label`,
  key1003209: `Marketing method`,
  key1003210: `Send Content`,
  key1003211: `Marketing campaign time`,
  key1003212: `Send time`,
  key1003213: `Select date and time`,
  key1003214: `The first step: customer requirements`,
  key1003215: `Number of customers covered`,
  key1003216: `Next step`,
  key1003217: `Step 2: Marketing method`,
  key1003218: `Previous`,
  key1003219: `To include the customer’s name in the E-mail or SMS, please use {{Customer Name}} as a placeholder.`,
  key1003220: `Please enter campaign content.`,
  key1003221: `Campaign name cannot be empty.`,
  key1003222: `Campaign content cannot be empty.`,
  key1003223: `Campaign date cannot be empty.`,
  key1003224: `The send time of messages cannot be empty.`,
  key1003225: `The send time of SMS must be within the time limit of campaign.`,
  key1003226: `Please enter the text content...`,
  key1003227: `Customer files will be collected from sales orders.`,
  key1003228: `Area`,
  key1003229: `Import customer files`,
  key1003230: `/file/Import and export sample of Customer file.xlsx`,
  key1003231: `Bulk add into blacklist`,
  key1003232: `Remove from blacklist`,
  key1003233: `Add into blacklist`,
  key1003234: `The filled customer file is uploaded below, and those with the same phone number or Email as the customer file in the system will be skipped.`,
  key1003235: `Import customer information`,
  key1003236: `Export customer information`,
  key1003237: `Export selected`,
  key1003238: `Export all`,
  key1003239: `Export customer information + order`,
  key1003240: `Please select the customers to export.`,
  key1003241: `Export the customer files you have filtered out to an Excel file.`,
  key1003242: `Export customer files`,
  key1003243: `Export all customer files to an Excel file.`,
  key1003244: `Please select the customers to be removed from the blacklist.`,
  key1003245: `Select the customers for SMS or E-mail marketing based on the conditions.`,
  key1003246: `Campaign date`,
  key1003247: `Conversion rate`,
  key1003248: `To start`,
  key1003249: `There will be a remind after blacklisted customers purchase products.`,
  key1003250: `Bulk remove from blacklist`,
  key1003251: `Export the customer files you have filtered out to an Excel file.`,
  key1003252: `home page`,
  key1003253: `Scrape list`,
  key1003254: `Watermark Template`,
  key1003255: `New Watermark Template`,
  key1003256: `Edit Watermark Template`,
  key1003257: `EBay product publishing`,
  key1003258: `Add draft`,
  key1003259: `Draft details`,
  key1003260: `Online Details`,
  key1003261: `eBay common templates`,
  key1003262: `Payment policy details`,
  key1003263: `New payment policy`,
  key1003264: `Transport policy details`,
  key1003265: `New shipping policy`,
  key1003266: `Details of return policy`,
  key1003267: `New return policy`,
  key1003268: `Item location details`,
  key1003269: `Add item location`,
  key1003270: `Lazada listings`,
  key1003271: `Shopee Product Listing`,
  key1003272: `Discount activities`,
  key1003273: `New Discount Activity`,
  key1003274: `Discount Activity Details`,
  key1003275: `Top selling promotion`,
  key1003276: `Product top promotion settings`,
  key1003277: `Coupang Product Publication`,
  key1003278: `Shopify product launch`,
  key1003279: `New product collection`,
  key1003280: `Modify Product Collection`,
  key1003281: `Shopline product publication`,
  key1003282: `New product classification`,
  key1003283: `Modify product classification`,
  key1003284: `Amazon product publishing`,
  key1003285: `New review template`,
  key1003286: `Edit Feedback Template`,
  key1003287: `Automatic review`,
  key1003288: `Edit automatic comments`,
  key1003289: `Category management`,
  key1003290: `Exporting and importing tasks`,
  key1003291: `Edit products`,
  key1003292: `Add assembly/bundled product`,
  key1003293: `Update assembly/bundled product`,
  key1003294: `Shopline online products`,
  key1003295: `Connect with My product`,
  key1003296: `Order`,
  key1003297: `Checking`,
  key1003298: `Order details`,
  key1003299: `New order`,
  key1003300: `Scan SKU shipment`,
  key1003301: `Order Import Template`,
  key1003302: `Custom channel order import`,
  key1003303: `手工訂單`,
  key1003304: `Procurement list`,
  key1003305: `Generate purchase order`,
  key1003306: `View purchase order`,
  key1003307: `Edit 1688 Purchase Order`,
  key1003308: `View 1688 Purchase Order`,
  key1003309: `Edit purchase order`,
  key1003310: `Supplier management`,
  key1003311: `Procurement settings`,
  key1003312: `Vendor-related products`,
  key1003313: `1688 Procurement`,
  key1003314: `1688 account authorization`,
  key1003315: `1688 procurement association`,
  key1003316: `Product stocks`,
  key1003317: `Inventory of assembly & bundled products`,
  key1003318: `Add new Assembly & Bundled product`,
  key1003319: `Assembly details`,
  key1003320: `View split orders`,
  key1003321: `Inventory change order`,
  key1003322: `Add inventory change order`,
  key1003323: `Inventory change order details`,
  key1003324: `Add repair order`,
  key1003325: `Repair order details`,
  key1003326: `Add transfer order`,
  key1003327: `Transfer order details`,
  key1003328: `Inventory Assistant`,
  key1003329: `Store SKU associated with inventory SKU`,
  key1003330: `Member`,
  key1003331: `Customer file`,
  key1003332: `Customer information details`,
  key1003333: `Blacklist`,
  key1003334: `Remarketing`,
  key1003335: `Create marketing campaign`,
  key1003336: `Marketing campaign details`,
  key1003337: `Return details`,
  key1003338: `Replacement details`,
  key1003339: `Repair details`,
  key1003340: `Warranty card pool`,
  key1003341: `Data`,
  key1003342: `Store analysis`,
  key1003343: `Store statistics`,
  key1003344: `Order Analysis`,
  key1003345: `Profit analysis`,
  key1003346: `Manufacturer performance statistics`,
  key1003347: `Performance statistics of procurement personnel`,
  key1003348: `Commodity sales analysis`,
  key1003349: `Commodity inventory analysis`,
  key1003350: `Customer distribution`,
  key1003351: `Issue/Receipt Analysis`,
  key1003352: `Best selling stores`,
  key1003353: `Amazon Report`,
  key1003354: `Compensation`,
  key1003355: `Inventory`,
  key1003356: `Monthly storage fee`,
  key1003357: `Long-term storage fee`,
  key1003358: `Settings`,
  key1003359: `Marketplace management`,
  key1003360: `Purchased`,
  key1003361: `Not purchased`,
  key1003362: `Results page`,
  key1003363: `Payment Order`,
  key1003364: `User center`,
  key1003365: `In station letter details`,
  key1003366: `Staff account`,
  key1003367: `Warehouse settings`,
  key1003368: `Function settings`,
  key1003369: `Exchange rate settings`,
  key1003370: `Task center`,
  key1003371: `SMS sending record`,
  key1003372: `Invoice template`,
  key1003373: `New Invoice`,
  key1003374: `Edit Invoice`,
  key1003375: `Email sending record`,
  key1003376: `Order rules`,
  key1003377: `Warehouse matching rules`,
  key1003378: `Logistics matching rules`,
  key1003379: `SMS rules`,
  key1003380: `Email sending rules`,
  key1003381: `Failed to get configuration details`,
  key1003382: `Waiting for scanning`,
  key1003383: `Scanning`,
  key1003384: `Scan complete`,
  key1003385: `Print complete`,
  key1003386: `Text translation`,
  key1003387: `It is applicable to one click translation, one click translation of title, description, etc. 50000 characters are free to use, and the purchase amount is required after exceeding the limit. The purchase amount is valid in the current month, and the unused part in the next month will be reset automatically`,
  key1003388: `It is applicable to the translation of pictures. 20 pictures are free of charge. If they exceed the last 10 yuan/200 pictures, they will be valid in the current month. The unused parts in the next month will be reset automatically.`,
  key1003389: `SMS charging package`,
  key1003390: `SMS charging package.`,
  key1003391: `Your products have been submitted to eBay, please go to [Listings] to view the submission result!`,
  key1003392: `Add product`,
  key1003393: `View product`,
  key1003394: `Published successfully`,
  key1003395: `Failed to publish products, please check the reason for the failure and republish after modification`,
  key1003396: `Continue to edit`,
  key1003397: `Your products have been successfully saved, please go to [Draft box] to view!`,
  key1003398: `Your products have been updated successfully, please go to [Online products] to view!`,
  key1003399: `Failed to update products, and the publication will keep the original content`,
  key1003400: `Update failed`,
  key1003401: `Your product has been submitted to Shopline. Please go to [Post Online] to view the submission results!`,
  key1003402: `Your product has been submitted to Shopify. Please go to [Post Online] to view the submission results!`,
  key1003403: `Your product has been submitted to Shopee, please go to Publish Online to see the submission results!`,
  key1003404: `Your product has been submitted to Coupang, please go to Publish Online to see the submission results!`,
  key1003405: `Your product has been submitted to Amazon. Please go to [Post Online] to view the submission results!`,
  key1003406: `Your product has been submitted to Lazada, please go to [Publish Online] to see the submission results!`,
  key1003407: `Not accepted`,
  key1003408: `Revoked`,
  key1003409: `Processed`,
  key1003410: `In transit`,
  key1003411: `Failed to collect`,
  key1003412: `Have been received`,
  key1003413: `Sign-off exception`
}

export enum RESPONSE_CODE {
  SUCCESS = 0,
  FAIL = 999999, // system error
  AUTH_FAIL = 999998 | 999997,
  NO_PERMISSION = 403,
  AFTERMES = 999993,
  NO_DATA = 404,
  NO_ACCOUNT = 30004,
  LIMIT = 5,
  ERROR_EMAIL = 50008,
  CODE_AUTH_FAILED = 20020,
  CODE_AUTO_OUTDATE = 20022,
  NO_ORDER = 20001,
  NO_USER = 50007,
  MERCHANT_ERROR = 20023,
  OLD_PASSWORD_ERROR = 70001,
  LISTING_DETAILS_NOT_FOUNT = 700001,
  HAS_ACCOUNT_CODE = 30001,
  HAS_SALEACOUNT = 30008,
  NO_BUY = 999990,
  ROLE_USEING = 101102,
  NO_MATCH_SKU = 30007, // 匹配不上SKU
  SKU_PICKING_FINISHED = 30008, // sku分揀完成
  NO_PICKINGCODE = 30005,
  UNDERSTOCK = 10102,
  STOCKLOCK = 10103
}

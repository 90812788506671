export default {
  key1000000: `コミット成功`,
  key1000001: `権限を持っていない`,
  key1000002: `インターフェイスに関してエラーを報告された。コンソールでエラー情報を確認してください`,
  key1000003: `ヒント`,
  key1000004: `購入に向かう`,
  key1000005: `キャンセル`,
  key1000006: `関連ユーザー情報が見つかりません`,
  key1000007: `保証番号を記入してください`,
  key1000008: `起動成功`,
  key1000009: `保証書番号はすでに使用されています`,
  key1000010: `保証番号が存在しない`,
  key1000011: `商品を選択`,
  key1000012: `商品名を入力してください`,
  key1000013: `検索`,
  key1000014: `合格品の在庫数：`,
  key1000015: `不良品の在庫数：`,
  key1000016: `保存`,
  key1000017: `商品を選択してください`,
  key1000018: `組み合わせ商品を選択してください`,
  key1000019: `売上統計`,
  key1000020: `プラットホーム`,
  key1000021: `売上`,
  key1000022: `情報が不完全です`,
  key1000023: `在庫変更記録`,
  key1000024: `時間`,
  key1000025: `人員`,
  key1000026: `変更の種類`,
  key1000027: `関連する注文番号`,
  key1000028: `合格品在庫変更`,
  key1000029: `不良品の変更`,
  key1000030: `輸送中の在庫数の変化`,
  key1000031: `出荷待ち在庫の変更`,
  key1000032: `エクスポート`,
  key1000033: `閉鎖`,
  key1000034: `インポートとエクスポートタスク`,
  key1000035: `関`,
  key1000036: `閉じる`,
  key1000037: `上載任務正在執行， 您也可以關閉窗口， 上載任務將會在後台自行執行直到結束， 您也可以到 `,
  key1000038: `送金タスクの結果を表示します。`,
  key1000039: `初期化在庫のインポート`,
  key1000040: `購入`,
  key1000041: `商品を準備する`,
  key1000042: `注文状態が新しい注文に戻りました`,
  key1000043: `発送`,
  key1000044: `在庫確認`,
  key1000045: `出荷済注文のインポート`,
  key1000046: `初期化`,
  key1000047: `返品`,
  key1000048: `異動届`,
  key1000049: `在庫移動伝票`,
  key1000050: `組み合わせ商品の組立データ`,
  key1000051: `組み合わせ商品を分割する`,
  key1000052: `返品修理伝票`,
  key1000053: `返品修理の作成`,
  key1000054: `修理注文の更新が返されました`,
  key1000055: `返品修理データの削除`,
  key1000056: `注文がキャンセルされました`,
  key1000057: `注文は返金されました`,
  key1000058: `注文の状態が受取`,
  key1000059: `注文書の削除`,
  key1000060: `注文書の作成`,
  key1000061: `発注書が強制完結`,
  key1000062: `発注書の変更`,
  key1000063: `商品を返品する`,
  key1000064: `受け取てない`,
  key1000065: `エクスポートしますか`,
  key1000066: `チップ`,
  key1000067: `確定`,
  key1000068: `現在の在庫をエクスポートする`,
  key1000069: `送金タスクは実行中です。ウィンドウを閉じることもできます。送金タスクは終了するまでバックグラウンドで自動的に実行され、`,
  key1000070: `エクスポートタスクの結果を表示します。`,
  key1000071: `エクスポートに失敗しました。ファイルをダウンロードして失敗の原因を確認しますか？`,
  key1000072: `エクスポートは成功しました`,
  key1000073: `ディフォルト`,
  key1000074: `配送先住所の同期にする`,
  key1000075: `配送先を選択してください`,
  key1000076: `住所を選択してください`,
  key1000077: `該当画面にアクセスする権限が持っていない`,
  key1000078: `トップページへ`,
  key1000079: `前のページに戻る`,
  key1000080: `素早く記入`,
  key1000081: `画像が大きすぎます`,
  key1000082: `入力してください`,
  key1000083: `ユーザー情報`,
  key1000084: `設定`,
  key1000085: `情報を編集する`,
  key1000086: `名前`,
  key1000087: `パスワードを変更する`,
  key1000088: `古いパスワード`,
  key1000089: `新しいパスワード`,
  key1000090: `パスワードを確認する`,
  key1000091: `中国語繁体`,
  key1000092: `日本語`,
  key1000093: `コントロールセンター`,
  key1000094: `アカウント管理`,
  key1000095: `ユーザー名`,
  key1000096: `売店番号`,
  key1000097: `コピー`,
  key1000098: `支払い情報`,
  key1000099: `付加価値リソースパッケージ`,
  key1000100: `デフォルト通貨`,
  key1000101: `テーマ設定`,
  key1000102: `おすすめ色`,
  key1000103: `アフターサービス`,
  key1000104: `ドキュメントとツール`,
  key1000105: `製品マニュアル`,
  key1000106: `システムからログアウトします`,
  key1000107: `パスワードを入力してください`,
  key1000108: `パスワードをもう一度入力してください`,
  key1000109: `2回入力したパスワードが一致しません！`,
  key1000110: `古いパスワードを空にすることはできません`,
  key1000111: `ユーザー名を空にすることはできません`,
  key1000112: `保存に成功しました`,
  key1000113: `正常に変更されました`,
  key1000114: `古いパスワードが正しくありません`,
  key1000115: `コピーを成功しました`,
  key1000116: `コピーを失敗しました`,
  key1000117: `マークアップ成功`,
  key1000118: `メッセージタグ読み取り失敗`,
  key1000119: `展開`,
  key1000120: `片付け`,
  key1000121: `に知らせる`,
  key1000122: `公告`,
  key1000123: `メッセージのヒント`,
  key1000124: `すべてを表示`,
  key1000125: `すべてのタグ読み取り済み`,
  key1000126: `メニュー`,
  key1000127: `データが一時的にありません`,
  key1000128: `購買管理`,
  key1000129: `完結済`,
  key1000130: `未収品数`,
  key1000131: `納品明細書`,
  key1000132: `完結ではない`,
  key1000133: `すべて`,
  key1000134: `日`,
  key1000135: `周`,
  key1000136: `年`,
  key1000137: `オーダー数量`,
  key1000138: `受注額`,
  key1000139: `店舗分析統計の照会に失敗しました`,
  key1000140: `倉庫管理`,
  key1000141: `在庫を確認中`,
  key1000142: `再修理オーダーは未完了です`,
  key1000143: `再修理オーダー入荷待ち数`,
  key1000144: `在庫確認していない`,
  key1000145: `店`,
  key1000146: `合計＃ゴウケイ＃`,
  key1000147: `下書き`,
  key1000148: `発表中`,
  key1000149: `オンライン製品`,
  key1000150: `発表に失敗しました`,
  key1000151: `產品刊登總數`,
  key1000152: `店舗の照会に失敗しました`,
  key1000153: `店舗失効通知`,
  key1000154: `詳細を表示`,
  key1000155: `（残り`,
  key1000156: `日`,
  key1000157: `期限切れ）`,
  key1000158: `種別名`,
  key1000159: `オーダー金額`,
  key1000160: `オーダー数`,
  key1000161: `販売数量`,
  key1000162: `最暢銷分類`,
  key1000163: `未分類`,
  key1000164: `上位5名`,
  key1000165: `トップ10`,
  key1000166: `前15名`,
  key1000167: `上位20名`,
  key1000168: `前25名`,
  key1000169: `オーダー名`,
  key1000170: `システム通知`,
  key1000171: `商品情報`,
  key1000172: `ベストセラー商品`,
  key1000173: `掲載商品`,
  key1000174: `自分の商品`,
  key1000175: `プラットフォームのベストセラー`,
  key1000176: `店舗の売れ筋商品`,
  key1000177: `上位3位`,
  key1000178: `アフターサービス`,
  key1000179: `交換完了`,
  key1000180: `引き換えに`,
  key1000181: `修理が完了しました`,
  key1000182: `修理中`,
  key1000183: `返品が完了しました`,
  key1000184: `返品中`,
  key1000185: `オーダー管理`,
  key1000186: `輸送情報の更新に失敗しました`,
  key1000187: `元の注文を照会する時に異常が発生しました`,
  key1000188: `注文が見つかりません`,
  key1000189: `注文の配送方法が一致しません`,
  key1000190: `注文の状態は異常であり`,
  key1000191: `注文は未払い`,
  key1000192: `在庫が足りません`,
  key1000193: `在庫がロックされています`,
  key1000194: `注文商品が一致しません`,
  key1000195: `注文がキャンセルされました`,
  key1000196: `払戻済注文`,
  key1000197: `顧客ブラックリスト`,
  key1000198: `倉庫がマッチングされていない`,
  key1000199: `託送リスト照会失敗`,
  key1000200: `保存中`,
  key1000201: `ユーザーウェアハウスリストの取得に失敗しました`,
  key1000202: `通貨リストのクエリに失敗しました`,
  key1000203: `削除してもよろしいですか？`,
  key1000204: `確定`,
  key1000205: `検索を入力するには、システムのデフォルト通貨を選択してください`,
  key1000206: `EasySellersへようこそ`,
  key1000207: `より良い使用体験を得るために、システムのデフォルト通貨を設定してください`,
  key1000208: `（注：システム機能通貨`,
  key1000209: `設定すると、システム内の統計レポートでデフォルトで通貨が選択されます）`,
  key1000210: `リマインダ`,
  key1000211: `デフォルト通貨を選択してください`,
  key1000212: `保存に失敗しました`,
  key1000213: `照会に失敗しました`,
  key1000214: `キーワードを選択または入力して検索してください`,
  key1000215: `確認`,
  key1000216: `最大50文字まで入力してください`,
  key1000217: `必須項目`,
  key1000218: `エクスポートされたデータのダウンロード有効期間は7日、７日以降はダウンロードできなくなります。`,
  key1000219: `タスクタイプ`,
  key1000220: `開始時間`,
  key1000221: `完了時間`,
  key1000222: `状態`,
  key1000223: `結果`,
  key1000224: `結果のエクスポート`,
  key1000225: `処理`,
  key1000226: `処理が完了しました`,
  key1000227: `失敗`,
  key1000228: `戻る`,
  key1000229: `合計：`,
  key1000230: `成功／失敗：`,
  key1000231: `ダウンロード`,
  key1000232: `実行中`,
  key1000233: `実行を完成しました`,
  key1000234: `実行に失敗しました`,
  key1000235: `商品`,
  key1000236: `顧客`,
  key1000237: `在庫数を初期化する`,
  key1000238: `注文書`,
  key1000239: `組合せ商品`,
  key1000240: `注文履歴`,
  key1000241: `在庫チェック`,
  key1000242: `交換`,
  key1000243: `修理`,
  key1000244: `エクスポート`,
  key1000245: `インポート`,
  key1000246: `Eメールが正常に送信されました。Eメールにアクセスして認証コードを確認してください。`,
  key1000247: `該当メールがゴミ箱に移動されたかもしれませんので、ゴミ箱に確認してください`,
  key1000248: `5分経っても届かない場合は、もう一度クリックして認証コードを取得してください`,
  key1000249: `正しいメールアドレスを入力してください`,
  key1000250: `携帯電話番号を入力してください`,
  key1000251: `SMSは正常に送信しました。携帯電話に確認してください。5分経っても受信してない場合は、もう一度クリックして認証コードを取得してください。`,
  key1000252: `送信に失敗しました`,
  key1000253: `認証コードは必須項目`,
  key1000254: `認証コードエラー`,
  key1000255: `認証コードの有効期限が切れています`,
  key1000256: `販売者IDは必須項目`,
  key1000257: `顧客は存在しません`,
  key1000258: `ログインに失敗しました`,
  key1000259: `保証カード番号`,
  key1000260: `保証カード番号を入力してください`,
  key1000261: `使用するか`,
  key1000262: `使用するかを選択してください`,
  key1000263: `送金時間`,
  key1000264: `保証カード番号を一括追加する`,
  key1000265: `生成規則:プレフィックス固定3桁コード+10桁ランダム文字(ランダムな英文小文字又は数字)`,
  key1000266: `プレフィックス`,
  key1000267: `生成数`,
  key1000268: `郵送の設定`,
  key1000269: `部品名`,
  key1000270: `モデル名`,
  key1000271: `保証書を輸入する`,
  key1000272: `/file/保証書の輸入と輸出テンプレート.xlsx`,
  key1000273: `フィルター`,
  key1000274: `リセット`,
  key1000275: `インポート・エクスポート`,
  key1000276: `選択したエクスポート`,
  key1000277: `すべてエクスポート`,
  key1000278: `ルールに基づいて生成`,
  key1000279: `保証カードを設定する`,
  key1000280: `一括削除`,
  key1000281: `未使用`,
  key1000282: `使用済`,
  key1000283: `保証書輸入するテンプレートのExcleサンプルをダウンロードする（`,
  key1000284: `凡例.xlsx`,
  key1000285: `完成した保証書テンプレートを下方にアップロードします。`,
  key1000286: `ここにExcelファイルをドラッグする、或は`,
  key1000287: `クリックしてアップロード`,
  key1000288: `2MBまでの.xlsxファイルのみアップロードできる。`,
  key1000289: `保証カード番号【`,
  key1000290: `】既存`,
  key1000291: `】既に使われた`,
  key1000292: `保証書を郵送する`,
  key1000293: `匯出任務正在執行，您也可以關閉窗口，匯出任務將會在後台自行執行直到結束，您可以到 `,
  key1000294: `処理日`,
  key1000295: `に`,
  key1000296: `開始日`,
  key1000297: `終了日`,
  key1000298: `カテゴリ`,
  key1000299: `タイプを選択してください`,
  key1000300: `キーワード`,
  key1000301: `検索キーワードを入力してください`,
  key1000302: `注文番号`,
  key1000303: `連絡先プラットフォーム/担当者`,
  key1000304: `修理商品/数量`,
  key1000305: `受取人`,
  key1000306: `電話`,
  key1000307: `住所`,
  key1000308: `維修類別

`,
  key1000309: `處理方式

`,
  key1000310: `運賃（発送/返品）`,
  key1000311: `受領日`,
  key1000312: `返却日`,
  key1000313: `備考`,
  key1000314: `輸送伝票番号`,
  key1000315: `操作する`,
  key1000316: `返品のインポート`,
  key1000317: `/ file / 返品インポートサンプル.xlsx`,
  key1000318: `修理を完成しました`,
  key1000319: `発送日：`,
  key1000320: `発送日`,
  key1000321: `輸送番号：`,
  key1000322: `修理情報をエクスポート`,
  key1000323: `すべてエクスポート`,
  key1000324: `選択済：`,
  key1000325: `件データ`,
  key1000326: `編集`,
  key1000327: `削除`,
  key1000328: `インポートされた返品のExcelサンプルをダウンロードします（`,
  key1000329: `入力済み返品データは下にアップロードできます。`,
  key1000330: `発送日を選択してください`,
  key1000331: `追跡番号を入力してください`,
  key1000332: `先週`,
  key1000333: `先月`,
  key1000334: `過去3か月`,
  key1000335: `操作に成功しました`,
  key1000336: `アフターサービスは空です`,
  key1000337: `在庫数インターフェイス異常`,
  key1000338: `在庫不足`,
  key1000339: `インポートに失敗しました。ファイルをダウンロードして失敗の理由を確認しますか？`,
  key1000340: `アップロードファイルのサイズは2Mを超えることはできません`,
  key1000341: `修理の注文を選択してください`,
  key1000342: `フィルタリングされた修理をExcelファイルにエクスポートします。`,
  key1000343: `返品倉庫`,
  key1000344: `返品/数量`,
  key1000345: `交換発送倉庫`,
  key1000346: `交換商品／数量`,
  key1000347: `交換種別`,
  key1000348: `処理方法`,
  key1000349: `交換を完了しました`,
  key1000350: `交換情報をエクスポートする`,
  key1000351: `交換用注文を選択してください`,
  key1000352: `フィルタリングされた交換情報をExcelファイルにエクスポートします。`,
  key1000353: `受け取ていない商品／数量`,
  key1000354: `未取原因

`,
  key1000355: `接收倉庫

`,
  key1000356: `一括追加`,
  key1000357: `選択してください`,
  key1000358: `エクスポートしたが受け取ていない`,
  key1000359: `受け取ていない記録を保存済場合、在庫数が補充されます。`,
  key1000360: `注文番号：`,
  key1000361: `受け取てない理由：`,
  key1000362: `受取倉庫：`,
  key1000363: `他の`,
  key1000364: `<p>注文番号：`,
  key1000365: `注文は存在しません`,
  key1000366: `注文番号の集合の検索結果が空です！`,
  key1000367: `完全に記入してください`,
  key1000368: `受取てない注文を選択してください`,
  key1000369: `フィルタリングされた受け取てない情報をExcelファイルにエクスポートします。`,
  key1000370: `連絡先プラットフォーム：`,
  key1000371: `連絡窓口：`,
  key1000372: `返品カテゴリ：`,
  key1000373: `処理方法：`,
  key1000374: `物流運賃タイプ：`,
  key1000375: `運賃（発送）：`,
  key1000376: `払い戻し金額：`,
  key1000377: `備考：`,
  key1000378: `写真`,
  key1000379: `商品番号/商品名/規格`,
  key1000380: `注文数量`,
  key1000381: `返品数量`,
  key1000382: `購入者負担`,
  key1000383: `売手負担`,
  key1000384: `返品詳細の取得に失敗しました`,
  key1000385: `データ辞書の取得エラー`,
  key1000386: `修理種別：`,
  key1000387: `運賃（返品）：`,
  key1000388: `受取日:`,
  key1000389: `受信者：`,
  key1000390: `住所1：`,
  key1000391: `住所2/地区：`,
  key1000392: `市：`,
  key1000393: `県／市：`,
  key1000394: `国：`,
  key1000395: `電話番号：`,
  key1000396: `修理数`,
  key1000397: `交換種別：`,
  key1000398: `交換数量`,
  key1000399: `返品の商品`,
  key1000400: `交換商品`,
  key1000401: `商品を追加する`,
  key1000402: `返品受取倉庫と交換出荷倉庫を空にすることはできません`,
  key1000403: `返品商品および交換商品を空にすることはできません`,
  key1000404: `返品カテゴリ`,
  key1000405: `受入倉庫`,
  key1000406: `運賃（発送）`,
  key1000407: `返金金額`,
  key1000408: `返品を完成しました`,
  key1000409: `受取倉庫：`,
  key1000410: `返品エクスポート`,
  key1000411: `返品を完了する場合、在庫数が補充されます。`,
  key1000412: `受領日を選択してください`,
  key1000413: `受取倉庫を選択してください`,
  key1000414: `返品の注文を選択してください`,
  key1000415: `フィルタリングされた返品をExcelファイルにエクスポートします。`,
  key1000416: `商品番号商品名規格`,
  key1000417: `30日間の売上`,
  key1000418: `60日間の売り上げ`,
  key1000419: `合計売上高`,
  key1000420: `オーダー周期`,
  key1000421: `オーダー水線`,
  key1000422: `注文数`,
  key1000423: `仕入単価`,
  key1000424: `受取合格品/不良品`,
  key1000425: `今回受取合格品/不良品の数量`,
  key1000426: `不良品の備考`,
  key1000427: `メーカー：`,
  key1000428: `倉庫：`,
  key1000429: `到着予定日：`,
  key1000430: `注文した商品数：`,
  key1000431: `注文の合計：`,
  key1000432: `購入商品の総額:`,
  key1000433: `1688の合計金額：`,
  key1000434: `受け取てない：`,
  key1000435: `メーカー`,
  key1000436: `キーワードを入力してください`,
  key1000437: `購入日`,
  key1000438: `出荷倉庫`,
  key1000439: `1688仕入を支払いかどうか`,
  key1000440: `1688注文状態`,
  key1000441: `発注書タイプ`,
  key1000442: `発注番号`,
  key1000443: `カスタマイズ番号`,
  key1000444: `倉庫`,
  key1000445: `注文日`,
  key1000446: `到着予定日`,
  key1000447: `商品の種類の数`,
  key1000448: `商品の総量`,
  key1000449: `受け取った数量`,
  key1000450: `1688取引状態`,
  key1000451: `金額`,
  key1000452: `作成者`,
  key1000453: `注文書のステータス`,
  key1000454: `強制結審人`,
  key1000455: `強制結審日時`,
  key1000456: `発注書のエクスポート`,
  key1000457: `物流追跡`,
  key1000458: `物流情報`,
  key1000459: `カスタムフィルター`,
  key1000460: `カスタムフィルターを保存`,
  key1000461: `隠す`,
  key1000462: `削除`,
  key1000463: `注文書を追加する`,
  key1000464: `1688発注書を追加`,
  key1000465: `1688情報を同期する`,
  key1000466: `1688注文の支払い`,
  key1000467: `Alipay`,
  key1000468: `誠E賒`,
  key1000469: `跨境寶`,
  key1000470: `購入金額:`,
  key1000471: `1688金額:`,
  key1000472: `受取待ちを生成しました`,
  key1000473: `一部受領（完結されてない）`,
  key1000474: `受け取り`,
  key1000475: `Excelにエクスポート`,
  key1000476: `強制完結`,
  key1000477: `1688注文`,
  key1000478: `物流追跡`,
  key1000479: `エクスポートタスクが実行されています、`,
  key1000480: `画面を閉じることもできます、`,
  key1000481: `エクスポートタスクは、バックグラウンドで完了まで実行されます。`,
  key1000482: `あなたは次の場所に`,
  key1000483: `物流の状態:`,
  key1000484: `輸送会社：`,
  key1000485: `荷物追跡番号:`,
  key1000486: `1688の注文ではない`,
  key1000487: `続行を無視しますか？`,
  key1000488: `支払いは完了していますか?`,
  key1000489: `支払い済`,
  key1000490: `未払い`,
  key1000491: `支払い待ち`,
  key1000492: `商品発送待ち`,
  key1000493: `輸送業者の集荷に待っている`,
  key1000494: `お客様受取待ち`,
  key1000495: `お客様受取済み`,
  key1000496: `受取済み`,
  key1000497: `取引が成功しました`,
  key1000498: `取引キャンセル`,
  key1000499: `取引中止`,
  key1000500: `受取待ち`,
  key1000501: `カテゴリリストの照会に失敗しました`,
  key1000502: `同期に成功しました、後で確認してください`,
  key1000503: `同期に失敗しました`,
  key1000504: `1688注文に成功しました、注文結果を後で確認してください`,
  key1000505: `保存成功失敗`,
  key1000506: `同期対象発注書を選んでください`,
  key1000507: `1688個の発注書を最大30個まで選択して同期させる`,
  key1000508: `1688の注文を選択して同期してください`,
  key1000509: `保存名を入力してください`,
  key1000510: `支払い完了しました、情報を同期します`,
  key1000511: `支払いに失敗しました`,
  key1000512: `お支払い対象の1688の発注書を選択してください`,
  key1000513: `1688個の発注書を最大30個まで選択して支払できる`,
  key1000514: `1688注文を選択してお支払いください`,
  key1000515: `このフィルターは保存されていません`,
  key1000516: `正常に削除されました`,
  key1000517: `発注書を強制的完結すると、未受領の在庫が償却されます。`,
  key1000518: `削除しますか？`,
  key1000519: `注文書を選択してください`,
  key1000520: `選択した発注書をExcelファイルにエクスポートします。`,
  key1000521: `発注書の郵送に失敗しました`,
  key1000522: `進捗の照会に失敗しました`,
  key1000523: `在庫が商品オーダー限界値以下の場合、該当商品はオーダーリストに追加される。商品のオーダー数が30日販売数、60日販売数、オーダー周期に基づいて計算にお勧めします。`,
  key1000524: `荷物番号商品名`,
  key1000525: `希望メーカー/オーダー周期`,
  key1000526: `選んでください`,
  key1000527: `代替メーカー1/注文周期`,
  key1000528: `候補メーカー2／オーダー周期`,
  key1000529: `まず優先メーカーを選択してください`,
  key1000530: `希望メーカーと代替メーカーを同じにすることはできません`,
  key1000531: `代替ベンダー2を代替ベンダー1と同じにすることはできません`,
  key1000532: `まず代替メーカーを選択してください`,
  key1000533: `内容を入力してください`,
  key1000534: `日付を選択`,
  key1000535: `移動中在庫/既存良品在庫`,
  key1000536: `最小購買量`,
  key1000537: `推奨注文数量`,
  key1000538: `購買単価`,
  key1000539: `通貨＃ツウカ＃`,
  key1000540: `各倉庫の在庫`,
  key1000541: `輸送中の在庫`,
  key1000542: `既存の合格品在庫`,
  key1000543: `到着予定日`,
  key1000544: `新製品`,
  key1000545: `その他の倉庫在庫の表示`,
  key1000546: `他の倉庫にはデータがありません`,
  key1000547: `他の倉庫在庫の照会に失敗しました`,
  key1000548: `商品の追加に失敗しました`,
  key1000549: `発注書の詳細取得に失敗`,
  key1000550: `メーカーを選択してください`,
  key1000551: `倉庫を選択してください`,
  key1000552: `カスタマイズ番号を重複することはできません`,
  key1000553: `このリストは、1688ベンダーを取得したり、1688注文を生成したりするための1688バイヤーアカウントのリストです。`,
  key1000554: `アカウント名`,
  key1000555: `1688アカウント名`,
  key1000556: `期限切れの時間。`,
  key1000557: `認証ステータス`,
  key1000558: `認証された従業員`,
  key1000559: `認証を追加`,
  key1000560: `編集`,
  key1000561: `再認証`,
  key1000562: `無効`,
  key1000563: `有効`,
  key1000564: `1688承認リストの照会が失敗しました`,
  key1000565: `ライセンスを再度与えるかどうか`,
  key1000566: `1688の認証URLの取得に失敗しました`,
  key1000567: `従業員の詳細を照会に失敗しました`,
  key1000568: `新しい従業員を追加に失敗しました`,
  key1000569: `このアカウントを利用停止にしますか?`,
  key1000570: `利用停止成功`,
  key1000571: `利用停止失敗`,
  key1000572: `認証に成功しました`,
  key1000573: `サービスエラー`,
  key1000574: `アカウントまたはアカウントコードはすでに存在してます`,
  key1000575: `アカウントが存在しません`,
  key1000576: `アカウントが一致しません`,
  key1000577: `認証に失敗しました`,
  key1000578: `ここでメーカープロファイルを管理します。`,
  key1000579: `メーカータイプ`,
  key1000580: `メーカー番号`,
  key1000581: `メーカー名`,
  key1000582: `連絡窓口`,
  key1000583: `連絡先番号`,
  key1000584: `メーカーのホームページ`,
  key1000585: `一括削除`,
  key1000586: `増加`,
  key1000587: `メーカー番号を空にすることはできません`,
  key1000588: `メーカー名を空にすることはできません`,
  key1000589: `メーカーを追加`,
  key1000590: `デフォルトでは選択していない`,
  key1000591: `未処理の注文があります`,
  key1000592: `メーカーコードを重複することはできません`,
  key1000593: `メーカー名を重複することはできません`,
  key1000594: `メーカーを編集`,
  key1000595: `メーカーを選択してください`,
  key1000596: `廠商檔案刪除後將不可恢復， 請謹慎決定。 `,
  key1000597: `メーカーを削除する`,
  key1000598: `1時間は再計算することできません`,
  key1000599: `現在/輸送中の在庫`,
  key1000600: `合格品在庫`,
  key1000601: `再計算`,
  key1000602: `メーカーを変更する`,
  key1000603: `ダイヤルアップ倉庫の選択`,
  key1000604: `選択した発注書を生成する`,
  key1000605: `すべての発注書を生成する`,
  key1000606: `ダイヤルアップの生成`,
  key1000607: `その他の倉庫の在庫`,
  key1000608: `オーダーリストの計算には大量のデータの集計が必要で、時間がかかる場合がありますのでご注意ください。一度計算すると、1時間以内に再計算することはできません。`,
  key1000609: `注文リストを選択してください`,
  key1000610: `ダイヤルアップオーダーの生成選択した倉庫は一貫している必要があります`,
  key1000611: `倉庫リストの取得に失敗しました`,
  key1000612: `倉庫の商品状況の照会に失敗しました`,
  key1000613: `ダイヤルアップオーダーの生成に失敗しました`,
  key1000614: `クエリに失敗しました`,
  key1000615: `再計算に失敗しました`,
  key1000616: `合計`,
  key1000617: `在庫詳細の照会に失敗しました`,
  key1000618: `保存して認証する`,
  key1000619: `アカウント編集`,
  key1000620: `アカウント名は必須項目`,
  key1000621: `商品情報`,
  key1000622: `発注リンク`,
  key1000623: `新規の商品関連付け`,
  key1000624: `商品を追加してください`,
  key1000625: `発注リンクを入力してください`,
  key1000626: `このリストは、1688メーカーのローカル商品と1688プラットフォームの商品との関連付けです`,
  key1000627: `1688関連商品`,
  key1000628: `skuを入力してください`,
  key1000629: `ローカル製品情報`,
  key1000630: `1688製品情報`,
  key1000631: `関連を編集する`,
  key1000632: `編集をキャンセル`,
  key1000633: `商品が照会できない場合は、発注リンクが正しいか確認してください`,
  key1000634: `未識別`,
  key1000635: `関連付けられていません`,
  key1000636: `関連済`,
  key1000637: `メーカーの参照に失敗`,
  key1000638: `連関変更に失敗`,
  key1000639: `発注関連リストの照会失敗`,
  key1000640: `受領数量　合格品/不良品`,
  key1000641: `受け取たの記録`,
  key1000642: `受領時間`,
  key1000643: `合格品入庫数量`,
  key1000644: `受け取った不良品の数量`,
  key1000645: `不良品備考`,
  key1000646: `受取人`,
  key1000647: `カスタマイズ番号：`,
  key1000648: `作成者：`,
  key1000649: `1688アカウント`,
  key1000650: `受取先住所`,
  key1000651: `1688注文取引方法`,
  key1000652: `仕入倉庫`,
  key1000653: `商品届ける予定日`,
  key1000654: `1688購入者からメッセージ`,
  key1000655: `合計`,
  key1000656: `保存して1688に注文する`,
  key1000657: `認証アカウントを追加する`,
  key1000658: `リフレッシュ`,
  key1000659: `普通大口注文`,
  key1000660: `分譲注文`,
  key1000661: `自動的に選択`,
  key1000662: `alipayは取引を保証する`,
  key1000663: `ストアアカウントを空にすることはできない`,
  key1000664: `誠E賒`,
  key1000665: `アドレスの同期に成功しました`,
  key1000666: `配送先を照会に失敗しました`,
  key1000667: `1688アカウントを選択してください`,
  key1000668: `1688注文成功`,
  key1000669: `関連付けを追加`,
  key1000670: `関連付け取消`,
  key1000671: `1688商品の関連情報を照会に失敗しました`,
  key1000672: `バインディング関連を削除するか`,
  key1000673: `連関キャンセル成功`,
  key1000674: `連関キャンセル失敗`,
  key1000675: `操作対象データを選んでください`,
  key1000676: `新規の商品関連付け成功`,
  key1000677: `新規の商品関連付け失敗`,
  key1000678: `商品関連付けの変更が成功しました`,
  key1000679: `商品関連付けの変更が失敗しました`,
  key1000680: `添加部件`,
  key1000681: `預覽`,
  key1000682: `テンプレート名を入力してください`,
  key1000683: `佈局`,
  key1000684: `システム通知`,
  key1000685: `显示系统的站内信通知`,
  key1000686: `通知最近快到期的店鋪，可以點擊續費`,
  key1000687: `銷量分析`,
  key1000688: `平台/店鋪銷量`,
  key1000689: `已平台或店铺为维度统计平台的销量数据`,
  key1000690: `產品分析`,
  key1000691: `刊登產品數`,
  key1000692: `顯示系統中總的刊登產品數`,
  key1000693: `最暢銷產品`,
  key1000694: `顯示產品銷量最高的產品或系統商品`,
  key1000695: `最暢銷分类`,
  key1000696: `顯示最暢銷我的商品中銷量最高的分類`,
  key1000697: `待處理事項`,
  key1000698: `顯示訂單目前需要處理的訂單數量`,
  key1000699: `售後管理`,
  key1000700: `顯示售後目前需要處理的訂單數量`,
  key1000701: `顯示採購目前需要處理的採購單`,
  key1000702: `顯示目前倉庫需要處理的採購單`,
  key1000703: `修改佈局`,
  key1000704: `店舗ライセンス`,
  key1000705: `ライセンス後にEasySellersを起動して、迅速な掲載、注文処理など多くの機能を楽しむことができます！`,
  key1000706: `テンプレートを追加する`,
  key1000707: `默認模板`,
  key1000708: `プラットフォームリストの照会に失敗しました`,
  key1000709: `是否刪除當前模板?`,
  key1000710: `テンプレートの削除に失敗しました`,
  key1000711: `部件不能為空`,
  key1000712: `修改模板成功`,
  key1000713: `新增模板成功`,
  key1000714: `修改模板失敗`,
  key1000715: `新增模板失敗`,
  key1000716: `當前模板尚未保存, 是否放棄保存當前模板?`,
  key1000717: `查詢模板列表失敗`,
  key1000718: `最多添加6個部件`,
  key1000719: `テンプレート名は必須項目`,
  key1000720: `模板名稱已存在`,
  key1000721: `最初のコードを入力してください`,
  key1000722: `テールコードを入力してください`,
  key1000723: `SKU混同解析機能設定`,
  key1000724: `オンライン製品の掲載または同期時に、店舗SKUとシステム在庫SKUのマッチング関係を自動的にルールに基づいて構築する`,
  key1000725: `【店舗SKU】と【在庫SKU】が一致した場合のみ自動照合し、照合が成功した後は他の切り取り規則による照合は行わない`,
  key1000726: `カスタムSKU切り取りマッチング機能をオンにする`,
  key1000727: `優先照合在庫SKUをオープンするかどうか`,
  key1000728: `チェックする時：優先的に在庫SKUでマッチングし、マッチング上は他のルールでマッチングしなくなり、マッチング上は切り取りルールでマッチングし、マッチング上は【店舗SKUと在庫SKUの関連関係】でマッチングする`,
  key1000729: `チェックされていない場合：切り取りルールのみに基づいてマッチングし、切り取りが成功すると自動的にマッチングし、再マッチングができない場合は【店舗SKUと在庫SKUとの関連関係】に従ってマッチングする`,
  key1000730: `前、尾コードで切り取る`,
  key1000731: `ビット数による切り取り`,
  key1000732: `先頭を切る`,
  key1000733: `テールコードを取る`,
  key1000734: `前のXビットを取り除く`,
  key1000735: `後ろのXビットを削除`,
  key1000736: `テストサンプル`,
  key1000737: `テスト`,
  key1000738: `テスト結果：`,
  key1000739: `テスト結果がNULLです`,
  key1000740: `編集に失敗しました`,
  key1000741: `クエリに失敗しました`,
  key1000742: `ロール名`,
  key1000743: `ロール名を入力してください`,
  key1000744: `権限`,
  key1000745: `モジュール`,
  key1000746: `すべて選択`,
  key1000747: `すべて選択（`,
  key1000748: `権限を空にすることはできません`,
  key1000749: `ロール名を重複ことはできません`,
  key1000750: `受信者の携帯電話番号`,
  key1000751: `受信者の携帯電話番号を入力してください`,
  key1000752: `送信時刻`,
  key1000753: `受信者の市外局番`,
  key1000754: `テンプレート名`,
  key1000755: `SMS支出`,
  key1000756: `SMS分割件数`,
  key1000757: `最終更新時刻`,
  key1000758: `再送`,
  key1000759: `失敗`,
  key1000760: `成功`,
  key1000761: `SMSテンプレート`,
  key1000762: `記録を送信する`,
  key1000763: `再送メッセージを選択してください`,
  key1000764: `再送信に成功しました`,
  key1000765: `支払方法`,
  key1000766: `支払勘定番号`,
  key1000767: `サービス内容`,
  key1000768: `種類`,
  key1000769: `プロアクティブ購入`,
  key1000770: `自動控除`,
  key1000771: `EasySellersでの購入に使用される支払方法`,
  key1000772: `取引明細書`,
  key1000773: `メッセージの内容`,
  key1000774: `メッセージ・タイプ`,
  key1000775: `ステーションレター`,
  key1000776: `詳細を確認してください`,
  key1000777: `メッセージ一覧の照会エラー`,
  key1000778: `関連情報が見つかりません`,
  key1000779: `公開日：`,
  key1000780: `メッセージ詳細の取得に失敗しました`,
  key1000781: `受信者のメールアドレス`,
  key1000782: `受信者のメールアドレスを入力してください`,
  key1000783: `メールテンプレート名`,
  key1000784: `失敗の原因`,
  key1000785: `メールテンプレート`,
  key1000786: `再送信対象メールを選択してください`,
  key1000787: `優先順位`,
  key1000788: `一番上にする`,
  key1000789: `一番下に`,
  key1000790: `ドラッグして配置`,
  key1000791: `1レベルを上げる`,
  key1000792: `1レベル下げる`,
  key1000793: `規則名`,
  key1000794: `倉庫名をマッピング`,
  key1000795: `最終更新日時`,
  key1000796: `出荷倉庫をマッピング`,
  key1000797: `新規追加`,
  key1000798: `オプション条件`,
  key1000799: `プラットフォームショップ`,
  key1000800: `プラットフォーム/サイト/ストア`,
  key1000801: `到着国`,
  key1000802: `注文商品`,
  key1000803: `注文の合計金額`,
  key1000804: `注文合計`,
  key1000805: `購入者は輸送方法を指定します`,
  key1000806: `出荷倉庫`,
  key1000807: `アドレスにキーワードが含まれています`,
  key1000808: `カスタマーサービス備考にはキーワードが含まれています`,
  key1000809: `支店名`,
  key1000810: `選択された条件`,
  key1000811: `トリガーアクション`,
  key1000812: `（上記で選択した条件が満たされた場合にアクションが有効になります）`,
  key1000813: `運送会社を選択してください`,
  key1000814: `配送方法を選択してください`,
  key1000815: `規則名を入力してください`,
  key1000816: `規則を追加してください`,
  key1000817: `規則リストの照会に失敗しました`,
  key1000818: `配送方法の照会に失敗しました`,
  key1000819: `規則を削除しますか`,
  key1000820: `規則削除`,
  key1000821: `規則の削除に成功しました`,
  key1000822: `規則の削除に失敗しました`,
  key1000823: `規則は正常に保存しました`,
  key1000824: `規則の保存に失敗しました`,
  key1000825: `プラットフォームサイトリストの照会に失敗しました`,
  key1000826: `ストアリストの照会に失敗しました`,
  key1000827: `規則編集`,
  key1000828: `規則の詳細を取得できませんでした`,
  key1000829: `並べ替えを正常に変更しました`,
  key1000830: `規則リストの並べ替えに失敗しました`,
  key1000831: `読み込み中`,
  key1000832: `トリガー時間`,
  key1000833: `注文の関連顧客の顧客情報の携帯電話番号が送信対象になる`,
  key1000834: `SMSテンプレートを送信する`,
  key1000835: `携帯電話市外局番`,
  key1000836: `利用開始`,
  key1000837: `利用停止`,
  key1000838: `メッセージを送信`,
  key1000839: `編集できるオプションをクリックしてください`,
  key1000840: `保証が必要な商品が注文書に含まれていますか`,
  key1000841: `(同じ条件が或はの関係、異なる条件は且つの関系)`,
  key1000842: `注文商品が発送される（出荷される）とき`,
  key1000843: `オフライン注文が導入され、且つステータスが出荷済場合`,
  key1000844: `注文実行交換時`,
  key1000845: `ステータスの更新に成功しました`,
  key1000846: `配送アカウント番号を入力してください`,
  key1000847: `物流ルートをマッチングする`,
  key1000848: `運送会社をマッチングする`,
  key1000849: `輸送方法をマッチングする`,
  key1000850: `輸送アカウント`,
  key1000851: `輸送方法：`,
  key1000852: `注文の関連顧客の顧客情報のメールアドレスが送信対象になる`,
  key1000853: `メールテンプレートを送信する`,
  key1000854: `メールを送信`,
  key1000855: `責任者`,
  key1000856: `レストラン名`,
  key1000857: `透かしの内容`,
  key1000858: `作成時間`,
  key1000859: `即決`,
  key1000860: `競売`,
  key1000861: `注文番号`,
  key1000862: `選択した店舗`,
  key1000863: `ショートメッセージチャージ方式`,
  key1000864: `チャージ金額`,
  key1000865: `カスタム入力`,
  key1000866: `こていちいき`,
  key1000867: `メッセージ数`,
  key1000868: `コース内容`,
  key1000869: `購入時間`,
  key1000870: `PayPalアカウントに接続し、それを使用してアカウントを支払います。支払情報を追加するためにPayPalにリダイレクトされます`,
  key1000871: `エラーメッセージ`,
  key1000872: `SMS金額チャージ`,
  key1000873: `固定地域ショートメッセージチャージ`,
  key1000874: `カスタマイズ`,
  key1000875: `バー`,
  key1000876: `月`,
  key1000877: `計算中...`,
  key1000878: `現在バインドされているアカウント：`,
  key1000879: `即時支払い`,
  key1000880: `支払の取消`,
  key1000881: `プラットフォームのリストに戻る`,
  key1000882: `選択したデータは割引されます`,
  key1000883: `取`,
  key1000884: `削除`,
  key1000885: `確`,
  key1000886: `firm`,
  key1000887: `ショートメッセージの数は100の倍数にしかなりません`,
  key1000888: `折る`,
  key1000889: `メッセージの所在地の照会に失敗しました`,
  key1000890: `支払オーダが存在しないか期限切れです`,
  key1000891: `支払オーダ詳細の取得に失敗しました`,
  key1000892: `半月`,
  key1000893: `ヶ月`,
  key1000894: `1四半期`,
  key1000895: `支払の作成に失敗しました。再試行してください`,
  key1000896: `現在のページを終了するかどうか`,
  key1000897: `支払チャネルの取得に失敗しました`,
  key1000898: `不正なパラメータ`,
  key1000899: `該当リストは、システムより支援する販売プラットフォームを示している。新たのカスタムプラットフォームを追加することができます。カスタムプラットフォームは' C- 'で始まる`,
  key1000900: `自動継続料金の設定`,
  key1000901: `一括購入`,
  key1000902: `一括継続料金`,
  key1000903: `授權`,
  key1000904: `継続期間を選択してください`,
  key1000905: `月賦金`,
  key1000906: `年賦金`,
  key1000907: `プラットフォームリストのアカウント数のクエリに失敗しました`,
  key1000908: `店舗詳細の照会に失敗しました`,
  key1000909: `名称が重複する`,
  key1000910: `保存に失敗しました`,
  key1000911: `店舗リストの取得に失敗しました`,
  key1000912: `承認中です。まもなく次のステップへ`,
  key1000913: `承認に失敗しました。後で再試行してください`,
  key1000914: `ebay認証に失敗しました。後で再試行してください`,
  key1000915: `プラットフォームストアを削除すると、復元できなくなります。このストアの注文データはすべて削除されます。慎重に決定してください。`,
  key1000916: `当該店舗アカウントは無料店舗アカウントであり、削除後は当該無料店舗の権益を享受しなくなりますので、慎重に削除してください`,
  key1000917: `ストアを削除`,
  key1000918: `同期に成功しました`,
  key1000919: `coupanアドレスの同期に失敗しました`,
  key1000920: `サイトリストの取得に失敗しました`,
  key1000921: `アカウントまたはアカウントコードはすでに存在します`,
  key1000922: `ストアはすでに存在します`,
  key1000923: `店舗修正に失敗しました`,
  key1000924: `新規店舗の失敗`,
  key1000925: `サイト検索失敗`,
  key1000926: `削除するかどうか`,
  key1000927: `プラットフォームを削除する`,
  key1000928: `プラットフォームの下に店舗があるので、削除してから作業を行ってください`,
  key1000929: `プラットフォームの削除に失敗しました`,
  key1000930: `購入する店舗を選んでください`,
  key1000931: `支払オーダの作成に失敗しました`,
  key1000932: `自動更新のオープンに失敗しました`,
  key1000933: `自動継続料金のキャンセルを確認しますか？キャンセル後はサービス終了日の控除は行われません`,
  key1000934: `シャットダウン成功！`,
  key1000935: `自動チャージオフに失敗しました`,
  key1000936: `サイト`,
  key1000937: `サイトを選択してください`,
  key1000938: `配送方法`,
  key1000939: `メールの件名を入力してください`,
  key1000940: `添付ファイルアップロード`,
  key1000941: `Word、PDF、Excel、PPTファイルをここにドラッグするか、`,
  key1000942: `添付ファイルは最大5つまでアップロード出来ます`,
  key1000943: `電子メールテンプレートを編集する`,
  key1000944: `電子メールテンプレートを追加する`,
  key1000945: `本文を入力してください...`,
  key1000946: `店舗のアカウントを入力してください`,
  key1000947: `アカウントIDを入力してください`,
  key1000948: `accessKeyを入力してください`,
  key1000949: `secretKeyを入力してください`,
  key1000950: `Seller ApiKeyを入力してください`,
  key1000951: `ユーザーIDを入力してください`,
  key1000952: `パスワードを入力してください`,
  key1000953: `所属するプラットフォーム`,
  key1000954: `店舗名`,
  key1000955: `「shopline店舗の機能変数名の最初のコードを記入します。例えば：zhangsan.myshopline.comの店舗名：zhangsan”`,
  key1000956: `ストアアカウント`,
  key1000957: `アカウントID`,
  key1000958: `販売エリア`,
  key1000959: `サイト`,
  key1000960: `ストアロゴ`,
  key1000961: `有効期間`,
  key1000962: `パスワード`,
  key1000963: `米国東部地域`,
  key1000964: `米国西部地域`,
  key1000965: `ヨーロッパ地域`,
  key1000966: `ファイルを選ぶ`,
  key1000967: `プラットフォームを選択してください`,
  key1000968: `店舗名を入力してください`,
  key1000969: `ストアを追加`,
  key1000970: `ストアを編集`,
  key1000971: `販売エリアを選択してください`,
  key1000972: `このオプションは必須項目`,
  key1000973: `システムが接続成功しました`,
  key1000974: `貨物追跡URL`,
  key1000975: `顧客コード`,
  key1000976: `キー（トークン）`,
  key1000977: `集荷サイト`,
  key1000978: `コードを入力してください`,
  key1000979: `差出人名`,
  key1000980: `差出人電話`,
  key1000981: `差出人住所`,
  key1000982: `差出人の市`,
  key1000983: `差出人の市/県`,
  key1000984: `差出人国`,
  key1000985: `輸送方法名`,
  key1000986: `バインドされている実際の輸送方法`,
  key1000987: `代金引換通貨と為替レート`,
  key1000988: `輸送方法を追加`,
  key1000989: `コード`,
  key1000990: `名前`,
  key1000991: `アカウントを追加する`,
  key1000992: `eBay輸送方法のバインディングを追加`,
  key1000993: `サイト：`,
  key1000994: `運送会社を追加する`,
  key1000995: `託送会社名を入力してください`,
  key1000996: `運送会社`,
  key1000997: `API連結`,
  key1000998: `連結されていません`,
  key1000999: `会社名`,
  key1001000: `アカウントを追加する`,
  key1001001: `相対米ドル為替レート`,
  key1001002: `託送会社名：`,
  key1001003: `現在のアカウントを削除するかどうか`,
  key1001004: `サイトは必須項目`,
  key1001005: `配送方法は必須項目`,
  key1001006: `コードを空にすることはできません`,
  key1001007: `名前は必須項目`,
  key1001008: `運送会社のアカウント番号が重複しています`,
  key1001009: `運送会社の名前は違法であり、存在できません＃`,
  key1001010: `運送会社のアカウント名は不法であり、保存することはできません。`,
  key1001011: `正常に削除されました`,
  key1001012: `削除に失敗しました`,
  key1001013: `ステータスの変更に失敗しました`,
  key1001014: `削除に失敗しました`,
  key1001015: `アカウントが存在しません`,
  key1001016: `操作に失敗しました`,
  key1001017: `Ebayサイトのリクエストに失敗しました`,
  key1001018: `インターフェースの照会に失敗しました`,
  key1001019: `運送会社の編集`,
  key1001020: `運送会社名を重複しています`,
  key1001021: `配送方法名を編集する`,
  key1001022: `編集に失敗する`,
  key1001023: `集荷倉庫のリストを取得できませんでした`,
  key1001024: `重複の配送方法`,
  key1001025: `照会できませんでしたｘｘ`,
  key1001026: `この運送会社を無効にするかどうか`,
  key1001027: `有効化成功`,
  key1001028: `運送会社リストの照会に失敗しました`,
  key1001029: `ロールを追加`,
  key1001030: `SMS署名`,
  key1001031: `API連結ステータス`,
  key1001032: `マーケティング承認ステータス`,
  key1001033: `eBayは支払いを管理しています`,
  key1001034: `有効期限`,
  key1001035: `無料ユーザー`,
  key1001036: `オフ`,
  key1001037: `オン`,
  key1001038: `再認証`,
  key1001039: `販売承認`,
  key1001040: `返品先の同期`,
  key1001041: `輸送と支払い方法を同期する`,
  key1001042: `自動継続料金の取消`,
  key1001043: `支店名を入力してください`,
  key1001044: `カスタマーサービス備考に、次のようなキーワードが含まれています`,
  key1001045: `支店名は`,
  key1001046: `購入者は輸送方法を指定しました：`,
  key1001047: `はい`,
  key1001048: `いいえ`,
  key1001049: `保証が必要な商品が含まれていますか`,
  key1001050: `アドレスのキーワードは`,
  key1001051: `注文金額は`,
  key1001052: `通貨は`,
  key1001053: `注文の合計重量は`,
  key1001054: `出荷倉庫は`,
  key1001055: `プラットフォームは`,
  key1001056: `サイトは`,
  key1001057: `店は`,
  key1001058: `到着国は`,
  key1001059: `プラットフォーム/ルート名`,
  key1001060: `カスタムプラットフォーム/ルート名を入力してください`,
  key1001061: `カスタムプラットフォームを追加`,
  key1001062: `アドレスのキーワードを入力してください、複数の場合、英字「,」で区切りで入力してください`,
  key1001063: `アドレスキーワードは5つまで追加できる`,
  key1001064: `広東省深セン市南山区南山智園C 1棟6階`,
  key1001065: `説明`,
  key1001066: `量`,
  key1001067: `付加価値税率`,
  key1001068: `単価`,
  key1001069: `含む`,
  key1001070: `付加価値税`,
  key1001071: `（材料）801-Heraクラシックシリーズ卵殻白面`,
  key1001072: `合計価格`,
  key1001073: `ご注文ありがとうございます`,
  key1001074: `住所（1`,
  key1001075: `住所1を入力してください`,
  key1001076: `住所を入力してください`,
  key1001077: `売り手情報`,
  key1001078: `会社名を入力してください`,
  key1001079: `アドレス2`,
  key1001080: `住所2を入力してください`,
  key1001081: `市`,
  key1001082: `市を入力ください`,
  key1001083: `地域/国`,
  key1001084: `地域/国を入力してください`,
  key1001085: `税金番号`,
  key1001086: `税金番号を入力してください`,
  key1001087: `携帯番号`,
  key1001088: `携帯番号を入力してください`,
  key1001089: `ファクシミリ`,
  key1001090: `ファックスを入力してください`,
  key1001091: `メールボックス`,
  key1001092: `メールアドレスを入力してください`,
  key1001093: `サプライヤ`,
  key1001094: `仕入先を入力してください`,
  key1001095: `仕入先所在地`,
  key1001096: `仕入先住所を入力してください`,
  key1001097: `連絡番号：`,
  key1001098: `請求書日付`,
  key1001099: `請求書番号`,
  key1001100: `請求書金額`,
  key1001101: `顧客情報`,
  key1001102: `電話番号：`,
  key1001103: `送信者情報`,
  key1001104: `オーダー情報`,
  key1001105: `注文日：`,
  key1001106: `請求書詳細`,
  key1001107: `（付加価値税は含まれていません）`,
  key1001108: `（付加価値税を含む）`,
  key1001109: `単項総額`,
  key1001110: `請求書合計`,
  key1001111: `付加価値税の総額`,
  key1001112: `順豊エクスプレス`,
  key1001113: `ABC店舗`,
  key1001114: `商品名`,
  key1001115: `製品の総額`,
  key1001116: `コメントを入力してください`,
  key1001117: `請求書番号：`,
  key1001118: `請求書日付：`,
  key1001119: `注文番号：`,
  key1001120: `注文時間：`,
  key1001121: `会社名：`,
  key1001122: `会社住所：`,
  key1001123: `税金番号：`,
  key1001124: `携帯番号：`,
  key1001125: `ファックス：`,
  key1001126: `メールアドレス：`,
  key1001127: `メールアドレス`,
  key1001128: `購入者名：`,
  key1001129: `出荷先住所：`,
  key1001130: `電話番号：`,
  key1001131: `商品小計：`,
  key1001132: `税込み小計：`,
  key1001133: `割引金額：`,
  key1001134: `託送費用：`,
  key1001135: `注文金額：`,
  key1001136: `請求書コメント：`,
  key1001137: `カスタマーサービス備考のキーワードを入力してください、複数の場合、英字「,」で区切りで入力してください`,
  key1001138: `クリア`,
  key1001139: `注文の最小金額が入力されていない、注文の合計金額がクリアされます`,
  key1001140: `注文の最大金額が入力されていない、注文の合計金額がクリアされます`,
  key1001141: `通貨が選択されていない、注文の合計金額がクリアされます`,
  key1001142: `注文の金額の最大値は最小値を下回ることはできない、注文合計金額がクリアされます`,
  key1001143: `注文合計重量`,
  key1001144: `注文合計重量（KG）`,
  key1001145: `注文の最小合計量が入力されていない、注文の合計重量がクリアされます`,
  key1001146: `注文の最大合計量が入力されていない、注文の合計重量がクリアされます`,
  key1001147: `注文の合計重量の最大値は最小値を下回ることはできない、注文合計重量がクリアされます`,
  key1001148: `アップデータ実行者`,
  key1001149: `ご注文に該当する【店舗名】`,
  key1001150: `ご注文に該当する【お客様名】`,
  key1001151: `ご注文に該当する【注文番号】`,
  key1001152: `ご注文に該当する【商品名】`,
  key1001153: `保証確認用URL`,
  key1001154: `以下の為替レートはすべて米ドルに対するものであり、リアルタイムの為替レートはその日の最新の為替レートです。`,
  key1001155: `通貨名`,
  key1001156: `通貨コード`,
  key1001157: `公定為替レート`,
  key1001158: `為替レート`,
  key1001159: `為替レートの種類`,
  key1001160: `最終更新時刻`,
  key1001161: `設定為替レート`,
  key1001162: `固定為替レート`,
  key1001163: `リアルタイムの為替レート`,
  key1001164: `為替レートは必須項目`,
  key1001165: `従業員リストの取得に失敗しました`,
  key1001166: `ヒントに従って操作してください`,
  key1001167: `承認に成功しました`,
  key1001168: `サービスエラー`,
  key1001169: `アカウントまたはアカウントプログラムコードはすでに存在します`,
  key1001170: `アカウントが存在しません`,
  key1001171: `アカウントが一致しません`,
  key1001172: `承認に失敗しました`,
  key1001173: `パラメータエラー`,
  key1001174: `う従業員アカウント、従業員の役割、および役割の権限を該当画面に管理しています。`,
  key1001175: `スタッフ`,
  key1001176: `ロール＃ロール＃`,
  key1001177: `従業員の名前`,
  key1001178: `選択したロール`,
  key1001179: `無効化/有効化`,
  key1001180: `ロール名`,
  key1001181: `関連サブ勘定科目番号`,
  key1001182: `管理者`,
  key1001183: `利用禁止`,
  key1001184: `正しいメールアドレスを入力してください`,
  key1001185: `従業員の変更に失敗しました`,
  key1001186: `ロールリストの取得`,
  key1001187: `新しい従業員を追加する`,
  key1001188: `ロールの編集`,
  key1001189: `スタッフを編集する`,
  key1001190: `無効にしますか？`,
  key1001191: `従業員の非アクティブ化に失敗しました`,
  key1001192: `有効化に失敗しました`,
  key1001193: `ロールは使用中であり、削除できません`,
  key1001194: `ロールの削除に失敗しました`,
  key1001195: `テンプレートタイプ`,
  key1001196: `ログイン用認証コード`,
  key1001197: `マーケティング`,
  key1001198: `認証`,
  key1001199: `保証メールテンプレート`,
  key1001200: `認証用メールを削除した場合、認証コードをメールで送信できなくなりますため、慎重に削除してください。`,
  key1001201: `該当テンプレートを削除しますか`,
  key1001202: `テンプレートを削除`,
  key1001203: `テンプレートが正常に削除されました`,
  key1001204: `透かしタイプ`,
  key1001205: `私の透かし`,
  key1001206: `透かしレイアウト`,
  key1001207: `サイズ変更`,
  key1001208: `フォントタイプ`,
  key1001209: `フォントサイズ`,
  key1001210: `フォントの色`,
  key1001211: `透かしの間隔`,
  key1001212: `透かしを回転させる`,
  key1001213: `透明性`,
  key1001214: `透かしの場所`,
  key1001215: `テンプレート情報`,
  key1001216: `画像透かし`,
  key1001217: `テキスト透かし`,
  key1001218: `カスタマイズ`,
  key1001219: `タイル`,
  key1001220: `加粗`,
  key1001221: `効果プレビュー`,
  key1001222: `微軟雅黒`,
  key1001223: `仿宋`,
  key1001224: `明朝体`,
  key1001225: `黒体`,
  key1001226: `楷体`,
  key1001227: `幼圆`,
  key1001228: `ストレッチ（ボーダー）`,
  key1001229: `再購入`,
  key1001230: `有効期限：`,
  key1001231: `クエリエラー`,
  key1001232: `ここに倉庫、発送用倉庫を設定します。`,
  key1001233: `倉庫名`,
  key1001234: `倉庫の非アクティブ化/有効化`,
  key1001235: `県／市`,
  key1001236: `国/地域`,
  key1001237: `郵便番号`,
  key1001238: `差出人`,
  key1001239: `倉庫名を空にすることはできません`,
  key1001240: `OKこの倉庫を無効にするには、受注、発注、在庫がある倉庫は無効にできません`,
  key1001241: `OK倉庫を有効にするには、有効にすると倉庫に選択できます`,
  key1001242: `倉庫使用中、無効化に失敗しました`,
  key1001243: `倉庫は注文書、発注書、在庫がある場合、削除できません、慎重にしてください。`,
  key1001244: `倉庫を削除する`,
  key1001245: `倉庫が使用れている、削除できません`,
  key1001246: `倉庫を変更する`,
  key1001247: `倉庫名はすでに存在します`,
  key1001248: `倉庫を追加`,
  key1001249: `テンプレート名：`,
  key1001250: `言語`,
  key1001251: `消費税（VAT）`,
  key1001252: `消費税率`,
  key1001253: `請求書番号の生成`,
  key1001254: `請求書接頭辞`,
  key1001255: `テンプレート構成`,
  key1001256: `テンプレートサイズ：`,
  key1001257: `買い手情報`,
  key1001258: `買い手名`,
  key1001259: `請求書情報`,
  key1001260: `注文時間`,
  key1001261: `バーコード`,
  key1001262: `物流会社`,
  key1001263: `託送費用`,
  key1001264: `割引金額`,
  key1001265: `請求書コメント`,
  key1001266: `請求書タイプ1`,
  key1001267: `請求書タイプ2`,
  key1001268: `請求書タイプ3`,
  key1001269: `請求書タイプ4`,
  key1001270: `消費税なし`,
  key1001271: `商品には消費税込み`,
  key1001272: `商品には消費税が含まれていません`,
  key1001273: `支払い時間`,
  key1001274: `印刷時間`,
  key1001275: `エクスポートファイルのダウンロード有効期間は7日、期間過ぎるとダウンロードできなくなります。`,
  key1001276: `タスクタイプを選択してください`,
  key1001277: `収集アドレスを入力し、複数の場合Enterキーを使用して改行で入力してください、一度に最大10個を収集できます。`,
  key1001278: `採集の出典：`,
  key1001279: `製品タイトル`,
  key1001280: `タイトル`,
  key1001281: `販売価格`,
  key1001282: `受領済プラットフォーム`,
  key1001283: `採集時間`,
  key1001284: `リンク採集`,
  key1001285: `eBayを利用可能`,
  key1001286: `対応しているプラットフォーム`,
  key1001287: `プラグインをダウンロード`,
  key1001288: `Shopifyのリンクですか？`,
  key1001289: `収集を開始します`,
  key1001290: `収集を開始します`,
  key1001291: `受領`,
  key1001292: `個製品は移動しました。下書きボックスに確認してください`,
  key1001293: `件データ採集は成功しました`,
  key1001294: `受領中`,
  key1001295: `製品受領中`,
  key1001296: `採集情報が空です`,
  key1001297: `最大10本まで接続できるので、接続数を減らしてください`,
  key1001298: `eBay接続を他の接続と一緒に採集することはできません`,
  key1001299: `採集中…`,
  key1001300: `パス収集プラグインをインストールして収集してください！`,
  key1001301: `クリックしてインストールチュートリアルを表示`,
  key1001302: `削除対象の採集したデータを選択してください`,
  key1001303: `採集したデータを削除しますか`,
  key1001304: `収集したデータを削除する`,
  key1001305: `活発`,
  key1001306: `レコード`,
  key1001307: `在庫あり`,
  key1001308: `非公開`,
  key1001309: `削除しました`,
  key1001310: `オンライン`,
  key1001311: `無効にしました`,
  key1001312: `製品は移動しています。下書きボックスに確認してください`,
  key1001313: `データを選択してください`,
  key1001314: `元価格`,
  key1001315: `在庫数`,
  key1001316: `移動`,
  key1001317: `サイトを選択`,
  key1001318: `お店を選ぶ`,
  key1001319: `すべての店舗を選択`,
  key1001320: `motorsカテゴリを優先的に推奨しますか`,
  key1001321: `eBayにコピーしています、画面を閉じてバックグラウンドで続行し、進行状況をコピーする：`,
  key1001322: `製品の移動`,
  key1001323: `お店をお選びください`,
  key1001324: `キーワードを入力してください`,
  key1001325: `類目映射規則`,
  key1001326: `属性置換規則`,
  key1001327: `タスクセンター`,
  key1001328: `一括移動`,
  key1001329: `-規格名稱`,
  key1001330: `-規格值類型`,
  key1001331: `-類目屬性`,
  key1001332: `數據加載中...`,
  key1001333: `建議類目選擇`,
  key1001334: `映射平台屬性名稱`,
  key1001335: `映射平台規格值類型`,
  key1001336: `系統匹配中`,
  key1001337: `系統匹配已完成`,
  key1001338: `映射平台：`,
  key1001339: `映射平台站點：`,
  key1001340: `建議類目：`,
  key1001341: `應用`,
  key1001342: `選択した種類名：`,
  key1001343: `商品規格映射：`,
  key1001344: `系統建議匹配`,
  key1001345: `匹配狀態：`,
  key1001346: `已匹配規格：`,
  key1001347: `已匹配規格值：`,
  key1001348: `添加屬性值`,
  key1001349: `商品屬性映射：`,
  key1001350: `匹配狀態:`,
  key1001351: `已匹配屬性：`,
  key1001352: `已匹配屬性值：`,
  key1001353: `カスタムプロパティを追加する`,
  key1001354: `新增映射`,
  key1001355: `更新が完了しました`,
  key1001356: `新規成功`,
  key1001357: `修改映射`,
  key1001358: `已映射`,
  key1001359: `(已映射)`,
  key1001360: `文本框`,
  key1001361: `單選可定義值`,
  key1001362: `單選`,
  key1001363: `多選`,
  key1001364: `多選可定義值`,
  key1001365: `属性名`,
  key1001366: `請輸入屬性名稱`,
  key1001367: `屬性值`,
  key1001368: `プロパティ値を入力してください`,
  key1001369: `前後不能有空格`,
  key1001370: `新增自定義屬性`,
  key1001371: `新增自定義屬性值`,
  key1001372: `屬性名稱重複`,
  key1001373: `系統檢測到重複屬性值，已自動去重`,
  key1001374: `屬性值重複`,
  key1001375: `系统檢測到重複屬性值，已自動去重`,
  key1001376: `複数属性ですか`,
  key1001377: `输入关键字过滤`,
  key1001378: `映射平台`,
  key1001379: `映射平台站點`,
  key1001380: `映射平台類目`,
  key1001381: `原平台類目`,
  key1001382: `(已選)`,
  key1001383: `平台類目映射`,
  key1001384: `lazadaカテゴリデータの取得に失敗しました`,
  key1001385: `請選擇要刪除的映射`,
  key1001386: `確認刪除？刪除後系統將清除類目的映射關系。`,
  key1001387: `刪除提示`,
  key1001388: `获取类目映射列表失败`,
  key1001389: `属性を入力してください`,
  key1001390: `設定マッピング`,
  key1001391: `商品のプロパティ：`,
  key1001392: `商品のプロパティをカスタマイズ`,
  key1001393: `すべて表示する`,
  key1001394: `プロパティ値は必須項目`,
  key1001395: `マッピングプロパティが選択されていません`,
  key1001396: `同じ値を見つかりません、マッチングできません`,
  key1001397: `プロパティを選択してください`,
  key1001398: `属性を選択してください、カスタム属性の入力をサポートする`,
  key1001399: `属性を選択してください。複数選択もできる`,
  key1001400: `属性を選択してください。複数選択とカスタム属性の入力もできる`,
  key1001401: `追加したプロパティはすでに存在します`,
  key1001402: `名前を付けて保存`,
  key1001403: `テンプレート名`,
  key1001404: `保存に失敗しました、同じ名前が既に存在します`,
  key1001405: `ブロック国／地域`,
  key1001406: `もっと`,
  key1001407: `カスタムバリアント`,
  key1001408: `プロパティ名`,
  key1001409: `属性値`,
  key1001410: `プロパティ名を入力してください`,
  key1001411: `コンポーネントテンプレートを適用する`,
  key1001412: `適用`,
  key1001413: `テキスト通訳`,
  key1001414: `ソース言語`,
  key1001415: `自動的に`,
  key1001416: `目標とする言語`,
  key1001417: `ロシア`,
  key1001418: `スペイン語`,
  key1001419: `フランス語`,
  key1001420: `ポルトガル語`,
  key1001421: `ドイツ語`,
  key1001422: `イタリアの`,
  key1001423: `簡体字中国語`,
  key1001424: `タイ語`,
  key1001425: `アラビア語`,
  key1001426: `日本語`,
  key1001427: `ベトナム語`,
  key1001428: `インドネシア語`,
  key1001429: `マレー語`,
  key1001430: `対象言語を選択してください`,
  key1001431: `翻訳された内容は空ではありません`,
  key1001432: `画像のURLをアップロード`,
  key1001433: `画像のURL、複数の画像のURLを入力してください。複数の画像の場合、「Enter」キーを使って改行してください`,
  key1001434: `画像のURLが空です`,
  key1001435: `画像のグループ化`,
  key1001436: `画像名.xxx`,
  key1001437: `レベル1`,
  key1001438: `レベル二の1-1`,
  key1001439: `レベル三の1-1-1`,
  key1001440: `レベル2`,
  key1001441: `レベル二の2-1`,
  key1001442: `レベル三の2-1-1`,
  key1001443: `レベル二の2-2`,
  key1001444: `レベル三の2-2-1`,
  key1001445: `レベル3`,
  key1001446: `レベル二の3-1`,
  key1001447: `レベル三の 3-1-1`,
  key1001448: `レベル二の3-2`,
  key1001449: `レベル三の3-2-1`,
  key1001450: `カテゴリを選択してください`,
  key1001451: `先に削除対象データを選択してください`,
  key1001452: `削除しています`,
  key1001453: `内容の検証に失敗しました`,
  key1001454: `保存成功、商品のアドレスリストは次の`,
  key1001455: `同期ポリシー`,
  key1001456: `データは同期されています、後で確認してください`,
  key1001457: `変更を保存することを確認する`,
  key1001458: `テンプレートの内容を編集する場合、該当ポリシーを利用しているすべてのオンライン製品のポリシー内容が同時に変更されます`,
  key1001459: `国内払戻しの日数を選択してください`,
  key1001460: `国内の払戻しの費用の負担側を選択してください`,
  key1001461: `国際払戻しの日数を選択してください`,
  key1001462: `国際の払戻しの費用は誰が負担するを選択してください`,
  key1001463: `名前を付けて保存に成功しました、払戻しポリシーリストに遷移します`,
  key1001464: `保存に成功しました、払戻しポリシーリストに遷移します`,
  key1001465: `少なくとも30日の返品ポリシーを提供することによって、あなたの商品がトップセラーの特典の対象となるに役立ちます`,
  key1001466: `返金について`,
  key1001467: `返品日数`,
  key1001468: `返品の運賃は誰が負担しますか？`,
  key1001469: `国内払戻しを受け入れる`,
  key1001470: `国際払戻しを受け入れる`,
  key1001471: `国または地域`,
  key1001472: `商品アドレス`,
  key1001473: `への輸送`,
  key1001474: `輸送地域を入力してください`,
  key1001475: `現地輸送`,
  key1001476: `国際輸送`,
  key1001477: `輸送を選択してください`,
  key1001478: `運賃`,
  key1001479: `送料は必須項目`,
  key1001480: `もう1つの送料`,
  key1001481: `もう1つの送料は必須項目`,
  key1001482: `送料無料`,
  key1001483: `該当輸送を削除する`,
  key1001484: `複数商品注文でも配送料は増えない`,
  key1001485: `名前を付けて保存`,
  key1001486: `お支払い方法を1つ以上選択してください`,
  key1001487: `電子決済方法`,
  key1001488: `あなたのPayPalアカウントのメールアドレス（売上入金用）`,
  key1001489: `その他のお支払い方法`,
  key1001490: `支払いポリシー`,
  key1001491: `現在の店舗の管理支払いがオンになります、次に`,
  key1001492: `ストア認証管理`,
  key1001493: `、支払い管理のステータスを更新します`,
  key1001494: `入金アカウント`,
  key1001495: `購入者は、[今すぐ購入]を使用するときに即時支払いを要求します`,
  key1001496: `加盟者クレジットカード`,
  key1001497: `処理時間を選択してください`,
  key1001498: `手数料は必須項目`,
  key1001499: `当日または1日の処理時間を提供することによって、あなたの商品がトップセラーの特典の対象となるに役立ちます`,
  key1001500: `国内輸送`,
  key1001501: `処理時間`,
  key1001502: `手数料`,
  key1001503: `国内運賃テーブル`,
  key1001504: `国際輸送`,
  key1001505: `国際輸送を提供することによって、あなたの商品を他のeBayサイトで見えるようにして、国際的な購入者が利用できるようにする`,
  key1001506: `交渉済運賃`,
  key1001507: `国際運賃テーブル`,
  key1001508: `輸送できないエリア`,
  key1001509: `配送ポリシー`,
  key1001510: `現地輸送を増やす`,
  key1001511: `同期する`,
  key1001512: `グローバル輸送計画を使用する`,
  key1001513: `国際物流を増やす`,
  key1001514: `平均：購入者1人あたりの費用は同じ`,
  key1001515: `ポリシーローカル輸送タイプ`,
  key1001516: `計算：料金は購入者の住所によって異なります`,
  key1001517: `ポリシー国際物流タイプ`,
  key1001518: `配達なし：地元で手渡すのみ`,
  key1001519: `貨物輸送：150ポンドを超える荷物`,
  key1001520: `先に店を選択してください`,
  key1001521: `最初にサイトを選択してください`,
  key1001522: `運賃テーブルを同期しています`,
  key1001523: `運賃テーブルを同期に成功しました`,
  key1001524: `ストアは必須項目`,
  key1001525: `テンプレートの説明`,
  key1001526: `デフォルトのテンプレートとして設定`,
  key1001527: `基本情報`,
  key1001528: `輸送しない：`,
  key1001529: `すべてキャンセル`,
  key1001530: `国内：`,
  key1001531: `国際：`,
  key1001532: `[すべての国／地域を非表示]`,
  key1001533: `他の：`,
  key1001534: `支払い方法は必須項目です`,
  key1001535: `PayPalを選択している場合、支払いアカウントのメールアドレスは必須項目です`,
  key1001536: `名前を付けて保存に成功しました、支払いポリシーリストに遷移します`,
  key1001537: `保存に成功しました、支払いポリシーリストに遷移します`,
  key1001538: `名前を付けて保存に成功しました、配送ポリシーリストに遷移します`,
  key1001539: `正常に保存され、配送ポリシーのリストにジャンプします`,
  key1001540: `からの移動：`,
  key1001541: `店舗情報`,
  key1001542: `商品のプロパティ`,
  key1001543: `バリアント情報`,
  key1001544: `画像情報`,
  key1001545: `販売情報`,
  key1001546: `ビジネスポリシー`,
  key1001547: `配送の詳細`,
  key1001548: `説明`,
  key1001549: `情報処理中`,
  key1001550: `関連データが見つかりません`,
  key1001551: `先にカテゴリーを選択してください`,
  key1001552: `設定に成功しました`,
  key1001553: `ウォーターマークの追加に失敗しました`,
  key1001554: `まず製品画像を追加してください`,
  key1001555: `修正しています`,
  key1001556: `販売の種類`,
  key1001557: `キーワード`,
  key1001558: `あいまい検索のキーワードを入力してください`,
  key1001559: `開始価格`,
  key1001560: `即決`,
  key1001561: `ソース`,
  key1001562: `商品を同期する`,
  key1001563: `すべての製品を同期する`,
  key1001564: `選択した製品を同期する`,
  key1001565: `透かしを追加する`,
  key1001566: `公開時間：`,
  key1001567: `更新時間：`,
  key1001568: `ウォーターマークを追加する製品を選択してください`,
  key1001569: `移動対象商品を選択してください`,
  key1001570: `同期する商品を選択してください`,
  key1001571: `データを同期しています、後で確認してください`,
  key1001572: `店：`,
  key1001573: `モジュール：`,
  key1001574: `送料割引：`,
  key1001575: `ローカル輸送`,
  key1001576: `運賃`,
  key1001577: `もう1つの送料`,
  key1001578: `その他のお支払い方法：`,
  key1001579: `お支払い説明：`,
  key1001580: `返品プラン`,
  key1001581: `返品期限`,
  key1001582: `返品運賃`,
  key1001583: `返品方法`,
  key1001584: `国際返品ポリシー：`,
  key1001585: `返品手順：`,
  key1001586: `ロジスティクス設定`,
  key1001587: `モジュールを削除する`,
  key1001588: `eBayアカウントの標準配送割引設定を使用する（Flat`,
  key1001589: `eBayアカウントのプロモーション配送割引設定を使用する（プロモーション`,
  key1001590: `現地輸送を追加`,
  key1001591: `国際輸送を追加`,
  key1001592: `購入者の要求`,
  key1001593: `以下購入者は製品を購入することできません`,
  key1001594: `主な配送先住所が配送範囲外です`,
  key1001595: `受け取った`,
  key1001596: `件放棄された入札、過去`,
  key1001597: `過去10日間で、商品の入札または購入の数が設定した制限に達しました`,
  key1001598: `この制限を適用するには、購入者のクレジットインデックスが小なり`,
  key1001599: `支払いアカウント：`,
  key1001600: `返品規則`,
  key1001601: `現地返品ポリシー：`,
  key1001602: `送料割引を選択してください`,
  key1001603: `ローカル輸送を選択してください`,
  key1001604: `国際輸送を選択してください`,
  key1001605: `shipToを選択してください`,
  key1001606: `線付きの価格を選択してください`,
  key1001607: `設定の制限を選択してください`,
  key1001608: `インジケーターを選択してください`,
  key1001609: `支払いアカウントを選択してください`,
  key1001610: `返品方法を選択してください`,
  key1001611: `返品期限を選択してください`,
  key1001612: `返品運賃を選択してください`,
  key1001613: `返品方法を選択してください`,
  key1001614: `輸送設定`,
  key1001615: `購入者の要求`,
  key1001616: `返品規則`,
  key1001617: `ブロック国／地域`,
  key1001618: `互換性リストを表示`,
  key1001619: `シリアル番号`,
  key1001620: `チェック`,
  key1001621: `テンプレート名：`,
  key1001622: `サイト：`,
  key1001623: `英語`,
  key1001624: `文字翻訳は【文字元】で料金を計算して、現在あなたの文字翻訳の使用量は`,
  key1001625: `、毎月のリソース枠を追加する必要がある場合は、`,
  key1001626: `【付加価値リソースパッケージ】`,
  key1001627: `購入する`,
  key1001628: `現在のリソースの使用可能数が不足しています。`,
  key1001629: `翻譯失敗:`,
  key1001630: `通訳に成功しました`,
  key1001631: `梱包情報`,
  key1001632: `商品アドレステンプレートを選択します`,
  key1001633: `梱包制度`,
  key1001634: `梱包の種類`,
  key1001635: `不規則小包`,
  key1001636: `パッケージの寸法`,
  key1001637: `パッケージの重量`,
  key1001638: `テンプレートを選択してください`,
  key1001639: `テンプレートを作成する`,
  key1001640: `先に店舗とサイトを選択してください`,
  key1001641: `商品アドレステンプレートを選択してください`,
  key1001642: `操作に失敗しました。選択したテンプレートデータが不完全です。別のテンプレートを選択してください`,
  key1001643: `サブタイトル（$ 6.00）`,
  key1001644: `販売形態`,
  key1001645: `固定価格タイプ`,
  key1001646: `商品状態を選択してください`,
  key1001647: `商品状態の説明`,
  key1001648: `通訳`,
  key1001649: `単一の商品がオークションまたは固定価格の形で販売されている場合、この項目は商品のSKUです。`,
  key1001650: `販売形態が実体である場合、該当項目は製品の親SKUとして、バリアントSKUを生成するための接頭辞として利用することができる。`,
  key1001651: `固定価格`,
  key1001652: `競売`,
  key1001653: `単一の製品`,
  key1001654: `バリアント`,
  key1001655: `配送ポリシー`,
  key1001656: `ビジネスポリシー`,
  key1001657: `読み込み中`,
  key1001658: `作成`,
  key1001659: `すべてのサイトを選択`,
  key1001660: `すべての店舗を選択`,
  key1001661: `画像通訳`,
  key1001662: `画像通訳結果`,
  key1001663: `クリックして画像を拡大して表示する`,
  key1001664: `画像を選択して、[確定]ボタンをクリックして設定します`,
  key1001665: `中国語簡体字`,
  key1001666: `英語`,
  key1001667: `選択された画像`,
  key1001668: `通訳完了画像`,
  key1001669: `元画像`,
  key1001670: `変換中：`,
  key1001671: `通訳する`,
  key1001672: `編集を確認`,
  key1001673: `画像翻訳は【次】で費用を計算し、現在の画像翻訳の使用量は`,
  key1001674: `通訳対象画像を選択してください`,
  key1001675: `库存数量:`,
  key1001676: `批量库存修改`,
  key1001677: `ターゲットプラットフォームのプロパティを選択してください`,
  key1001678: `必要`,
  key1001679: `プロパティ置換`,
  key1001680: `元のプラットフォームのプロパティ`,
  key1001681: `元プラットフォーム`,
  key1001682: `ターゲットプラットフォームカテゴリ`,
  key1001683: `ターゲットプラットフォームのプロパティ`,
  key1001684: `お勧め`,
  key1001685: `購入者はこれらのプロパティを頻繁に検索します`,
  key1001686: `添付`,
  key1001687: `購入者もこれらに興味があるかもしれません`,
  key1001688: `同じ属性名がすでに存在しています、もう一度入力してください`,
  key1001689: `プロパティ名は必須項目`,
  key1001690: `移動に対応するプロパティリストの照会に失敗しました`,
  key1001691: `カテゴリを選択してください`,
  key1001692: `マッピングリストを正常に保存します`,
  key1001693: `マッピングリストの保存に失敗しました`,
  key1001694: `远程数据加载中`,
  key1001695: `請輸入推荐分類名`,
  key1001696: `推薦類目:`,
  key1001697: `推荐類目`,
  key1001698: `ワンクリック通訳`,
  key1001699: `下書きに保存`,
  key1001700: `今すぐ掲載`,
  key1001701: `掲載更新`,
  key1001702: `デフォルト画像`,
  key1001703: `プロパティ画像は必須項目`,
  key1001704: `商品画像`,
  key1001705: `画像の片方が500ピクセルより大きい、最初の画像がメイン画像です。画像をドラッグして、並び順を調整できます。`,
  key1001706: `バリアント画像`,
  key1001707: `少なくとも片方が500ピクセルより大きい`,
  key1001708: `オンライン画像のURL`,
  key1001709: `ローカルアップロード`,
  key1001710: `画像編集`,
  key1001711: `画像サイス`,
  key1001712: `白い背景の画像`,
  key1001713: `画像タイプ`,
  key1001714: `アップロードできる項目数最大：`,
  key1001715: `枚画像、アップロード可能`,
  key1001716: `枚画像`,
  key1001717: `アップロードに失敗しました`,
  key1001718: `該当カテゴリの画像は最大12枚までアップロードできる`,
  key1001719: `写真はJPG、JPEG、PNG、WEBPタイプのみをサポートしています！`,
  key1001720: `画像サイズは5MBを超えることはできません！`,
  key1001721: `画像処理中`,
  key1001722: `SKUを空にすることはできません`,
  key1001723: `長さは最大50文字まで`,
  key1001724: `価格（`,
  key1001725: `価格は必須項目`,
  key1001726: `数量は必須項目`,
  key1001727: `バリアントプロパティ`,
  key1001728: `カスタム属性名`,
  key1001729: `バリアント属性を選択してください`,
  key1001730: `バリアントの組み合わせ`,
  key1001731: `SKUを一括生成する`,
  key1001732: `価格を一括生成する`,
  key1001733: `価格を一括入力する`,
  key1001734: `在庫を一括生成する`,
  key1001735: `在庫を一括入力する`,
  key1001736: `バリアントの組み合わせを完成してください`,
  key1001737: `カスタム属性名を入力してください`,
  key1001738: `バリエーション属性値は30個まで`,
  key1001739: `画像の幅を入力してください`,
  key1001740: `既存画像のサイズ`,
  key1001741: `変更後サイズを入力してください`,
  key1001742: `サイズを一括設定`,
  key1001743: `カスタムサイズ`,
  key1001744: `サイズを調整したい画像を選択してください`,
  key1001745: `プロパティ名、例えば年`,
  key1001746: `属性値、例2018`,
  key1001747: `に置換する`,
  key1001748: `白い背景の画像の結果`,
  key1001749: `白色の背景`,
  key1001750: `白い背景を変換する`,
  key1001751: `白い背景を変換する画像を選択してください`,
  key1001752: `開始価格を入力してください`,
  key1001753: `価格を入力してください`,
  key1001754: `在庫を入力してください`,
  key1001755: `掲載日数`,
  key1001756: `予約価格`,
  key1001757: `価格`,
  key1001758: `在庫数`,
  key1001759: `購入者から価格交渉を受け入れる`,
  key1001760: `プライベート上場`,
  key1001761: `消費税`,
  key1001762: `VAT税率`,
  key1001763: `バルク販売`,
  key1001764: `卸売数量`,
  key1001765: `オンにする`,
  key1001766: `より大きい場合自動的に受け入れる`,
  key1001767: `より小さい場合自動的に拒否します`,
  key1001768: `購入者が他のeBayユーザーに対して匿名のままでいることを許可する`,
  key1001769: `送料と手数料にも適用されます`,
  key1001770: `製品カテゴリ`,
  key1001771: `カテゴリ名を入力してください`,
  key1001772: `現在のカテゴリを更新する`,
  key1001773: `推荐类目`,
  key1001774: `未公開`,
  key1001775: `公開に失敗しました`,
  key1001776: `公開中`,
  key1001777: `下書き番号`,
  key1001778: `オンラインで変更`,
  key1001779: `製品を追加する`,
  key1001780: `一括掲載`,
  key1001781: `批量翻译`,
  key1001782: `批量修改库存`,
  key1001783: `作成時間：`,
  key1001784: `掲載`,
  key1001785: `公開対象下書きを選択してください`,
  key1001786: `処理中です。後で処理結果を確認してください`,
  key1001787: `請選擇要翻譯的草稿`,
  key1001788: `請選擇要修改庫存的草稿`,
  key1001789: `国或は地域を選択してください`,
  key1001790: `商品画像をアップロードしてください`,
  key1001791: `製品タイトルを入力してください`,
  key1001792: `タイトルは最大255文字まで`,
  key1001793: `記述情報を入力してください`,
  key1001794: `商品タイトルを入力してください`,
  key1001795: `製品カテゴリ属性を選択してください`,
  key1001796: `ブランドを入力してください`,
  key1001797: `ブランドの長さは100文字以内`,
  key1001798: `出庫地を選んでください`,
  key1001799: `宅配業者を選んでください`,
  key1001800: `配送方法を選択してください`,
  key1001801: `配送運賃の種類を選択してください`,
  key1001802: `返品・交換先をお選んでください`,
  key1001803: `初期配送料(片道)を入力してください`,
  key1001804: `返品運賃(片道)を入力してください`,
  key1001805: `タイトルを入力してください`,
  key1001806: `タイトルの長さは最大80文字まで`,
  key1001807: `サブタイトルの長さは最大50文字まで`,
  key1001808: `SKUを入力してください`,
  key1001809: `SKUの長さは最大50文字まで`,
  key1001810: `販売形式を選択してください`,
  key1001811: `商品状態を選択してください`,
  key1001812: `製品カテゴリを選択してください`,
  key1001813: `掲載日数を選択してください`,
  key1001814: `国または地域を選択してください`,
  key1001815: `支払いポリシーを選択してください`,
  key1001816: `配送ポリシーを選択してください`,
  key1001817: `払戻しポリシーを選択してください`,
  key1001818: `製品タイプを選択してください`,
  key1001819: `少なくとも1つ輸送方法を選択する必要です。`,
  key1001820: `変形画像をアップロードしてください`,
  key1001821: `卸売り価格の内容をきちんと記入してください`,
  key1001822: `梱包重量を入力してください`,
  key1001823: `元のプラットフォームプロパティ或はターゲットプラットフォームプロパティを入力してください`,
  key1001824: `1.製品が移動されるときに、属性置換規則が自動的に適用されます。2.規則の編集また削除は、移動完了製品には影響しません。`,
  key1001825: `ターゲットプラットフォーム`,
  key1001826: `リストを読み込めませんでした、もう一度やり直してください`,
  key1001827: `削除に失敗しました、もう一度やり直してください`,
  key1001828: `発表`,
  key1001829: `一括発表の商品を選択してください`,
  key1001830: `選択済商品を一括発表しますか`,
  key1001831: `操作は成功して、データが処理されています。後で照会してください`,
  key1001832: `削除対象商品を選択してください`,
  key1001833: `下書きデータを削除しますか`,
  key1001834: `製品を同期する`,
  key1001835: `選択対象を同期する`,
  key1001836: `全て同期する`,
  key1001837: `非公開`,
  key1001838: `公開`,
  key1001839: `操作が成功しました、データは同期されています、タスクセンターで処理の結果を確認できる`,
  key1001840: `同期対象商品を選択してください`,
  key1001841: `オンラインで掲載されたデータを削除しますか`,
  key1001842: `非公開する商品を選択してください`,
  key1001843: `現在のデータを非公開するかどうか`,
  key1001844: `操作が成功しました、後でデータを確認してください`,
  key1001845: `掲載データを選択してください`,
  key1001846: `現在のデータを再度掲載しますか`,
  key1001847: `該当掲載データを非公開にしますか`,
  key1001848: `非公開完了`,
  key1001849: `カテゴリ属性`,
  key1001850: `こちらが製品ページのメイン画像です。複数の画像を同時にアップロードできる。最大8画像です。サイズは330 × 330と5000 × 5000の間。ファイルサイズは5mb以下。画像をドラッグするより順番を調整できること`,
  key1001851: `画像は最大8枚まで、最小330px`,
  key1001852: `操作データを正常に送信されました。Lazadaでオンラインで処理結果を確認してください。`,
  key1001853: `lazada詳細データの取得に失敗しました`,
  key1001854: `長さの説明`,
  key1001855: `もっと表示`,
  key1001856: `製品説明`,
  key1001857: `見立て説明`,
  key1001858: `収縮`,
  key1001859: `Video URLを入力してください`,
  key1001860: `ソースURL`,
  key1001861: `ソースURLを入力してください`,
  key1001862: `保証タイプ`,
  key1001863: `保証タイプをお願いします`,
  key1001864: `保証期間`,
  key1001865: `保証期間`,
  key1001866: `写真のサイズ制限がある`,
  key1001867: `該当カテゴリに、画像が最大8枚までアップロードできる`,
  key1001868: `いろんな梱包のサイズと重さの入力を切り替える`,
  key1001869: `梱包重量を記入してください`,
  key1001870: `梱包サイズ（L * W * H）`,
  key1001871: `完全なパッケージ情報を入力してください`,
  key1001872: `選択したカテゴリ：`,
  key1001873: `重みを空にすることはできず、0にすることもできません`,
  key1001874: `重さは必須項目`,
  key1001875: `長さを空にすることはできず、0にすることもできません`,
  key1001876: `長さは必須項目`,
  key1001877: `幅を空にすることはできず、0に指定することもできません`,
  key1001878: `幅は必須項目`,
  key1001879: `高さを空にすることはできず、0に指定することもできません`,
  key1001880: `高さは必須項目`,
  key1001881: `SellerSKUは必須項目`,
  key1001882: `最大200文字まで`,
  key1001883: `販売価格 （`,
  key1001884: `販売価格は必須項目`,
  key1001885: `割引後の価格（`,
  key1001886: `パッケージ重量（KG）`,
  key1001887: `パッケージサイズ（長さ*幅*高さCM）`,
  key1001888: `販促品の販売価格。 価格に対する販売価格の比率は、0.20から1.00の間でなければなりません`,
  key1001889: `製品の属性`,
  key1001890: `製品ポートフォリオ`,
  key1001891: `製品ポートフォリオを完成してください`,
  key1001892: `追加`,
  key1001893: `価格を一括生成する`,
  key1001894: `販売価格を一括て入力する`,
  key1001895: `割引価格を一括て生成する`,
  key1001896: `割引価格を一括入力する`,
  key1001897: `再アップロード`,
  key1001898: `プロモーション時期`,
  key1001899: `該当割引価格を削除する`,
  key1001900: `データ重複`,
  key1001901: `バリアント属性値は最大50個まで`,
  key1001902: `先に価格を設定してください`,
  key1001903: `割引価格を設定してください`,
  key1001904: `割引価格の範囲は価格の0*2-1の間です`,
  key1001905: `プロモーション時期を設定してください`,
  key1001906: `価格を0にすることはできません`,
  key1001907: `最初の図だけ透かしを追加`,
  key1001908: `すべての製品図にウォーターマークを追加`,
  key1001909: `ウォーターマークテンプレートのクエリに失敗しました`,
  key1001910: `ミスの原因`,
  key1001911: `原因を参照`,
  key1001912: `価格`,
  key1001913: `割引後の価格`,
  key1001914: `注：以下の為替レートはすべて米ドルに対するものであり、リアルタイムの為替レートは当日の最新の為替レートです。`,
  key1001915: `通貨`,
  key1001916: `固定為替レート`,
  key1001917: `リアルタイムの為替レート`,
  key1001918: `編集`,
  key1001919: `複数の画像を同時にアップロードすることができます。最大200画像。アップロード解像度は800*800/1024*1024 pxを推奨します。サイズが1 MB以下の画像。画像をドラッグして順序を調整できます`,
  key1001920: `在庫追跡なし`,
  key1001921: `在庫ゼロ発注は許可されていません`,
  key1001922: `在庫ゼロ発注を許可`,
  key1001923: `検索エンジン最適化（SEO）`,
  key1001924: `操作のコミットに成功しました。Shopifyにオンラインでコミット結果を表示してください`,
  key1001925: `shopify詳細データの取得に失敗しました`,
  key1001926: `クエリー収集リストに失敗しました`,
  key1001927: `分類`,
  key1001928: `分類を入力してください`,
  key1001929: `メーカーを入力してください`,
  key1001930: `ラベル`,
  key1001931: `英字で区切ったラベルを複数入力してください`,
  key1001932: `プロダクトコレクション`,
  key1001933: `プロダクトコレクション名を入力してください`,
  key1001934: `提示販売価格は空にすることはできません`,
  key1001935: `在庫は少なくとも1`,
  key1001936: `税関コード`,
  key1001937: `原価（`,
  key1001938: `*推奨販売価格（`,
  key1001939: `輸送不要`,
  key1001940: `*使用可能な在庫`,
  key1001941: `重量（KG）`,
  key1001942: `税金がかかる`,
  key1001943: `在庫ポリシー`,
  key1001944: `バリアント属性の値は250を超えることはできません`,
  key1001945: `同一の名称が存在する`,
  key1001946: `空にしてはならない`,
  key1001947: `空の場合、プラットフォームは製品タイトルから生成されます`,
  key1001948: `Webサイトへのアクセス`,
  key1001949: `空の場合、プラットフォームは製品説明によって生成されます`,
  key1001950: `コレクション名`,
  key1001951: `カスタムコレクション`,
  key1001952: `カスタムコレクションがパブリッシュされていません`,
  key1001953: `画像セット`,
  key1001954: `コレクションを含める`,
  key1001955: `元のフィールドコレクション`,
  key1001956: `集合名を入力してください`,
  key1001957: `コレクションの保存に成功しました`,
  key1001958: `コレクションの保存に失敗しました`,
  key1001959: `URLを入力してください（httpsのみ対応）`,
  key1001960: `正しい画像アドレスを入力してください`,
  key1001961: `未公開`,
  key1001962: `公開済み`,
  key1001963: `どうきしゅうごう`,
  key1001964: `クエリーコレクションリストに失敗しました`,
  key1001965: `アイテムコレクションを削除するかどうか`,
  key1001966: `同期中`,
  key1001967: `同期するコレクションを選択してください`,
  key1001968: `分類の説明`,
  key1001969: `分類名を記入してください`,
  key1001970: `分類の保存に成功しました`,
  key1001971: `分類の保存に失敗しました`,
  key1001972: `バリエーション情報を入力してください`,
  key1001973: `操作のコミットに成功しました。オンラインでコミット結果を確認するためにshoplineに掲載してください`,
  key1001974: `分類リストのクエリに失敗しました`,
  key1001975: `shopline詳細データの取得に失敗しました`,
  key1001976: `製品の概要`,
  key1001977: `ブランド`,
  key1001978: `製品分類を入力してください`,
  key1001979: `超買戦略`,
  key1001980: `許可しない`,
  key1001981: `許可する`,
  key1001982: `出荷するかどうか`,
  key1001983: `在庫追跡`,
  key1001984: `分類番号`,
  key1001985: `同期分類`,
  key1001986: `アイテム分類を削除するかどうか`,
  key1001987: `同期する分類を選択してください`,
  key1001988: `イベント名`,
  key1001989: `アクティビティ時間`,
  key1001990: `終了日の選択`,
  key1001991: `割引キャンペーンが始まった時。`,
  key1001992: `開始時間は現在の時間より1時間遅くなければなりません`,
  key1001993: `終了時間は開始時間より1時間遅く、割引期間は180日少なくなければなりません。`,
  key1001994: `イベント名を入力してください`,
  key1001995: `アクティビティの日付を入力してください`,
  key1001996: `開始時間は現在の時間より少なくとも1時間遅くなければなりません`,
  key1001997: `今後1週間`,
  key1001998: `今後1か月`,
  key1001999: `今後3か月`,
  key1002000: `割引キャンペーン詳細の照会に失敗しました`,
  key1002001: `規格荷物番号`,
  key1002002: `アクティビティの編集`,
  key1002003: `キャンペーン商品の編集`,
  key1002004: `アクティビティの削除`,
  key1002005: `アクティビティの終了`,
  key1002006: `同期アクティビティ`,
  key1002007: `新規アクティビティ`,
  key1002008: `すべて同期`,
  key1002009: `開始時間：`,
  key1002010: `終了時間：`,
  key1002011: `同期の進行状況`,
  key1002012: `進行中`,
  key1002013: `進行中`,
  key1002014: `期限切れ`,
  key1002015: `アクティブなデータを選択してください`,
  key1002016: `このアクティビティを終了するかどうか`,
  key1002017: `このアクティビティを削除するかどうか`,
  key1002018: `データは10分ごとに更新されます`,
  key1002019: `セットアップモード`,
  key1002020: `自動セットアップのオン/オフ`,
  key1002021: `販売台数が最も高い商品を自動的にセットアップする`,
  key1002022: `計画による指定`,
  key1002023: `期限自動更新`,
  key1002024: `規格荷物番号を入力してください`,
  key1002025: `オンライン商品`,
  key1002026: `売切り`,
  key1002027: `販売禁止`,
  key1002028: `ビデオメッセージ`,
  key1002029: `9枚までで、1枚目は表紙です。ファイルサイズは5mb以下。画像をドラッグするより順序を調整できる、最小サイズは500 × 500ピクセルです`,
  key1002030: `操作提出成功,Shopee掲載オンラインで提出結果を確認できます`,
  key1002031: `同期化は成功しました、少し後てページを更新してください`,
  key1002032: `Shopee詳細データの取得に失敗しました`,
  key1002033: `ビデオメッセージ`,
  key1002034: `サイズ制限がある`,
  key1002035: `MP4フォーマットのみ対応!`,
  key1002036: `製品の状態`,
  key1002037: `新品`,
  key1002038: `中古`,
  key1002039: `予約購入するかどうか`,
  key1002040: `発表に失敗しないように、店舗バックオフィスで対応している輸送方法を必ず確認してください`,
  key1002041: `否(3日以内に出荷必要)`,
  key1002042: `私には`,
  key1002043: `出荷日数(5 ~ 10日まで記入可能)`,
  key1002044: `すべて選択`,
  key1002045: `SKU(規格荷物番号)`,
  key1002046: `卸売り価格`,
  key1002047: `最小`,
  key1002048: `最大`,
  key1002049: `製品価格`,
  key1002050: `出典元url:`,
  key1002051: `製品名/製品ID`,
  key1002052: `製品名：`,
  key1002053: `製品ID：`,
  key1002054: `在庫：`,
  key1002055: `現在、セットトップ商品はありません`,
  key1002056: `商品の除去`,
  key1002057: `現在のセットアップ商品`,
  key1002058: `製品`,
  key1002059: `セットアップ残り時間`,
  key1002060: `セットアップ設定`,
  key1002061: `セットアップモード：`,
  key1002062: `自動サイクリック最高売り上げ商品`,
  key1002063: `計画に基づいて頂点に移動`,
  key1002064: `1.現在のセットアップ待ち商品の自動継続期間：オープン後のセットアップ期限が切れた後に自動的にセットアップを期待する商品、`,
  key1002065: `2.自動セットアップ販売量が最も高い商品：オープン後、システムは自動的にセットアップ販売量が最も高い5つの商品をセットバックする、`,
  key1002066: `3.計画に基づいて屋根を置く：開けた後に計画した時間帯と商品に基づいて屋根を置く`,
  key1002067: `各セットアップ期間`,
  key1002068: `セットトップ`,
  key1002069: `新規天井待ち商品`,
  key1002070: `最大5つのセットトップ商品しか追加できません。`,
  key1002071: `現在追加することもできます`,
  key1002072: `個の商品`,
  key1002073: `以上`,
  key1002074: `期間は重複できません`,
  key1002075: `各期間を4時間固定します。再選択してください`,
  key1002076: `最大6つの期間を追加`,
  key1002077: `この設定をキャンセルするかどうか`,
  key1002078: `セットトップ商品を追加してください`,
  key1002079: `計画時間が正しく設定されていません`,
  key1002080: `製品名を入力してください`,
  key1002081: `製品ID`,
  key1002082: `製品IDを入力してください`,
  key1002083: `割引価格`,
  key1002084: `購入制限`,
  key1002085: `有効化/無効化`,
  key1002086: `イベント参加グッズ`,
  key1002087: `商品を削除するか`,
  key1002088: `商品を削除`,
  key1002089: `注文導入テンプレートを削除に失敗しました`,
  key1002090: `追加成功`,
  key1002091: `その他の情報`,
  key1002092: `操作のコミットに成功しました。amazonにオンラインでコミット結果を表示してください`,
  key1002093: `Amazonクラスデータの取得に失敗しました`,
  key1002094: `サイトの取得に失敗しました`,
  key1002095: `アマゾンテンプレートの取得に失敗しました`,
  key1002096: `アマゾン属性グループの取得に失敗しました`,
  key1002097: `アマゾン属性の取得に失敗しました`,
  key1002098: `Amazon詳細データの取得に失敗しました`,
  key1002099: `画像のアップロードに失敗しました`,
  key1002100: `複数属性の追加`,
  key1002101: `メイン図URLを入力してください`,
  key1002102: `他の画像URLを入力してください`,
  key1002103: `Swatch画像URLを入力してください`,
  key1002104: `製品タイプ`,
  key1002105: `key検索を入力してください`,
  key1002106: `コメントの星`,
  key1002107: `レビューテンプレート`,
  key1002108: `星が開いた状態で、良いものを見つけるにはレビューテンプレートが必要です`,
  key1002109: `自動レビューは現在、LazadaとShopeeプラットフォームのみをサポートしており、残りのプラットフォームは続々とオープンする`,
  key1002110: `属性`,
  key1002111: `本日送信`,
  key1002112: `レビュー設定`,
  key1002113: `レビュー記録`,
  key1002114: `閉じる`,
  key1002115: `現在のテンプレートを削除するかどうか。`,
  key1002116: `ユーザー名`,
  key1002117: `コメントの内容`,
  key1002118: `コメント時間`,
  key1002119: `レビュー内容`,
  key1002120: `レビュー時間`,
  key1002121: `{value}星`,
  key1002122: `eBayにコピー`,
  key1002123: `マレーシア`,
  key1002124: `タイ`,
  key1002125: `ベトナム`,
  key1002126: `フィリピン`,
  key1002127: `シンガポール`,
  key1002128: `インドネシア`,
  key1002129: `データが選択されていません`,
  key1002130: `登録商品名`,
  key1002131: `登録商品名を入力してください`,
  key1002132: `販売者の商品番号`,
  key1002133: `販売者の商品番号を入力してください`,
  key1002134: `商品IDを登録する`,
  key1002135: `登録商品IDを入力してください`,
  key1002136: `一時保存`,
  key1002137: `承認待ち`,
  key1002138: `承認済`,
  key1002139: `一部承認`,
  key1002140: `差し戻し`,
  key1002141: `商品削除済`,
  key1002142: `主な情報`,
  key1002143: `配送情報`,
  key1002144: `返品・交換`,
  key1002145: `告知情報`,
  key1002146: `1枚目の写真は主図で、各写真は10 mを超えないように、最小100PX以上、最大10枚まで`,
  key1002147: `Coupangの分類データ取得に失敗`,
  key1002148: `公告リストの請求失敗`,
  key1002149: `画像タイプを変更すると、関連データがクリアされるので、切り替えを確認してください`,
  key1002150: `属性タイプを変更すると、関連データがクリアされるので、切り替えるかどうかを確認してください`,
  key1002151: `出庫リスト照会失敗`,
  key1002152: `操作提出成功,Coupangオンラインで提出結果を確認してください`,
  key1002153: `ブランドを入力してください。ノーブランドや自作品の場合は空にしてください`,
  key1002154: `メーカー`,
  key1002155: `ノーブランドの自作商品は右側の「ノーブランド」をご確認ください。`,
  key1002156: `並行輸入`,
  key1002157: `未成年者の購入`,
  key1002158: `海外からの買い物代行か`,
  key1002159: `並行輸入しない`,
  key1002160: `できる`,
  key1002161: `いけない`,
  key1002162: `PCCが必要かどうか(個人通関コード)`,
  key1002163: `1人あたり最大購入数`,
  key1002164: `特定期間の中に購入できる最大数量を設定する`,
  key1002165: `設置しない`,
  key1002166: `個`,
  key1002167: `販売期間`,
  key1002168: `登録販売期間`,
  key1002169: `付加価値税`,
  key1002170: `販売商品に付加価値税を追加するかどうか選択してください`,
  key1002171: `課税`,
  key1002172: `免税`,
  key1002173: `最近3日間`,
  key1002174: `最近5日間`,
  key1002175: `最近7日間`,
  key1002176: `最近15日間`,
  key1002177: `最近30日間`,
  key1002178: `最近60日間`,
  key1002179: `最近90日間`,
  key1002180: `最近120日間`,
  key1002181: `告知分類`,
  key1002182: `情報名称を知らせる`,
  key1002183: `详细`,
  key1002184: `商品詳細画面を参照`,
  key1002185: `製品詳細情報ページを参照`,
  key1002186: `商品名`,
  key1002187: `実際の販売ページに表示されている商品名に関して、Coupangの基準によって変更される可能性があります。`,
  key1002188: `商品名を登録する`,
  key1002189: `注文書に使われている商品名は、お客様には見えません。管理しやすい名前に設定してください。`,
  key1002190: `オプション`,
  key1002191: `画像アップロード`,
  key1002192: `オンライン画像`,
  key1002193: `基本登録`,
  key1002194: `オプションに従って登録する`,
  key1002195: `単一商品`,
  key1002196: `該当画像のアップロードは10枚まで`,
  key1002197: `出庫地`,
  key1002198: `済州/遠隔地配送の可否`,
  key1002199: `宅配業者`,
  key1002200: `配送方法`,
  key1002201: `束ねて配達する`,
  key1002202: `配送運賃の種類`,
  key1002203: `出庫所要日(日)`,
  key1002204: `出庫にかかる日`,
  key1002205: `顧客注文後、出庫までの所要時間を入力してください`,
  key1002206: `-配送方法は通常配送、新鮮冷凍時:1~7日`,
  key1002207: `-配送方法は注文制作、購入代行、取付配送及び直接販売者へ伝達する場合:1~20日`,
  key1002208: `購入オプションで入力してください`,
  key1002209: `一括設定`,
  key1002210: `普通配達`,
  key1002211: `新鮮冷凍`,
  key1002212: `カスタマイズ`,
  key1002213: `買い物代行`,
  key1002214: `設置配送、或いは売り手に直接届ける`,
  key1002215: `無料配送`,
  key1002216: `有料配送`,
  key1002217: `運賃着払い`,
  key1002218: `条件を満足する場合、無料配送する`,
  key1002219: `先にデータを選択してください`,
  key1002220: `メッセージ`,
  key1002221: `出庫所要日を一括設定する`,
  key1002222: `日数を入力してください`,
  key1002223: `出庫所要日は正しくない,最小1日最大30日`,
  key1002224: `売り上げの状態`,
  key1002225: `販売価格`,
  key1002226: `棚卸数量`,
  key1002227: `在庫切れ`,
  key1002228: `登録商品ID：`,
  key1002229: `販売済み`,
  key1002230: `未販売`,
  key1002231: `内容`,
  key1002232: `内容編集`,
  key1002233: `基本入力`,
  key1002234: `SKUを入力してください`,
  key1002235: `記入内容がある`,
  key1002236: `まだ内容を記入していない`,
  key1002237: `返品・交換先`,
  key1002238: `初期配送料(片道)`,
  key1002239: `返品運賃(片道)`,
  key1002240: `顧客の都合で返品する場合、往復返品/配送料は0ウォン、初期配送料+返品配送料の合計金額だ。`,
  key1002241: `売り手商品コード/SKU`,
  key1002242: `商品の改定前価格、coupang販売の割引適用前の価格を入力してください。`,
  key1002243: `モデル`,
  key1002244: `バーコード`,
  key1002245: `多属性`,
  key1002246: `単一の属性`,
  key1002247: `多属性の商品`,
  key1002248: `単一の属性の商品`,
  key1002249: `複数属性の商品を補完してください`,
  key1002250: `個別の属性商品を改善してください`,
  key1002251: `オリジナル価格(`,
  key1002252: `多属性商品を登録する`,
  key1002253: `単一の属性の商品を登録する`,
  key1002254: `変形属性を追加してください`,
  key1002255: `原価を一括生成する`,
  key1002256: `一括入荷原価`,
  key1002257: `モデルを一括生成する`,
  key1002258: `バーコードを一括生成する`,
  key1002259: `実際にCoupangで販売されている価格を入力してください。`,
  key1002260: `(最低10ウォン組職)`,
  key1002261: `セール期間中は価格の変更は不可ですが、終了后は価格の変更が可能です。`,
  key1002262: `販売可能な在庫数を入力する。`,
  key1002263: `(0から99999まで)品切れはお客様の経験に悪影響を及ぼしますので、十分な在庫数を入力してください。`,
  key1002264: `テンプレートの内容`,
  key1002265: `要素の追加`,
  key1002266: `テキストの内容`,
  key1002267: `フォント`,
  key1002268: `フォントサイズ`,
  key1002269: `フォント色`,
  key1002270: `太くするかどうか`,
  key1002271: `画像をアップロード`,
  key1002272: `透かしレイアウト`,
  key1002273: `ズーム`,
  key1002274: `回転`,
  key1002275: `画像`,
  key1002276: `ウォータマーク`,
  key1002277: `テンプレートを空にする`,
  key1002278: `背景画像を変更`,
  key1002279: `自分の製品のサイズに合った画像を選ぶことを提案して、800*800/1000*1000/1500*1500を使うことを推薦します`,
  key1002280: `パネルを空にするかどうか`,
  key1002281: `切り替えタイプを切り替えるとパネルがリセットされ、切り替えを続行するかどうか`,
  key1002282: `Microsoft Yahei（マイクロソフト・ヤク）`,
  key1002283: `透かしテンプレート名を入力してください`,
  key1002284: `ウォーターマークを追加してください`,
  key1002285: `カスタムモードは透かし図1枚のみをサポートしています`,
  key1002286: `ウォーターマークテンプレートの新規作成に失敗しました`,
  key1002287: `時間フィルタ`,
  key1002288: `顧客数`,
  key1002289: `支払オーダ数`,
  key1002290: `支払総額`,
  key1002291: `支払顧客数`,
  key1002292: `払戻オーダー数`,
  key1002293: `払戻合計金額`,
  key1002294: `払戻顧客数`,
  key1002295: `顧客数`,
  key1002296: `店舗分析データの照会に失敗しました`,
  key1002297: `受注利益分析`,
  key1002298: `店舗利益の分析`,
  key1002299: `商品利益の分析`,
  key1002300: `日付`,
  key1002301: `オーダー数量`,
  key1002302: `払戻数量`,
  key1002303: `購買合計数量`,
  key1002304: `売上出庫`,
  key1002305: `使用可能な在庫`,
  key1002306: `ざんりゅうふりょう`,
  key1002307: `商品在庫分析統計の照会に失敗しました`,
  key1002308: `購買時間`,
  key1002309: `発注番号`,
  key1002310: `購買担当者`,
  key1002311: `購買数量`,
  key1002312: `商品種類数`,
  key1002313: `購買合計金額`,
  key1002314: `入庫良品数`,
  key1002315: `入庫残次品数`,
  key1002316: `入庫合計金額`,
  key1002317: `期首在庫`,
  key1002318: `期末在庫`,
  key1002319: `出庫`,
  key1002320: `出庫要約`,
  key1002321: `品物を交換して出庫する.`,
  key1002322: `移荷出庫`,
  key1002323: `入庫`,
  key1002324: `入庫要約`,
  key1002325: `購買入庫`,
  key1002326: `返品入庫`,
  key1002327: `ライブラリへの移動`,
  key1002328: `交換入庫`,
  key1002329: `さいよく売れる緯度`,
  key1002330: `ベストセラー順位`,
  key1002331: `ベストセラータイプ`,
  key1002332: `商品SKU`,
  key1002333: `売上高合計`,
  key1002334: `売上総原価`,
  key1002335: `チャネルチャージ`,
  key1002336: `商品総収入`,
  key1002337: `平均利益`,
  key1002338: `利潤`,
  key1002339: `粗利率`,
  key1002340: `払戻率`,
  key1002341: `販売手数料`,
  key1002342: `取引手数料`,
  key1002343: `商品売上高`,
  key1002344: `商品総原価`,
  key1002345: `ebayクロスオフ取引費：`,
  key1002346: `ebayホスティング支払税：`,
  key1002347: `取引手数料：`,
  key1002348: `サービス料：`,
  key1002349: `関税：`,
  key1002350: `商品付加価値税：`,
  key1002351: `物流費`,
  key1002352: `物流コスト = 送料 + 返品物流コスト`,
  key1002353: `返金物流費用は、物流運賃タイプ選択業者が負担する必要があります。`,
  key1002354: `払戻金額への計算`,
  key1002355: `店舗利益データの照会に失敗しました`,
  key1002356: `受注数`,
  key1002357: `販売商品数`,
  key1002358: `払戻商品数`,
  key1002359: `受注総収益`,
  key1002360: `受注収益`,
  key1002361: `商品原価`,
  key1002362: `受注利益データの照会に失敗しました`,
  key1002363: `支払売上高`,
  key1002364: `支払金額`,
  key1002365: `払戻売上高`,
  key1002366: `最初のオーダー時間：`,
  key1002367: `最終オーダー時間：`,
  key1002368: `統計タイプ`,
  key1002369: `アカウント`,
  key1002370: `支払い時間`,
  key1002371: `国`,
  key1002372: `直近7日間の要約`,
  key1002373: `過去30日間の要約`,
  key1002374: `売上高`,
  key1002375: `発注数量`,
  key1002376: `総貸付金`,
  key1002377: `SKU個数`,
  key1002378: `入庫数量`,
  key1002379: `良品率`,
  key1002380: `平均納期（日）`,
  key1002381: `ASKU/FNSKUを入力してください`,
  key1002382: `スナップショット時間`,
  key1002383: `6-12ヶ月間の有料商品量`,
  key1002384: `6～12ヶ月料金`,
  key1002385: `12ヶ月以上の有料商品量`,
  key1002386: `12ヶ月以上の料金`,
  key1002387: `個々の商品容積`,
  key1002388: `軽商品`,
  key1002389: `料金月`,
  key1002390: `月を選択`,
  key1002391: `倉庫番号`,
  key1002392: `長辺`,
  key1002393: `中間エッジ`,
  key1002394: `短辺`,
  key1002395: `重量`,
  key1002396: `たいせき`,
  key1002397: `製品規格`,
  key1002398: `ざいこりょう`,
  key1002399: `除去対象量`,
  key1002400: `そうたいせき`,
  key1002401: `倉庫保管費の見積もり`,
  key1002402: `注文番号/取引番号/ASN/MSCU/FNSKUを入力してください`,
  key1002403: `賠償番号`,
  key1002404: `理由`,
  key1002405: `商品タイトル`,
  key1002406: `ステータス`,
  key1002407: `1件あたりの賠償金額`,
  key1002408: `合計金額`,
  key1002409: `賠償数量（現金）`,
  key1002410: `賠償数量（在庫）`,
  key1002411: `賠償数量（合計）`,
  key1002412: `元の賠償番号`,
  key1002413: `補償タイプ`,
  key1002414: `取引番号/MSCU/FNSKUを入力してください`,
  key1002415: `出荷日`,
  key1002416: `取引番号`,
  key1002417: `オペレーションセンター`,
  key1002418: `在庫属性`,
  key1002419: `国/地域`,
  key1002420: `顧客分析データのクエリに失敗しました`,
  key1002421: `ここにあなた`,
  key1002422: `ストア内の商品については、Shoplineの商品番号と実際に配送する商品とマッピングを関連する必要があります。`,
  key1002423: `タイプを選択してください`,
  key1002424: `すべて`,
  key1002425: `贈り物`,
  key1002426: `注文追加`,
  key1002427: `すべてのサイト`,
  key1002428: `商品ID/荷物番号`,
  key1002429: `商品タイプ`,
  key1002430: `複数の規格`,
  key1002431: `動作`,
  key1002432: `商品カテゴリを設定する`,
  key1002433: `一括更新`,
  key1002434: `関連`,
  key1002435: `設定の商品カテゴリはサブカテゴリにしなければなりません`,
  key1002436: `組み合わせ商品番号`,
  key1002437: `組み合わせ商品番号を入力してください`,
  key1002438: `組み合わせ商品名`,
  key1002439: `組み合わせ商品の名前を入力してください`,
  key1002440: `組み立て済み`,
  key1002441: `申告品名`,
  key1002442: `申告価値`,
  key1002443: `サイズ`,
  key1002444: `長さ`,
  key1002445: `幅`,
  key1002446: `高さ`,
  key1002447: `荷物番号`,
  key1002448: `製品コード`,
  key1002449: `SKUを入力してください`,
  key1002450: `グラム（g）`,
  key1002451: `センチメートル（CM）`,
  key1002452: `子供向け商品を含まれている`,
  key1002453: `サブ商品を追加`,
  key1002454: `組み合わせ商品番号を空にすることはできません`,
  key1002455: `組み合わせ商品の名を空にすることはできません`,
  key1002456: `サブ商品を空にすることはできません`,
  key1002457: `サブ商品の数量は1以上でなければなりません`,
  key1002458: `バインディング商品は在庫記録があるので、組み立て済みでないものに変換することはできません`,
  key1002459: `バインディング商品は必須項目`,
  key1002460: `商品番号はすでに存在します`,
  key1002461: `商品が存在しません`,
  key1002462: `商品商品が存在しません`,
  key1002463: `商品番号または商品名を入力してください`,
  key1002464: `実商品名`,
  key1002465: `実際の商品名を入力してください`,
  key1002466: `実際の商品が含まれています`,
  key1002467: `組み合わせ商品をインポートする`,
  key1002468: `/ file/組み合わせ商品インポートサンプル.xlsx`,
  key1002469: `インポート・エクスポート`,
  key1002470: `組み合わせ商品をエクスポート`,
  key1002471: `インポートし組み合わせ商品用Excelサンプルデータをダウンロードします（`,
  key1002472: `作成した組み合わせ商品データを下にアップロードし、システムに同じ商品番号の商品が存在する場合上書きします。`,
  key1002473: `商品のインポート`,
  key1002474: `アップロードタスクは実行中です。ウィンドウを閉じることもできます。アップロードタスクは終了するまでバックグラウンドで自動的に実行され、`,
  key1002475: ` 查看匯入任務的結果。`,
  key1002476: `注文を選択してください`,
  key1002477: `選択した組み合わせ商品をExcelファイルにエクスポートします。これを変更して再度インポートすると、組み合わせのファイルを更新できます。`,
  key1002478: `組立品の送金に失敗しました`,
  key1002479: `先に商品を選択してください`,
  key1002480: `組み立て商品が削除する場合、永久に非表示になります。`,
  key1002481: `組み合わせ商品を削除`,
  key1002482: `コンポーネントの削除に失敗しました`,
  key1002483: `複合品リストのクエリに失敗しました`,
  key1002484: `一部入金成功`,
  key1002485: `入金成功`,
  key1002486: `送金失敗`,
  key1002487: `送金成功`,
  key1002488: `複数の規格にしますか`,
  key1002489: `商品SPU`,
  key1002490: `商品SPUを入力してください`,
  key1002491: `英語名`,
  key1002492: `保証かどうか`,
  key1002493: `保証期間（日）`,
  key1002494: `商品写真`,
  key1002495: `基本情報`,
  key1002496: `メイン画像`,
  key1002497: `スペック情報`,
  key1002498: `規格を追加`,
  key1002499: `自動生成`,
  key1002500: `自動的にSKUルールを生成する`,
  key1002501: `複数スペックの自動生成では、spu番号+仕様名が値として使用されます`,
  key1002502: `例:`,
  key1002503: `spu値はGT、`,
  key1002504: `規格値が赤でサイズが大きければsku値はGT-赤-大きサイズ`,
  key1002505: `指導用原価`,
  key1002506: `サイズ（cm）`,
  key1002507: `商品呼び設定（メーカー-最小購買量-商品呼びサイクル）`,
  key1002508: `希望メーカー`,
  key1002509: `代替メーカー1`,
  key1002510: `代替メーカー2`,
  key1002511: `商品番号を入力してください`,
  key1002512: `製品カテゴリを選択してください`,
  key1002513: `先にテーブルデータを追加してください`,
  key1002514: `優先メーカーを入力してください`,
  key1002515: `商品情報をきちんと記入してください`,
  key1002516: `スペック情報をご記入ください`,
  key1002517: `skuは必須項目`,
  key1002518: `スペックは保存されていません`,
  key1002519: `商品の保存に失敗しました`,
  key1002520: `同じ規格名が存在します`,
  key1002521: `スペック名をご記入ください`,
  key1002522: `規格を切り替えると、追加済商品データをクリアされるため、切り替えますか`,
  key1002523: `製品の詳細を照会できませんでした`,
  key1002524: `カテゴリ名を空にすることはできません`,
  key1002525: `カテゴリに所属商品を存在するため、削除できません`,
  key1002526: `レベル三の3-2-2`,
  key1002527: `システムは注文の商品を認識できますし、Shoplineのオンライン在庫を同期できるようにするため。ストアの商品に関連します。`,
  key1002528: `マイ製品に関連を追加する`,
  key1002529: `SKUは存在しません`,
  key1002530: `同じSKUデータが存在します`,
  key1002531: `インポートに成功`,
  key1002532: `規格`,
  key1002533: `価値`,
  key1002534: `/ file / 商品インポートサンプル.xlsx`,
  key1002535: `一括操作`,
  key1002536: `カテゴリを設定`,
  key1002537: `商品をエクスポートする`,
  key1002538: `インポート商品用Excelサンプルデータをダウンロード（`,
  key1002539: `入力済み商品情報は下にアップロードできる、商品番号がシステムのデータと同じ場合、システムデータが更新されます。`,
  key1002540: `規格なし`,
  key1002541: `商品が削除されると、再表示できない、履歴の中には該当商品が削除済みで表示されます。`,
  key1002542: `選択した商品をExcelファイルにエクスポートします。商品データを修正して再度インポートして更新できます。`,
  key1002543: `現在在庫数`,
  key1002544: `在庫準備済`,
  key1002545: `不良品の在庫`,
  key1002546: `在庫の取得に失敗しました`,
  key1002547: `元倉庫`,
  key1002548: `目的地の倉庫`,
  key1002549: `内容を入力してください`,
  key1002550: `合格品在庫移動数理`,
  key1002551: `合格品の在庫移動数量を入力してください`,
  key1002552: `不良品在庫移動数量`,
  key1002553: `不良品転送数量を入力してください`,
  key1002554: `ソースリポジトリを選択してください`,
  key1002555: `目的先の倉庫を選択してください`,
  key1002556: `元倉庫を選択してください`,
  key1002557: `商品を選択してください`,
  key1002558: `合格品の移動数量と不良品の移動数量はどちらも0です。`,
  key1002559: `ライブラリ移動オーダーの保存に失敗しました`,
  key1002560: `合格品の在庫変更`,
  key1002561: `不良品の在庫の変化`,
  key1002562: `異動伝票番号：`,
  key1002563: `理由：`,
  key1002564: `すべての倉庫`,
  key1002565: `システムは、組み合わせ商品の原材料の在庫に基づいて、組み合わせ商品の仮想在庫を計算します。`,
  key1002566: `倉庫を選択`,
  key1002567: `組み立て済みではありません`,
  key1002568: `商品番号/商品名`,
  key1002569: `組み立て済み数量`,
  key1002570: `既存の不良在庫`,
  key1002571: `/file/商品在庫のインポートとエクスポートサンプル.xlsx`,
  key1002572: `サブ商品在庫`,
  key1002573: `不良品の備考をご記入ください`,
  key1002574: `在庫のエクスポート`,
  key1002575: `初期化在庫をインポートする用Excelサンプルデータをダウンロードします（`,
  key1002576: `記入済み製品初期化在庫ファイルは下にアップロードできます。製品が発送されると、在庫を初期化することはできなくなり、システムは自動的にスキップします。`,
  key1002577: `クリア`,
  key1002578: `すべての商品の現在の在庫をExcelファイルにエクスポートします。`,
  key1002579: `エクスポート中です。しばらくお待ちください`,
  key1002580: `在庫移動は、ある倉庫から別の倉庫に在庫を移動するために使用されます。`,
  key1002581: `倉庫移動書類番号`,
  key1002582: `複数の場合、英字スペース「 」で区切りで入力してください`,
  key1002583: `作成日`,
  key1002584: `在庫移動番号`,
  key1002585: `移動オーダーの送金`,
  key1002586: `/file/移庫単送金テンプレート.xlsx`,
  key1002587: `カスタムエクスポート`,
  key1002588: `インポートされたライブラリ移動オーダーのExcelサンプル資料のダウンロード（`,
  key1002589: `倉庫移動書類のエクスポート`,
  key1002590: `移動オーダーの送金に失敗しました`,
  key1002591: `エクスポートする倉庫移動書類を選択してください`,
  key1002592: `フィルタ済倉庫移動書類をExcelファイルにエクスポートします。`,
  key1002593: `すべての倉庫移動書類をExcelファイルにエクスポートします。`,
  key1002594: `カスタムエクスポート`,
  key1002595: `請輸入需要導出的數據 `,
  key1002596: `元倉庫：`,
  key1002597: `宛先倉庫：`,
  key1002598: `出荷待ちされる在庫`,
  key1002599: `在庫確認結果（合格品/不良品）`,
  key1002600: `在庫確認結果`,
  key1002601: `在庫確認記録`,
  key1002602: `在庫確認時間`,
  key1002603: `在庫確認前の数量（合格品、不良品）`,
  key1002604: `チェック済在庫（合格品、不良品）`,
  key1002605: `在庫確認データのインポート`,
  key1002606: `/file/在庫確認結果のインポート用サンプル.xlsx`,
  key1002607: `在庫確認結果を入力する`,
  key1002608: `在庫確認開始（選択中）`,
  key1002609: `在庫確認の開始（すべて）`,
  key1002610: `在庫確認結果のエクスポート（選択中）`,
  key1002611: `在庫確認のエクスポート（すべての結果セット）`,
  key1002612: `在庫の不良品の備考を上書き`,
  key1002613: `記録待ち`,
  key1002614: `顧客データをインポート用Excelサンプルデータをダウンロードします（`,
  key1002615: `在庫確認結果の全商品をExcelファイルにエクスポートします。在庫結果を修正してシステムに再インポートして保存できます。`,
  key1002616: `在庫確認結果をインポートのExcelサンプルデータをダウンロードする`,
  key1002617: `完成した在庫確認結果データは下にアップロードされます。在庫確認結果が正常にインポートされると、現在の在庫が変更されます。`,
  key1002618: `製品のカウントが開始されると、製品はロックされ、注文への配布が許可されなくなります。また、在庫結果が入力され、在庫が完了すると、ロックが解除されます。
商品の在庫確認を開始したら、在庫をロックされて、注文の商品準備にできません。在庫確認完了して、結果を保存してからロックを解除します。`,
  key1002619: `エクスポートする在庫確認結果を選択してください`,
  key1002620: `在庫確認のエクスポート`,
  key1002621: `分割数`,
  key1002622: `組立数量`,
  key1002623: `在庫数が補充されます`,
  key1002624: `必要な在庫数量`,
  key1002625: `既存の在庫`,
  key1002626: `分割注文番号`,
  key1002627: `組立商品番号`,
  key1002628: `必要な原材料`,
  key1002629: `備考を入力してください`,
  key1002630: `受け取り倉庫：`,
  key1002631: `修理回数`,
  key1002632: `メモを入力してください`,
  key1002633: `今回受け取った数量`,
  key1002634: `返品修理伝票番号：`,
  key1002635: `返品修理時間：`,
  key1002636: `発注番号：`,
  key1002637: `返品数：`,
  key1002638: `修理の合計：`,
  key1002639: `受け取ていない`,
  key1002640: `組み合わせ商品の組立用原材料商品の在庫は、組立予定の組み合わせ商品の在庫に変換する`,
  key1002641: `組み立てオーダー`,
  key1002642: `分割注文`,
  key1002643: `分割後の原材料を在庫数に補充されます。`,
  key1002644: `組み合わせ商品を追加`,
  key1002645: `組み立て商品`,
  key1002646: `分割注文を追加`,
  key1002647: `先に倉庫を選択してください`,
  key1002648: `組み合わせ商品を選択されていません`,
  key1002649: `在庫が足りません`,
  key1002650: `不良品を修理のためにメーカーに返品する時、返品伝票を使用されます。`,
  key1002651: `返品修理注文番号`,
  key1002652: `返品修理データの状態`,
  key1002653: `修理注文のエクスポート`,
  key1002654: `払戻しをマークする`,
  key1002655: `エクスポートタスク`,
  key1002656: `返品修理中`,
  key1002657: `戻ってきた`,
  key1002658: `返金済み`,
  key1002659: `一部受け取た`,
  key1002660: `修理品は倉庫に返送されず、メーカーが直接返金します。`,
  key1002661: `すべての状態`,
  key1002662: `強制完結しますか、強制完結の場合在庫が補充されない`,
  key1002663: `パラメータ異常`,
  key1002664: `在庫チェック中`,
  key1002665: `選択した返品修理の注文データをExcelファイルにエクスポートします。`,
  key1002666: `内容を入力してください`,
  key1002667: `注文書エラー`,
  key1002668: `倉庫を選択してください`,
  key1002669: `返品修理数を0にすることはできません`,
  key1002670: `注文書が存在しません`,
  key1002671: `受け取てないの発注書`,
  key1002672: `在庫が足りません`,
  key1002673: `在庫同期時間の設定`,
  key1002674: `自動同期`,
  key1002675: `どうきじかん`,
  key1002676: `時間の選択`,
  key1002677: `時間別`,
  key1002678: `間隔による`,
  key1002679: `間隔`,
  key1002680: `分に1回`,
  key1002681: `オンライン掲載数`,
  key1002682: `店舗/itemId`,
  key1002683: `オンライン在庫数`,
  key1002684: `売れ行き超過防止設定`,
  key1002685: `超過販売防止`,
  key1002686: `プラットフォームの有効化`,
  key1002687: `在庫警戒値の設定`,
  key1002688: `SKU在庫が警戒値より低い場合は、当該SKU対応同期店舗センターライン上のみ`,
  key1002689: `日間で最も売れた店の在庫、他の店の在庫は同期して0`,
  key1002690: `貨物ラベルを印刷する。`,
  key1002691: `バーコードサイズ。`,
  key1002692: `荷台のサイズ`,
  key1002693: `バーコード位置`,
  key1002694: `荷台位置`,
  key1002695: `货位`,
  key1002696: `荷台の上にある`,
  key1002697: `荷台の下にある`,
  key1002698: `左寄り`,
  key1002699: `中央`,
  key1002700: `右寄り`,
  key1002701: `数量`,
  key1002702: `一括設定`,
  key1002703: `印刷プレビュー`,
  key1002704: `貨物バーコードの取得に失敗`,
  key1002705: `数量を記入してください`,
  key1002706: `在庫SKU`,
  key1002707: `在庫SKUを空にすることはできません`,
  key1002708: `新規関連`,
  key1002709: `店舗SKU`,
  key1002710: `部分一致に成功しました`,
  key1002711: `存在しない`,
  key1002712: `一致に失敗しました`,
  key1002713: `新規失敗`,
  key1002714: `返品日`,
  key1002715: `注文者/国`,
  key1002716: `返品修理回数`,
  key1002717: `備考`,
  key1002718: `理由を入力してください`,
  key1002719: `倉庫を選択してください`,
  key1002720: `合格品在庫変化と不良品在庫変化に関して、両方が0です`,
  key1002721: `在庫が不足ため差し引かれない`,
  key1002722: `商品がロックされて、異動に失敗しました`,
  key1002723: `商品の倉庫の在庫二関して、初回目システムを利用する時に、商品の在庫を初期化できます。在庫が一度変更したら、再度初期化することはできません。`,
  key1002724: `货位`,
  key1002725: `ポジションを入力してください`,
  key1002726: `貨物スペースが空いている`,
  key1002727: `荷台番号を入力してください`,
  key1002728: `在庫単価`,
  key1002729: `在庫総額`,
  key1002730: `データをクリア`,
  key1002731: `在庫備考`,
  key1002732: `在庫備考を入力してください`,
  key1002733: `荷台一括クリア`,
  key1002734: `すべての商品を表示`,
  key1002735: `商品在庫合計値を表示する`,
  key1002736: `合格品在庫合計値を表示する`,
  key1002737: `輸送中商品合計値を表示する`,
  key1002738: `在庫商品の合計値:`,
  key1002739: `合格品在庫合計値：`,
  key1002740: `輸送中商品合計値：`,
  key1002741: `商品在庫をインポート用Excelサンプルデータをダウンロードします（`,
  key1002742: `この倉庫に存在しない商品をクリアできます。該当商品の各在庫が0場合のみクリアできる`,
  key1002743: `選択データに商品の荷物番号を持っていない`,
  key1002744: `荷台位置の修正に失敗`,
  key1002745: `商品の合計値を照会に失敗`,
  key1002746: `在庫明細の更新に失敗`,
  key1002747: `在庫の削除に失敗しました`,
  key1002748: `在庫明細の照会に失敗しました`,
  key1002749: `当面の在庫の振り出しに失敗`,
  key1002750: `備考追記失敗`,
  key1002751: `備考削除失敗`,
  key1002752: `不良品の備考を取得失敗`,
  key1002753: `商品在庫の照会に失敗しました`,
  key1002754: `在庫の初期化に失敗しました`,
  key1002755: `在庫SKU&数量`,
  key1002756: `店舗SKU関連在庫SKU導入`,
  key1002757: `/file/店舗SKU関連在庫SKU導入モデル.xlsx`,
  key1002758: `相互関係のインポート`,
  key1002759: `店舗SKU関連在庫SKU導入モデルをダウンロード（`,
  key1002760: `インポートに成功しました。後で確認してください`,
  key1002761: `現在のコンテンツを削除しますか？`,
  key1002762: `在庫異動伝票は、既存良品と不良品の在庫数量を調整するために使用されます。`,
  key1002763: `在庫異動番号`,
  key1002764: `有効なプラットフォーム`,
  key1002765: `売れ行き超過防止状態`,
  key1002766: `商品名/在庫SKU`,
  key1002767: `良品在庫数`,
  key1002768: `警戒値`,
  key1002769: `オンライン掲載数`,
  key1002770: `在庫警告（`,
  key1002771: `超過販売防止の設定`,
  key1002772: `選択した設定`,
  key1002773: `すべてを設定`,
  key1002774: `手動関連在庫SKU`,
  key1002775: `在庫同期時間の設定`,
  key1002776: `有効`,
  key1002777: `有効化されていません`,
  key1002778: `超過販売防止リストの照会に失敗しました`,
  key1002779: `このデータを削除するかどうか`,
  key1002780: `選択したデータを削除するかどうか`,
  key1002781: `listingオンラインリストの取得に失敗しました`,
  key1002782: `注文番号、SKUまたは商品名を入力してください`,
  key1002783: `操作ログ`,
  key1002784: `商品／数量`,
  key1002785: `商品を準備する`,
  key1002786: `すべての商品を準備する`,
  key1002787: `新しい注文を取消す`,
  key1002788: `（在庫切れ）`,
  key1002789: `商品が空です`,
  key1002790: `システムは現在の在庫数に応じて、注文日の順に在庫数が自動的に差し引かれます。在庫数が十分にある注文は発送可能に変更され、在庫が不足している注文は在庫切れに変更されます。`,
  key1002791: `託送状態`,
  key1002792: `文書を列刷する`,
  key1002793: `ピッキング方式`,
  key1002794: `列印刷履歴日`,
  key1002795: `キーワードを入力してフィルタ`,
  key1002796: `金額/支払い方法`,
  key1002797: `配送方法/輸送番号`,
  key1002798: `輸送番号`,
  key1002799: `出荷発注失敗理由`,
  key1002800: `注文時間`,
  key1002801: `送金明細書番号`,
  key1002802: `/file/カスタム物流ルート物流番号導入テンプレート.xlsx`,
  key1002803: `同期されていません`,
  key1002804: `処理中`,
  key1002805: `発送可能`,
  key1002806: `同期をキャンセル`,
  key1002807: `商品準備データを印刷する`,
  key1002808: `列印刷シート`,
  key1002809: `注文別で商品を選別する`,
  key1002810: `拾い集め`,
  key1002811: `物流請求書が取得されていません`,
  key1002812: `ピッキングリストを再印刷する`,
  key1002813: `再印刷シート`,
  key1002814: `運送状番号のインポート`,
  key1002815: `託送注文`,
  key1002816: `出荷済みに変更`,
  key1002817: `選択して変更する`,
  key1002818: `すべて変更`,
  key1002819: `スキャンで出荷`,
  key1002820: `出荷可能商品を返品`,
  key1002821: `注文をスキップ`,
  key1002822: `物流請求書の取得`,
  key1002823: `再印刷`,
  key1002824: `エラーを解決する`,
  key1002825: `カスタム物流チャネル物流番号インポートテンプレートのExcelサンプルをダウンロードします（`,
  key1002826: `記入されたカスタム物流チャネル物流番号インポートテンプレートは、下にアップロードされます。`,
  key1002827: `(カスタムチャネル)`,
  key1002828: `船荷証券番号の送金`,
  key1002829: `注文待ち`,
  key1002830: `発注中`,
  key1002831: `発注成功`,
  key1002832: `発注失敗`,
  key1002833: `商品発送待ち`,
  key1002834: `保留中の印刷シート`,
  key1002835: `出荷待ち`,
  key1002836: `選択して印刷`,
  key1002837: `すべてを列印刷`,
  key1002838: `一括印刷`,
  key1002839: `受注列印刷のスキャン`,
  key1002840: `スキャンSKU列印刷`,
  key1002841: `スキャン内容を入力してください`,
  key1002842: `シートの印刷に失敗しました`,
  key1002843: `先に注文を選んでください`,
  key1002844: `ピックリストを選択してください`,
  key1002845: `ご注文は、輸送会社に引き渡すの準備ができたら、発送済みに変更できます。`,
  key1002846: `発送中に変更`,
  key1002847: `変更出荷に失敗しました`,
  key1002848: `要約ピックリストの印刷に失敗しました`,
  key1002849: `ピックオーダーの生成に失敗しました`,
  key1002850: `物流番号の修正に失敗`,
  key1002851: `同期託送状態失敗`,
  key1002852: `出荷中リスト照会失敗`,
  key1002853: `注文のスキップに失敗しました`,
  key1002854: `倉庫リスト照会失敗`,
  key1002855: `シートの取得に成功しました`,
  key1002856: `物流請求書の取得に失敗しました`,
  key1002857: `出荷中の数量獲得に失敗`,
  key1002858: `識別番号`,
  key1002859: `注文番号または物流オーダー番号を入力/スキャンしてください`,
  key1002860: `配送方法`,
  key1002861: `出荷状態`,
  key1002862: `スキャン成功で自動出荷`,
  key1002863: `出荷する`,
  key1002864: `スキャン済み注文数:`,
  key1002865: `出荷済み注文数:`,
  key1002866: `発送済み`,
  key1002867: `未出荷`,
  key1002868: `注文番号や物流番号は存在しない`,
  key1002869: `注文番号や物流番号は出荷中ではない`,
  key1002870: `スキャンで出荷に失敗`,
  key1002871: `出荷成功の実行`,
  key1002872: `出荷に失敗`,
  key1002873: `倉庫/積荷証券番号`,
  key1002874: `注文履歴のインポート`,
  key1002875: `/ file / 注文インポートサンプル.xlsx`,
  key1002876: `/ file/Momoインポートテンプレート-宅配.xlsx`,
  key1002877: `/ file/Momoインポートテンプレート-Convenience store pickup.xlsx`,
  key1002878: `/ file/LINEクーポンインポート注文テンプレート.xlsx`,
  key1002879: `/file/RS-注文テンプレート.xlsx`,
  key1002880: `標準テンプレートをインポートする`,
  key1002881: `Momo宅配テンプレートをインポートする`,
  key1002882: `Momoスーパーフェッチテンプレートをインポートする`,
  key1002883: `LINEクーポンテンプレートをインポートする`,
  key1002884: `RS注文テンプレートのインポート`,
  key1002885: `インポート注文用のExcelサンプルデータをダウンロードする`,
  key1002886: `標準テンプレート凡例.xlsx`,
  key1002887: `Momoインポートテンプレート-宅配.xlsx`,
  key1002888: `Momoインポートテンプレート-Convenience store pickup.xlsx`,
  key1002889: `LINEクーポンテンプレート.xlsx`,
  key1002890: `RS-注文テンプレート.xlsx`,
  key1002891: `入力済注文データは下にアップロードできる、現在サーバに同じ注文番号は存在する場合、該当データをスキップする。`,
  key1002892: `上載任務正在執行，您也可以關閉窗口，上載任務將會在後台自行執行直到結束，您也可以到 `,
  key1002893: `出荷済みリストの照会に失敗しました`,
  key1002894: `支払い済をマークする`,
  key1002895: `確認中に戻る`,
  key1002896: `注文を支払い済みにマークし、他の項目を確認します。`,
  key1002897: `オフライン支払い`,
  key1002898: `支払い成功をマークする`,
  key1002899: `EC店舗の注文をシステムに同期化します。
確定したら画面が閉じられて、バックグラウンドで読み込みます。`,
  key1002900: `オンライン注文を同期する`,
  key1002901: `注文をキャンセル後、取引を中断します`,
  key1002902: `注文をキャンセルする`,
  key1002903: `請輸入訂單號,若不填，系統將自動生成`,
  key1002904: `修改運費`,
  key1002905: `修改訂單總額`,
  key1002906: `訂購人`,
  key1002907: `聯繫電話`,
  key1002908: `訂購人E-mail`,
  key1002909: `省/洲`,
  key1002910: `実際の輸送方法`,
  key1002911: `輸送番号`,
  key1002912: `注文備考`,
  key1002913: `請輸入商品名稱/SPU/SKU`,
  key1002914: `新增手工訂單`,
  key1002915: `注文情報`,
  key1002916: `支払情報`,
  key1002917: `產品金額：`,
  key1002918: `運費：`,
  key1002919: `合計注文額：`,
  key1002920: `注文者情報`,
  key1002921: `配送情報`,
  key1002922: `提交`,
  key1002923: `添加本頁`,
  key1002924: `已添加`,
  key1002925: `請輸入收件人`,
  key1002926: `請輸入收件人聯繫電話`,
  key1002927: `国／地域を選択してください`,
  key1002928: `請輸入省/洲`,
  key1002929: `ピッキングリスト`,
  key1002930: `SKUを入力/スキャンしてください`,
  key1002931: `未払数量`,
  key1002932: `スキャン済み数量`,
  key1002933: `欠落した数量`,
  key1002934: `面単列印刷状態`,
  key1002935: `ピッキング番号：`,
  key1002936: `今回の選別を終了する`,
  key1002937: `今回の選別を取り消す`,
  key1002938: `受注合計数：`,
  key1002939: `印刷済注文数：`,
  key1002940: `関連オーダーが見つかりません`,
  key1002941: `SKUの選別完了`,
  key1002942: `SKUは現在ピックリストに存在しません`,
  key1002943: `ピッキング番号は存在しません`,
  key1002944: `スキャン成功`,
  key1002945: `現在のピックリストはピック済みです`,
  key1002946: `クエリに成功しました`,
  key1002947: `今回の選別を終了しますか？`,
  key1002948: `ピッキング成功の終了`,
  key1002949: `終了選別失敗`,
  key1002950: `今回の選別を取り消しますか。`,
  key1002951: `分割解除成功`,
  key1002952: `ピッキングの取り消しに失敗しました`,
  key1002953: `進行中のピックリスト照会に成功しました`,
  key1002954: `アフターサービス番号`,
  key1002955: `市県`,
  key1002956: `電話番号`,
  key1002957: `ストア備考：`,
  key1002958: `出荷期限：`,
  key1002959: `返品数量`,
  key1002960: `修理`,
  key1002961: `注文プラットフォーム：`,
  key1002962: `支払期日：`,
  key1002963: `注文日：`,
  key1002964: `shopline支店名：`,
  key1002965: `購入者のメッセージ`,
  key1002966: `商品詳細`,
  key1002967: `交換`,
  key1002968: `現在の合格品在庫：`,
  key1002969: `商品を追加する`,
  key1002970: `元注文商品`,
  key1002971: `一致する実際の商品はありません`,
  key1002972: `返品情報`,
  key1002973: `商品代金：`,
  key1002974: `運賃：`,
  key1002975: `消費税：`,
  key1002976: `調整金額：`,
  key1002977: `商品割引：`,
  key1002978: `商品割引：`,
  key1002979: `支払方法：`,
  key1002980: `販売者の実際の入金：`,
  key1002981: `購入者の支払い：`,
  key1002982: `注文者：`,
  key1002983: `注文者の電子メール：`,
  key1002984: `輸送番号を入力します`,
  key1002985: `出荷倉庫：`,
  key1002986: `配送方法：`,
  key1002987: `実際の輸送方法：`,
  key1002988: `ストア備考：`,
  key1002989: `出荷期限：`,
  key1002990: `受け取ていないことを確認済場合、入庫倉庫に在庫数が補充されます。`,
  key1002991: `関連データなし`,
  key1002992: `到着した`,
  key1002993: `受取済`,
  key1002994: `発送期限を過ぎました`,
  key1002995: `商品準備中`,
  key1002996: `処理待ち`,
  key1002997: `返品済み`,
  key1002998: `返品の店が閉店しました`,
  key1002999: `輸送中`,
  key1003000: `閉店`,
  key1003001: `受信者を空にすることはできません`,
  key1003002: `実際の輸送方法を選択してください`,
  key1003003: `住所1を空にすることはできません`,
  key1003004: `電話番号を空にすることはできません`,
  key1003005: `国を選択してください`,
  key1003006: `交換商品が空です`,
  key1003007: `返品交換`,
  key1003008: `なし`,
  key1003009: `カテゴリー`,
  key1003010: `方式`,
  key1003011: `その他を入力してください`,
  key1003012: `受け取ていない理由を選択してください`,
  key1003013: `受け取ていない理由は必須項目`,
  key1003014: `輸送番号を入力します`,
  key1003015: `skuの組み合わせ商品は異常であり、追加できません`,
  key1003016: `製品を空にすることはできません`,
  key1003017: `異常注文のキャンセル`,
  key1003018: `お客様の郵便番号`,
  key1003019: `Shopline支店名`,
  key1003020: `注文番号を入力/スキャンして、クリックまたはEnterでOKしてください`,
  key1003021: `列印刷に成功すると、列印刷に直接マークを付ける`,
  key1003022: `ピックリストを一緒に印刷するかどうか`,
  key1003023: `フェースシートがPDF形式の場合、ピックシートを一緒に印刷することはできません`,
  key1003024: `に印を押す`,
  key1003025: `列印刷済みのマーク`,
  key1003026: `印刷結果の確認`,
  key1003027: `注文書が生成されました。印刷されている場合は、マークをクリックして印刷してください`,
  key1003028: `列印刷設定`,
  key1003029: `スキャン印刷シート`,
  key1003030: `出荷先住所`,
  key1003031: `オペレーションレコード`,
  key1003032: `オペレータ`,
  key1003033: `そうさじかん`,
  key1003034: `閉じる`,
  key1003035: `クエリ操作ログに失敗しました`,
  key1003036: `商品準備データを印刷する`,
  key1003037: `倉庫に商品を選択する時に商品選択リストを使用している`,
  key1003038: `注文のページ別で印刷`,
  key1003039: `ストア商品`,
  key1003040: `販売ルート`,
  key1003041: `注文に複数SKUを導入する方式`,
  key1003042: `注文番号が重複している場合`,
  key1003043: `システムフィールド`,
  key1003044: `Excelファイルのインポートタイトル`,
  key1003045: `Excelファイルの列ヘッダーを入力してください`,
  key1003046: `利用可能範囲`,
  key1003047: `導入設定`,
  key1003048: `一行ずつ`,
  key1003049: `同じ行の複数列`,
  key1003050: `同じ行の複数列のパイプを選択して導入するときに、タイトル列名にSKUが入力された場合、`,
  key1003051: `2番目のSKUはデフォルトでSKU1、3番目のSKU2と呼ばれ、こうして類推する。`,
  key1003052: `導入に失敗しないように、ユーザーはexcleの列名を合わせて調整必要です`,
  key1003053: `無視する`,
  key1003054: `覆われ`,
  key1003055: `テンプレートフィールドマッピング`,
  key1003056: `マッピング`,
  key1003057: `この列に、Excelファイルに該当する列のタイトルを入力してください。`,
  key1003058: `入力しないと該当するフィールドにマッピングできない`,
  key1003059: `注文導入テンプレートを編集する`,
  key1003060: `固定値`,
  key1003061: `空白のままにしてください`,
  key1003062: `注文貨幣`,
  key1003063: `送料`,
  key1003064: `商品の金額`,
  key1003065: `商品のsku`,
  key1003066: `商品数`,
  key1003067: `商品単価`,
  key1003068: `顧客名`,
  key1003069: `顧客の性別`,
  key1003070: `お客様の誕生日`,
  key1003071: `顧客メール`,
  key1003072: `お客様受取先住所1`,
  key1003073: `お客様受取先住所2`,
  key1003074: `お客様の国`,
  key1003075: `顧客県市`,
  key1003076: `顧客都市`,
  key1003077: `お客様の郵便番号`,
  key1003078: `実際の郵送方法`,
  key1003079: `追跡番号`,
  key1003080: `出荷日時`,
  key1003081: `郵送方法を指定する`,
  key1003082: `宛先の電話番号`,
  key1003083: `宛先1`,
  key1003084: `宛先2`,
  key1003085: `宛先の県`,
  key1003086: `宛先の国`,
  key1003087: `宛先郵便番号`,
  key1003088: `宛先都市`,
  key1003089: `注文導入テンプレートを追加`,
  key1003090: `完成内容を入力してください`,
  key1003091: `ピックアップ番号`,
  key1003092: `SKU数`,
  key1003093: `列刷り者`,
  key1003094: `ロットピッキング`,
  key1003095: `導入後に実行予定動作`,
  key1003096: `注文導入必要のexcelファイルを選択する`,
  key1003097: `出荷済みに設定する`,
  key1003098: `保証商品が含めている場合、保証通知をする`,
  key1003099: `アップロードファイルのタイプはxlsx、xlsのみ、ファイルのサイズの最大制限は10 MB`,
  key1003100: `導入開始`,
  key1003101: `サイズの最大制限は10 MB!`,
  key1003102: `プラットフォームのアカウントの照会に失敗`,
  key1003103: `全ての受注導入テンプレートのリストを取得に失敗しました`,
  key1003104: `導入作業が成功しました、タスクセンターで処理の進捗を確認できる`,
  key1003105: `アップロードファイルのサイズの最大制限は10 M`,
  key1003106: `テンプレートを選んでください`,
  key1003107: `販売ルートを選んでください`,
  key1003108: `注文確認に戻る`,
  key1003109: `テンプレートに基づいて注文を導入する`,
  key1003110: `注文導入テンプレートのリスト照会に失敗しました`,
  key1003111: `注文導入テンプレートを削除するかどうか`,
  key1003112: `注文導入テンプレートを削除する`,
  key1003113: `ルール注文導入テンプレートの削除に成功しました`,
  key1003114: `注文導入テンプレートの詳細照会に失敗しました`,
  key1003115: `請求書の列刷`,
  key1003116: `出荷可能`,
  key1003117: `チェックすることを注意喚起`,
  key1003118: `再確認`,
  key1003119: `選択したものを再確認`,
  key1003120: `すべて再確認`,
  key1003121: `支払済をマーク`,
  key1003122: `支払い済をマークしてすべて選択する`,
  key1003123: `添加手工訂單`,
  key1003124: `展示更多(隱藏`,
  key1003125: `條)`,
  key1003126: `EC店舗の注文をシステムに同期化します。
確定したら画面が閉じられて、バックグラウンドで読み込みます。`,
  key1003127: `過去の注文数`,
  key1003128: `過去の総消費量`,
  key1003129: `最後注文日`,
  key1003130: `性別`,
  key1003131: `誕生日`,
  key1003132: `誕生日を選択`,
  key1003133: `顧客タグを選択または入力してください`,
  key1003134: `保証書番号`,
  key1003135: `関連する注文`,
  key1003136: `保証期間`,
  key1003137: `保証開始日`,
  key1003138: `保証ステータス`,
  key1003139: `注文状態`,
  key1003140: `マーケティングイベント名`,
  key1003141: `イベント開始日`,
  key1003142: `イベント終了日`,
  key1003143: `マーケティング方式`,
  key1003144: `注文数`,
  key1003145: `消費を促進する`,
  key1003146: `発送済`,
  key1003147: `出荷中`,
  key1003148: `キャンセル済`,
  key1003149: `基本プロフィール`,
  key1003150: `男`,
  key1003151: `女`,
  key1003152: `重さ`,
  key1003153: `設定`,
  key1003154: `保証番号`,
  key1003155: `保証を開始します`,
  key1003156: `荷物を交換した`,
  key1003157: `商品名：`,
  key1003158: `SKU：`,
  key1003159: `スペック:`,
  key1003160: `失効した`,
  key1003161: `保証有効期間内`,
  key1003162: `保証期間が終了しました`,
  key1003163: `起動していない`,
  key1003164: `マーケティング記録`,
  key1003165: `保証番号を記入してください`,
  key1003166: `アクティベーション成功`,
  key1003167: `顧客の携帯電話番号はすでに存在します`,
  key1003168: `顧客のメールアドレスはすでに存在します`,
  key1003169: `顧客タグが空です`,
  key1003170: `お客様のメールアドレスと携帯電話番号が空です`,
  key1003171: `メール内容：`,
  key1003172: `メッセージの内容：`,
  key1003173: `イベント名：`,
  key1003174: `イベント開始日：`,
  key1003175: `マーケティング方法：`,
  key1003176: `顧客の要望：`,
  key1003177: `対象となる顧客の数：`,
  key1003178: `促進された注文：`,
  key1003179: `促進された売上：`,
  key1003180: `転換律'：`,
  key1003181: `配達状況`,
  key1003182: `郵便物`,
  key1003183: `ショートメッセージ`,
  key1003184: `あります`,
  key1003185: `7日以内`,
  key1003186: `15日以内`,
  key1003187: `30日以内`,
  key1003188: `60日以内`,
  key1003189: `60日前`,
  key1003190: `7日以上`,
  key1003191: `7日未満`,
  key1003192: `15日以上`,
  key1003193: `15日未満`,
  key1003194: `30日以上`,
  key1003195: `30日未満`,
  key1003196: `60日以上`,
  key1003197: `60日未満`,
  key1003198: `合計売上がより上`,
  key1003199: `消費合計金額は以下`,
  key1003200: `電話を登録された`,
  key1003201: `携帯電話番号を登録されている`,
  key1003202: `顧客エリア`,
  key1003203: `再度購入`,
  key1003204: `注文の総消費量`,
  key1003205: `お客様の連絡情報`,
  key1003206: `金額を入力してください`,
  key1003207: `メールを登録された`,
  key1003208: `顧客タグ`,
  key1003209: `マーケティング`,
  key1003210: `内容を発送する`,
  key1003211: `マーケティングイベント時間`,
  key1003212: `情報送信時間`,
  key1003213: `日時を選択`,
  key1003214: `ステップ1：顧客の要件`,
  key1003215: `対象となるお客様数`,
  key1003216: `次のステップ`,
  key1003217: `ステップ2：マーケティング`,
  key1003218: `前へ`,
  key1003219: `電子メールまたはメッセージに顧客の名前を出力したい場合、置換場所に{{顧客名}}を使用してください`,
  key1003220: `イベント内容を入力してください`,
  key1003221: `イベント名を空にすることはできません`,
  key1003222: `イベントの内容を空にすることはできません`,
  key1003223: `イベントの日付を空にすることはできません`,
  key1003224: `メッセージ送信時間を空にすることはできません`,
  key1003225: `情報の送信時間は、イベントの範囲内である必要があります`,
  key1003226: `本文を入力してください...`,
  key1003227: `顧客プロファイルは、プラットフォームの注文から収集されます。`,
  key1003228: `地域`,
  key1003229: `顧客データのインポート`,
  key1003230: `/file/顧客プロフィールのインポートとエクスポート用サンプル.xlsx`,
  key1003231: `ブラックリストに一括追加する`,
  key1003232: `ブラックリストを削除する`,
  key1003233: `ブラックリストに追加`,
  key1003234: `完成した顧客プロファイルは下にアップロードできます、システムに保存済顧客プロファイルと同じ携帯電話番号または電子メールはスキップされます。`,
  key1003235: `顧客データのインポート`,
  key1003236: `顧客データのエクスポート`,
  key1003237: `選択したものエクスポート`,
  key1003238: `すべてエクスポート`,
  key1003239: `顧客データと注文データのエクスポート`,
  key1003240: `エクスポートする顧客データを選択してください`,
  key1003241: `フィルタリングされた顧客データをExcelファイルにエクスポートします。`,
  key1003242: `顧客データのエクスポート`,
  key1003243: `すべての顧客データをExcelファイルにエクスポートします。`,
  key1003244: `ブラックリストから削除する顧客を選択してください`,
  key1003245: `マーケティング対象顧客を条件に応じて探して、SMSまたはEメールを送信する。`,
  key1003246: `イベント日付`,
  key1003247: `転換率`,
  key1003248: `実行されない`,
  key1003249: `顧客ブラックリストに登録されている顧客は注文する場合、システムはあなたにお知らせします。`,
  key1003250: `ブラックリストを一括削除`,
  key1003251: `フィルタリングされた顧客データをExcelファイルにエクスポートします。`,
  key1003252: `トップページ`,
  key1003253: `採集リスト`,
  key1003254: `ウォーターマークテンプレート`,
  key1003255: `新しいウォーターマークテンプレート`,
  key1003256: `ウォーターマークテンプレートの編集`,
  key1003257: `イーベイ製品掲載`,
  key1003258: `下書きを追加`,
  key1003259: `下書き詳細`,
  key1003260: `オンラインの詳細`,
  key1003261: `eBay共通テンプレート`,
  key1003262: `支払いポリシーの詳細`,
  key1003263: `支払いポリシーを新規する`,
  key1003264: `配送ポリシーの詳細`,
  key1003265: `配送ポリシーを新規する`,
  key1003266: `払戻しポリシーの詳細`,
  key1003267: `返金ポリシーを新規する`,
  key1003268: `商品アドレスの詳細`,
  key1003269: `新しい商品のアドレス`,
  key1003270: `Lazada製品を掲載する`,
  key1003271: `Shopee製品掲載`,
  key1003272: `割引アクティビティ`,
  key1003273: `新規割引アクティビティ`,
  key1003274: `割引キャンペーン詳細`,
  key1003275: `商品のプロモーション`,
  key1003276: `商品セットアッププロモーション設定`,
  key1003277: `Coupang製品掲載`,
  key1003278: `Shopify製品掲載`,
  key1003279: `新製品コレクション`,
  key1003280: `プロダクトコレクションの変更`,
  key1003281: `Shopline製品掲載`,
  key1003282: `新製品カテゴリー`,
  key1003283: `製品区分の変更`,
  key1003284: `Amazon製品掲載`,
  key1003285: `新規レビューテンプレート`,
  key1003286: `レビューテンプレートの編集`,
  key1003287: `自動レビュー`,
  key1003288: `自動レビューの編集`,
  key1003289: `分類管理`,
  key1003290: `エクスポートとインポートのタスク`,
  key1003291: `商品編集`,
  key1003292: `組み合わせ商品を追加する`,
  key1003293: `組み合わせ商品を更新`,
  key1003294: `Shoplineオンライン商品`,
  key1003295: `自分の商品を関連する`,
  key1003296: `注文`,
  key1003297: `確認中`,
  key1003298: `注文詳細`,
  key1003299: `新規注文`,
  key1003300: `スキャンSKU出荷`,
  key1003301: `注文導入テンプレート`,
  key1003302: `カスタムルート注文導入`,
  key1003303: `手工訂單`,
  key1003304: `オーダーリスト`,
  key1003305: `発注書を生成する`,
  key1003306: `発注書を表示する`,
  key1003307: `1688発注書を編集する`,
  key1003308: `1688発注書を照会`,
  key1003309: `発注書の編集`,
  key1003310: `メーカー管理`,
  key1003311: `調達設定`,
  key1003312: `メーカー関連商品`,
  key1003313: `1688発注`,
  key1003314: `1688アカウント承認`,
  key1003315: `1688発注関連`,
  key1003316: `商品在庫数`,
  key1003317: `組み合わせ商品在庫数`,
  key1003318: `組み合わせ商品を追加`,
  key1003319: `組み合わせ商品の詳細`,
  key1003320: `分割書類を表示`,
  key1003321: `在庫異動票`,
  key1003322: `在庫異動票を追加する`,
  key1003323: `在庫異动書の詳細`,
  key1003324: `返品伝票を追加する`,
  key1003325: `返品伝票詳細`,
  key1003326: `在庫移動書類を追加`,
  key1003327: `在庫移動書類詳細`,
  key1003328: `在庫アシスタント`,
  key1003329: `店舗SKU関連在庫SKU`,
  key1003330: `会員`,
  key1003331: `顧客情報`,
  key1003332: `顧客詳細情報`,
  key1003333: `ブラックリスト`,
  key1003334: `リマーケティング`,
  key1003335: `マーケティングキャンペーンを作成する`,
  key1003336: `マーケティングイベントの詳細`,
  key1003337: `返品の詳細`,
  key1003338: `交換詳細`,
  key1003339: `修理の詳細`,
  key1003340: `保証カードプール`,
  key1003341: `データ＃データ＃`,
  key1003342: `店舗分析`,
  key1003343: `店舗統計`,
  key1003344: `受注分析`,
  key1003345: `利益分析`,
  key1003346: `メーカー業績統計`,
  key1003347: `購買担当者業績統計`,
  key1003348: `商品販売量の分析`,
  key1003349: `商品在庫分析`,
  key1003350: `顧客プロファイル`,
  key1003351: `入出庫分析`,
  key1003352: `ベスト・セラー`,
  key1003353: `アマゾンレポート`,
  key1003354: `に報いる`,
  key1003355: `たな卸し`,
  key1003356: `月別倉庫保管費`,
  key1003357: `長期倉庫保管費`,
  key1003358: `設定`,
  key1003359: `プラットフォーム管理`,
  key1003360: `購入済み`,
  key1003361: `未購入`,
  key1003362: `結果ページ`,
  key1003363: `支払オーダ`,
  key1003364: `ユーザーセンター`,
  key1003365: `駅構内レター詳細`,
  key1003366: `従業員アカウント`,
  key1003367: `倉庫設定`,
  key1003368: `機能設定`,
  key1003369: `為替レートの設定`,
  key1003370: `タスクセンター`,
  key1003371: `SMS送信記録`,
  key1003372: `請求書テンプレート`,
  key1003373: `新規請求書`,
  key1003374: `請求書の編集`,
  key1003375: `メール送信の記録`,
  key1003376: `注文ルール`,
  key1003377: `倉庫マッピングルール`,
  key1003378: `物流マッチングの規則`,
  key1003379: `SMSルール`,
  key1003380: `メール送信ルール`,
  key1003381: `構成詳細の取得に失敗しました`,
  key1003382: `スキャン待ち`,
  key1003383: `スキャン中`,
  key1003384: `スキャン完了`,
  key1003385: `列印刷完了`,
  key1003386: `テキスト翻訳`,
  key1003387: `ワンクリック翻訳、ワンクリック翻訳タイトル、説明などに適しています。50000字元は無料で使用され、後に購入する必要があり、購入額は当月有効で、翌月に使い切らなかった部分は自動的にゼロになる。`,
  key1003388: `画像翻訳に適用して、無料で20枚、後10元/200枚を超えて、当月は有効で、翌月は使い終わっていない部分は自動的にゼロになります。`,
  key1003389: `メール料金プラン`,
  key1003390: `メール料金プラン。`,
  key1003391: `あなたの製品はeBayに提出しました、[掲載オンライン]に提出結果を確認してください。`,
  key1003392: `製品を追加する`,
  key1003393: `製品を見る`,
  key1003394: `発表成功`,
  key1003395: `製品の発表に失敗しました。失敗の理由を確認して、修正して再度発表してください`,
  key1003396: `編集を続ける`,
  key1003397: `あなたの製品は保存しました、[下書きボックス]に確認してください。`,
  key1003398: `製品が正常に更新されました。[オンライン製品]に確認してください。`,
  key1003399: `製品の更新に失敗しました。元内容を保持してください`,
  key1003400: `更新に失敗しました`,
  key1003401: `あなたの製品はShoplineを提出しました。【オンライン掲載】で提出結果を確認してください！`,
  key1003402: `あなたの製品はShopifyを提出しました。【オンライン掲載】で提出結果を確認してください！`,
  key1003403: `あなたの制品はすでにShopeeに提出しました、【掲載オンライン】まで提出結果を確認してください!`,
  key1003404: `あなたの制品はすでにCoupangに提出して、【掲載オンライン】まで提出結果を確認してください!`,
  key1003405: `あなたの製品はすでにAmazonに提出されていますので、【オンライン掲載】で提出結果をご覧ください！`,
  key1003406: `あなたの制品はすでにLazadaに提出しました、【掲載オンライン】まで提出結果を確認してください!`,
  key1003407: `未受付`,
  key1003408: `取消済`,
  key1003409: `処理済み`,
  key1003410: `輸送中`,
  key1003411: `商品の集荷に失敗しました`,
  key1003412: `受取済`,
  key1003413: `受取異常`
}

export default {
  token_time: 1800000,
  curTime: 30000,
  version: '1000100',
  chromeCollectionUrl:
    'https://img2.tongtool.com/s/static/plugin/1.2.4/tongtool-dataCollection.crx',
  collectionHelpUrl:
    'http://help.tongtool.com/service.html?artId=360&&categoryId=194&&groupId=3',
  serverURlPrefix:
    process.env.NODE_ENV === 'production' ? '/twerp-service' : '',
  twMer: [],
  customerTokenKey: 'customer_token',
  customerTokenTime: 900000
}

export default {
  key1000000: `提交成功`,
  key1000001: `没有权限`,
  key1000002: `接口报错了，请在控制台查看接口错误`,
  key1000003: `提示`,
  key1000004: `前往購買`,
  key1000005: `取消`,
  key1000006: `查詢不到相關用戶信息`,
  key1000007: `請填寫保固號碼`,
  key1000008: `啟動成功`,
  key1000009: `保固卡號已被使用`,
  key1000010: `保固卡號不存在`,
  key1000011: `選取商品`,
  key1000012: `請輸入商品名稱`,
  key1000013: `搜索`,
  key1000014: `良品數：`,
  key1000015: `殘次品庫存數：`,
  key1000016: `保存`,
  key1000017: `請選擇商品`,
  key1000018: `選取組合品`,
  key1000019: `銷量統計`,
  key1000020: `平台`,
  key1000021: `銷量`,
  key1000022: `信息未填寫完整`,
  key1000023: `庫存變動記錄`,
  key1000024: `時間`,
  key1000025: `人員`,
  key1000026: `變動類型`,
  key1000027: `相關單號`,
  key1000028: `良品庫存變動`,
  key1000029: `殘次品變動`,
  key1000030: `在途庫存變動`,
  key1000031: `待出貨庫存變動`,
  key1000032: `導出`,
  key1000033: `關閉`,
  key1000034: `匯入匯出任務`,
  key1000035: `關`,
  key1000036: `閉`,
  key1000037: `上載任務正在執行， 您也可以關閉窗口， 上載任務將會在後台自行執行直到結束， 您也可以到 `,
  key1000038: `查看匯入任務的結果。`,
  key1000039: `匯入初始化庫存`,
  key1000040: `採購`,
  key1000041: `配貨`,
  key1000042: `訂單退回到新訂單`,
  key1000043: `發貨`,
  key1000044: `盤點`,
  key1000045: `已出貨訂單導入`,
  key1000046: `初始化`,
  key1000047: `退貨`,
  key1000048: `異動單`,
  key1000049: `移庫單`,
  key1000050: `組合品裝配單`,
  key1000051: `组合品拆分单`,
  key1000052: `返修單`,
  key1000053: `返修單創建`,
  key1000054: `返修單更新已返回`,
  key1000055: `返修單刪除`,
  key1000056: `訂單已取消`,
  key1000057: `訂單已退款`,
  key1000058: `採購單收貨`,
  key1000059: `採購單刪除`,
  key1000060: `採購單創建`,
  key1000061: `採購單強制結案`,
  key1000062: `採購單修改`,
  key1000063: `退货`,
  key1000064: `未取`,
  key1000065: `是否匯出`,
  key1000066: `溫馨提示`,
  key1000067: `確定`,
  key1000068: `匯出當前庫存`,
  key1000069: `匯出任務正在執行，您也可以關閉窗口，匯出任務將會在後台自行執行直到結束，您可以到`,
  key1000070: `查看匯出任務的結果。`,
  key1000071: `導出失敗，是否下載文件查看失敗原因?`,
  key1000072: `導出成功`,
  key1000073: `默認`,
  key1000074: `同步收貨地址`,
  key1000075: `選擇收貨地址`,
  key1000076: `請選擇地址`,
  key1000077: `你没有访问该页面的权限`,
  key1000078: `返回首页`,
  key1000079: `返回上一页`,
  key1000080: `快捷填入`,
  key1000081: `图片过大`,
  key1000082: `請輸入`,
  key1000083: `用户信息`,
  key1000084: `設置`,
  key1000085: `編輯資料`,
  key1000086: `姓名`,
  key1000087: `修改密碼`,
  key1000088: `舊密碼`,
  key1000089: `新密碼`,
  key1000090: `確認密碼`,
  key1000091: `中文繁體`,
  key1000092: `日本語`,
  key1000093: `管理中心`,
  key1000094: `帳號管理`,
  key1000095: `用户名`,
  key1000096: `商戶號`,
  key1000097: `複製`,
  key1000098: `付款信息`,
  key1000099: `增值資源包`,
  key1000100: `默认币种`,
  key1000101: `主題設置`,
  key1000102: `推荐颜色`,
  key1000103: `售後`,
  key1000104: `文檔與工具`,
  key1000105: `產品幫助文檔`,
  key1000106: `登出系統`,
  key1000107: `请输入密码`,
  key1000108: `请再次输入密码`,
  key1000109: `两次输入密码不一致!`,
  key1000110: `舊密碼不能為空`,
  key1000111: `用戶名不能為空`,
  key1000112: `保存成功`,
  key1000113: `修改成功`,
  key1000114: `舊密碼不正確`,
  key1000115: `複製成功`,
  key1000116: `複製失敗`,
  key1000117: `標記成功`,
  key1000118: `站內信標記已讀失敗`,
  key1000119: `展開`,
  key1000120: `收起`,
  key1000121: `通知`,
  key1000122: `公告`,
  key1000123: `消息提示`,
  key1000124: `查看所有`,
  key1000125: `全部標記已讀`,
  key1000126: `菜單管理`,
  key1000127: `暂无数据`,
  key1000128: `採購管理`,
  key1000129: `已結案`,
  key1000130: `待收貨數`,
  key1000131: `交貨清單`,
  key1000132: `未結案`,
  key1000133: `全部`,
  key1000134: `日`,
  key1000135: `周`,
  key1000136: `年`,
  key1000137: `訂單量`,
  key1000138: `訂單額`,
  key1000139: `查詢店鋪分析统计失敗`,
  key1000140: `倉庫管理`,
  key1000141: `正在盤點`,
  key1000142: `返修單未結案`,
  key1000143: `返修單待收貨數`,
  key1000144: `未在盤點`,
  key1000145: `店鋪`,
  key1000146: `總計`,
  key1000147: `草稿`,
  key1000148: `發佈中`,
  key1000149: `在線產品`,
  key1000150: `發佈失敗`,
  key1000151: `產品刊登總數`,
  key1000152: `查詢店铺失敗`,
  key1000153: `店鋪到期通知`,
  key1000154: `查看更多`,
  key1000155: `(剩餘`,
  key1000156: `天`,
  key1000157: `到期)`,
  key1000158: `分類名稱`,
  key1000159: `訂單金額`,
  key1000160: `訂單數`,
  key1000161: `銷售數量`,
  key1000162: `最暢銷分類`,
  key1000163: `未分類`,
  key1000164: `前5名`,
  key1000165: `前10名`,
  key1000166: `前15名`,
  key1000167: `前20名`,
  key1000168: `前25名`,
  key1000169: `訂單名數`,
  key1000170: `系統通告`,
  key1000171: `商品信息`,
  key1000172: `最暢銷商品`,
  key1000173: `刊登商品`,
  key1000174: `我的商品`,
  key1000175: `平台最暢銷商品`,
  key1000176: `店鋪最暢銷商品`,
  key1000177: `前3名`,
  key1000178: `售後服務`,
  key1000179: `換貨完成`,
  key1000180: `換貨中`,
  key1000181: `維修完成`,
  key1000182: `維修中`,
  key1000183: `退貨完成`,
  key1000184: `退貨中`,
  key1000185: `訂單管理`,
  key1000186: `更新物流信息失敗`,
  key1000187: `查詢原始訂單異常`,
  key1000188: `未查詢到訂單`,
  key1000189: `訂單配送方式未匹配`,
  key1000190: `訂單狀態異常`,
  key1000191: `訂單未支付`,
  key1000192: `庫存不足夠`,
  key1000193: `庫存已鎖定`,
  key1000194: `訂單商品未匹配`,
  key1000195: `已取消訂單`,
  key1000196: `已退款訂單`,
  key1000197: `黑名單客戶`,
  key1000198: `倉庫未匹配`,
  key1000199: `查询托運列表失败`,
  key1000200: `保存中`,
  key1000201: `獲取用戶倉庫列表失敗`,
  key1000202: `查询貨幣列表失败`,
  key1000203: `是否确认删除?`,
  key1000204: `确定`,
  key1000205: `请选择系统默认币种，可输入搜索`,
  key1000206: `欢迎使用EasySellers`,
  key1000207: `为了您有更好的使用体验，请设置您的系统默认币种`,
  key1000208: `（注：系统本币币种`,
  key1000209: `设置后，系统中的统计报表将默认选中该币种）`,
  key1000210: `提醒`,
  key1000211: `请选择默认货币`,
  key1000212: `保存失败`,
  key1000213: `查询失败`,
  key1000214: `请选择或者輸入關鍵詞搜索`,
  key1000215: `確認`,
  key1000216: `最大輸入50個字符`,
  key1000217: `不能为空`,
  key1000218: `匯出的檔案最多可支援下載的時限為七天，超過時間將無法下載。`,
  key1000219: `任務類型`,
  key1000220: `開始時間`,
  key1000221: `完成時間`,
  key1000222: `狀態`,
  key1000223: `結果`,
  key1000224: `結果匯出`,
  key1000225: `处理中`,
  key1000226: `处理完成`,
  key1000227: `失败`,
  key1000228: `返回`,
  key1000229: `總共:`,
  key1000230: `成功/失敗:`,
  key1000231: `下載`,
  key1000232: `執行中`,
  key1000233: `執行完成`,
  key1000234: `執行失敗`,
  key1000235: `商品`,
  key1000236: `客戶`,
  key1000237: `初始化庫存`,
  key1000238: `採購單`,
  key1000239: `組合品`,
  key1000240: `歷史訂單`,
  key1000241: `庫存盤點`,
  key1000242: `換貨`,
  key1000243: `維修`,
  key1000244: `匯出`,
  key1000245: `匯入`,
  key1000246: `E-mail發送成功，請到E-mail信箱查看驗證碼。`,
  key1000247: `該信件有可能出現在「垃圾信箱」裡，可至「垃圾信箱」確認，`,
  key1000248: `若5分鐘後未收到，請再次點擊取得驗證碼`,
  key1000249: `請輸入正確的郵箱地址`,
  key1000250: `請輸入手機號碼`,
  key1000251: `簡訊發送成功，請至手機查看，若5分鐘後未收到，請再次點擊取得驗證碼`,
  key1000252: `發送失敗`,
  key1000253: `驗證碼不能為空`,
  key1000254: `驗證碼錯誤`,
  key1000255: `驗證碼已過期`,
  key1000256: `商家號不能為空`,
  key1000257: `客戶不存在`,
  key1000258: `登錄失敗`,
  key1000259: `保固卡號`,
  key1000260: `請輸入模保固卡號`,
  key1000261: `是否使用`,
  key1000262: `請選擇是否使用`,
  key1000263: `匯出時間`,
  key1000264: `批量添加保固卡號`,
  key1000265: `生成规则：前缀固定3位码+10位亂序码（隨機英文小寫或數字组成）`,
  key1000266: `前缀`,
  key1000267: `生成數量`,
  key1000268: `匯出設置`,
  key1000269: `零件名稱`,
  key1000270: `型號名稱`,
  key1000271: `匯入保修單`,
  key1000272: `/file/保固卡匯入匯出模板.xlsx`,
  key1000273: `篩選`,
  key1000274: `重置`,
  key1000275: `匯入匯出`,
  key1000276: `匯出所選`,
  key1000277: `匯出所有`,
  key1000278: `按規則生成`,
  key1000279: `設置保固卡`,
  key1000280: `批量删除`,
  key1000281: `未使用`,
  key1000282: `已使用`,
  key1000283: `下載保固卡匯入模板的Excel範例資料（`,
  key1000284: `範例.xlsx`,
  key1000285: `填寫好的保固卡模板在下方上載。`,
  key1000286: `將excel文件拖到此處，或`,
  key1000287: `點擊上傳`,
  key1000288: `只能上傳.xlsx文件，且不超過2MB`,
  key1000289: `保固卡號【`,
  key1000290: `】已存在`,
  key1000291: `】已被使用`,
  key1000292: `匯出保修單`,
  key1000293: `匯出任務正在執行，您也可以關閉窗口，匯出任務將會在後台自行執行直到結束，您可以到 `,
  key1000294: `處理日期`,
  key1000295: `至`,
  key1000296: `開始日期`,
  key1000297: `結束日期`,
  key1000298: `商品分類`,
  key1000299: `請選擇分類`,
  key1000300: `關鍵詞`,
  key1000301: `請輸入搜索關鍵詞`,
  key1000302: `訂單編號`,
  key1000303: `聯絡平台/聯絡人`,
  key1000304: `維修商品/數量`,
  key1000305: `收件人`,
  key1000306: `電話`,
  key1000307: `地址`,
  key1000308: `維修類別

`,
  key1000309: `處理方式

`,
  key1000310: `物流費用（來/回）`,
  key1000311: `收到日期`,
  key1000312: `寄回日期`,
  key1000313: `備註`,
  key1000314: `貨運單號`,
  key1000315: `操作`,
  key1000316: `匯入退貨`,
  key1000317: `/file/退貨匯入樣本.xlsx`,
  key1000318: `完成維修`,
  key1000319: `寄出日期：`,
  key1000320: `寄出日期`,
  key1000321: `貨運單號：`,
  key1000322: `匯出維修`,
  key1000323: `匯出全部`,
  key1000324: `已選擇：`,
  key1000325: `條數據`,
  key1000326: `編輯`,
  key1000327: `删除`,
  key1000328: `下載匯入退貨的Excel範例資料（`,
  key1000329: `填寫好的退貨檔案在下方上載。`,
  key1000330: `請選擇寄出日期`,
  key1000331: `請輸入貨運單號`,
  key1000332: `最近一周`,
  key1000333: `最近一個月`,
  key1000334: `最近三個月`,
  key1000335: `操作成功`,
  key1000336: `售后服务为空`,
  key1000337: `庫存接口異常`,
  key1000338: `庫存不足`,
  key1000339: `導入失敗，是否下載文件查看失敗原因?`,
  key1000340: `上傳文件大小不能超過2M`,
  key1000341: `請選擇維修訂單`,
  key1000342: `將你篩選出的維修匯出到Excel檔案。`,
  key1000343: `退貨接收倉庫`,
  key1000344: `退貨商品/數量`,
  key1000345: `換貨出貨倉庫`,
  key1000346: `換貨商品/數量`,
  key1000347: `換貨類別`,
  key1000348: `處理方式`,
  key1000349: `完成換貨`,
  key1000350: `匯出換貨`,
  key1000351: `請選擇換貨訂單`,
  key1000352: `將你篩選出的換貨匯出到Excel檔案。`,
  key1000353: `未取商品/數量`,
  key1000354: `未取原因

`,
  key1000355: `接收倉庫

`,
  key1000356: `批量新增`,
  key1000357: `请选择`,
  key1000358: `匯出未取`,
  key1000359: `確定保存未取記錄，庫存將會回補。`,
  key1000360: `訂單編號：`,
  key1000361: `未取原因：`,
  key1000362: `接收倉庫:`,
  key1000363: `其他`,
  key1000364: `<p>訂單編號：`,
  key1000365: `訂單不存在`,
  key1000366: `查询的订单编号集合为空!`,
  key1000367: `請填寫完整`,
  key1000368: `請選擇未取訂單`,
  key1000369: `將你篩選出的未取匯出到Excel檔案。`,
  key1000370: `聯絡平台：`,
  key1000371: `聯絡人：`,
  key1000372: `退貨類別：`,
  key1000373: `處理方式：`,
  key1000374: `物流運費類型：`,
  key1000375: `物流費用（來）：`,
  key1000376: `退款金額：`,
  key1000377: `備註：`,
  key1000378: `圖片`,
  key1000379: `貨號/商品名稱/規格`,
  key1000380: `訂購數量`,
  key1000381: `退貨數量`,
  key1000382: `買家承擔`,
  key1000383: `賣家承擔`,
  key1000384: `獲取退貨詳情失敗`,
  key1000385: `獲取數據字典報錯`,
  key1000386: `維修類別：`,
  key1000387: `物流費用（回）：`,
  key1000388: `收到日期：`,
  key1000389: `收件人：`,
  key1000390: `地址1：`,
  key1000391: `地址2/區：`,
  key1000392: `城市：`,
  key1000393: `省/州：`,
  key1000394: `國家：`,
  key1000395: `電話號碼：`,
  key1000396: `維修數量`,
  key1000397: `換貨類別：`,
  key1000398: `換貨數量`,
  key1000399: `退貨商品`,
  key1000400: `換貨商品`,
  key1000401: `添加商品`,
  key1000402: `退貨接收倉庫和換貨出貨倉庫不能為空`,
  key1000403: `退貨商品和換貨商品不能為空`,
  key1000404: `退貨類別`,
  key1000405: `接收倉庫`,
  key1000406: `物流費用（來）`,
  key1000407: `退款金額`,
  key1000408: `完成退貨`,
  key1000409: `接收倉庫：`,
  key1000410: `匯出退貨`,
  key1000411: `完成退貨，庫存將會回補`,
  key1000412: `請選擇收到日期`,
  key1000413: `請選擇接受倉庫`,
  key1000414: `請選擇退貨訂單`,
  key1000415: `將你篩選出的退貨匯出到Excel檔案。`,
  key1000416: `貨號商品名稱規格`,
  key1000417: `30天銷量`,
  key1000418: `60天銷量`,
  key1000419: `累計銷量`,
  key1000420: `叫貨週期`,
  key1000421: `叫貨水線`,
  key1000422: `叫貨數量`,
  key1000423: `采购单价`,
  key1000424: `已收貨良品/殘次品`,
  key1000425: `本次收貨數量良品/殘次品`,
  key1000426: `残次品備註`,
  key1000427: `廠商：`,
  key1000428: `倉庫：`,
  key1000429: `預計到貨日：`,
  key1000430: `叫貨商品數：`,
  key1000431: `叫貨總數：`,
  key1000432: `採購商品總價：`,
  key1000433: `1688總金額：`,
  key1000434: `未收貨:`,
  key1000435: `廠商`,
  key1000436: `请输入关键词`,
  key1000437: `採購日期`,
  key1000438: `出貨倉庫`,
  key1000439: `1688採購是否支付`,
  key1000440: `1688訂單狀態`,
  key1000441: `採購單類型`,
  key1000442: `採購單編號`,
  key1000443: `客製化編號`,
  key1000444: `倉庫`,
  key1000445: `訂單日期`,
  key1000446: `預計到達日期`,
  key1000447: `商品品種數`,
  key1000448: `商品總數量`,
  key1000449: `已收貨數量`,
  key1000450: `1688交易狀態`,
  key1000451: `金額`,
  key1000452: `創建人`,
  key1000453: `採購單狀態`,
  key1000454: `強制結案人`,
  key1000455: `強制結案時間`,
  key1000456: `匯出採購單`,
  key1000457: `物流跟蹤`,
  key1000458: `物流信息`,
  key1000459: `自訂篩選`,
  key1000460: `保存自訂篩選`,
  key1000461: `隱藏`,
  key1000462: `刪除`,
  key1000463: `新增採購單`,
  key1000464: `新增1688採購單`,
  key1000465: `同步1688信息`,
  key1000466: `1688訂單支付`,
  key1000467: `支付寶`,
  key1000468: `誠E賒`,
  key1000469: `跨境寶`,
  key1000470: `採購金額：`,
  key1000471: `1688金額：`,
  key1000472: `已生成待收貨`,
  key1000473: `部分收貨（未結案）`,
  key1000474: `收貨`,
  key1000475: `導出Excel`,
  key1000476: `強制結案`,
  key1000477: `1688下单`,
  key1000478: `物流跟踪`,
  key1000479: `匯出任務正在執行，`,
  key1000480: `您也可以關閉窗口，`,
  key1000481: `匯出任務將會在後台自行執行直到結束，`,
  key1000482: `您可以到`,
  key1000483: `物流狀態：`,
  key1000484: `物流公司：`,
  key1000485: `物流單號：`,
  key1000486: `非1688訂單,`,
  key1000487: `是否忽略继续進行?`,
  key1000488: `是否完成支付？`,
  key1000489: `已付款`,
  key1000490: `未付款`,
  key1000491: `等待買家付款`,
  key1000492: `等待賣家發貨`,
  key1000493: `等待物流公司攬件`,
  key1000494: `等待買家簽收`,
  key1000495: `買家已簽收`,
  key1000496: `已收貨`,
  key1000497: `交易成功`,
  key1000498: `交易取消`,
  key1000499: `交易終止`,
  key1000500: `待收貨`,
  key1000501: `查詢分類列表失敗`,
  key1000502: `同步成功，請稍候查看`,
  key1000503: `同步失敗`,
  key1000504: `1688下单成功，请稍候查询结果`,
  key1000505: `保存成功失敗`,
  key1000506: `請選擇要同步的採購單`,
  key1000507: `最多選擇30條1688採購單進行同步`,
  key1000508: `請選擇1688訂單進行同步`,
  key1000509: `請輸入保存名稱`,
  key1000510: `已完成支付,同步信息`,
  key1000511: `支付失敗`,
  key1000512: `請選擇要支付的1688採購單`,
  key1000513: `最多選擇30條1688採購單進行支付`,
  key1000514: `請選擇1688訂單進行支付`,
  key1000515: `該篩選尚未保存`,
  key1000516: `刪除成功`,
  key1000517: `採購單強制結案將會把未收貨的庫存沖銷掉。`,
  key1000518: `是否刪除？`,
  key1000519: `請選擇採購單`,
  key1000520: `將妳已選擇的採購單匯出到Excel檔案。`,
  key1000521: `匯出採購單失敗`,
  key1000522: `查詢進度失敗`,
  key1000523: `庫存低於叫貨水線的商品將會出現在叫貨清單當中，建議叫貨量是根據30天銷量、60天銷量、叫貨週期計算出來的。`,
  key1000524: `貨號商品名稱`,
  key1000525: `首選廠商/叫貨週期`,
  key1000526: `請選擇`,
  key1000527: `備選廠商1/叫貨週期`,
  key1000528: `備選廠商2/叫貨週期`,
  key1000529: `請先選擇首選廠商`,
  key1000530: `首選廠商和備選的廠商不能一樣`,
  key1000531: `備選廠商2不能和備選廠商1一樣`,
  key1000532: `請先選擇備選廠商`,
  key1000533: `请输入内容`,
  key1000534: `選擇日期`,
  key1000535: `在途庫存/現有良品庫存`,
  key1000536: `最小採購量`,
  key1000537: `建議叫貨量`,
  key1000538: `採購單價`,
  key1000539: `幣種`,
  key1000540: `各倉庫庫存`,
  key1000541: `在途庫存`,
  key1000542: `現有良品庫存`,
  key1000543: `預計到貨日`,
  key1000544: `新增商品`,
  key1000545: `查看其它倉庫庫存`,
  key1000546: `其它倉庫沒有數據`,
  key1000547: `查詢其它倉庫庫存失敗`,
  key1000548: `添加貨品失敗`,
  key1000549: `獲取採購單詳情失敗`,
  key1000550: `請選擇廠商`,
  key1000551: `請選擇倉庫`,
  key1000552: `客製化編號不能重複`,
  key1000553: `此清單為您的1688買家帳號清單，用於獲取1688廠商和生成1688訂單等功能`,
  key1000554: `帳號名稱`,
  key1000555: `1688帳號名稱`,
  key1000556: `過期時間`,
  key1000557: `授權狀態`,
  key1000558: `授權給到的員工`,
  key1000559: `新增授權`,
  key1000560: `编辑`,
  key1000561: `重新授權`,
  key1000562: `無效`,
  key1000563: `有效`,
  key1000564: `查詢1688授權列表失敗`,
  key1000565: `是否重新授權`,
  key1000566: `獲取1688授權URL失敗`,
  key1000567: `查詢員工詳情失敗`,
  key1000568: `新增員工失敗`,
  key1000569: `是否停用該帳號？`,
  key1000570: `停用成功`,
  key1000571: `停用失敗`,
  key1000572: `授权成功`,
  key1000573: `服务错误`,
  key1000574: `账号或者账号代码已存在`,
  key1000575: `账号不存在`,
  key1000576: `账号不匹配`,
  key1000577: `授权失败`,
  key1000578: `在此管理廠商的檔案。`,
  key1000579: `廠商類型`,
  key1000580: `廠商編號`,
  key1000581: `廠商名稱`,
  key1000582: `聯絡人`,
  key1000583: `聯絡電話`,
  key1000584: `廠商網址`,
  key1000585: `批量刪除`,
  key1000586: `增加`,
  key1000587: `廠商編號不能為空`,
  key1000588: `廠商名稱不能為空`,
  key1000589: `新增廠商`,
  key1000590: `默認不選`,
  key1000591: `存在未結案的採購單`,
  key1000592: `廠商編碼不能重複`,
  key1000593: `廠商名稱不能重複`,
  key1000594: `編輯廠商`,
  key1000595: `選擇廠商`,
  key1000596: `廠商檔案刪除後將不可恢復， 請謹慎決定。 `,
  key1000597: `刪除廠商`,
  key1000598: `一個小時內將不允許再次重新計算`,
  key1000599: `現有/在途庫存`,
  key1000600: `良品庫存`,
  key1000601: `重新計算`,
  key1000602: `修改廠商`,
  key1000603: `選擇調撥倉庫`,
  key1000604: `生成所選採購單`,
  key1000605: `生成全部採購單`,
  key1000606: `生成調撥單`,
  key1000607: `其他倉庫存`,
  key1000608: `計算叫貨清單需要統計大量數據，可能會需要較長時間，請謹慎執行，一旦重新計算，一個小時內將不允許再次重新計算。`,
  key1000609: `請選擇叫貨清單`,
  key1000610: `生成調撥單所選倉庫必須一致`,
  key1000611: `獲取倉庫列表失敗`,
  key1000612: `查詢倉庫商品情況失敗`,
  key1000613: `生成調撥單失敗`,
  key1000614: `查詢失敗`,
  key1000615: `重新计算失敗`,
  key1000616: `合計`,
  key1000617: `查询库存明细失敗`,
  key1000618: `保存並授權`,
  key1000619: `編輯帳號`,
  key1000620: `帳號名稱不能為空`,
  key1000621: `產品信息`,
  key1000622: `採購鏈接`,
  key1000623: `新增關聯商品`,
  key1000624: `請添加商品`,
  key1000625: `請輸入採購鏈接`,
  key1000626: `此清單為您的1688廠商本地商品與1688平臺商品關聯關係`,
  key1000627: `1688關聯商品`,
  key1000628: `請輸入sku`,
  key1000629: `本地產品信息`,
  key1000630: `1688產品信息`,
  key1000631: `修改關聯`,
  key1000632: `取消修改`,
  key1000633: `未檢索到商品，請確認採購連結是否正確`,
  key1000634: `未識別`,
  key1000635: `未關聯`,
  key1000636: `已關聯`,
  key1000637: `查詢廠商失敗`,
  key1000638: `修改關聯失敗`,
  key1000639: `查詢採購關聯列表失敗`,
  key1000640: `已收貨數量 良品/殘次品`,
  key1000641: `收貨記錄`,
  key1000642: `收貨時間`,
  key1000643: `良品收貨數量`,
  key1000644: `殘次品收貨數量`,
  key1000645: `殘次品備註`,
  key1000646: `收貨人`,
  key1000647: `客製化編號：`,
  key1000648: `創建人：`,
  key1000649: `1688帳號`,
  key1000650: `收貨地址`,
  key1000651: `1688訂單交易方式`,
  key1000652: `採購倉庫`,
  key1000653: `預計到貨日期`,
  key1000654: `1688買家留言`,
  key1000655: `小計`,
  key1000656: `保存并向1688下单`,
  key1000657: `新增授權帳號`,
  key1000658: `刷新`,
  key1000659: `大市場普通訂單`,
  key1000660: `分銷訂單`,
  key1000661: `自動選擇`,
  key1000662: `支付寶擔保交易`,
  key1000663: `店鋪帳號不能為空`,
  key1000664: `請選擇採購倉庫`,
  key1000665: `同步地址成功`,
  key1000666: `查詢收貨地址失敗`,
  key1000667: `請選擇1688帳號`,
  key1000668: `1688下單成功`,
  key1000669: `新增關聯`,
  key1000670: `取消關聯`,
  key1000671: `查詢1688商品關聯信息失敗`,
  key1000672: `是否刪除綁定關聯`,
  key1000673: `取消關聯成功`,
  key1000674: `取消關聯失敗`,
  key1000675: `請選擇要操作的數據`,
  key1000676: `新增商品關聯成功`,
  key1000677: `新增商品關聯失敗`,
  key1000678: `修改商品關聯成功`,
  key1000679: `修改商品關聯失敗`,
  key1000680: `添加部件`,
  key1000681: `預覽`,
  key1000682: `請輸入模板名稱`,
  key1000683: `佈局`,
  key1000684: `系統通知`,
  key1000685: `显示系统的站内信通知`,
  key1000686: `通知最近快到期的店鋪，可以點擊續費`,
  key1000687: `銷量分析`,
  key1000688: `平台/店鋪銷量`,
  key1000689: `已平台或店铺为维度统计平台的销量数据`,
  key1000690: `產品分析`,
  key1000691: `刊登產品數`,
  key1000692: `顯示系統中總的刊登產品數`,
  key1000693: `最暢銷產品`,
  key1000694: `顯示產品銷量最高的產品或系統商品`,
  key1000695: `最暢銷分类`,
  key1000696: `顯示最暢銷我的商品中銷量最高的分類`,
  key1000697: `待處理事項`,
  key1000698: `顯示訂單目前需要處理的訂單數量`,
  key1000699: `售後管理`,
  key1000700: `顯示售後目前需要處理的訂單數量`,
  key1000701: `顯示採購目前需要處理的採購單`,
  key1000702: `顯示目前倉庫需要處理的採購單`,
  key1000703: `修改佈局`,
  key1000704: `店鋪授權`,
  key1000705: `授權後可啟動使用EasySellers，享用快速刊登、訂單處理等眾多功能！`,
  key1000706: `新增模板`,
  key1000707: `默認模板`,
  key1000708: `查詢平台列表失敗`,
  key1000709: `是否刪除當前模板?`,
  key1000710: `刪除模板失敗`,
  key1000711: `部件不能為空`,
  key1000712: `修改模板成功`,
  key1000713: `新增模板成功`,
  key1000714: `修改模板失敗`,
  key1000715: `新增模板失敗`,
  key1000716: `當前模板尚未保存, 是否放棄保存當前模板?`,
  key1000717: `查詢模板列表失敗`,
  key1000718: `最多添加6個部件`,
  key1000719: `模板名稱不能為空`,
  key1000720: `模板名稱已存在`,
  key1000721: `請輸入首碼`,
  key1000722: `請輸入尾碼`,
  key1000723: `SKU混淆解析功能設置`,
  key1000724: `刊登或同步線上產品時，自動根據規則建立店鋪SKU和系統庫存SKU的匹配關係`,
  key1000725: `僅當【店鋪SKU】與【庫存SKU】一致時自動匹配，匹配成功後不再通過其他截取規則匹配`,
  key1000726: `開啟自定義SKU截取匹配功能`,
  key1000727: `是否開啟優先匹配庫存SKU`,
  key1000728: `勾选时：优先用库存SKU匹配，匹配上了不再按其他规则匹配，匹配不上再按截取规则匹配，再匹配不上则按【店铺SKU与库存SKU关联关系】匹配`,
  key1000729: `未勾选时：仅根据截取规则匹配，截取成功后自动匹配，再匹配不上则按【店铺SKU与库存SKU关联关系】匹配`,
  key1000730: `按前、尾碼截取`,
  key1000731: `按位數截取`,
  key1000732: `去掉首碼`,
  key1000733: `去掉尾碼`,
  key1000734: `去掉前面X位`,
  key1000735: `去掉後面X位`,
  key1000736: `測試樣例`,
  key1000737: `測試`,
  key1000738: `測試結果:`,
  key1000739: `测试结果为空`,
  key1000740: `修改失敗`,
  key1000741: `查询失敗`,
  key1000742: `角色名稱`,
  key1000743: `請輸入角色名稱`,
  key1000744: `權限`,
  key1000745: `模塊`,
  key1000746: `全選`,
  key1000747: `全選(`,
  key1000748: `權限不能為空`,
  key1000749: `角色名稱不能重複`,
  key1000750: `接收人手機號`,
  key1000751: `請輸入接收人手機號`,
  key1000752: `發送時間`,
  key1000753: `接收人區號`,
  key1000754: `模板名稱`,
  key1000755: `短信支出`,
  key1000756: `短信拆分條數`,
  key1000757: `最近更新時間`,
  key1000758: `重新發送`,
  key1000759: `失敗`,
  key1000760: `成功`,
  key1000761: `短信模板`,
  key1000762: `發送記錄`,
  key1000763: `請選擇要重新發送的短信`,
  key1000764: `重新發送操作成功`,
  key1000765: `付款方式`,
  key1000766: `付款帳號`,
  key1000767: `服務內容`,
  key1000768: `類型`,
  key1000769: `主動購買`,
  key1000770: `自動扣費`,
  key1000771: `EasySellers中的購買使用的付款方式`,
  key1000772: `交易清單`,
  key1000773: `消息內容`,
  key1000774: `消息類型`,
  key1000775: `站內信`,
  key1000776: `查看詳情`,
  key1000777: `查詢站內信列表出錯`,
  key1000778: `沒有找到相關內容`,
  key1000779: `發佈日期：`,
  key1000780: `獲取站內信詳情失敗`,
  key1000781: `收件人郵箱`,
  key1000782: `請輸入收件人郵箱`,
  key1000783: `郵件模板名稱`,
  key1000784: `失敗原因`,
  key1000785: `郵件模板`,
  key1000786: `請選擇要重新發送的Email`,
  key1000787: `優先級`,
  key1000788: `置頂`,
  key1000789: `置底`,
  key1000790: `拖動擺放`,
  key1000791: `往上一層`,
  key1000792: `往下一層`,
  key1000793: `規則名稱`,
  key1000794: `匹配倉庫名稱`,
  key1000795: `最近修改時間`,
  key1000796: `匹配發送倉庫`,
  key1000797: `新增`,
  key1000798: `可選條件`,
  key1000799: `平台店鋪`,
  key1000800: `平台/站點/店鋪`,
  key1000801: `收貨國家`,
  key1000802: `訂單商品`,
  key1000803: `訂單總金額`,
  key1000804: `訂單總總量`,
  key1000805: `買家指定托運方式`,
  key1000806: `發貨倉庫`,
  key1000807: `地址包含關鍵字`,
  key1000808: `客服備註包含關鍵字`,
  key1000809: `分店名稱`,
  key1000810: `已選條件`,
  key1000811: `觸發動作`,
  key1000812: `（滿足以上所選條件時，動作生效）`,
  key1000813: `請選擇托運公司`,
  key1000814: `請選擇托運方式`,
  key1000815: `請輸入規則名稱`,
  key1000816: `新增規則`,
  key1000817: `查詢規則列表失敗`,
  key1000818: `查詢托運方式失敗`,
  key1000819: `是否刪除規則`,
  key1000820: `刪除規則`,
  key1000821: `刪除規則成功`,
  key1000822: `刪除規則失敗`,
  key1000823: `規則保存成功`,
  key1000824: `保存規則失敗`,
  key1000825: `查詢平台站點列表失敗`,
  key1000826: `查詢店鋪列表失敗`,
  key1000827: `規則修改`,
  key1000828: `獲取規則詳情失敗`,
  key1000829: `修改排序成功`,
  key1000830: `规则列表排序失败`,
  key1000831: `加載中`,
  key1000832: `觸發時間`,
  key1000833: `發送對象為訂單中對應客戶其客戶資料中的手機號`,
  key1000834: `發送短信模板`,
  key1000835: `手機區號`,
  key1000836: `啟用`,
  key1000837: `停用`,
  key1000838: `發送短信`,
  key1000839: `（點擊可編輯可選條件）`,
  key1000840: `訂單是否包含需要保修的商品`,
  key1000841: `（同條件為或關係，不同條件為且關係）`,
  key1000842: `訂單執行發貨（出貨）時`,
  key1000843: `線下訂單導入且狀態為已發貨時`,
  key1000844: `訂單執行換貨時`,
  key1000845: `更新狀態成功`,
  key1000846: `請輸入托運賬號`,
  key1000847: `匹配物流渠道`,
  key1000848: `匹配托運公司`,
  key1000849: `匹配托運方式`,
  key1000850: `托運賬號`,
  key1000851: `托運方式：`,
  key1000852: `發送對象為訂單中對應客戶其客戶資料中的E-mail`,
  key1000853: `發送郵件模板`,
  key1000854: `發送郵件`,
  key1000855: `責任人`,
  key1000856: `餐厅名`,
  key1000857: `水印內容`,
  key1000858: `創建時間`,
  key1000859: `一口价`,
  key1000860: `拍卖`,
  key1000861: `訂單號`,
  key1000862: `已選店鋪`,
  key1000863: `短信充值方式`,
  key1000864: `充值金額`,
  key1000865: `自定義輸入`,
  key1000866: `固定地區`,
  key1000867: `短信條數`,
  key1000868: `套餐內容`,
  key1000869: `購買時長`,
  key1000870: `連接您的PayPal帳戶並使用它來支付您的帳單。 您將被重定向到PayPal以添加付款資訊`,
  key1000871: `錯誤提示`,
  key1000872: `短信金額充值`,
  key1000873: `固定地區短信充值`,
  key1000874: `自定義`,
  key1000875: `條`,
  key1000876: `月`,
  key1000877: `計算中...`,
  key1000878: `當前已綁定帳號：`,
  key1000879: `立即支付`,
  key1000880: `取消付款`,
  key1000881: `返回平台列表`,
  key1000882: `所選數據可以獲得折扣`,
  key1000883: `取`,
  key1000884: `消`,
  key1000885: `确`,
  key1000886: `定`,
  key1000887: `短信條數只能為100的倍數`,
  key1000888: `折`,
  key1000889: `查詢短信所在地區失敗`,
  key1000890: `付款訂單不存在或已過期`,
  key1000891: `獲取付款訂單詳情失敗`,
  key1000892: `半個月`,
  key1000893: `個月`,
  key1000894: `1個季度`,
  key1000895: `創建付款失敗，請重新嘗試`,
  key1000896: `是否退出當前頁面`,
  key1000897: `获取支付通道失败`,
  key1000898: `非法參數`,
  key1000899: `此清單展示系統支援的銷售平台，可新增自定義平台，自定義平臺以'C-'開頭`,
  key1000900: `設置自動續費`,
  key1000901: `批量購買`,
  key1000902: `批量續費`,
  key1000903: `授權`,
  key1000904: `請選擇續費週期`,
  key1000905: `按月續費`,
  key1000906: `按年續費`,
  key1000907: `查詢平台列表帳號數量失敗`,
  key1000908: `查詢店鋪詳情失敗`,
  key1000909: `名稱重複`,
  key1000910: `保存失敗`,
  key1000911: `獲取店鋪列表失敗`,
  key1000912: `授权进行中，即将进行跳转`,
  key1000913: `授權失敗，請稍後重試`,
  key1000914: `ebay授權失敗，請稍後重試`,
  key1000915: `平台店鋪刪除後將不可恢復，所有此店鋪的訂單將會刪除，請謹慎決定。`,
  key1000916: `该店铺账号为免费店铺账号，删除后将不再享有该免费店铺权益，请谨慎删除`,
  key1000917: `刪除店鋪`,
  key1000918: `同步成功`,
  key1000919: `同步coupan地址失敗`,
  key1000920: `獲取站點列表失敗`,
  key1000921: `帳號或帳號代號已存在`,
  key1000922: `店鋪已存在`,
  key1000923: `修改店鋪失敗`,
  key1000924: `新增店鋪失敗`,
  key1000925: `查詢站點失敗`,
  key1000926: `是否刪除`,
  key1000927: `刪除平台`,
  key1000928: `平台下存在店鋪，請刪除後再進行操作`,
  key1000929: `刪除平台失敗`,
  key1000930: `請選擇要購買的店鋪`,
  key1000931: `創建付款訂單失敗`,
  key1000932: `開通自動續費失敗`,
  key1000933: `是否確認取消自動續費？ 取消後將不再服務截止日期執行扣費`,
  key1000934: `關閉成功!`,
  key1000935: `關閉自動續費失敗`,
  key1000936: `站點`,
  key1000937: `請選擇站點`,
  key1000938: `托運方式`,
  key1000939: `請輸入郵件標題`,
  key1000940: `附件上傳`,
  key1000941: `將word、pdf、excel或ppt文件拖到此處，或`,
  key1000942: `最多上傳5個附件`,
  key1000943: `修改E-mail模板`,
  key1000944: `新增E-mail模板`,
  key1000945: `请输入正文内容...`,
  key1000946: `請輸入店鋪帳號`,
  key1000947: `請輸入帳號ID`,
  key1000948: `請輸入accessKey`,
  key1000949: `請輸入secretKey`,
  key1000950: `請輸入Seller ApiKey`,
  key1000951: `請輸入User ID`,
  key1000952: `請輸入密碼`,
  key1000953: `所屬平台`,
  key1000954: `店鋪名稱`,
  key1000955: `填shopline店鋪功能變數名稱的首碼，如：zhangsan. myshopline.
          com的店鋪名為：zhangsan`,
  key1000956: `店鋪帳號`,
  key1000957: `帳號ID`,
  key1000958: `銷售區域`,
  key1000959: `站点`,
  key1000960: `店鋪LOGO`,
  key1000961: `有效期`,
  key1000962: `密碼`,
  key1000963: `美東地區`,
  key1000964: `美西地區`,
  key1000965: `歐洲地區`,
  key1000966: `选取文件`,
  key1000967: `請選擇平台`,
  key1000968: `請輸入店鋪名稱`,
  key1000969: `新增店鋪`,
  key1000970: `編輯店鋪`,
  key1000971: `請選擇銷售區域`,
  key1000972: `該選項不能為空`,
  key1000973: `系統已對接`,
  key1000974: `貨件追蹤URL`,
  key1000975: `客戶代碼`,
  key1000976: `key（令牌）`,
  key1000977: `攬件網點`,
  key1000978: `請輸入代碼`,
  key1000979: `寄件人姓名`,
  key1000980: `寄件人電話`,
  key1000981: `寄件人地址`,
  key1000982: `寄件人城市`,
  key1000983: `寄件人省/洲`,
  key1000984: `寄件人國家`,
  key1000985: `托運方式名稱`,
  key1000986: `綁定的實際托運方式`,
  key1000987: `COD幣種及匯率`,
  key1000988: `新增托運方式`,
  key1000989: `代碼`,
  key1000990: `名稱`,
  key1000991: `新增賬號`,
  key1000992: `增加eBay托運方式綁定`,
  key1000993: `站點：`,
  key1000994: `新增托運公司`,
  key1000995: `請輸入托運公司名稱`,
  key1000996: `托運公司`,
  key1000997: `API串聯`,
  key1000998: `未串聯`,
  key1000999: `公司名稱`,
  key1001000: `添加賬號`,
  key1001001: `相對USD匯率`,
  key1001002: `托運公司名稱：`,
  key1001003: `是否刪除當前帳號`,
  key1001004: `站點不能為空`,
  key1001005: `托運方式不能為空`,
  key1001006: `代碼不能為空`,
  key1001007: `名稱不能為空`,
  key1001008: `重复的托运公司账号`,
  key1001009: `托运公司名不合法，不可以存在#`,
  key1001010: `托运公司账号名不合法，不可以存#`,
  key1001011: `删除成功`,
  key1001012: `删除失败`,
  key1001013: `修改状态失败`,
  key1001014: `刪除失敗`,
  key1001015: `賬戶不存在`,
  key1001016: `操作失敗`,
  key1001017: `请求Ebay站点失败`,
  key1001018: `接口查询失败`,
  key1001019: `編輯托運公司`,
  key1001020: `托運公司名字重複`,
  key1001021: `編輯托運方式名稱`,
  key1001022: `修改失败`,
  key1001023: `获取揽件仓库列表失败`,
  key1001024: `重複的托運方式`,
  key1001025: `查询失败xx`,
  key1001026: `是否停用該托運公司`,
  key1001027: `啟用成功`,
  key1001028: `查詢托運公司列表失敗`,
  key1001029: `增加角色`,
  key1001030: `短信簽名`,
  key1001031: `API串聯狀態`,
  key1001032: `營銷授權狀態`,
  key1001033: `ebay管理支付`,
  key1001034: `到期時間`,
  key1001035: `免費用戶`,
  key1001036: `未開啟`,
  key1001037: `已開啟`,
  key1001038: `重新授权`,
  key1001039: `營銷授權`,
  key1001040: `同步退貨地址`,
  key1001041: `同步物流和付款方式`,
  key1001042: `取消自動續費`,
  key1001043: `請輸入分店名稱`,
  key1001044: `客服備註包含關鍵字為`,
  key1001045: `分店名稱為`,
  key1001046: `買家指定物流方式為`,
  key1001047: `是`,
  key1001048: `否`,
  key1001049: `是否包含需要保修的商品`,
  key1001050: `地址關鍵字為`,
  key1001051: `訂單金額為`,
  key1001052: `貨幣為`,
  key1001053: `訂單總重量為`,
  key1001054: `發貨倉庫為`,
  key1001055: `平台為`,
  key1001056: `站點為`,
  key1001057: `店鋪為`,
  key1001058: `到貨國家為`,
  key1001059: `平台/渠道名稱`,
  key1001060: `請輸入自定義平台/渠道名稱`,
  key1001061: `新增自定義平台`,
  key1001062: `请输入地址關鍵字，多個用英文字符,隔開`,
  key1001063: `最多只能添加五個地址關鍵字`,
  key1001064: `广东省深圳市南山区南山智园C1栋6楼`,
  key1001065: `描述`,
  key1001066: `數量`,
  key1001067: `增值稅率`,
  key1001068: `單價`,
  key1001069: `含`,
  key1001070: `增值稅`,
  key1001071: `(料)801-Hera經典系列蛋殼白面`,
  key1001072: `總價`,
  key1001073: `感謝您的訂單`,
  key1001074: `地址1`,
  key1001075: `請輸入地址1`,
  key1001076: `請輸入地址`,
  key1001077: `賣家信息`,
  key1001078: `請輸入公司名稱`,
  key1001079: `地址2`,
  key1001080: `請輸入地址2`,
  key1001081: `城市`,
  key1001082: `請輸入城市`,
  key1001083: `地區/國家`,
  key1001084: `請輸入地區/國家`,
  key1001085: `稅號`,
  key1001086: `请請輸入稅號`,
  key1001087: `手機號`,
  key1001088: `請輸入手機號`,
  key1001089: `傳真`,
  key1001090: `請輸入傳真`,
  key1001091: `郵箱`,
  key1001092: `請輸入郵箱`,
  key1001093: `供应商`,
  key1001094: `請輸入供应商`,
  key1001095: `供应商地址`,
  key1001096: `請輸入供应商地址`,
  key1001097: `聯繫號碼:`,
  key1001098: `發票日期`,
  key1001099: `發票號`,
  key1001100: `發票金額`,
  key1001101: `客戶信息`,
  key1001102: `聯繫電話:`,
  key1001103: `發件人信息`,
  key1001104: `訂單信息`,
  key1001105: `訂單日期:`,
  key1001106: `發票詳細`,
  key1001107: `(不包含增值稅)`,
  key1001108: `(包含增值稅)`,
  key1001109: `單項總價`,
  key1001110: `發票總額`,
  key1001111: `增值稅總價`,
  key1001112: `順豐快遞`,
  key1001113: `ABC店鋪`,
  key1001114: `產品名稱`,
  key1001115: `產品總價`,
  key1001116: `请输入备注`,
  key1001117: `發票號碼：`,
  key1001118: `發票日期：`,
  key1001119: `訂單號：`,
  key1001120: `下單時間：`,
  key1001121: `公司名稱：`,
  key1001122: `公司地址：`,
  key1001123: `稅號：`,
  key1001124: `手機號：`,
  key1001125: `傳真：`,
  key1001126: `郵箱：`,
  key1001127: `郵寄地址`,
  key1001128: `買家姓名：`,
  key1001129: `收貨地址：`,
  key1001130: `電話：`,
  key1001131: `商品小計：`,
  key1001132: `稅後小計：`,
  key1001133: `折扣金額：`,
  key1001134: `托運費用：`,
  key1001135: `訂單金額：`,
  key1001136: `發票備註：`,
  key1001137: `请输入客服備註關鍵字，多個用英文字符,隔開`,
  key1001138: `清空`,
  key1001139: `訂單最小金額沒有填寫，將清空訂單總金額數據`,
  key1001140: `訂單最大金額沒有填寫，將清空訂單總金額數據`,
  key1001141: `沒有選擇貨幣，將清空訂單總金額數據`,
  key1001142: `訂單金額最大值不能小於最小值，將清空訂單總金額數據`,
  key1001143: `訂單總重量`,
  key1001144: `訂單總重量(KG)`,
  key1001145: `訂單最小總重量沒有填寫，將清空訂單總重量數據`,
  key1001146: `訂單最大總重量沒有填寫，將清空訂單總重量數據`,
  key1001147: `訂單總重量最大值不能小於最小值，將清空訂單總重量數據`,
  key1001148: `更新人`,
  key1001149: `訂單中對應的【店鋪名稱】`,
  key1001150: `訂單中對應的【客戶名稱】`,
  key1001151: `訂單中對應的【訂單編號】`,
  key1001152: `訂單中對應的【商品名稱】`,
  key1001153: `保修查詢網址`,
  key1001154: `以下匯率均為對美元匯率，實時匯率為當天最新匯率`,
  key1001155: `貨幣名稱`,
  key1001156: `貨幣代碼`,
  key1001157: `官方匯率`,
  key1001158: `匯率`,
  key1001159: `匯率類型`,
  key1001160: `最後更新時間`,
  key1001161: `設置匯率`,
  key1001162: `固定匯率`,
  key1001163: `實時匯率`,
  key1001164: `匯率不能為空`,
  key1001165: `獲取員工列表失敗`,
  key1001166: `请根据提示进行操作`,
  key1001167: `授權成功`,
  key1001168: `服務錯誤`,
  key1001169: `帳號或者帳號程式碼已存在`,
  key1001170: `帳號不存在`,
  key1001171: `帳號不匹配`,
  key1001172: `授權失敗`,
  key1001173: `參數錯誤`,
  key1001174: `在此管理員工的賬號以及員工角色和各角色的權限。`,
  key1001175: `員工`,
  key1001176: `角色`,
  key1001177: `員工姓名`,
  key1001178: `所屬角色`,
  key1001179: `停用/啟用`,
  key1001180: `角色名`,
  key1001181: `關聯子帳號`,
  key1001182: `管理員`,
  key1001183: `禁用`,
  key1001184: `請填寫正確的郵箱地址`,
  key1001185: `修改員工失敗`,
  key1001186: `獲取角色列表`,
  key1001187: `新增員工`,
  key1001188: `編輯角色`,
  key1001189: `編輯員工`,
  key1001190: `是否停用？`,
  key1001191: `停用員工失敗`,
  key1001192: `啟用失敗`,
  key1001193: `角色正在使用中，無法刪除`,
  key1001194: `刪除角色失敗`,
  key1001195: `模板類型`,
  key1001196: `登錄驗證碼`,
  key1001197: `營銷`,
  key1001198: `驗證`,
  key1001199: `保固E-mail模板`,
  key1001200: `驗證類型郵件刪除後將無法發送郵件驗證碼，請謹慎刪除！`,
  key1001201: `是否刪除該模板`,
  key1001202: `刪除模板`,
  key1001203: `刪除模板成功`,
  key1001204: `水印類型`,
  key1001205: `我的水印`,
  key1001206: `水印佈局`,
  key1001207: `大小調整`,
  key1001208: `字體類型`,
  key1001209: `字體大小`,
  key1001210: `字體顏色`,
  key1001211: `水印間距`,
  key1001212: `旋轉水印`,
  key1001213: `透明度`,
  key1001214: `水印位置`,
  key1001215: `模板信息`,
  key1001216: `圖片水印`,
  key1001217: `文字水印`,
  key1001218: `自定义`,
  key1001219: `平铺`,
  key1001220: `加粗`,
  key1001221: `效果预览`,
  key1001222: `微软雅黑`,
  key1001223: `仿宋`,
  key1001224: `宋体`,
  key1001225: `黑体`,
  key1001226: `楷体`,
  key1001227: `幼圆`,
  key1001228: `拉伸(边框)`,
  key1001229: `再次購買`,
  key1001230: `到期時間：`,
  key1001231: `查詢出錯`,
  key1001232: `在此設定您的倉庫，以及用於出貨的倉庫。`,
  key1001233: `倉庫名稱`,
  key1001234: `停用/啟用倉庫`,
  key1001235: `省/州`,
  key1001236: `國家/地區`,
  key1001237: `郵編`,
  key1001238: `寄件人`,
  key1001239: `倉庫名稱不能為空`,
  key1001240: `確定要禁用該倉庫，存在訂單、採購單、庫存的倉庫不能禁用`,
  key1001241: `確定要啟用該倉庫，啟用後可以選擇到該倉庫`,
  key1001242: `倉庫使用中，禁用失敗`,
  key1001243: `存在訂單、採購單、庫存的倉庫將無法刪除，請謹慎決定。`,
  key1001244: `刪除倉庫`,
  key1001245: `倉庫使用中，不能刪除`,
  key1001246: `修改倉庫`,
  key1001247: `倉庫名稱已存在`,
  key1001248: `新增倉庫`,
  key1001249: `模板名稱：`,
  key1001250: `語言`,
  key1001251: `消費稅（VAT）`,
  key1001252: `消費稅率`,
  key1001253: `生成發票號`,
  key1001254: `發票前綴`,
  key1001255: `模板配置`,
  key1001256: `模板尺寸：`,
  key1001257: `買家信息`,
  key1001258: `買家名`,
  key1001259: `發票信息`,
  key1001260: `下單時間`,
  key1001261: `條形碼`,
  key1001262: `物流公司`,
  key1001263: `托運費用`,
  key1001264: `折扣金額`,
  key1001265: `發票備註`,
  key1001266: `發票類型1`,
  key1001267: `發票類型2`,
  key1001268: `發票類型3`,
  key1001269: `發票類型4`,
  key1001270: `無消費稅`,
  key1001271: `商品含消費稅`,
  key1001272: `商品不含消費稅`,
  key1001273: `支付時間`,
  key1001274: `打印時間`,
  key1001275: `匯出的文件下載有效期為7天，超出時間將無法下載`,
  key1001276: `請選擇任務類型`,
  key1001277: `請輸入採集地址，多個用回車鍵換行，最多一次可採集10個。`,
  key1001278: `採集來源`,
  key1001279: `產品標題`,
  key1001280: `標題`,
  key1001281: `售價`,
  key1001282: `已認領平台`,
  key1001283: `採集時間`,
  key1001284: `鏈接採集`,
  key1001285: `支持ebay`,
  key1001286: `支持平台`,
  key1001287: `下載插件`,
  key1001288: `是否Shopify連接`,
  key1001289: `開始採集`,
  key1001290: `开始采集`,
  key1001291: `認領`,
  key1001292: `個產品搬家成功，請前往草稿箱查看`,
  key1001293: `條數據採集成功`,
  key1001294: `認領中...`,
  key1001295: `產品認領中`,
  key1001296: `採集信息為空`,
  key1001297: `最多支持10條連接，請刪減`,
  key1001298: `ebay連接不能與其他連接一起採集`,
  key1001299: `採集中...`,
  key1001300: `請安裝通途採集插件進行採集！`,
  key1001301: `點擊查看安裝教程`,
  key1001302: `請選擇要刪除的採集數據`,
  key1001303: `是否刪除採集數據`,
  key1001304: `刪除採集數據`,
  key1001305: `活躍`,
  key1001306: `存檔`,
  key1001307: `在售`,
  key1001308: `已下架`,
  key1001309: `已刪除`,
  key1001310: `在線`,
  key1001311: `已禁用`,
  key1001312: `產品搬家中，請前往草稿箱查看`,
  key1001313: `請選擇數據`,
  key1001314: `原價`,
  key1001315: `庫存`,
  key1001316: `搬家`,
  key1001317: `選擇站點`,
  key1001318: `選擇店鋪`,
  key1001319: `選擇所有店鋪`,
  key1001320: `是否優先推薦motors類目`,
  key1001321: `正在複製到ebay，可關閉窗口後台進行，複製進度：`,
  key1001322: `產品搬家`,
  key1001323: `請選擇店鋪`,
  key1001324: `請輸入關鍵詞`,
  key1001325: `類目映射規則`,
  key1001326: `屬性替換規則`,
  key1001327: `任务中心`,
  key1001328: `批量搬家`,
  key1001329: `-規格名稱`,
  key1001330: `-規格值類型`,
  key1001331: `-類目屬性`,
  key1001332: `數據加載中...`,
  key1001333: `建議類目選擇`,
  key1001334: `映射平台屬性名稱`,
  key1001335: `映射平台規格值類型`,
  key1001336: `系統匹配中`,
  key1001337: `系統匹配已完成`,
  key1001338: `映射平台：`,
  key1001339: `映射平台站點：`,
  key1001340: `建議類目：`,
  key1001341: `應用`,
  key1001342: `已選類名：`,
  key1001343: `商品規格映射：`,
  key1001344: `系統建議匹配`,
  key1001345: `匹配狀態：`,
  key1001346: `已匹配規格：`,
  key1001347: `已匹配規格值：`,
  key1001348: `添加屬性值`,
  key1001349: `商品屬性映射：`,
  key1001350: `匹配狀態:`,
  key1001351: `已匹配屬性：`,
  key1001352: `已匹配屬性值：`,
  key1001353: `添加自定義屬性`,
  key1001354: `新增映射`,
  key1001355: `更新成功`,
  key1001356: `新增成功`,
  key1001357: `修改映射`,
  key1001358: `已映射`,
  key1001359: `(已映射)`,
  key1001360: `文本框`,
  key1001361: `單選可定義值`,
  key1001362: `單選`,
  key1001363: `多選`,
  key1001364: `多選可定義值`,
  key1001365: `屬性名稱`,
  key1001366: `請輸入屬性名稱`,
  key1001367: `屬性值`,
  key1001368: `請輸入屬性值`,
  key1001369: `前後不能有空格`,
  key1001370: `新增自定義屬性`,
  key1001371: `新增自定義屬性值`,
  key1001372: `屬性名稱重複`,
  key1001373: `系統檢測到重複屬性值，已自動去重`,
  key1001374: `屬性值重複`,
  key1001375: `系统檢測到重複屬性值，已自動去重`,
  key1001376: `是否多屬性`,
  key1001377: `输入关键字过滤`,
  key1001378: `映射平台`,
  key1001379: `映射平台站點`,
  key1001380: `映射平台類目`,
  key1001381: `原平台類目`,
  key1001382: `(已選)`,
  key1001383: `平台類目映射`,
  key1001384: `获取lazada类目数据失败`,
  key1001385: `請選擇要刪除的映射`,
  key1001386: `確認刪除？刪除後系統將清除類目的映射關系。`,
  key1001387: `刪除提示`,
  key1001388: `获取类目映射列表失败`,
  key1001389: `請輸入屬性`,
  key1001390: `設置映射`,
  key1001391: `物品屬性：`,
  key1001392: `自定義物品屬性`,
  key1001393: `展示所有`,
  key1001394: `屬性值不能為空`,
  key1001395: `未選擇映射屬性`,
  key1001396: `没有相同值，无法映射`,
  key1001397: `請選擇屬性`,
  key1001398: `請選擇屬性，支持輸入自定義屬性`,
  key1001399: `請選擇屬性，支持多選`,
  key1001400: `請選擇屬性，支持多選、輸入自定義屬性`,
  key1001401: `您添加的屬性已存在`,
  key1001402: `另存為兼容列表`,
  key1001403: `模板名称`,
  key1001404: `保存失敗, 存在相同的名称`,
  key1001405: `屏蔽國家/地區`,
  key1001406: `更多`,
  key1001407: `自定义多属性`,
  key1001408: `属性名`,
  key1001409: `属性值`,
  key1001410: `請輸入屬性名`,
  key1001411: `套用組件模板`,
  key1001412: `套用`,
  key1001413: `文字翻译`,
  key1001414: `源語種`,
  key1001415: `自動`,
  key1001416: `目標語種`,
  key1001417: `俄語`,
  key1001418: `西班牙語`,
  key1001419: `法語`,
  key1001420: `葡萄牙語`,
  key1001421: `德語`,
  key1001422: `意大利語`,
  key1001423: `簡體中文`,
  key1001424: `泰語`,
  key1001425: `阿拉伯語`,
  key1001426: `日語`,
  key1001427: `越南語`,
  key1001428: `印尼語`,
  key1001429: `馬來語`,
  key1001430: `請選擇目標語種`,
  key1001431: `翻譯的內容未空`,
  key1001432: `上傳圖片URL`,
  key1001433: `請輸入圖片URL，多個圖片URL請回車換行`,
  key1001434: `圖片URL為空`,
  key1001435: `图片分组`,
  key1001436: `图片名称.xxx`,
  key1001437: `一级 1`,
  key1001438: `二级 1-1`,
  key1001439: `三级 1-1-1`,
  key1001440: `一级 2`,
  key1001441: `二级 2-1`,
  key1001442: `三级 2-1-1`,
  key1001443: `二级 2-2`,
  key1001444: `三级 2-2-1`,
  key1001445: `一级 3`,
  key1001446: `二级 3-1`,
  key1001447: `三级 3-1-1`,
  key1001448: `二级 3-2`,
  key1001449: `三级 3-2-1`,
  key1001450: `選擇類目`,
  key1001451: `请先勾选要删除的数据`,
  key1001452: `刪除中`,
  key1001453: `內容校驗不通過`,
  key1001454: `保存成功，即將物品地址列表`,
  key1001455: `同步政策`,
  key1001456: `數據正在同步中，請稍後查看`,
  key1001457: `確認保存修改`,
  key1001458: `修改模板內容，將同步更改所有已使用該政策的在線商品的政策內容`,
  key1001459: `請選擇國內退款退貨天數`,
  key1001460: `請選擇國內退款退貨費用由誰承擔`,
  key1001461: `請選擇國際退款退貨天數`,
  key1001462: `請選擇國際退款退貨費用由誰承擔`,
  key1001463: `另存為成功，即將跳轉退款政策列表`,
  key1001464: `保存成功,即將跳轉退款政策列表`,
  key1001465: `提供至少30天的退貨政策可幫助您的商品獲得頂級賣家福利。`,
  key1001466: `退款政策`,
  key1001467: `退貨天數`,
  key1001468: `退貨運費由誰承擔`,
  key1001469: `接受國內退款`,
  key1001470: `接受國際退款`,
  key1001471: `國家或地區`,
  key1001472: `物品地址`,
  key1001473: `運輸到`,
  key1001474: `請輸入運輸到區域`,
  key1001475: `當地物流`,
  key1001476: `國際物流`,
  key1001477: `請選擇物流`,
  key1001478: `運費`,
  key1001479: `運費不能為空`,
  key1001480: `多一件運費`,
  key1001481: `多一件運費不能為空`,
  key1001482: `免運費`,
  key1001483: `刪除當前物流`,
  key1001484: `多一件運費不能高過運費`,
  key1001485: `另存為`,
  key1001486: `請至少選擇一種支付方式`,
  key1001487: `電子支付方式`,
  key1001488: `您的PayPAl帳戶電子郵件地址（用於接收付款）`,
  key1001489: `其他支付方式`,
  key1001490: `付款政策`,
  key1001491: `若當前店鋪的管理支付已開啟，請到`,
  key1001492: `店鋪授權管理`,
  key1001493: `，刷新管理支付的狀態`,
  key1001494: `收款賬號`,
  key1001495: `買家使用“立即購買”時要求立即付款`,
  key1001496: `商戶信用卡`,
  key1001497: `请选择處理時間`,
  key1001498: `处理费用不能为空`,
  key1001499: `提供當天或1天的處理時間有助於您的物品刊登有資格獲得頂級賣家福利`,
  key1001500: `國內運輸`,
  key1001501: `處理時間`,
  key1001502: `处理费用`,
  key1001503: `國內運費表`,
  key1001504: `國際運輸`,
  key1001505: `提供國際運輸可能使您的商品信息在其他eBay網站上可見並可供國際買家使用`,
  key1001506: `協商運費`,
  key1001507: `國際運費表`,
  key1001508: `不運送地區`,
  key1001509: `運輸政策`,
  key1001510: `增加當地物流`,
  key1001511: `同步`,
  key1001512: `使用全球運輸計劃`,
  key1001513: `增加國際物流`,
  key1001514: `平均：每个买家的费用相同`,
  key1001515: `policy本地物流类型`,
  key1001516: `计算：费用因买家位置而异`,
  key1001517: `policy国际物流类型`,
  key1001518: `不送货：仅限本地取货`,
  key1001519: `货运：超过 150 磅的大件物品`,
  key1001520: `請先選擇店鋪`,
  key1001521: `請先選擇站點`,
  key1001522: `運費表同步中`,
  key1001523: `運費表同步成功`,
  key1001524: `店鋪不能為空`,
  key1001525: `模板描述`,
  key1001526: `設為默認模板`,
  key1001527: `基本信息`,
  key1001528: `已選不送至：`,
  key1001529: `取消全部`,
  key1001530: `國內:`,
  key1001531: `國際：`,
  key1001532: `[隱藏所有國家/地區]`,
  key1001533: `其他:`,
  key1001534: `至少要選擇一種付款方式`,
  key1001535: `勾選PayPal後必須填寫付款賬號郵箱地址`,
  key1001536: `另存為成功，即將跳轉付款政策列表`,
  key1001537: `保存成功,即將跳轉付款政策列表`,
  key1001538: `另存為成功，即將跳轉運輸政策列表`,
  key1001539: `保存成功,即將跳轉運輸政策列表`,
  key1001540: `搬家来自：`,
  key1001541: `店鋪信息`,
  key1001542: `物品屬性`,
  key1001543: `變體信息`,
  key1001544: `圖片信息`,
  key1001545: `銷售信息`,
  key1001546: `商業政策`,
  key1001547: `運輸細節`,
  key1001548: `描述信息`,
  key1001549: `數據處理中`,
  key1001550: `未找到相關數據`,
  key1001551: `請先選擇類目`,
  key1001552: `設置成功`,
  key1001553: `添加水印失敗`,
  key1001554: `請先添加產品圖片`,
  key1001555: `修改中`,
  key1001556: `銷售類型`,
  key1001557: `关键词`,
  key1001558: `請輸入模糊搜索`,
  key1001559: `起拍價`,
  key1001560: `一口價`,
  key1001561: `來源`,
  key1001562: `同步商品`,
  key1001563: `同步所有產品`,
  key1001564: `同步選中產品`,
  key1001565: `添加水印`,
  key1001566: `刊登時間：`,
  key1001567: `更新時間：`,
  key1001568: `請選擇要添加水印的產品`,
  key1001569: `請選擇要搬家的商品`,
  key1001570: `请选择要同步的商品`,
  key1001571: `数据同步中，请稍候查看`,
  key1001572: `店鋪：`,
  key1001573: `模塊：`,
  key1001574: `运输折扣：`,
  key1001575: `当地物流`,
  key1001576: `运费`,
  key1001577: `多一件运费`,
  key1001578: `其他付款方式：`,
  key1001579: `付款說明：`,
  key1001580: `退貨方案`,
  key1001581: `退貨期限`,
  key1001582: `退貨運費`,
  key1001583: `退貨方式`,
  key1001584: `國際退貨鎮策：`,
  key1001585: `退貨說明：`,
  key1001586: `物流設置`,
  key1001587: `移除模塊`,
  key1001588: `使用ebay账号标准运输折扣设置(Flat`,
  key1001589: `使用eBay账号促销运输折扣设置(Promotional`,
  key1001590: `添加當地物流`,
  key1001591: `添加國際物流`,
  key1001592: `買家要求`,
  key1001593: `不允許以下買家購買我的商品`,
  key1001594: `主要運送地址在我的運送範圍之外`,
  key1001595: `曾收到`,
  key1001596: `個棄標個案，在過去的`,
  key1001597: `在過去10天內，曾出價次數或購買我的物品，已達到我所設定的限制`,
  key1001598: `這項限制只選用于買家信用指標等於或者低於`,
  key1001599: `付款賬號：`,
  key1001600: `退貨政策`,
  key1001601: `當地退貨鎮策：`,
  key1001602: `請選擇運輸折扣`,
  key1001603: `請選擇当地物流`,
  key1001604: `請選擇國際物流`,
  key1001605: `請選擇shipTo`,
  key1001606: `請選擇划線價格`,
  key1001607: `請選擇設定的限制`,
  key1001608: `請選擇指標`,
  key1001609: `請選擇付款賬號`,
  key1001610: `請選擇退貨方案`,
  key1001611: `請選擇退貨期限`,
  key1001612: `請選擇退貨運費`,
  key1001613: `請選擇退貨方式`,
  key1001614: `物流设置`,
  key1001615: `买家要求`,
  key1001616: `退货政策`,
  key1001617: `屏蔽国家/地区`,
  key1001618: `查看兼容列表`,
  key1001619: `序號`,
  key1001620: `查看`,
  key1001621: `模板名称:`,
  key1001622: `站點:`,
  key1001623: `英語`,
  key1001624: `文字翻譯按【字元】計費，當前您的文字翻譯用量為`,
  key1001625: `，如需新增每月資源額度請前往`,
  key1001626: `【增值資源包】`,
  key1001627: `購買`,
  key1001628: `當前資源可用次數不足，請前往`,
  key1001629: `翻譯失敗:`,
  key1001630: `翻譯成功`,
  key1001631: `包裝信息`,
  key1001632: `選擇物品地址模板`,
  key1001633: `包裝制度`,
  key1001634: `包裝類型`,
  key1001635: `不規則包裹`,
  key1001636: `包裝尺寸`,
  key1001637: `包裝重量`,
  key1001638: `選擇模板`,
  key1001639: `創建模板`,
  key1001640: `請先選擇店鋪和站點`,
  key1001641: `請選擇物品地址模板`,
  key1001642: `操作失敗,所選模板數據不全,請選擇其他模板`,
  key1001643: `子標題($6.00)`,
  key1001644: `售賣形式`,
  key1001645: `固價類型`,
  key1001646: `物品狀況`,
  key1001647: `物品狀況描述`,
  key1001648: `翻譯`,
  key1001649: `當售賣形式為拍賣或固定價格的單品時，此項為產品的SKU。`,
  key1001650: `當售賣形式為實體時，此項為產品父級的SKU，可以作為生成變體SKU的前綴`,
  key1001651: `固定價格`,
  key1001652: `拍賣`,
  key1001653: `單品`,
  key1001654: `變體`,
  key1001655: `运输政策`,
  key1001656: `商业政策`,
  key1001657: `加载中`,
  key1001658: `创建`,
  key1001659: `選擇所有站點`,
  key1001660: `選擇所有店铺`,
  key1001661: `圖片翻譯`,
  key1001662: `圖片翻譯結果`,
  key1001663: `點擊查看大圖`,
  key1001664: `選擇圖片後點擊確定進行設置`,
  key1001665: `中文簡體`,
  key1001666: `英文`,
  key1001667: `已選擇圖片`,
  key1001668: `翻譯圖`,
  key1001669: `原圖`,
  key1001670: `正在進行轉化：`,
  key1001671: `執行翻譯`,
  key1001672: `確認修改`,
  key1001673: `圖片翻譯按【次】計費，當前圖片翻譯用量為`,
  key1001674: `請選擇要進行翻譯的圖片`,
  key1001675: `库存数量:`,
  key1001676: `批量库存修改`,
  key1001677: `請選擇目標平台屬性`,
  key1001678: `必需`,
  key1001679: `屬性替換`,
  key1001680: `原平台屬性`,
  key1001681: `原平台`,
  key1001682: `目標平台類目`,
  key1001683: `目標平台屬性`,
  key1001684: `推薦`,
  key1001685: `買家經常搜索這些屬性`,
  key1001686: `附加`,
  key1001687: `買家也可能對這些感興趣`,
  key1001688: `存在相同名稱屬性，請重新輸入`,
  key1001689: `屬性名不能為空`,
  key1001690: `查詢搬家對應屬性列表失敗`,
  key1001691: `請選擇類目`,
  key1001692: `保存映射列表成功`,
  key1001693: `保存映射列表失敗`,
  key1001694: `远程数据加载中`,
  key1001695: `請輸入推荐分類名`,
  key1001696: `推薦類目:`,
  key1001697: `推荐類目`,
  key1001698: `一鍵翻譯`,
  key1001699: `保存到草稿箱`,
  key1001700: `立即发布`,
  key1001701: `更新刊登`,
  key1001702: `默認圖片`,
  key1001703: `屬性圖片不能為空`,
  key1001704: `產品圖片`,
  key1001705: `圖片要求至少有一邊大於500像素，首張圖片為主圖，拖動圖片可調整順序`,
  key1001706: `變體圖片`,
  key1001707: `至少有一邊大於500像素`,
  key1001708: `在線圖片URL`,
  key1001709: `本地上傳`,
  key1001710: `圖片編輯`,
  key1001711: `圖片尺寸`,
  key1001712: `圖片白底`,
  key1001713: `圖片類型`,
  key1001714: `當前欄目最多上傳`,
  key1001715: `張圖片，尚可以上傳`,
  key1001716: `張圖片`,
  key1001717: `上傳失敗`,
  key1001718: `該類上傳圖片不能超過12張`,
  key1001719: `圖片只支持 JPG、JPEG、PNG、WEBP格式!`,
  key1001720: `圖片大小不能超過 5MB!`,
  key1001721: `图片处理中`,
  key1001722: `SKU不能為空`,
  key1001723: `長度不能超過50個字符`,
  key1001724: `價格(`,
  key1001725: `價格不能為空`,
  key1001726: `數量不能為空`,
  key1001727: `變體屬性`,
  key1001728: `自定義屬性名稱`,
  key1001729: `請選擇變體屬性`,
  key1001730: `變體組合`,
  key1001731: `批量生成SKU`,
  key1001732: `批量生成价格`,
  key1001733: `批量輸入價格`,
  key1001734: `批量生成库存`,
  key1001735: `批量輸入庫存`,
  key1001736: `請完善變體組合`,
  key1001737: `請輸入自定義屬性名`,
  key1001738: `變體屬性值不能超過30個`,
  key1001739: `請輸入圖片寬度`,
  key1001740: `現有圖片尺寸`,
  key1001741: `請輸入要修改的尺寸`,
  key1001742: `批量設置大小`,
  key1001743: `自定義大小`,
  key1001744: `請選擇要修改大小的圖片`,
  key1001745: `屬性名，例如Year`,
  key1001746: `屬性值，例如 2018`,
  key1001747: `替換為`,
  key1001748: `圖片白底結果`,
  key1001749: `白底圖`,
  key1001750: `轉換白底`,
  key1001751: `請選擇要進行轉換白底的圖片`,
  key1001752: `請輸入起拍價`,
  key1001753: `請輸入價格`,
  key1001754: `請輸入庫存`,
  key1001755: `刊登天數`,
  key1001756: `保底價`,
  key1001757: `价格`,
  key1001758: `库存`,
  key1001759: `接受買家還價`,
  key1001760: `私人上市`,
  key1001761: `銷售稅`,
  key1001762: `VAT稅率`,
  key1001763: `批量銷售`,
  key1001764: `批發數量`,
  key1001765: `開啟`,
  key1001766: `自動接受大於`,
  key1001767: `自動拒絕少於`,
  key1001768: `允許買家對其他eBay用戶保持匿名`,
  key1001769: `銷售稅寶行運輸和處理費用`,
  key1001770: `產品分類`,
  key1001771: `請輸入分類名`,
  key1001772: `更新當前類目`,
  key1001773: `推荐类目`,
  key1001774: `未上架`,
  key1001775: `上架失败`,
  key1001776: `上架中`,
  key1001777: `草稿編號`,
  key1001778: `修改在线`,
  key1001779: `新增产品`,
  key1001780: `批量刊登`,
  key1001781: `批量翻译`,
  key1001782: `批量修改库存`,
  key1001783: `創建時間：`,
  key1001784: `刊登`,
  key1001785: `請選擇要上架的草稿`,
  key1001786: `正在處理，請稍後查看處理結果`,
  key1001787: `請選擇要翻譯的草稿`,
  key1001788: `請選擇要修改庫存的草稿`,
  key1001789: `請選擇國家或地區`,
  key1001790: `請上傳產品圖片`,
  key1001791: `請輸入產品標題`,
  key1001792: `標題長度不能超過255個字符`,
  key1001793: `請輸入描述信息`,
  key1001794: `請輸入商品標題`,
  key1001795: `請選擇產品類目屬性`,
  key1001796: `請輸入品牌`,
  key1001797: `品牌長度不能超過100個字符`,
  key1001798: `請選擇出庫地`,
  key1001799: `請選擇快遞公司`,
  key1001800: `請選擇配送方法`,
  key1001801: `請選擇配送運費種類`,
  key1001802: `請選擇退貨/換貨地`,
  key1001803: `請輸入初始配送費（單程）`,
  key1001804: `請輸入退貨運費（單程）`,
  key1001805: `請輸入標題`,
  key1001806: `標題長度不能超過80個字符`,
  key1001807: `子標題長度不能超過50個字符`,
  key1001808: `請輸入SKU`,
  key1001809: `SKU長度不能超過50個字符`,
  key1001810: `請選擇售賣形式`,
  key1001811: `請選擇物品狀況`,
  key1001812: `請選擇產品分類`,
  key1001813: `請選擇刊登天数`,
  key1001814: `請選擇國家或者地區`,
  key1001815: `請選擇付款政策`,
  key1001816: `請選擇运输政策`,
  key1001817: `請選擇退款政策`,
  key1001818: `請選擇產品類型`,
  key1001819: `至少要選擇一種物流方式`,
  key1001820: `請上傳變體圖片`,
  key1001821: `請填寫完整的批發價內容`,
  key1001822: `請輸入包装重量`,
  key1001823: `請輸入原平台屬性或目標平台屬性`,
  key1001824: `1.屬性替換規則在產品搬家時自動應用 2.修改或刪除規則不影響已經完成搬家的產品`,
  key1001825: `目標平台`,
  key1001826: `加載列表失敗，請重新嘗試`,
  key1001827: `刪除失敗，請重新嘗試`,
  key1001828: `發佈`,
  key1001829: `請選擇要批量發佈的產品`,
  key1001830: `是否批量發佈所選產品`,
  key1001831: `操作成功，數據處理中，請稍候查詢`,
  key1001832: `請選擇要刪除的產品`,
  key1001833: `是否刪除草稿數據`,
  key1001834: `同步產品`,
  key1001835: `同步所選`,
  key1001836: `同步所有`,
  key1001837: `下架`,
  key1001838: `上架`,
  key1001839: `操作成功，數據後台同步中，您可以到任務中心查看結果`,
  key1001840: `請選擇要同步的產品`,
  key1001841: `是否刪除刊登在線數據`,
  key1001842: `請選擇要下架的產品`,
  key1001843: `是否下架當前數據`,
  key1001844: `操作成功，請稍候查看數據`,
  key1001845: `請選擇刊登數據`,
  key1001846: `是否重新上架當前數據`,
  key1001847: `是否下架當前刊登數據`,
  key1001848: `下架成功`,
  key1001849: `類目屬性`,
  key1001850: `這是您的產品頁面的主圖像。 可以同時上傳多個圖片。 最大8圖像。 尺寸在330x330和5000x5000之間。 文件大小不超過5mb。可以通過拖動圖片調整順序`,
  key1001851: `最多8張圖片，最小330像素`,
  key1001852: `操作提交成功，請在Lazada刊登在線查看提交結果`,
  key1001853: `获取lazada详情数据失败`,
  key1001854: `長描述`,
  key1001855: `展示更多`,
  key1001856: `產品描述`,
  key1001857: `亮點描述`,
  key1001858: `收縮`,
  key1001859: `請輸入Video URL`,
  key1001860: `來源網址`,
  key1001861: `請輸入來源網址`,
  key1001862: `質保類型`,
  key1001863: `請質保類型`,
  key1001864: `保修期`,
  key1001865: `請保修期`,
  key1001866: `圖片大小不能超過`,
  key1001867: `該類上傳圖片不能超過8張`,
  key1001868: `切換輸入不同的包裝尺寸和重量`,
  key1001869: `請填寫包裝重量`,
  key1001870: `包裝尺寸(長*寬*高)`,
  key1001871: `請填寫完整包裝信息`,
  key1001872: `已选类目：`,
  key1001873: `重量不能為空且不能為0`,
  key1001874: `重量不能為空`,
  key1001875: `長度不能為空且不能為0`,
  key1001876: `長度不能為空`,
  key1001877: `寬度不能為空且不能為0`,
  key1001878: `寬度不能為空`,
  key1001879: `高度不能為空且不能為0`,
  key1001880: `高度不能為空`,
  key1001881: `SellerSKU不能為空`,
  key1001882: `長度不能超過200個字符`,
  key1001883: `售價(`,
  key1001884: `售價不能為空`,
  key1001885: `折後價(`,
  key1001886: `包裹重量（KG）`,
  key1001887: `包裹尺寸（長*寬*高 CM）`,
  key1001888: `促銷產品的銷售價格。 銷售價與價格的比率必須介於0.20和1.00之間`,
  key1001889: `產品屬性`,
  key1001890: `產品組合`,
  key1001891: `請完善產品組合`,
  key1001892: `添加`,
  key1001893: `批量生成售價`,
  key1001894: `批量輸入售價`,
  key1001895: `批量生成折後價`,
  key1001896: `批量輸入折後價`,
  key1001897: `重新上传`,
  key1001898: `推廣日期`,
  key1001899: `刪除該項折後價`,
  key1001900: `數據重複`,
  key1001901: `變體屬性值不能超過50個`,
  key1001902: `請先設置價格`,
  key1001903: `請設置折後價`,
  key1001904: `折後價格的範圍是價格的0*2 - 1之間`,
  key1001905: `請設置活動推廣日期`,
  key1001906: `售價不能為0`,
  key1001907: `只是首圖添加水印`,
  key1001908: `全部產品圖添加水印`,
  key1001909: `查詢水印模板失敗`,
  key1001910: `錯誤原因`,
  key1001911: `查看原因`,
  key1001912: `價格`,
  key1001913: `折後價`,
  key1001914: `說明:以下匯率均為對美元匯率，實时匯率為當天最新匯率。`,
  key1001915: `貨幣`,
  key1001916: `固定汇率`,
  key1001917: `实时汇率`,
  key1001918: `修改`,
  key1001919: `可以同時上傳多個圖片。 最大200圖像。 建议上传分辨率为800*800/1024*1024px。 大小不超过1MB的图片。可以通過拖動圖片調整順序`,
  key1001920: `不跟蹤庫存`,
  key1001921: `不允許零庫存下單`,
  key1001922: `允許零庫存下單`,
  key1001923: `搜索引擎優化(SEO)`,
  key1001924: `操作提交成功，請在Shopify刊登在線查看提交結果`,
  key1001925: `获取shopify详情数据失败`,
  key1001926: `查詢收集列表失敗`,
  key1001927: `分類`,
  key1001928: `請輸入分類`,
  key1001929: `請輸入廠商`,
  key1001930: `標籤`,
  key1001931: `請輸入標籤，多個以英文字符,隔開`,
  key1001932: `產品集合`,
  key1001933: `请输入產品集合名稱`,
  key1001934: `建議銷售價不能為空`,
  key1001935: `庫存至少為1`,
  key1001936: `海關編碼`,
  key1001937: `原價(`,
  key1001938: `*建議銷售價(`,
  key1001939: `不需要運輸`,
  key1001940: `*可用庫存`,
  key1001941: `重量(KG)`,
  key1001942: `需要收稅`,
  key1001943: `庫存策略`,
  key1001944: `變體屬性值不能超過250個`,
  key1001945: `存在相同的名稱`,
  key1001946: `不能為空`,
  key1001947: `如果為空，平台將通過產品標題生成`,
  key1001948: `訪問網址`,
  key1001949: `如果為空，平台將通過產品描述生成`,
  key1001950: `集合名稱`,
  key1001951: `自定義集合`,
  key1001952: `未發佈自定義集合`,
  key1001953: `圖像集`,
  key1001954: `包含集合`,
  key1001955: `原始字段集合`,
  key1001956: `請填寫集合名稱`,
  key1001957: `保存集合成功`,
  key1001958: `保存集合失敗`,
  key1001959: `请输入URL(僅支持https)`,
  key1001960: `請輸入正確的圖片地址`,
  key1001961: `未發佈`,
  key1001962: `已發佈`,
  key1001963: `同步集合`,
  key1001964: `查詢集合列表失敗`,
  key1001965: `是否刪除該項集合`,
  key1001966: `同步中`,
  key1001967: `請選擇要同步的集合`,
  key1001968: `分類說明`,
  key1001969: `請填寫分類名稱`,
  key1001970: `保存分類成功`,
  key1001971: `保存分類失敗`,
  key1001972: `請填寫變體信息`,
  key1001973: `操作提交成功，請在shopline刊登在線查看提交結果`,
  key1001974: `查詢分类列表失敗`,
  key1001975: `获取shopline详情数据失败`,
  key1001976: `產品摘要`,
  key1001977: `品牌`,
  key1001978: `请输入產品產品分類`,
  key1001979: `超買策略`,
  key1001980: `不允許`,
  key1001981: `允許`,
  key1001982: `是否發貨`,
  key1001983: `庫存跟蹤`,
  key1001984: `分類編號`,
  key1001985: `同步分类`,
  key1001986: `是否刪除該項分类`,
  key1001987: `請選擇要同步的分类`,
  key1001988: `活動名稱`,
  key1001989: `活動時間`,
  key1001990: `選擇結束日期`,
  key1001991: `折扣活動開始的時候。`,
  key1001992: `開始時間必須比當前時間晚1小時`,
  key1001993: `結束時間必須比開始時間晚1小時，折扣期必須少於180天。`,
  key1001994: `請輸入活動名稱`,
  key1001995: `請輸入活動日期`,
  key1001996: `開始時間至少必須比當前時間晚1小時`,
  key1001997: `未來一周`,
  key1001998: `未來一個月`,
  key1001999: `未來三個月`,
  key1002000: `查詢折扣活動詳情失敗`,
  key1002001: `規格貨號`,
  key1002002: `編輯活動`,
  key1002003: `編輯活動商品`,
  key1002004: `刪除活動`,
  key1002005: `結束活動`,
  key1002006: `同步活動`,
  key1002007: `新增活動`,
  key1002008: `同步全部`,
  key1002009: `開始時間：`,
  key1002010: `結束時間：`,
  key1002011: `同步進度`,
  key1002012: `即將進行`,
  key1002013: `正在進行`,
  key1002014: `已過期`,
  key1002015: `請選擇活動數據`,
  key1002016: `是否結束此項活動`,
  key1002017: `是否刪除此項活動`,
  key1002018: `數據每10分鐘更新一次`,
  key1002019: `置頂模式`,
  key1002020: `開啟/關閉自動置頂`,
  key1002021: `自動置頂銷量最高商品`,
  key1002022: `按計劃指定`,
  key1002023: `到期自動續期`,
  key1002024: `請輸入規格貨號`,
  key1002025: `在線商品`,
  key1002026: `已售完`,
  key1002027: `禁售`,
  key1002028: `視頻信息`,
  key1002029: `不超過9張，第一張為封面圖。 文件大小不超過5mb。可以通過拖動圖片調整順序, 最小500*500像素`,
  key1002030: `操作提交成功，請在Shopee刊登在線查看提交結果`,
  key1002031: `同步成功，請稍候刷新頁面查看`,
  key1002032: `获取Shopee详情数据失败`,
  key1002033: `视频信息`,
  key1002034: `大小不能超過`,
  key1002035: `只支持MP4格式!`,
  key1002036: `產品狀況`,
  key1002037: `全新`,
  key1002038: `二手`,
  key1002039: `是否預購`,
  key1002040: `為避免發佈失敗，請務必確認已在店鋪後臺開啟對應的物流`,
  key1002041: `否（需3天內發貨）`,
  key1002042: `我需要`,
  key1002043: `出貨天數（可以填寫5-10天）`,
  key1002044: `全选`,
  key1002045: `SKU（規格貨號）`,
  key1002046: `批發價`,
  key1002047: `最小`,
  key1002048: `最大`,
  key1002049: `產品價格`,
  key1002050: `來源網址：`,
  key1002051: `產品名稱/產品ID`,
  key1002052: `產品名稱：`,
  key1002053: `產品ID：`,
  key1002054: `庫存：`,
  key1002055: `當前暫無置頂商品`,
  key1002056: `移除商品`,
  key1002057: `當前置頂商品`,
  key1002058: `產品`,
  key1002059: `置頂剩餘時長`,
  key1002060: `置頂設置`,
  key1002061: `置顶模式：`,
  key1002062: `自動循環置頂銷量最高商品`,
  key1002063: `按計劃置頂`,
  key1002064: `1.當前待置頂商品自動續期：開啟後置頂到期後自動續期待置頂的商品；`,
  key1002065: `2.自動置頂銷量最高商品：開啟後系統會自動迴圈置頂銷量最高的5個商品；`,
  key1002066: `3.按計畫置頂：開啟後按所計畫的時間段和商品置頂`,
  key1002067: `每次置頂時段`,
  key1002068: `待置頂`,
  key1002069: `新增待置顶商品`,
  key1002070: `最多只能添加5個置頂商品,`,
  key1002071: `當前還能添加`,
  key1002072: `個商品`,
  key1002073: `已結束`,
  key1002074: `時間段不能重複`,
  key1002075: `每個時段固定4小時， 請重新選擇`,
  key1002076: `最多添加6個時間段`,
  key1002077: `是否取消此次設置`,
  key1002078: `請添加置頂商品`,
  key1002079: `計劃時間沒有設置正確`,
  key1002080: `請輸入產品名稱`,
  key1002081: `產品ID`,
  key1002082: `請輸入產品ID`,
  key1002083: `折扣價`,
  key1002084: `購買限制`,
  key1002085: `啟用/停用`,
  key1002086: `參加活動商品`,
  key1002087: `是否刪除商品`,
  key1002088: `刪除商品`,
  key1002089: `刪除訂單導入模板失敗`,
  key1002090: `添加成功`,
  key1002091: `其他信息`,
  key1002092: `操作提交成功，請在amazon刊登在線查看提交結果`,
  key1002093: `获取Amazon类目数据失败`,
  key1002094: `獲取站點失敗`,
  key1002095: `獲取亞馬遜模板失敗`,
  key1002096: `獲取亞馬遜属性分組失敗`,
  key1002097: `獲取亞馬遜属性失敗`,
  key1002098: `获取Amazon详情数据失败`,
  key1002099: `圖片上傳失敗`,
  key1002100: `添加多屬性`,
  key1002101: `請輸入主图URL`,
  key1002102: `請輸入其他圖片URL`,
  key1002103: `請輸入Swatch 图片URL`,
  key1002104: `產品類型`,
  key1002105: `請輸入key搜索`,
  key1002106: `評論星級`,
  key1002107: `回評模板`,
  key1002108: `星級開啟狀態下，找好要有一個回評模板`,
  key1002109: `自動回評目前只支持Lazada和Shopee平台， 其餘平台將陸續開放`,
  key1002110: `屬性`,
  key1002111: `今日發送`,
  key1002112: `回評設置`,
  key1002113: `回評記錄`,
  key1002114: `已關閉`,
  key1002115: `是否删除当前模板。`,
  key1002116: `用戶名`,
  key1002117: `評論內容`,
  key1002118: `評論時間`,
  key1002119: `回評內容`,
  key1002120: `回評時間`,
  key1002121: `{value}星`,
  key1002122: `複製到ebay`,
  key1002123: `马来西亚`,
  key1002124: `泰国`,
  key1002125: `越南`,
  key1002126: `菲律宾`,
  key1002127: `新加坡`,
  key1002128: `印度尼西亚`,
  key1002129: `未選擇數據`,
  key1002130: `註冊商品名`,
  key1002131: `請輸入註冊商品名`,
  key1002132: `賣家商品編號`,
  key1002133: `請輸入賣家商品編號`,
  key1002134: `註冊商品ID`,
  key1002135: `請輸入註冊商品ID`,
  key1002136: `臨時保存`,
  key1002137: `等待審批`,
  key1002138: `批准完畢`,
  key1002139: `部分批准`,
  key1002140: `未通過審批`,
  key1002141: `商品已刪除`,
  key1002142: `主要信息`,
  key1002143: `配送信息`,
  key1002144: `退貨/換貨`,
  key1002145: `公告信息`,
  key1002146: `首張圖片為主圖，每張圖片不能超過10M，最小100PX以上，總共不超過10張`,
  key1002147: `获取Coupang类目数据失败`,
  key1002148: `請求公告列表失敗`,
  key1002149: `變更圖片類型時，會清空相關數據，請確定是否切換`,
  key1002150: `變更屬性類型時，會清空相關數據，請確定是否切換`,
  key1002151: `查询出库地列表失败`,
  key1002152: `操作提交成功，請在Coupang刊登在線查看提交結果`,
  key1002153: `請輸入品牌，若無品牌或自製請留空`,
  key1002154: `製造商`,
  key1002155: `沒有品牌的自製商品請確認右側的'無品牌'。`,
  key1002156: `平行進口`,
  key1002157: `未成年人購買`,
  key1002158: `是否海外代購`,
  key1002159: `不平行進口`,
  key1002160: `可以`,
  key1002161: `不可以`,
  key1002162: `是否需要PCC（個人通關程式碼）`,
  key1002163: `人均最大購買數量`,
  key1002164: `設定特定期間內可購買的最大數量。`,
  key1002165: `不設置`,
  key1002166: `个`,
  key1002167: `銷售期`,
  key1002168: `登記銷售時間`,
  key1002169: `附加稅`,
  key1002170: `請選擇銷售商品是否另加增值稅。`,
  key1002171: `征稅`,
  key1002172: `免稅`,
  key1002173: `最近3天`,
  key1002174: `最近5天`,
  key1002175: `最近7天`,
  key1002176: `最近15天`,
  key1002177: `最近30天`,
  key1002178: `最近60天`,
  key1002179: `最近90天`,
  key1002180: `最近120天`,
  key1002181: `公告類別`,
  key1002182: `告知資訊名稱`,
  key1002183: `详情`,
  key1002184: `參照商品詳細介面`,
  key1002185: `請參閱產品詳細資訊頁面`,
  key1002186: `商品名稱`,
  key1002187: `在實際銷售頁面顯示的商品名。 可能會根據Coupang的標準進行變更。`,
  key1002188: `登記商品名`,
  key1002189: `訂貨單上使用的商品名，顧客看不到。 請設定成便於管理的名字。`,
  key1002190: `選項`,
  key1002191: `圖片上傳`,
  key1002192: `網絡圖片`,
  key1002193: `基本注册`,
  key1002194: `根据选项注册`,
  key1002195: `單個商品`,
  key1002196: `該類上傳圖片不能超過10張`,
  key1002197: `出庫地`,
  key1002198: `濟州/偏遠地區配送與否`,
  key1002199: `快遞公司`,
  key1002200: `配送方法`,
  key1002201: `捆綁配送`,
  key1002202: `配送運費種類`,
  key1002203: `出庫所需日期(天)`,
  key1002204: `出庫所需日期`,
  key1002205: `請輸入顧客下單後出庫所需時間。`,
  key1002206: `-配送方法為普通配送、新鮮冷凍時：1~7天`,
  key1002207: `-配送方法為訂購製作、代購、安裝配送及直接傳達給賣家時：1~20日`,
  key1002208: `按購買選項輸入`,
  key1002209: `批量設置`,
  key1002210: `普通配送`,
  key1002211: `新鮮冷凍`,
  key1002212: `定制`,
  key1002213: `代購`,
  key1002214: `安裝配送或直接傳送給賣方`,
  key1002215: `免費配送`,
  key1002216: `付費配送`,
  key1002217: `運費到付`,
  key1002218: `指定條件包郵`,
  key1002219: `請先選擇數據`,
  key1002220: `消息`,
  key1002221: `批量設置出庫所需日期`,
  key1002222: `请输入天数`,
  key1002223: `出庫所需日期不正確，最小1天最大30天`,
  key1002224: `銷售狀態`,
  key1002225: `銷售價格`,
  key1002226: `存貨數量`,
  key1002227: `缺貨`,
  key1002228: `註冊商品ID：`,
  key1002229: `已銷售`,
  key1002230: `未銷售`,
  key1002231: `內容`,
  key1002232: `內容編輯`,
  key1002233: `基本輸入`,
  key1002234: `按SKU輸入`,
  key1002235: `有填写的内容`,
  key1002236: `尚未填寫內容`,
  key1002237: `退貨/換貨地`,
  key1002238: `初始配送費（單程）`,
  key1002239: `退貨運費（單程）`,
  key1002240: `因顾客原因退货时，往返退货/配送费是 0 韩元 , 初始配送费+退货配送费的 合计金额。`,
  key1002241: `賣方商品代碼/SKU`,
  key1002242: `請輸入商品的原始價格，即為coupang銷售的折扣適用前的價格。`,
  key1002243: `型號`,
  key1002244: `商品條碼`,
  key1002245: `多屬性`,
  key1002246: `單個屬性`,
  key1002247: `多個屬性商品`,
  key1002248: `單個屬性商品`,
  key1002249: `請完善多個屬性商品`,
  key1002250: `請完善單個屬性商品`,
  key1002251: `原始價格(`,
  key1002252: `多屬性商品註冊`,
  key1002253: `單個屬性商品註冊`,
  key1002254: `請添加變體屬性`,
  key1002255: `批量生成原價`,
  key1002256: `批量輸入原價`,
  key1002257: `批量生成型號`,
  key1002258: `批量生成條碼`,
  key1002259: `請輸入實際在Coupang銷售的價格。`,
  key1002260: `（至少10韓元組織）`,
  key1002261: `促銷期間價格不可更改，促銷期結束後可更改價格。`,
  key1002262: `輸入可供銷售的庫存數量。`,
  key1002263: `（從0到99999為止）斷貨會對顧客經驗產生負面影響，請輸入充足的庫存數量。`,
  key1002264: `模板內容`,
  key1002265: `添加元素`,
  key1002266: `文本内容`,
  key1002267: `字体`,
  key1002268: `字体大小`,
  key1002269: `字体颜色`,
  key1002270: `是否加粗`,
  key1002271: `上传图片`,
  key1002272: `水印布局`,
  key1002273: `缩放`,
  key1002274: `旋转`,
  key1002275: `图片`,
  key1002276: `水印`,
  key1002277: `清空模板`,
  key1002278: `更換背景圖片`,
  key1002279: `建議選擇符合自己產品尺寸的圖片，推薦使用800*800/1000*1000/1500*1500`,
  key1002280: `是否清空面板`,
  key1002281: `切換類型將重置面板，是否繼續切換`,
  key1002282: `Microsoft Yahei(微软雅黑)`,
  key1002283: `請輸入水印模板名稱`,
  key1002284: `请添加水印`,
  key1002285: `自定义模式只支持1张水印图`,
  key1002286: `新增水印模板失败`,
  key1002287: `時間篩選`,
  key1002288: `客戶數`,
  key1002289: `付款訂單數`,
  key1002290: `付款總金額`,
  key1002291: `付款客戶數`,
  key1002292: `退款訂單數`,
  key1002293: `退款總金額`,
  key1002294: `退款客戶數`,
  key1002295: `客户数`,
  key1002296: `查詢店鋪分析數據失敗`,
  key1002297: `訂單利潤分析`,
  key1002298: `店鋪利潤分析`,
  key1002299: `商品利潤分析`,
  key1002300: `日期`,
  key1002301: `訂單數量`,
  key1002302: `退款數量`,
  key1002303: `採購總數量`,
  key1002304: `銷售出庫`,
  key1002305: `可用庫存`,
  key1002306: `殘次品`,
  key1002307: `查詢商品庫存分析統計數據失敗`,
  key1002308: `採購時間`,
  key1002309: `採購單號`,
  key1002310: `採購人員`,
  key1002311: `採購數量`,
  key1002312: `商品種類數量`,
  key1002313: `採購總金額`,
  key1002314: `入庫良品數`,
  key1002315: `入庫殘次品數`,
  key1002316: `入庫總金額`,
  key1002317: `期初庫存`,
  key1002318: `期末庫存`,
  key1002319: `出庫`,
  key1002320: `出庫匯總`,
  key1002321: `換貨出庫`,
  key1002322: `移貨出庫`,
  key1002323: `入庫`,
  key1002324: `入庫匯總`,
  key1002325: `採購入庫`,
  key1002326: `退貨入庫`,
  key1002327: `移庫入庫`,
  key1002328: `換貨入庫`,
  key1002329: `最暢銷緯度`,
  key1002330: `最暢銷名次`,
  key1002331: `最暢銷類型`,
  key1002332: `商品SKU`,
  key1002333: `銷售总額`,
  key1002334: `銷售總成本`,
  key1002335: `渠道收費`,
  key1002336: `商品總收入`,
  key1002337: `平均利潤`,
  key1002338: `利潤`,
  key1002339: `毛利率`,
  key1002340: `退款率`,
  key1002341: `銷售佣金`,
  key1002342: `交易佣金`,
  key1002343: `商品銷售額`,
  key1002344: `商品總成本`,
  key1002345: `ebay跨關交易費：`,
  key1002346: `ebay託管支付稅：`,
  key1002347: `交易手續費：`,
  key1002348: `服務費：`,
  key1002349: `關稅：`,
  key1002350: `商品增值稅：`,
  key1002351: `物流費用`,
  key1002352: `物流费用=发货的运费+退货物流费用`,
  key1002353: `退款物流费用必须是物流运费类型选择卖家承担才会`,
  key1002354: `计算到退款金额`,
  key1002355: `查詢店鋪利潤數據失敗`,
  key1002356: `銷售訂單數`,
  key1002357: `銷售商品數`,
  key1002358: `退款商品數`,
  key1002359: `訂單總收入`,
  key1002360: `訂單收入`,
  key1002361: `商品成本`,
  key1002362: `查詢訂單利潤數據失敗`,
  key1002363: `付款销售量`,
  key1002364: `付款金額`,
  key1002365: `退款销售量`,
  key1002366: `首單時間：`,
  key1002367: `尾單時間：`,
  key1002368: `統計類型`,
  key1002369: `帳號`,
  key1002370: `付款時間`,
  key1002371: `國家`,
  key1002372: `最近7天匯總`,
  key1002373: `最近30天匯總`,
  key1002374: `銷售額`,
  key1002375: `採購單數量`,
  key1002376: `總貸款`,
  key1002377: `SKU個數`,
  key1002378: `入庫數量`,
  key1002379: `良品率`,
  key1002380: `平均交貨日期（天）`,
  key1002381: `請輸入ASKU/FNSKU`,
  key1002382: `快照時間`,
  key1002383: `6-12個月收費商品量`,
  key1002384: `6-12個月收費`,
  key1002385: `12個月以上收費商品量`,
  key1002386: `12個月以上收費`,
  key1002387: `單個商品體積`,
  key1002388: `輕小商品`,
  key1002389: `收費月份`,
  key1002390: `选择月`,
  key1002391: `倉庫編號`,
  key1002392: `長邊`,
  key1002393: `中間邊`,
  key1002394: `短邊`,
  key1002395: `重量`,
  key1002396: `體積`,
  key1002397: `產品標準`,
  key1002398: `庫存量`,
  key1002399: `待移除量`,
  key1002400: `總體積`,
  key1002401: `預估倉儲費`,
  key1002402: `請輸入訂單號/交易編號/ASIN/MSKU/FNSKU`,
  key1002403: `賠償編號`,
  key1002404: `原因`,
  key1002405: `商品標題`,
  key1002406: `狀況`,
  key1002407: `每件賠償金額`,
  key1002408: `總金額`,
  key1002409: `賠償數量（現金）`,
  key1002410: `賠償數量（庫存）`,
  key1002411: `賠償數量（總計）`,
  key1002412: `原始賠償編號`,
  key1002413: `賠償類型`,
  key1002414: `請輸入交易編號/MSKU/FNSKU`,
  key1002415: `發貨日期`,
  key1002416: `交易編號`,
  key1002417: `運營中心`,
  key1002418: `庫存屬性`,
  key1002419: `国家/地区`,
  key1002420: `查詢客户分析數據失敗`,
  key1002421: `這裡是您`,
  key1002422: `店鋪內的商品，您需要將Shopline的商品貨號與您實際發貨的商品建立起映射關係。`,
  key1002423: `請選擇類型`,
  key1002424: `所有`,
  key1002425: `禮品`,
  key1002426: `加購`,
  key1002427: `所有站點`,
  key1002428: `商品ID/貨號`,
  key1002429: `商品類型`,
  key1002430: `多規格`,
  key1002431: `動作`,
  key1002432: `設定商品的分類`,
  key1002433: `批量更新`,
  key1002434: `關聯`,
  key1002435: `設定的商品分類必須是葉子分類。`,
  key1002436: `組合品貨號`,
  key1002437: `請輸入組合品貨號`,
  key1002438: `組合品名稱`,
  key1002439: `請輸入組合品名稱`,
  key1002440: `預裝配`,
  key1002441: `申報品名`,
  key1002442: `申報價值`,
  key1002443: `尺寸`,
  key1002444: `長`,
  key1002445: `寬`,
  key1002446: `高`,
  key1002447: `貨號`,
  key1002448: `商品貨號`,
  key1002449: `请输入SKU`,
  key1002450: `克(g)`,
  key1002451: `釐米(CM)`,
  key1002452: `包含的子商品`,
  key1002453: `增加子商品`,
  key1002454: `組合品貨號不能為空`,
  key1002455: `組合品名稱不能為空`,
  key1002456: `子商品不能為空`,
  key1002457: `子商品數量必須大於1`,
  key1002458: `綁定商品有庫存記錄不可轉換為非預裝配`,
  key1002459: `綁定商品貨品必填`,
  key1002460: `商品貨號已存在`,
  key1002461: `商品不存在`,
  key1002462: `商品商品不存在`,
  key1002463: `請輸入貨號或商品名稱`,
  key1002464: `實際商品名稱`,
  key1002465: `請輸入實際商品名稱`,
  key1002466: `包含的實際商品`,
  key1002467: `匯入組合品`,
  key1002468: `/file/組合品匯入樣本.xlsx`,
  key1002469: `匯出匯入`,
  key1002470: `匯出組合品`,
  key1002471: `下載匯入組合品的Excel範例資料（`,
  key1002472: `寫好的組合品檔案在下方上載，貨號與當前商品檔案庫裡面相同的將覆蓋。`,
  key1002473: `匯入商品`,
  key1002474: `上載任務正在執行，您也可以關閉窗口，上載任務將會在後台自行執行直到結束，您也可以到`,
  key1002475: ` 查看匯入任務的結果。`,
  key1002476: `請選擇訂單`,
  key1002477: `將你已選擇的組合品匯出到Excel檔案，您可以拿來修改后再次匯入以更新組合品的檔案。`,
  key1002478: `匯出組合品失敗`,
  key1002479: `請先選擇商品`,
  key1002480: `組合品刪除後將永久不可見。`,
  key1002481: `刪除組合品`,
  key1002482: `刪除組合品失敗`,
  key1002483: `查詢組合品列表失敗`,
  key1002484: `部分匯入成功`,
  key1002485: `匯入成功`,
  key1002486: `匯出失敗`,
  key1002487: `匯出成功`,
  key1002488: `是否多規格`,
  key1002489: `商品SPU`,
  key1002490: `請輸入商品SPU`,
  key1002491: `英文名稱`,
  key1002492: `是否質保`,
  key1002493: `質保期（天）`,
  key1002494: `商品圖片`,
  key1002495: `基礎信息`,
  key1002496: `主圖`,
  key1002497: `規格信息`,
  key1002498: `新增規格`,
  key1002499: `自動生成`,
  key1002500: `自動成成sku規則`,
  key1002501: `多規格自動生成將取spu號+規格名稱作為值`,
  key1002502: `如:`,
  key1002503: `spu值為GT,`,
  key1002504: `規格值紅色、大碼，則sku值為GT-紅色-大碼`,
  key1002505: `指導成本價`,
  key1002506: `尺寸（cm）`,
  key1002507: `叫貨設定(廠商-最小採購量-叫貨週期)`,
  key1002508: `首選廠商`,
  key1002509: `備選廠商1`,
  key1002510: `備選廠商2`,
  key1002511: `請輸入商品貨號`,
  key1002512: `請選擇商品分類`,
  key1002513: `請先添加表格數據`,
  key1002514: `請輸入首選廠商`,
  key1002515: `请填写完整的商品信息`,
  key1002516: `请填写规格信息`,
  key1002517: `sku不能為空`,
  key1002518: `規格尚未保存`,
  key1002519: `保存商品失敗`,
  key1002520: `存在相同的規格名稱`,
  key1002521: `請填寫規格名稱`,
  key1002522: `切換規格將清空已經添加的商品數據, 是否繼續切換`,
  key1002523: `查詢货品详情失敗`,
  key1002524: `分類名不能為空`,
  key1002525: `分類下有商品，無法刪除`,
  key1002526: `三级 3-2-2`,
  key1002527: `關聯到我的商品是為了讓系統在訂單產生後能夠識別到具體要發貨的商品，同時便於系統同步Shopline的線上庫存`,
  key1002528: `添加關聯的我的商品`,
  key1002529: `SKU不存在`,
  key1002530: `存在相同的SKU數據`,
  key1002531: `導入成功`,
  key1002532: `規格`,
  key1002533: `值`,
  key1002534: `/file/商品匯入樣本.xlsx`,
  key1002535: `批量操作`,
  key1002536: `設定分類`,
  key1002537: `匯出商品`,
  key1002538: `下載匯入商品的Excel範例資料（`,
  key1002539: `填寫好的商品檔案在下方上載，貨號與當前商品檔案庫裡面相同的將會更新商品的檔案。`,
  key1002540: `無規格`,
  key1002541: `商品刪除後將永久不可見，歷史的訂單中也會顯示商品已經刪除。`,
  key1002542: `將妳已選擇的商品匯出到Excel檔案，您可以拿來修改後再次匯入以更新商品的檔案。`,
  key1002543: `現有可用庫存`,
  key1002544: `已装配库存`,
  key1002545: `殘次品庫存`,
  key1002546: `請求獲取庫存失敗`,
  key1002547: `源倉庫`,
  key1002548: `目的倉庫`,
  key1002549: `请输入內容`,
  key1002550: `良品庫存轉移數量`,
  key1002551: `请输入良品庫存轉移數量`,
  key1002552: `殘次品庫存轉移數量`,
  key1002553: `请输入殘次品庫存轉移數量`,
  key1002554: `請選擇源倉庫`,
  key1002555: `請選擇目的倉庫`,
  key1002556: `请选择源倉庫`,
  key1002557: `请选择商品`,
  key1002558: `良品庫存轉移數量和殘次品庫存轉移數量都为0`,
  key1002559: `保存移庫單失敗`,
  key1002560: `良品庫存變化`,
  key1002561: `殘次品庫存變化`,
  key1002562: `異動單編號：`,
  key1002563: `原因：`,
  key1002564: `所有倉庫`,
  key1002565: `系統根據組合品的原料零件商品的現有庫存推算出組合品的虛擬庫存。`,
  key1002566: `選擇倉庫`,
  key1002567: `非預裝配`,
  key1002568: `貨號/商品名稱`,
  key1002569: `已裝配數量`,
  key1002570: `現有殘次品庫存`,
  key1002571: `/file/商品庫存匯入匯出樣本.xlsx`,
  key1002572: `子商品庫存`,
  key1002573: `请输入殘次品備註`,
  key1002574: `匯出庫存`,
  key1002575: `下載匯入初始化庫存的Excel範例資料（`,
  key1002576: `填寫好的商品初始化庫存檔案在下方上載，商品一旦配貨後將不再允許初始化庫存，系統會自動跳過。`,
  key1002577: `清除`,
  key1002578: `將所有商品的當前庫存匯出到Excel檔案。`,
  key1002579: `匯出任務正在執行，請稍候`,
  key1002580: `移庫單用於將一個倉庫中的庫存轉移到另一個倉庫中。`,
  key1002581: `移庫單號`,
  key1002582: `多個請用英文字符空格分開`,
  key1002583: `創建日期`,
  key1002584: `移庫單編號`,
  key1002585: `匯入移庫單`,
  key1002586: `/file/移庫單匯入模板.xlsx`,
  key1002587: `匯出自定義`,
  key1002588: `下載匯入移庫單的Excel範例資料（`,
  key1002589: `匯出移庫單`,
  key1002590: `匯出移庫單失敗`,
  key1002591: `請選擇要匯出的移庫單`,
  key1002592: `將你篩選出的移庫單匯出到Excel檔案。`,
  key1002593: `將所有移庫單匯出到Excel檔案。`,
  key1002594: `導出自定義`,
  key1002595: `請輸入需要導出的數據 `,
  key1002596: `源倉庫：`,
  key1002597: `目的倉庫：`,
  key1002598: `待出貨庫存`,
  key1002599: `盤點結果（良品/殘次品）`,
  key1002600: `盤點結果`,
  key1002601: `盤點記錄`,
  key1002602: `盤點時間`,
  key1002603: `盤點前庫存（良品、殘次品）`,
  key1002604: `盤點後庫存（良品、殘次品）`,
  key1002605: `匯入盤點單`,
  key1002606: `/file/盤點結果匯入樣本.xlsx`,
  key1002607: `輸入盤點結果`,
  key1002608: `開始盤點（選中）`,
  key1002609: `開始盤點（所有）`,
  key1002610: `匯出盤點單（選中）`,
  key1002611: `匯出盤點單（所有結果集）`,
  key1002612: `完全覆蓋庫存中殘次品備註`,
  key1002613: `等待錄入`,
  key1002614: `下載匯入客戶檔案的Excel範例資料（`,
  key1002615: `將正在盤點的所有商品的匯出到Excel檔案，您可以將盤點結果修改後重新匯入系統以保存盤點結果。`,
  key1002616: `下載匯入盤點結果的Excel範例資料`,
  key1002617: `填寫好的庫存盤點結果檔案在下方上載，盤點結果匯入成功後將修改當前的庫存。`,
  key1002618: `商品開始盤點之後將會被鎖定不允許配貨給訂單，錄入盤點結果完成盤點之後才會解除鎖定。`,
  key1002619: `請選擇要匯出的盤點單`,
  key1002620: `匯出盤點單`,
  key1002621: `拆分數量`,
  key1002622: `裝配數量`,
  key1002623: `回補庫存`,
  key1002624: `所需庫存`,
  key1002625: `現有庫存`,
  key1002626: `拆分單編號`,
  key1002627: `裝配單編號`,
  key1002628: `所需原料商品`,
  key1002629: `請輸入備註`,
  key1002630: `收貨倉庫：`,
  key1002631: `返修數量`,
  key1002632: `请输入備註`,
  key1002633: `本次收貨數量`,
  key1002634: `返修單編號：`,
  key1002635: `返修時間：`,
  key1002636: `採購單編號：`,
  key1002637: `返修商品數：`,
  key1002638: `返修總數：`,
  key1002639: `未收貨：`,
  key1002640: `組合品裝配用於將原料商品庫存轉換成需預裝配的組合品的庫存。`,
  key1002641: `裝配單`,
  key1002642: `拆分單`,
  key1002643: `拆分後原料商品庫存回補`,
  key1002644: `添加組合品`,
  key1002645: `組合品裝配`,
  key1002646: `新增拆分單`,
  key1002647: `請先選擇倉庫`,
  key1002648: `未選擇組合品`,
  key1002649: `庫存不足够`,
  key1002650: `返修單用於將殘次品返回給廠商進行維修。`,
  key1002651: `返修單編號`,
  key1002652: `返修單狀態`,
  key1002653: `匯出返修單`,
  key1002654: `標記退款`,
  key1002655: `匯出任務`,
  key1002656: `返修中`,
  key1002657: `已返回`,
  key1002658: `已退款`,
  key1002659: `部分收货`,
  key1002660: `返修單返修的商品將不會返回到倉庫，廠商直接退款。`,
  key1002661: `所有狀態`,
  key1002662: `確定強制結案嗎，強制結案庫存將不會回補。`,
  key1002663: `參數異常`,
  key1002664: `庫存盤點中`,
  key1002665: `將你已選擇的返修單匯出到Excel檔案。`,
  key1002666: `請輸入內容`,
  key1002667: `採購單錯誤`,
  key1002668: `请选择倉庫`,
  key1002669: `返修數量不能为0`,
  key1002670: `採購單不存在`,
  key1002671: `採購單未收貨`,
  key1002672: `庫存不住夠`,
  key1002673: `設定庫存同步時間`,
  key1002674: `自動同步`,
  key1002675: `同步時間`,
  key1002676: `选择时间`,
  key1002677: `按時間`,
  key1002678: `按間隔`,
  key1002679: `每隔`,
  key1002680: `分鐘一次`,
  key1002681: `在线刊登数量`,
  key1002682: `店鋪/itemId`,
  key1002683: `在線庫存數`,
  key1002684: `防超賣設定`,
  key1002685: `防超賣`,
  key1002686: `啟用平台`,
  key1002687: `設定庫存警戒值`,
  key1002688: `當SKU庫存低於警戒值時，只保留該SKU對應同步店鋪中線上`,
  key1002689: `天內銷量最好的店的庫存，其他店鋪庫存同步為0`,
  key1002690: `打印货位标签`,
  key1002691: `條碼大小`,
  key1002692: `貨位大小`,
  key1002693: `條碼位置`,
  key1002694: `貨位位置`,
  key1002695: `貨位`,
  key1002696: `在貨位上方`,
  key1002697: `在貨位下方`,
  key1002698: `居左`,
  key1002699: `居中`,
  key1002700: `居右`,
  key1002701: `数量`,
  key1002702: `批量设置`,
  key1002703: `预览打印`,
  key1002704: `獲取貨位條形碼失敗`,
  key1002705: `请填写数量`,
  key1002706: `庫存SKU`,
  key1002707: `庫存SKU不能為空`,
  key1002708: `新增關聯關係`,
  key1002709: `店鋪SKU`,
  key1002710: `部分匹配成功`,
  key1002711: `不存在`,
  key1002712: `匹配失敗`,
  key1002713: `新增失敗`,
  key1002714: `退貨日期`,
  key1002715: `訂購人/國家`,
  key1002716: `返修数量`,
  key1002717: `备注`,
  key1002718: `请输入原因`,
  key1002719: `请选择仓库`,
  key1002720: `良品庫存變化和殘次品庫存變化都为0`,
  key1002721: `庫存不足，無法扣減`,
  key1002722: `貨品有鎖，異動失敗`,
  key1002723: `商品的倉庫內現有庫存，首次使用系統可以初始化商品的庫存，庫存一旦配貨之後將不允許再次初始化。`,
  key1002724: `货位`,
  key1002725: `请输入货位`,
  key1002726: `货位为空`,
  key1002727: `请输入货位号`,
  key1002728: `库存单价`,
  key1002729: `库存总价`,
  key1002730: `清除數據`,
  key1002731: `庫存備註`,
  key1002732: `请输入庫存備註`,
  key1002733: `批量清除貨位`,
  key1002734: `展示所有商品`,
  key1002735: `展示倉庫貨品總值`,
  key1002736: `展示良品庫存總值`,
  key1002737: `展示在途庫存總值`,
  key1002738: `倉庫貨品總值:`,
  key1002739: `良品庫存總值:`,
  key1002740: `在途庫存總值:`,
  key1002741: `下載匯入商品庫存的Excel範例資料（`,
  key1002742: `已經不在此倉庫存貨的商品可以清除數據，要清除的數據必須是各項庫存都為0。`,
  key1002743: `所選數據中不存在有貨位號的商品`,
  key1002744: `修改货位失敗`,
  key1002745: `查詢貨品總值失敗`,
  key1002746: `更新庫存明細失敗`,
  key1002747: `清楚庫存失敗`,
  key1002748: `查詢庫存明細失敗`,
  key1002749: `匯出當前庫存失敗`,
  key1002750: `添加備註失敗`,
  key1002751: `刪除備註失敗`,
  key1002752: `獲取次品備註失敗`,
  key1002753: `查詢商品庫存失敗`,
  key1002754: `初始化庫存失敗`,
  key1002755: `庫存SKU&數量`,
  key1002756: `店鋪SKU關聯庫存SKU導入`,
  key1002757: `/file/店鋪SKU關聯庫存SKU導入範本.xlsx`,
  key1002758: `導入關聯關係`,
  key1002759: `下載店鋪SKU關聯庫存SKU導入範本（`,
  key1002760: `導入成功，請稍後查看`,
  key1002761: `是否刪除當前內容?`,
  key1002762: `庫存異動單用於調整現有良品庫存數量和現有殘次品庫存數量。`,
  key1002763: `庫存異動編號`,
  key1002764: `已啟用平台`,
  key1002765: `防超賣狀態`,
  key1002766: `商品名稱/庫存SKU`,
  key1002767: `良品庫存數`,
  key1002768: `警戒值`,
  key1002769: `在線刊登數`,
  key1002770: `庫存警告（`,
  key1002771: `設置防超賣`,
  key1002772: `設置所選`,
  key1002773: `設置全部`,
  key1002774: `手動關聯庫存SKU`,
  key1002775: `設置庫存同步時間`,
  key1002776: `已啟用`,
  key1002777: `未啟用`,
  key1002778: `查詢防超賣列表失敗`,
  key1002779: `是否刪除該項數據`,
  key1002780: `是否刪除所選數據`,
  key1002781: `獲取listing在線列表失敗`,
  key1002782: `請輸入訂單號、SKU或商品名稱`,
  key1002783: `操作日志`,
  key1002784: `商品/數量`,
  key1002785: `配貨所選`,
  key1002786: `配貨全部`,
  key1002787: `退回新訂單`,
  key1002788: `(缺貨)`,
  key1002789: `貨品為空`,
  key1002790: `系統自動根據當前庫存，按照訂單日期的先後扣減庫存，庫存夠用的訂單將會轉移到可出貨中，庫存不足的訂單將會轉移到缺貨中。`,
  key1002791: `托運狀態`,
  key1002792: `列印單據`,
  key1002793: `揀貨方式`,
  key1002794: `列印歷史日期`,
  key1002795: `輸入關鍵字進行過濾`,
  key1002796: `金额/付款方式`,
  key1002797: `配送方式/托運單號`,
  key1002798: `托運單號`,
  key1002799: `托運下單失敗原因`,
  key1002800: `訂單時間`,
  key1002801: `匯入托運單號`,
  key1002802: `/file/自定义物流渠道物流单号导入模板.xlsx`,
  key1002803: `未同步`,
  key1002804: `處理中`,
  key1002805: `可供出貨`,
  key1002806: `取消同步`,
  key1002807: `列印揀貨單`,
  key1002808: `列印面單`,
  key1002809: `逐單揀貨`,
  key1002810: `匯總揀貨`,
  key1002811: `未獲取物流面單`,
  key1002812: `重新列印揀貨單`,
  key1002813: `重新列印面單`,
  key1002814: `導入托運單號`,
  key1002815: `托運下單`,
  key1002816: `變更為已出貨`,
  key1002817: `變更所選`,
  key1002818: `變更全部`,
  key1002819: `掃描出貨`,
  key1002820: `退回可出貨`,
  key1002821: `跳過下單`,
  key1002822: `獲取物流面單`,
  key1002823: `重新列印`,
  key1002824: `處理出現錯誤`,
  key1002825: `下載自定义物流渠道物流单号导入模板的Excel範例資料（`,
  key1002826: `填寫好的自定义物流渠道物流单号导入模板在下方上載。`,
  key1002827: `(自定義渠道)`,
  key1002828: `匯出托運單號`,
  key1002829: `待下單`,
  key1002830: `下單中`,
  key1002831: `下單成功`,
  key1002832: `下單失敗`,
  key1002833: `待揀貨`,
  key1002834: `待列印面單`,
  key1002835: `待執行出貨`,
  key1002836: `列印所選`,
  key1002837: `列印所有`,
  key1002838: `批量列印`,
  key1002839: `掃描訂單列印`,
  key1002840: `掃描SKU列印`,
  key1002841: `請輸入掃描內容`,
  key1002842: `打印面單失敗`,
  key1002843: `請先選擇訂單`,
  key1002844: `請選擇揀貨單`,
  key1002845: `您的訂單已經出庫準備交給托運物流公司時可以變更為已出貨。`,
  key1002846: `變更為出貨中`,
  key1002847: `變更出貨失敗`,
  key1002848: `列印匯總揀貨單失敗`,
  key1002849: `生成揀貨單失敗`,
  key1002850: `修改物流單號失敗`,
  key1002851: `同步托運狀態失敗`,
  key1002852: `查詢出貨中列表失敗`,
  key1002853: `跳過下單失敗`,
  key1002854: `查詢倉庫列表失敗`,
  key1002855: `獲取面單成功`,
  key1002856: `獲取物流面單失敗`,
  key1002857: `獲取出貨中數量失敗`,
  key1002858: `識別號`,
  key1002859: `請輸入/掃描訂單號或者物流單號`,
  key1002860: `配送方式`,
  key1002861: `發貨狀態`,
  key1002862: `掃描成功自動發貨`,
  key1002863: `執行發貨`,
  key1002864: `已掃描訂單數:`,
  key1002865: `已出貨訂單數:`,
  key1002866: `已出貨`,
  key1002867: `未出貨`,
  key1002868: `訂單號或物流單號不存在`,
  key1002869: `訂單號或物流單號不處於出貨中`,
  key1002870: `掃描出貨失敗`,
  key1002871: `執行出貨成功`,
  key1002872: `執行發貨失敗`,
  key1002873: `仓库/托運單號`,
  key1002874: `匯入歷史訂單`,
  key1002875: `/file/訂單匯入樣本.xlsx`,
  key1002876: `/file/Momo匯入模板-宅配.xlsx`,
  key1002877: `/file/Momo匯入模板-超取.xlsx`,
  key1002878: `/file/LINE酷券導入訂單 模板.xlsx`,
  key1002879: `/file/RS-訂單模板.xlsx`,
  key1002880: `匯入標準模板`,
  key1002881: `匯入Momo宅配模板`,
  key1002882: `匯入Momo超取模板`,
  key1002883: `匯入LINE酷券模板`,
  key1002884: `匯入RS訂單模板`,
  key1002885: `下載匯入訂單的Excel範例資料`,
  key1002886: `標準模板範例.xlsx`,
  key1002887: `Momo匯入模板-宅配.xlsx`,
  key1002888: `Momo匯入模板-超取.xlsx`,
  key1002889: `LINE酷券模板.xlsx`,
  key1002890: `RS-訂單模板.xlsx`,
  key1002891: `填寫好的訂單檔案在下方上載，訂單編號與當前訂單檔案庫裡面相同的將會跳過。`,
  key1002892: `上載任務正在執行，您也可以關閉窗口，上載任務將會在後台自行執行直到結束，您也可以到 `,
  key1002893: `查詢已出貨列表失敗`,
  key1002894: `標記已付款`,
  key1002895: `回到核對中`,
  key1002896: `將訂單標記為已付款，並進行其他項目的核對。`,
  key1002897: `線下付款`,
  key1002898: `標記已付款成功`,
  key1002899: `系統自動串聯匯入平台線上訂單。確定後窗口將會關閉，系統將在後台自訂匯入。`,
  key1002900: `同步線上訂單`,
  key1002901: `取消後訂單將不再履行`,
  key1002902: `取消訂單`,
  key1002903: `請輸入訂單號,若不填，系統將自動生成`,
  key1002904: `修改運費`,
  key1002905: `修改訂單總額`,
  key1002906: `訂購人`,
  key1002907: `聯繫電話`,
  key1002908: `訂購人E-mail`,
  key1002909: `省/洲`,
  key1002910: `實際托運方式`,
  key1002911: `托運單號：`,
  key1002912: `訂單備註`,
  key1002913: `請輸入商品名稱/SPU/SKU`,
  key1002914: `新增手工訂單`,
  key1002915: `訂單資料`,
  key1002916: `付款資料`,
  key1002917: `產品金額：`,
  key1002918: `運費：`,
  key1002919: `訂單總額：`,
  key1002920: `訂購人資料`,
  key1002921: `配送資料`,
  key1002922: `提交`,
  key1002923: `添加本頁`,
  key1002924: `已添加`,
  key1002925: `請輸入收件人`,
  key1002926: `請輸入收件人聯繫電話`,
  key1002927: `請選擇國家/地區`,
  key1002928: `請輸入省/洲`,
  key1002929: `揀貨單`,
  key1002930: `請輸入/掃描SKU`,
  key1002931: `應發数量`,
  key1002932: `已掃描數量`,
  key1002933: `缺少數量`,
  key1002934: `面單列印狀態`,
  key1002935: `揀貨單號:`,
  key1002936: `結束此次分揀`,
  key1002937: `撤銷此次分揀`,
  key1002938: `總訂單數：`,
  key1002939: `已打印訂單數：`,
  key1002940: `沒有找到相關訂單`,
  key1002941: `SKU已經分揀完成`,
  key1002942: `SKU在當前揀貨單不存在`,
  key1002943: `揀貨單號不存在`,
  key1002944: `掃描成功`,
  key1002945: `當前揀貨單已經揀貨完成`,
  key1002946: `查詢成功`,
  key1002947: `是否結束此次分揀?`,
  key1002948: `結束分揀成功`,
  key1002949: `結束分揀失敗`,
  key1002950: `是否撤銷此次分揀?`,
  key1002951: `撤銷分揀成功`,
  key1002952: `撤銷分揀失敗`,
  key1002953: `進行中的揀貨單查詢成功`,
  key1002954: `售後編號`,
  key1002955: `省州`,
  key1002956: `電話號碼`,
  key1002957: `店家備註:`,
  key1002958: `發貨期限:`,
  key1002959: `退货數量`,
  key1002960: `维修`,
  key1002961: `訂單平台：`,
  key1002962: `付款日期：`,
  key1002963: `訂單日期：`,
  key1002964: `shopline分店名稱：`,
  key1002965: `買家留言`,
  key1002966: `商品詳情`,
  key1002967: `换货`,
  key1002968: `當前良品庫存：`,
  key1002969: `增加商品`,
  key1002970: `原始訂單商品`,
  key1002971: `未匹配到真實商品`,
  key1002972: `退換貨信息`,
  key1002973: `商品費用：`,
  key1002974: `送貨費用：`,
  key1002975: `銷售稅金:`,
  key1002976: `調整金額:`,
  key1002977: `折抵購物金：`,
  key1002978: `商品折扣：`,
  key1002979: `付款方式：`,
  key1002980: `賣家實收：`,
  key1002981: `買家實付：`,
  key1002982: `訂購人：`,
  key1002983: `訂購人Email：`,
  key1002984: `填写托运单号`,
  key1002985: `出貨倉庫：`,
  key1002986: `配送方式：`,
  key1002987: `實際托運方式：`,
  key1002988: `店家備註：`,
  key1002989: `發貨期限：`,
  key1002990: `確定未取後庫存將回補到接收倉庫。`,
  key1002991: `沒有相關數據`,
  key1002992: `已到達`,
  key1002993: `已取貨`,
  key1002994: `已過出貨期限`,
  key1002995: `備貨中`,
  key1002996: `待處理`,
  key1002997: `已退貨`,
  key1002998: `退貨門市關轉`,
  key1002999: `已送貨`,
  key1003000: `門市關閉`,
  key1003001: `收件人不能為空`,
  key1003002: `請選擇實際托運方式`,
  key1003003: `地址1不能為空`,
  key1003004: `電話號碼不能為空`,
  key1003005: `請選擇國家`,
  key1003006: `換貨商品為空`,
  key1003007: `退換貨`,
  key1003008: `無`,
  key1003009: `類別`,
  key1003010: `方式`,
  key1003011: `請輸入其他`,
  key1003012: `請選擇未取原因`,
  key1003013: `未取原因不能為空`,
  key1003014: `填寫托運單號`,
  key1003015: `sku組合品異常，不可添加`,
  key1003016: `商品不能為空`,
  key1003017: `取消下單異常`,
  key1003018: `客戶電話`,
  key1003019: `Shopline分店名稱`,
  key1003020: `請輸入/掃描訂單號， 點擊或Enter確定`,
  key1003021: `列印成功後直接標記已列印`,
  key1003022: `是否一起列印揀貨單`,
  key1003023: `當面單為PDF格式時，無法一起打印揀貨單`,
  key1003024: `列印`,
  key1003025: `标记已列印`,
  key1003026: `打印结果确认`,
  key1003027: `订单面单已生成, 如果已打印，請點擊標記已打印`,
  key1003028: `列印設置`,
  key1003029: `扫描打印面单`,
  key1003030: `收货地址`,
  key1003031: `操作记录`,
  key1003032: `操作人`,
  key1003033: `操作时间`,
  key1003034: `关闭`,
  key1003035: `查詢操作日志失敗`,
  key1003036: `提列印撿貨單示`,
  key1003037: `撿貨單是用於您去倉庫揀貨的清單。`,
  key1003038: `按訂單分頁打印`,
  key1003039: `店鋪商品`,
  key1003040: `銷售渠道`,
  key1003041: `訂單多SKU導入方式`,
  key1003042: `訂單編號重複時`,
  key1003043: `系統字段`,
  key1003044: `Excel文件導入標題`,
  key1003045: `請輸入Excel文件的列標題`,
  key1003046: `可用範圍`,
  key1003047: `導入設置`,
  key1003048: `每一行`,
  key1003049: `同一行多列`,
  key1003050: `當選擇同一行多列的管道導入時，如果填的標題列名是SKU，`,
  key1003051: `則第二個SKU默認叫SKU1，第三個SKU2，依此推導。`,
  key1003052: `所以使用者需要調整對應excel中的列名，以免導入失敗`,
  key1003053: `忽略`,
  key1003054: `覆蓋`,
  key1003055: `模板字段映射`,
  key1003056: `映射`,
  key1003057: `請在此列輸入Excel檔案中對應的列標題，`,
  key1003058: `不輸入會無法匹配到對應的欄位`,
  key1003059: `编辑訂單導入模板`,
  key1003060: `固定值`,
  key1003061: `留空`,
  key1003062: `訂單幣種`,
  key1003063: `郵費`,
  key1003064: `商品金額`,
  key1003065: `商品sku`,
  key1003066: `商品數量`,
  key1003067: `商品單價`,
  key1003068: `客戶姓名`,
  key1003069: `客戶性別`,
  key1003070: `客戶生日`,
  key1003071: `客戶email`,
  key1003072: `客戶收貨地址1`,
  key1003073: `客戶收貨地址2`,
  key1003074: `客戶國家`,
  key1003075: `客戶省州`,
  key1003076: `客戶城市`,
  key1003077: `客戶郵編`,
  key1003078: `實際郵寄方式`,
  key1003079: `跟蹤號`,
  key1003080: `發貨時間`,
  key1003081: `指定郵寄方式`,
  key1003082: `收件人電話`,
  key1003083: `收件人地址1`,
  key1003084: `收件人地址2`,
  key1003085: `收件人省`,
  key1003086: `收件人國家`,
  key1003087: `收件人郵編`,
  key1003088: `收件人城市`,
  key1003089: `新增訂單導入模板`,
  key1003090: `請輸入完成內容`,
  key1003091: `撿貨單號`,
  key1003092: `SKU數`,
  key1003093: `列印者`,
  key1003094: `批量揀貨`,
  key1003095: `導入後執行的動作`,
  key1003096: `選擇需要導入訂單的excel文件`,
  key1003097: `設定為已發貨`,
  key1003098: `如果包含保固商品，觸發保固通知`,
  key1003099: `只能上傳.xlsx、.xls文件，且不超過10MB`,
  key1003100: `開始導入`,
  key1003101: `大小不能超過 10MB!`,
  key1003102: `查詢平台帳號失敗`,
  key1003103: `獲取所有訂單導入模板列表失敗`,
  key1003104: `導入操作成功， 可以任務中心查看處理進度`,
  key1003105: `上傳文件大小不能超過10M`,
  key1003106: `請選擇模板`,
  key1003107: `請選擇銷售渠道`,
  key1003108: `退回核對中`,
  key1003109: `按模板導入訂單`,
  key1003110: `查詢訂單導入模板列表失敗`,
  key1003111: `是否刪除該訂單導入模板`,
  key1003112: `刪除訂單導入模板`,
  key1003113: `刪除規則訂單導入模板成功`,
  key1003114: `查詢訂單導入模板詳情失敗`,
  key1003115: `列印發票`,
  key1003116: `可出貨`,
  key1003117: `檢查提醒`,
  key1003118: `重新核對`,
  key1003119: `重新核對所選`,
  key1003120: `重新核對所有`,
  key1003121: `標記已付款所選`,
  key1003122: `標記已付款全選`,
  key1003123: `添加手工訂單`,
  key1003124: `展示更多(隱藏`,
  key1003125: `條)`,
  key1003126: `系統自動串聯匯入店鋪線上訂單。確定後窗口將會關閉，系統將在後台自訂匯入。`,
  key1003127: `歷史訂單數`,
  key1003128: `歷史消費總額`,
  key1003129: `最近訂單日期`,
  key1003130: `性別`,
  key1003131: `生日`,
  key1003132: `選擇生日`,
  key1003133: `請選擇或輸入客戶標籤`,
  key1003134: `保修卡號`,
  key1003135: `關聯訂單`,
  key1003136: `保固期`,
  key1003137: `啟動保固日期`,
  key1003138: `保固狀態`,
  key1003139: `訂單狀態`,
  key1003140: `行銷活動名稱`,
  key1003141: `活動發起日期`,
  key1003142: `活動結束日期`,
  key1003143: `行銷方式`,
  key1003144: `促成訂單數`,
  key1003145: `促成消費額`,
  key1003146: `已配貨`,
  key1003147: `出貨中`,
  key1003148: `已取消`,
  key1003149: `基本檔案`,
  key1003150: `男`,
  key1003151: `女`,
  key1003152: `重`,
  key1003153: `置`,
  key1003154: `保固號碼`,
  key1003155: `啟動保固`,
  key1003156: `已換貨`,
  key1003157: `商品名稱:`,
  key1003158: `商品貨號:`,
  key1003159: `規格:`,
  key1003160: `已失效`,
  key1003161: `有效保固中`,
  key1003162: `保固期間已過`,
  key1003163: `尚未啟動`,
  key1003164: `行銷記錄`,
  key1003165: `填寫保固號碼`,
  key1003166: `激活成功`,
  key1003167: `客戶手機號已存在`,
  key1003168: `客戶郵箱已存在`,
  key1003169: `客戶標籤為空`,
  key1003170: `客戶郵箱和手機號為空`,
  key1003171: `郵件內容：`,
  key1003172: `簡訊內容：`,
  key1003173: `活動名稱：`,
  key1003174: `活動發起日期：`,
  key1003175: `行銷方式：`,
  key1003176: `客戶要求：`,
  key1003177: `覆蓋客戶數：`,
  key1003178: `促成訂單數：`,
  key1003179: `促成消費額：`,
  key1003180: `轉換率：`,
  key1003181: `送達情況`,
  key1003182: `郵件`,
  key1003183: `短信`,
  key1003184: `有`,
  key1003185: `7天內`,
  key1003186: `15天內`,
  key1003187: `30天內`,
  key1003188: `60天內`,
  key1003189: `60天以前`,
  key1003190: `近7天大於`,
  key1003191: `近7天小於`,
  key1003192: `近15天大於`,
  key1003193: `近15天小於`,
  key1003194: `近30天大於`,
  key1003195: `近30天小於`,
  key1003196: `近60天大於`,
  key1003197: `近60天小於`,
  key1003198: `累計消費額大於`,
  key1003199: `累計消費額小於`,
  key1003200: `有留電話`,
  key1003201: `有留手機號碼`,
  key1003202: `客戶地域`,
  key1003203: `復購`,
  key1003204: `訂單總消費額`,
  key1003205: `客戶聯繫方式`,
  key1003206: `請輸入金額`,
  key1003207: `有留E-mail`,
  key1003208: `客戶標籤`,
  key1003209: `營銷方式`,
  key1003210: `發送內容`,
  key1003211: `行銷活動時間`,
  key1003212: `資訊發送時間`,
  key1003213: `选择日期时间`,
  key1003214: `第一步：客戶要求`,
  key1003215: `覆蓋客戶數`,
  key1003216: `下一步`,
  key1003217: `第二步：營銷方式`,
  key1003218: `上一步`,
  key1003219: `要再E-mail或者簡訊中包含客戶的姓名，請使用{{客戶姓名}}作為占位符號`,
  key1003220: `請輸入活動內容`,
  key1003221: `活動名稱不能為空`,
  key1003222: `活動內容不能為空`,
  key1003223: `活動日期不能為空`,
  key1003224: `資訊發送時間不能為空`,
  key1003225: `資訊發送時間需在活動範圍內`,
  key1003226: `請輸入正文內容...`,
  key1003227: `客戶檔案將從平台訂單中收集。`,
  key1003228: `地域`,
  key1003229: `匯入客戶檔案`,
  key1003230: `/file/客戶檔案匯入匯出樣本.xlsx`,
  key1003231: `批量加入黑名單`,
  key1003232: `移除黑名單`,
  key1003233: `加入黑名單`,
  key1003234: `填寫好的客戶檔案在下方上載，手機號碼或者E-mail與系統內客戶檔案相同的的將會跳過。`,
  key1003235: `匯入客戶資料`,
  key1003236: `匯出客戶資料`,
  key1003237: `導出所選`,
  key1003238: `導出所有`,
  key1003239: `匯出客戶資料+訂單`,
  key1003240: `請選擇要匯出的客戶`,
  key1003241: `將你篩選出的客戶檔案匯出到Excel檔案。`,
  key1003242: `匯出客戶檔案`,
  key1003243: `將所有客戶檔案匯出到Excel檔案。`,
  key1003244: `請選擇要移除黑名單的客戶`,
  key1003245: `按照條件撈出客戶進行簡訊或者E-mail行銷。`,
  key1003246: `活動日期`,
  key1003247: `轉換率`,
  key1003248: `未執行`,
  key1003249: `客戶黑名單將在這些客戶購買訂單之後會有提醒。`,
  key1003250: `批量移除黑名單`,
  key1003251: `將妳篩選出的客戶檔案匯出到Excel檔案。`,
  key1003252: `首页`,
  key1003253: `採集列表`,
  key1003254: `水印模板`,
  key1003255: `新增水印模板`,
  key1003256: `編輯水印模板`,
  key1003257: `Ebay產品刊登`,
  key1003258: `新增草稿`,
  key1003259: `草稿詳情`,
  key1003260: `在線詳情`,
  key1003261: `eBay常用模板`,
  key1003262: `付款政策詳情`,
  key1003263: `新建付款政策`,
  key1003264: `運輸政策詳情`,
  key1003265: `新建運輸政策`,
  key1003266: `退款政策詳情`,
  key1003267: `新建退款政策`,
  key1003268: `物品地址詳情`,
  key1003269: `新建物品地址`,
  key1003270: `Lazada產品刊登`,
  key1003271: `Shopee產品刊登`,
  key1003272: `折扣活動`,
  key1003273: `新建折扣活動`,
  key1003274: `折扣活動詳情`,
  key1003275: `商品置頂推廣`,
  key1003276: `商品置頂推廣設置`,
  key1003277: `Coupang產品刊登`,
  key1003278: `Shopify產品刊登`,
  key1003279: `新增產品集合`,
  key1003280: `修改產品集合`,
  key1003281: `Shopline產品刊登`,
  key1003282: `新增產品分類`,
  key1003283: `修改產品分類`,
  key1003284: `Amazon產品刊登`,
  key1003285: `新增回評模板`,
  key1003286: `編輯回評模板`,
  key1003287: `自動回評`,
  key1003288: `編輯自動回評`,
  key1003289: `分類管理`,
  key1003290: `匯出匯入任務`,
  key1003291: `編輯商品`,
  key1003292: `新增組合品`,
  key1003293: `更新組合品`,
  key1003294: `Shopline線上商品`,
  key1003295: `關聯到我的商品`,
  key1003296: `訂單`,
  key1003297: `核對中`,
  key1003298: `訂單詳情`,
  key1003299: `新訂單`,
  key1003300: `掃描SKU出貨`,
  key1003301: `訂單導入模板`,
  key1003302: `自定義渠道訂單導入`,
  key1003303: `手工訂單`,
  key1003304: `叫貨清單`,
  key1003305: `生成採購單`,
  key1003306: `查看採購單`,
  key1003307: `編輯1688採購單`,
  key1003308: `查看1688採購單`,
  key1003309: `編輯採購單`,
  key1003310: `廠商管理`,
  key1003311: `商品叫貨設定`,
  key1003312: `廠商關聯商品`,
  key1003313: `1688採購`,
  key1003314: `1688帳號授權`,
  key1003315: `1688採購關聯`,
  key1003316: `商品庫存`,
  key1003317: `組合品庫存`,
  key1003318: `新增組合品裝配`,
  key1003319: `組合品裝配詳情`,
  key1003320: `查看拆分单`,
  key1003321: `庫存異動單`,
  key1003322: `新增庫存異動單`,
  key1003323: `庫存異動單詳情`,
  key1003324: `新增返修單`,
  key1003325: `返修單詳情`,
  key1003326: `新增移庫單`,
  key1003327: `移庫單詳情`,
  key1003328: `庫存助手`,
  key1003329: `店鋪SKU關聯庫存SKU`,
  key1003330: `會員`,
  key1003331: `客戶檔案`,
  key1003332: `客戶資料詳情`,
  key1003333: `黑名單`,
  key1003334: `再行銷`,
  key1003335: `創建行銷活動`,
  key1003336: `行銷活動詳情`,
  key1003337: `退貨詳情`,
  key1003338: `換貨詳情`,
  key1003339: `維修詳情`,
  key1003340: `保修卡池`,
  key1003341: `數據`,
  key1003342: `店鋪分析`,
  key1003343: `店鋪統計`,
  key1003344: `訂單分析`,
  key1003345: `利潤分析`,
  key1003346: `廠商業績統計`,
  key1003347: `採購人員業績統計`,
  key1003348: `商品銷量分析`,
  key1003349: `商品庫存分析`,
  key1003350: `客戶分佈`,
  key1003351: `出入庫分析`,
  key1003352: `最暢銷店鋪`,
  key1003353: `亞馬遜報表`,
  key1003354: `賠償`,
  key1003355: `盤存`,
  key1003356: `月度倉儲費`,
  key1003357: `長期倉儲費`,
  key1003358: `設定`,
  key1003359: `平台管理`,
  key1003360: `已購買`,
  key1003361: `未購買`,
  key1003362: `結果頁`,
  key1003363: `付款訂單`,
  key1003364: `用戶中心`,
  key1003365: `站內信详情`,
  key1003366: `員工帳號`,
  key1003367: `倉庫設置`,
  key1003368: `功能設置`,
  key1003369: `匯率設置`,
  key1003370: `任務中心`,
  key1003371: `短信發送記錄`,
  key1003372: `發票模板`,
  key1003373: `新建發票`,
  key1003374: `編輯發票`,
  key1003375: `郵件發送記錄`,
  key1003376: `訂單規則`,
  key1003377: `仓库匹配規則`,
  key1003378: `物流匹配規則`,
  key1003379: `短信規則`,
  key1003380: `郵件發送規則`,
  key1003381: `獲取配置詳情失敗`,
  key1003382: `等待掃描`,
  key1003383: `掃描中`,
  key1003384: `掃描完成`,
  key1003385: `列印完成`,
  key1003386: `文字翻譯`,
  key1003387: `適用於一鍵翻譯，一鍵翻譯標題，描述等。50000字元免費使用，超出後需購買額度，購買額度當月有效，次月未用完部分自動清零。`,
  key1003388: `適用於圖片翻譯，免費20張，超出後10元/200張，當月有效，次月未用完部分自動清零。`,
  key1003389: `短信收费套餐`,
  key1003390: `短信收费套餐。`,
  key1003391: `您的產品已經提交eBay，請到【刊登在線】查看提交結果！`,
  key1003392: `新增產品`,
  key1003393: `查看產品`,
  key1003394: `發佈成功`,
  key1003395: `產品發佈失敗， 請查看失敗原因，修改後重新發佈`,
  key1003396: `繼續編輯`,
  key1003397: `您的產品已經保存成功，請到【草稿箱】查看！`,
  key1003398: `您的產品已經更新成功，請到【在線產品】查看！`,
  key1003399: `產品更新失敗， 刊登講保持原來內容`,
  key1003400: `更新失敗`,
  key1003401: `您的產品已經提交Shopline，請到【刊登在線】查看提交結果！`,
  key1003402: `您的產品已經提交Shopify，請到【刊登在線】查看提交結果！`,
  key1003403: `您的產品已經提交Shopee，請到【刊登在線】查看提交結果！`,
  key1003404: `您的產品已經提交Coupang，請到【刊登在線】查看提交結果！`,
  key1003405: `您的產品已經提交Amazon，請到【刊登在線】查看提交結果！`,
  key1003406: `您的產品已經提交Lazada，請到【刊登在線】查看提交結果！`,
  key1003407: `未受理`,
  key1003408: `已撤銷`,
  key1003409: `已處理`,
  key1003410: `運輸中`,
  key1003411: `攬件失敗`,
  key1003412: `已簽收`,
  key1003413: `簽收異常`
}

import config from '@/config/index'
const prefix = config.serverURlPrefix
export default {
  // baseData
  get_language: prefix + '/baseData/common/language/get', // language
  get_platformList: prefix + '/baseData/platform/list',
  get_purchasedPlatformList: prefix + '/saleAccount/buyPlatformId', // 查詢已購買的平台
  get_commonParameterList: prefix + '/baseData/commonParameter/list', // 条件查询common_base_parameter表
  get_countryList: prefix + '/baseData/country/query', // 查詢國家
  get_currencyInfo: prefix + '/baseData/currencyInfo/query', // 查詢幣種
  get_platformSites: prefix + '/baseData/common/site', // 根据平台查站点
  // common
  get_prefix: prefix + '/common/getPrefix', // 前綴
  get_token: prefix + '/auth/', // getToken
  refresh_token: prefix + '/refresh/',
  set_logout: prefix + '/logout/', // 退出登錄
  set_upload: prefix + '/common/uploadFile', // 上傳
  get_filterList: prefix + '/common/filter/list', // 查詢自定義篩選
  set_saveFilter: prefix + '/common/filter/save', // 保存自定義篩選
  set_deleteFilterItem: prefix + '/common/filter/delete/', // 刪除自定義篩選
  set_exportExcel: prefix + '/common/exportExcel', // 導出excel
  set_uploadExcel: prefix + '/common/uploadExcel', // 導入excel
  get_taskProgress: prefix + '/common/progress/', // 任務進度條
  get_commonTaskList: prefix + '/common/tasks/list', // 任務中心列表
  // userInfo
  get_loginMes: prefix + '/userInfo/queryCurrentUser', // 登錄用戶信息
  get_userInfo: prefix + '/userInfo/getAllUser', // 獲取所有用戶信息
  get_singlePageRoleCommon: prefix + '/userInfo/queryOperByMenu/', // 获取单页权限
  get_menuRole: prefix + '/userInfo/queryMenu', // 查询用户菜单
  set_newUser: prefix + '/userInfo',
  set_delRole: prefix + '/userInfo/disable', // 停用員工
  get_userQuery: prefix + '/userInfo/query', // 分页查询员工
  set_saleAccountDisable: prefix + '/saleAccount/disable', // 停用店鋪
  set_changeUserMes: prefix + '/userInfo/user/save', // 修改用戶資料
  set_changeUserPassword: prefix + '/userInfo/editPassword', // 修改用戶密碼
  // role
  get_roleList: prefix + '/role/query',
  get_roleOperatorList: prefix + '/role/',
  set_role: prefix + '/role',
  del_role: prefix + '/role/',
  // goods
  set_newCategory: prefix + '/product/category/add', // 新增分類
  set_updateCategory: prefix + '/product/category/update', // 編輯分類
  get_categoryList: prefix + '/product/category/list', // 查詢分類管理
  get_categoryListNoAuth: prefix + '/product/category/listWithoutPer', // 查詢分類管理
  set_deleteCategory: prefix + '/product/category/delete/', // 刪除分類
  get_goodsList: prefix + '/product/myProducts', // 我的商品列表
  get_inventoryParams: prefix + '/inventory/log/list', // 库存变动记录列表
  set_updateProductCategory: prefix + '/product/updateProductCategory', // 批量修改商品分类
  set_batchDelectGoods: prefix + '/product/batchDelete', // 批量刪除商品
  get_taskList: prefix + '/common/tasks/list', // 汇入汇出任务列表
  set_addProduct: prefix + '/product/add', // 新增商品
  get_goodsDetails: prefix + '/product/getProductDetail/',
  set_updateGoods: prefix + '/product/update', // 修改商品
  get_onlineProducts: prefix + '/product/myShoplineProduct', // online product list
  set_newBinding: prefix + '/product/addNewBinding', // 新增线上关联商品
  get_bindingItem: prefix + '/product/getBindingItem', // 查询关联商品
  set_deleteItem: prefix + '/product/unbindItem', // 删除关联商品
  set_updateBinding: prefix + '/product/updateItemBindingNum', // 修改關聯商品
  get_selectGoodsList: prefix + '/product/selectGoods', // 選擇貨品
  get_remoteData: prefix + '/product/fuzzyLookupGoods', // 远程搜索货品
  get_mulCommoditiesList: prefix + '/bindingProduct/bindingProduct/list', // 查詢組合商品列表
  set_delCommodities: prefix + '/bindingProduct/batchDelete', // 批量刪除商品
  get_commoditiesDetails: prefix + '/bindingProduct/get/', // 查詢組合品詳情 + {bindingProductId}
  set_newCommdities: prefix + '/bindingProduct/add', // 新增組合商品
  set_updateCommodities: prefix + '/bindingProduct/update', // 更新組合品
  set_shopOnlineBatchUpdate: prefix + '/product/syncListingItems', // 批量更新商品
  get_listInventoryByProductId: prefix + '/product/listInventoryByProductId/', // 根据商品id获取库存明细
  get_SaleCountByProductId:
    prefix + '/product/getSaleCount4SaleAccountByProductId', // 销量
  get_reCalc: prefix + '/purchaseInfo/stockWarning/reCalc', // 重新计算调用
  get_isCalc: prefix + '/purchaseInfo/stockWarning/isCalc', // 是否可以重新计算调用
  // order
  get_verificationList: prefix + '/order/checking/list', // 核對中的訂單分頁查詢
  set_recheck: prefix + '/order/recheck', // 重新核對
  get_newOrderList: prefix + '/order/checked/list', // 分頁查詢新訂單
  set_returnBackVerify: prefix + '/order/updateCheckingStatus', // 退回重新核對
  set_picking: prefix + '/order/pickingChecked', // 新訂單配貨
  set_pickingWithStockout: prefix + '/order/pickingStockout', // 缺貨訂單配貨
  get_stockOutList: prefix + '/order/stockout/list', // 獲取缺貨訂單
  set_returnBackNewOrder: prefix + '/order/updateCheckedStatus', // 退回新訂單
  set_shipping: prefix + '/order/updateOnShipmentStatus', // 設置為出貨中
  set_shipment: prefix + '/order/updateShippingStatus', // 設置為已出貨
  get_readyToShipList: prefix + '/order/beforeShipment/list', // 查詢可出貨列表
  get_onShipmentList: prefix + '/order/onShipment/list', // 查詢出貨中的列表
  get_onShipimentTagList: prefix + '/order/getOnShipment', // 出貨中物流列表
  set_returnBackReadyToShip: prefix + '/order/updateBeforeShipmentStatus', // 退回可出貨
  get_orderDetails: prefix + '/order/details/', // 商品詳情
  set_cancel: prefix + '/order/cancel', // 取消訂單
  post_orderCancelList: prefix + '/order/cancel/list', // 取消訂單
  post_orderUpdateCancelToCheckingStatus:
    prefix + '/order/updateCancelToCheckingStatus', // 已取消订单退回核对中
  set_orderUpdate: prefix + '/order/update', // 更新訂單
  get_afterShipmentList: prefix + '/order/delivered/list', // 獲取已出貨訂單列表
  set_printPickingList: prefix + '/order/printPickingList', // 打印揀貨單
  set_printLabelList: prefix + '/order/printLabelList', // 打印出貨標籤
  get_menuCount: prefix + '/order/getMenuCount', // 獲取訂單統計數量
  set_syncOrder: prefix + '/order/sync', // 同步線上訂單
  set_syncDeliveryStatus: prefix + '/order/createLogisticOrders', // 同步托運狀態
  set_markPaid: prefix + '/order/markPaid', // 標記為已付款
  set_updateShippingInformation: prefix + '/order/updateShippingInformation', // 修改订单详情配送地址
  set_updateTrackingNum: prefix + '/order/updateTrackingData', // 修改实际托运单号
  // purchase
  get_orderGoodsList: prefix + '/purchaseInfo/stockWarning/list', // 分頁查詢叫貨清單列表
  get_supplierList: prefix + '/supplier/list',
  get_allSupplierList: prefix + '/supplier/listAll',
  set_newSupplier: prefix + '/supplier/create',
  set_updateSupplier: prefix + '/supplier/update',
  set_deleteSupplier: prefix + '/supplier/delete', // 刪除廠商
  get_purchaseInfoList: prefix + '/purchaseInfo/list', // 獲取叫貨清單列表
  get_purchaseOrder: prefix + '/purchaseInfo/stockWarning/getSupplierProduct', // 根據叫貨清單生成採購單
  set_createNewPurchase: prefix + '/purchaseInfo/create', // 新建採購單
  get_purchaseCount: prefix + '/purchaseInfo/countByPurchaseStatus', // 獲取採購分頁的分項數量
  get_purchaseList: prefix + '/purchaseInfo/list', // 分頁查詢採購單
  get_goodsInfo: prefix + '/purchaseInfo/goods', // 查詢貨品信息
  get_purchaseDetails: prefix + '/purchaseInfo/product/arrived/get/', // 獲取採購單詳情
  set_updatePurchaseDetails: prefix + '/purchaseInfo/update', // 修改採購單詳情
  set_receiving: prefix + '/purchaseInfo/productArrived', // 收貨
  set_closePurchaseOrder: prefix + '/purchaseInfo/forceCompleted', // 強制結案
  set_delPurchaseOrder: prefix + '/purchaseInfo/delete/', // 刪除採購單
  get_purchaseOrderSettingList: prefix + '/purchaseInfo/setting/list', // 获取商品叫货设定列表
  set_saveSupplierList: prefix + '/purchaseInfo/setting', // 保存商品叫貨設定
  get_suppliers: prefix + '/purchaseInfo/getSuppliers/', // 獲取廠商配置廠商信息
  set_updateSuppliers: prefix + '/purchaseInfo/updateSupplier/', // 更新廠商廠商信息
  /* get_supplierCode: prefix + '/supplier/createCode', // 獲取廠商編號 */
  // shop
  set_newShop: prefix + '/shoplineAccount/insert', // 新增shopline店鋪
  get_shopDetails: prefix + '/shoplineAccount/detail/', // 查詢shopline店鋪詳情
  get_salesAccountList: prefix + '/shoplineAccount/list', // 分页查询帐户列表*(頁面上叫平台)
  get_salesAccountAllList: prefix + '/shoplineAccount/listAll', // 分页查询帐户列表*(頁面上叫平台)
  get_allSaleAccounts: prefix + '/saleAccount/getAllSaleAccounts', // 所有平台不分權限
  set_updateShop: prefix + '/shoplineAccount/update', // 更新shopline店鋪信息
  set_acsAccount: prefix + '/shoplineAccount/saveCustomerToken', // 托运设置ACS设置
  get_inBoxList: prefix + '/shoplineAccount/listSiteCode', // 獲取攬件倉庫列表
  // inventory
  get_transactionOrderList: prefix + '/inventory/change/list', // 庫存異動list
  get_bindingMulCommoditiesStock: prefix + '/inventory/commodities/list', // 查詢組合品庫存
  get_inventoryList: prefix + '/inventory/list', // 分頁查詢商品庫存
  set_initInventoryList: prefix + '/inventory/initial', // 初始化庫存
  get_inventoryVerificationCount:
    prefix + '/inventory/checked/countCheckedList', // 正在盘点和未在盘点统计
  get_inventoryVerificationList: prefix + '/inventory/checked/list', // 库存盘点查询
  get_inventoryVerificationDetailsHistory:
    prefix + '/inventory/checked/listHistoryChecked', // 按商品查询盘点历史
  set_startInventoryVerification: prefix + '/inventory/checked/startChecking', // 开始盘点
  set_saveInventoryVerificationList: prefix + '/inventory/checked/update', // 保存盘点单列表
  get_salesReturnList: prefix + '/inventory/returnGoods/list', // 退貨列表
  set_exportSalesReturnList: prefix + '/inventory/returnGoods/exportExcel', // 退貨導出
  get_recordList: prefix + '/purchaseInfo/product/arrivedDetail/list',
  get_assembleDetail: prefix + '/inventory/assemble/detail/', // 組合品裝配
  get_assembleCreate: prefix + '/inventory/assemble/create', // 組合品裝配
  get_assembleList: prefix + '/inventory/assemble/list', // 組合品裝配
  get_inventoryByGoodsId: prefix + '/inventory/listInventoryByGoodsId/', // 根据货品id获取库存明细
  delete_inventoryClear: prefix + '/inventory/clear', // 清除庫存記錄
  // customer
  get_customerList: prefix + '/customer/list', // 分頁查詢客戶列表
  set_blackList: prefix + '/customer/setBlackList', // 移進移出黑名單
  get_customerDetails: prefix + '/customer/details/', // 查詢客戶資料詳情
  set_customerBaseDataUpdate: prefix + '/customer/update', // 客户基本资料修改
  get_tags: prefix + '/customer/getTags', // 獲取客戶標籤
  get_blackList: prefix + '/customer/blackList', // 獲取黑名單列表
  get_markingList: prefix + '/customer/activity/list', // 獲取再行銷分頁列表
  get_marketingCount: prefix + '/customer/activity/countByActivityStatus', // 统计
  get_customerNum: prefix + '/customer/activity/getNum', // 獲取覆蓋用戶數
  set_activitySave: prefix + '/customer/activity/create', // 保存行銷活動
  get_marketingDetails: prefix + '/customer/activity/edmDetail/', // 行销活动查看
  get_marketingDetailsTableList: prefix + '/customer/activity/edmCustomer', // 分頁查詢行銷活動客戶列表
  delete_activity: prefix + '/customer/activity/delete/', // 刪除活動
  // setting
  set_changeWarehouseStatus:
    prefix + '/settings/warehouse/updateWarehouseStatus', // 停用启用仓库
  set_ebayOauthReload: prefix + '/ebayAccount/oauthUrl/', // 營銷授權
  get_allEnableWarehouseList: prefix + '/settings/getEnableWarehouseList', // 查詢所有啟用倉庫列表
  get_allWarehouseList: prefix + '/settings/getAllWarehouse', // 查詢所有倉庫列表
  set_createNewWarehouse: prefix + '/settings/warehouse/create', // 新增倉庫
  set_updateNewWarehouse: prefix + '/settings/warehouse/edit', // 更新倉庫數據
  set_updateWarehouseIsOrderShipment:
    prefix + '/settings/setOrderShipment/enable', // 標記為訂單默認倉
  get_warehouseDetails: prefix + '/settings/getWarehouseDetails/', // 查看倉庫詳情數據
  set_deleteWarehouse: prefix + '/settings/warehouse/', // 刪除倉庫
  set_emailModel: prefix + '/setting/setWarrantyEmail', // 設置EMAIL保固模板
  set_stockSync: prefix + '/setting/inventorySetValue', // 同步庫存助手
  set_warrantySync: prefix + '/setting/enableWarrantySetValue', // 同步保固助手
  get_stockSync: prefix + '/setting/getSyncItemWarningInfo', // 同步助手值獲取
  get_setting: prefix + '/setting/',
  get_emailModelSettings: prefix + '/setting/getMerchantSettingEmail', // 獲取EMAIL模板信息
  get_deliveryAndPaymentMethod:
    prefix + '/shoplineAccount/syncDeliveryPaymentMethod/', // 同步物流和付款方式
  get_deliveryMethodList: prefix + '/shoplineAccount/listAllDeliveryMethods', // 獲取貨流方式
  get_paymentMethodList: prefix + '/shoplineAccount/listAllPaymentMethods', // 獲取付款方式列表
  get_carrierCompanyList: prefix + '/shoplineAccount/getCarriersWithDetail', // 查询托运公司-托运方式
  set_updateCarrierShippingMethod: prefix + '/carrierShippingMethod/update', // 更新自定义托运方式
  set_updateCarrierShippingMethodStatus:
    prefix + '/carrierShippingMethod/updateStatus', // 更新自定义托运方式
  del_carrierCompany: prefix + '/carrier/delete/', //删除自定义托运公司
  del_carrierCompanyShippingMethod: prefix + '/carrierShippingMethod/delete/', // 刪除自定義公司托運方式
  get_carrierCompanyListNoAuth:
    prefix + '/shoplineAccount/getCarriersWithDetailWithoutPer', // 查询托运公司-托运方式 无权限
  get_deliveryList: prefix + '/carrier/list', // 查詢托運公司列表
  get_carrierShippingMethodList: prefix + '/carrierShippingMethod/list/', // 托运方式列表
  get_carrierShippingAccountList: prefix + '/carrierAccount/list', // 托運帳號列表
  set_updateCarrierStatus: prefix + '/carrierAccount/updateCarrierStatus', // 啟用停用托運公司
  get_carrierList: prefix + '/carrier/getCarrierList', // 托運公司列表分頁
  get_enableCarrierList: prefix + '/carrier/listCarriers', // 查询托运公司-托运方式(托运方式已开启)
  get_platformDeliveryMethod:
    prefix + '/deliveryMethod/queryPlatformDeliveryMethod', // 平台物流方式列表(多平台整合)
  set_updateCarrierCode: prefix + '/shoplineAccount/saveCustomerToken', // 修改客戶代碼
  get_getShippingMethodsList: prefix + '/shoplineAccount/getShippingMethods/', // 根据店铺id获取运输方式
  get_ShopeeShippingMethodsList: prefix + '/shopeeAccount/getLogisticsMethods', // shopee 根据店铺id获取运输方式
  set_updateDeliveryMethodCode:
    prefix + '/shoplineAccount/updateRealShippingMethod', // 修改实际托运方式
  set_newCustomCompany: prefix + '/shoplineAccount/insertCarrier', // 新增托運公司
  set_newCustomDeliveryMethod: prefix + '/shoplineAccount/insertShippingMethod', // 新增托運方式
  set_updateCustomCompanyName: prefix + '/shoplineAccount/updateCarrier', // 更新自定義托運公司
  set_udpateCod: prefix + '/shoplineAccount/updateCodCurrency', // 更新COD及匯率
  get_changeInventory: prefix + '/inventory/change/', // 查看移動端詳情
  addInventory: prefix + '/inventory/change/add', // 添加移動端
  get_repair: prefix + '/inventory/repair/list', // 返修单列表
  export_repairExcel: prefix + '/inventory/repair/repair/exportExcel', // 導出返修單
  delete_repair: prefix + '/inventory/repair/delete/', // 刪除返修單
  check_repair: prefix + '/inventory/repair/checkPurchaseOrder/', // 校验采购单
  get_repairDetail: prefix + '/inventory/repair/detail/', // 獲取返修單明細
  create_repairDetail: prefix + '/inventory/repair/create', // 新增返修單
  get_repairGoods: prefix + '/inventory/repair/goods', // 获取返修单所选货品信息
  update_repairDetail: prefix + '/inventory/repair/update', // 更新返修單明細
  insert_appropriation: prefix + '/inventory/appropriation/insert', // 移库单新增
  get_appropriation: prefix + '/inventory/appropriation/list', // 移库单列表
  get_inventoryDetail: prefix + '/inventory/detail/', // 移库单列表
  set_defectiveRemark: prefix + '/inventory/defectiveRemark/add', // 添加備註
  delete_defectiveRemark:
    prefix + '/inventory/defectiveRemark/deleteByInventoryId/', // 删除备注
  get_defectiveRemark: prefix + '/inventory/defectiveRemark/list', // 次品备注查询
  set_repairArrived: prefix + '/inventory/repair/arrived', // 收货
  get_repairCount: prefix + '/inventory/repair/count', // 獲取數量 返修單
  post_shoplineAccount_insertCarrierAccount:
    prefix + '/shoplineAccount/insertCarrierAccount', // 新增账号
  get_exchangeRateList: prefix + '/merchantExchange/list', // 查詢匯率列表
  set_fixedExchangeRate: prefix + '/merchantExchange/update', // 設置固定匯率
  // 售后服务
  get_afterSalesList: prefix + '/afterSales/afterSalesList', // 退货/换货/维修/未取列表
  get_afterSalesDetail: prefix + '/afterSales/afterSalesDetail/', // 退货/换货/维修/未取列表
  set_afterSalesExportExcel: prefix + '/afterSales/afterSalesExportExcel', // 退货/换货/维修/未取列表
  get_sysDict: prefix + '/sysDict/list/', // 获取数据字典
  set_operationAfterSale: prefix + '/afterSales/operationAfterSale', // 退货中操作 退货/换货/维修/未取
  get_countByAfterSalesStatus: prefix + '/afterSales/countByAfterSalesStatus', // 統計 退货/换货/维修/未取
  set_afterSalesUpdate: prefix + '/afterSales/afterSalesUpdate', // 更新 退货/换货/维修/未取
  set_finishOperationAfterSale: prefix + '/afterSales/finishOperationAfterSale', // 更新 退货/换货/维修/未取
  set_unReceiveBatchInsert: prefix + '/afterSales/unReceiveBatchInsert', // 未取批量新增
  delete_afterSalesDelete: prefix + '/afterSales/afterSalesDelete', // 未取批量新增
  // shopee 授权
  get_ebaySites: prefix + '/ebayAccount/getAllSites', // 获取ebay 站点
  get_ebayLogisticsMethods: prefix + '/ebayAccount/getLogisticsMethods', // eBay托运方式查询接口
  set_shippingMethodBinding: prefix + '/ebayAccount/shippingMethodBinding', // eBay绑定托运方式
  get_saleAccountCount: prefix + '/saleAccount/count', // 平台店铺統計
  set_inventoryUpdate: prefix + '/inventory/update', // 更新庫存明細
  set_delShippingSysAccount: prefix + '/carrierAccount/delete/', // 刪除系統托運公司帳號
  // product

  post_ebayListingDraft_getEbayDraftDiscountShipping:
    prefix + '/ebayListingDraft/getEbayDraftDiscountShipping/', // 获取ebay标准运输折扣物流模板
  post_datacollection_uploadEbay: prefix + '/datacollection/uploadEbay', // 採集上傳ebay

  post_ebayListing_getListingList: prefix + '/ebayListing/getListingList', // 获取刊登在线列表的数据
  query_ebayListing_getEbayListing: prefix + '/ebayListing/getEbayListing/', // 查询ebay在线详情
  post_ebayListing_saveEbayListing: prefix + '/ebayListing/saveEbayListing/', // 保存ebay在线详情
  post_ebayListingDraft_getEbayListingDraftList:
    prefix + '/ebayListingDraft/getEbayListingDraftList', // 获取ebay草稿列表
  post_ebayListingDraft_getEbayListingDraftById:
    prefix + '/ebayListingDraft/getEbayListingDraftById/', // 查询ebay草稿详情
  post_ebayListingDraft_deleteEbayListingDraft:
    prefix + '/ebayListingDraft/deleteEbayListingDraft', // 删除ebay草稿
  post_ebayListingDraft_saveEbayListingDraft:
    prefix + '/ebayListingDraft/saveEbayListingDraft', // 保存ebay草稿
  set_batchPublish: prefix + '/ebayListingDraft/batchPublish', // 批量上架
  post_ebayListingDraft_publish: prefix + '/ebayListingDraft/publish', // 發佈草稿
  post_translate: prefix + '/tools/languageTranslate', // 語言翻譯
  get_ebay_getEbayStoreCategory: prefix + '/ebay/getEbayStoreCategory/', // 查询ebay店铺分类
  get_ebay_syncEbayStoreCategory: prefix + '/ebay/syncEbayStoreCategory/', // 同步ebay店铺分类
  get_baseData_updateMerchantLanguage:
    prefix + '/baseData/common/updateMerchantLanguage/', // 修改語種
  post_listing_updateListing: prefix + '/ebayListingDraft/batchModify', // 批量修改
  post_shopeeListing_getListingList: prefix + '/shopeeListing/getListingList', // 获取shopee在线列表

  post_lazadaListing_getListingList: prefix + '/lazadaListing/getListingList', // 获取lazada在线列表
  post_listing_searchStatus: prefix + '/listing/searchStatus', // 获取listing产品状态
  post_datacollection_removeToEbay:
    prefix + '/datacollection/otherDataToEbayDraft', // 上传到ebay
  post_datacollection_batchOtherDataToEbayDraft:
    prefix + '/datacollection/batchOtherDataToEbayDraft', // 批量搬到ebay
  /* 組件模板 */
  post_saveEbayWidgetTemplate:
    prefix + '/ebayWidgetTemplate/saveEbayWidgetTemplate', // 添加组件模板
  query_getEbayWidgeTemplatetByTypeList:
    prefix + '/ebayWidgetTemplate/getEbayWidgeTemplatetByTypeList', // 添加组件模板
  post_getEbayWidgeTemplatetList:
    prefix + '/ebayWidgetTemplate/getEbayWidgeTemplatetList', // ebay组件模板列表
  post_getEbayWidgetTemplate:
    prefix + '/ebayWidgetTemplate/getEbayWidgetTemplate/', // 组件模板详情
  post_removeEbayWidgetTemplate:
    prefix + '/ebayWidgetTemplate/removeEbayWidgetTemplate/', // 刪除
  post_batchEbayListingDraftApplyWidgetTemplate:
    prefix + '/ebayListingDraft/batchEbayListingDraftApplyWidgetTemplate', // 草稿一键套用模板
  post_batchEbayListingApplyWidgetTemplate:
    prefix + '/ebayListing/batchEbayListingApplyWidgetTemplate', // 草稿一键套用模板
  post_ebayListingDraftBatchModify: prefix + '/ebayListingDraft/batchModify', // 批量修改
  post_listingGetProgressBar: prefix + '/listing/getProgressBar', // 獲取進度條
  /* 汇率 */
  post_merchantExchangeList: prefix + '/merchantExchange/list', // 获取货币汇率列表
  post_merchantExchangeUpdate: prefix + '/merchantExchange/update', // 修改货币汇率
  post_attributeMappingSaveAttributeMapping:
    prefix + '/attributeMapping/saveAttributeMapping', // 保存映射
  post_ebayComoatubleTemplateSave: prefix + '/ebayCompatubleTemplate/save', // 保存兼容模板
  post_ebayComoatubleTemplateRemove: prefix + '/ebayCompatubleTemplate/remove', // 删除兼容模板
  post_ebayComoatubleTemplateGetTemplateList:
    prefix + '/ebayCompatubleTemplate/getTemplateList', // 获取ebay兼容模板列表
  post_ebayComoatubleTemplateDetail:
    prefix + '/ebayCompatubleTemplate/getEbayCompatibleTemplate/', // 获取ebay兼容模板详情
  query_getCommonCategoryAttributeValueByAttrId:
    prefix + '/listing/getCommonCategoryAttributeValueByAttrId', // 批量查询类目属性表
  query_getShippingTableList: prefix + '/ebay/getEbayRateTables', // 获取运费列表
  query_getEbayReturnPolicyBySiteIdAndType:
    prefix + '/listing/getEbayReturnPolicyBySiteIdAndType', // 批量查询根据站点编号、退货政策类型查询ebay平台的退货政策列表(ds)
  query_goodsStatus: prefix + '/listingCommon/queryForList', // 獲取物品狀態，獲取城市
  query_ebayTaxJuridictionBySite:
    prefix + '/listingCommon/tax/juridiction/getEbayTaxJuridictionBySite', // 根據站點查詢銷售稅額管轄地區
  query_getAllSites: prefix + '/listingCommon/site/getAllSiteList', // 獲取所有站點列表
  query_commonCategoryInfoList:
    prefix + '/listingCommon/category/getCommonCategoryInfoList', // 查詢一級類目
  query_commonCategoryConditionList:
    prefix + '/listingCommon/category/condition/getCommonCategoryConditionList', // 獲取物品狀態
  query_commonCategoryAttributeListById:
    prefix +
    '/listingCommon/category/attribute/getCommonCategoryAttributeListById', // 获取類目屬性getEbayHandlingTimeDataResult
  query_ebayListingDuration:
    prefix +
    '/listingCommon/listing/duration/getEbayListingDurationBySiteIdAndDurationType', // 查询刊登时长
  query_getMulSpecs:
    prefix +
    '/listingCommon/category/attribute/variation/getCommonCategoryAttributeValueByAttrId',
  query_allFulfillmentPolicy:
    prefix + '/ebayPolicy/getAllFulfillmentPolicyList', // 查询物流政策详情
  query_allPaymentPolicy: prefix + '/ebayPolicy/getAllPaymentPolicyList', // 查询付款政策详情
  query_allReturnPolicy: prefix + '/ebayPolicy/getAllReturnPolicyList', // 查询退款政策详情
  query_listingDraftErrorMes:
    prefix + '/ebayListingDraft/getEbayReasonsDeatil/', // 查詢listingdraft錯誤詳情
  query_handlingTime:
    prefix +
    '/listingCommon/handling/time/getEbayHandlingTimeDataResult?ebaySiteId=', // 獲取處理時間
  query_getExcluedsShippingMethodCountryBySite:
    prefix +
    '/listingCommon/exclude/location/getEbayExcludeLocationListBySiteId?ebaySiteId=', // 查詢不可到達國家列表
  query_getShippingMethodCountryBySite:
    prefix +
    '/listingCommon/shipping/method/country/getEbayShippingMethodContryBySite', // 根据站点查询运输方式可到达国家列表
  query_getShippingPolicyShipppingList:
    prefix +
    '/listingCommon/shipping/method/getEbayShippingMethodBySiteIdAndIsinter', // 查詢運輸政策物流運輸方式列表
  query_getSitePayMethodById:
    prefix + '/listingCommon/site/geSitePayMethodById', // 获取其它付款方式
  query_shopeeSite: prefix + '/listingCommon/shopee/getShopeeSite', // 获取SHOPEE站点
  query_ebayCategoryName: prefix + '/listingCommon/category/searchCategory', // 搜索ebay分類
  set_retrieveCategory: prefix + '/datacollection/switchCategory', // 重新获取分类模板
  set_batchTranslate: prefix + '/tools/languageTranslateBatch', // 批量翻譯
  set_picBgChange: prefix + '/tools/matting', // 批量摳圖
  set_picTranslate: prefix + '/tools/pictureTranslate', // 批量批量翻譯
  set_picTransfer: prefix + '/tools/uploadImageUrl' // 网络图片转cdn
}

import config from '@/config/index'
const prefix = config.serverURlPrefix
export default {
  get_login: prefix + '/customers/login', // 保修卡登錄
  send_emailCode: prefix + '/email/send', // 發送郵件驗證碼
  get_warrantyList: prefix + '/customers/activationCard', // 獲取用戶保修單列表
  set_customerWarrantyActive: prefix + '/cardPool/merchantId/activationCard', // 客服激活保修卡
  get_memberResourceList: prefix + '/resourcePackage/list', // 獲取用戶資源包
  get_memeberResourcePrice: prefix + '/resourcePackage/calculateResourcePrice' // 計算資源包套餐價格
}

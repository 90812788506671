
import { alias29fe4faa05e144f890cc84c3cca9daf8 } from '@/customFolder/customVueAlias.js';
import { CountryItem } from './../../interface/baseData'
import { UserInfoItem } from '@/interface/baseData'
import moment from 'moment'
import { queryParams } from '@/utils/util'

export default {
  data() {
    return {
      labelWidthValue:
        localStorage.getItem('lang') === 'en-US' ? 'auto' : '140px',
      placeholderSrc: './images/placeholder.jpg',
      pageArray: [1, 10, 50, 100]
    }
  },
  computed: {
    userInfo(): UserInfoItem {
      let v: any = this
      return v.store.state.userInfo
    },
    controlList() {
      let v: any = this
      return v.store.state.roleList
    },
    countryList(): CountryItem[] {
      let v: any = this
      return v.store.state.countryList
    }
  },
  methods: {
    windowOpen(url: string, params: any) {
      if (params) {
        window.open(url + '?' + queryParams(params))
      } else {
        window.open(url)
      }
    },
    goNoPermission() {
      let v: any = this
      v.$router.push('/noPermission')
    },
    getPermission(name: string): boolean {
      // 是否有权限
      let v: any = this
      const { isAdmin } = v.userInfo
      if (isAdmin === '1' || v.controlList[name]) return true
      return false
    },
    getUniversalTime(date: string, type = 'fulltime') {
      // UTC时间
      if (!date) return
      let formate = 'YYYY-MM-DD HH:mm:ss'
      if (type !== 'fulltime') {
        formate = 'YYYY-MM-DD'
      }
      return moment.utc(date).format(formate)
    },
    getLocalDate(
      date: string,
      type: string = 'fulltime',
      returnText: string = '-'
    ) {
      // 獲取本地時間
      if (!date) return returnText
      let formate = 'YYYY-MM-DD HH:mm:ss'
      if (type !== 'fulltime') {
        formate = 'YYYY-MM-DD'
      }
      return moment.parseZone(date).local().format(formate)
    },
    setImgPath(path: string) {
      let v: any = this
      // 设置图片路径
      if (path && (path.includes('https://') || path.includes('http://'))) {
        return path
      } else if (path) {
        return v.store.state.imgPrefix + path
      } else {
        return v.placeholderSrc
      }
    },
    uploadIntercept(message: string) {
      let v: any = this
      v.$confirm(message, alias29fe4faa05e144f890cc84c3cca9daf8.t('key1000003'), {
        distinguishCancelAndClose: true,
        confirmButtonText: alias29fe4faa05e144f890cc84c3cca9daf8.t('key1000004'),
        cancelButtonText: alias29fe4faa05e144f890cc84c3cca9daf8.t('key1000005')
      }).then(() => {
        window.location.href = '/index.html#/settings/platform'
      })
    },
    routeTo(path: string, query: any) {
      // common router method
      let v: any = this
      if (!query) {
        v.$router.push(path)
      } else {
        v.$router.push({
          path: path,
          query: query
        })
      }
    },
    getCountryName(code: string) {
      let v: any = this
      if (v.countryList && v.countryList.length) {
        let country = v.countryList.find((n: CountryItem) => {
          return n.twoCode === code
        })
        if (country && country.name) {
          return country.name
        }
      }
      return ''
    }
  }
}

import config from '@/config/index'
const prefix = config.serverURlPrefix
export default {
  get_smsTemplate: prefix + '/sms/template', // 短信模板列表
  get_smsRecord: prefix + '/sms/message', // 短信發送記錄列表
  set_reSendSms: prefix + '/sms/message/resend', // 重新發送短信
  set_sendSms: prefix + '/sms/message/send', // 發送短信
  get_smsRuleList: prefix + '/autoRuler/sms', // 短信規則列表
  get_smsRuleDetails: prefix + '/autoRuler/sms/', // 獲取短信規則詳情
  set_newSmsRule: prefix + '/autoRuler/sms', // 新增短信規則
  delete_smsRule: prefix + '/autoRuler/sms/', // 刪除短信規則
  set_updateRuleStatus: prefix + '/autoRuler/sms/status', // 更新短信規則狀態
  get_emailList: prefix + '/email/template', // 獲取郵件模板列表
  del_emailTemplate: prefix + '/email/template', // 刪除郵件模板
  set_updateEmailTemplate: prefix + '/email/template', // 修改郵件模板
  get_emailTemplateDetails: prefix + '/email/template/', // 查看郵件模板
  set_newEmailTemplate: prefix + '/email/template/add', // 修改郵件模板
  get_emailRecord: prefix + '/email/message', // 查看郵件發送記錄
  set_reSendEmail: prefix + '/email/message/resend', // 修改郵件模板
  get_emailRuleList: prefix + '/autoRuler/email', // 郵件規則列表， 不分頁
  delete_emailRule: prefix + '/autoRuler/email', // 刪除郵件規則
  set_updateEmailRule: prefix + '/autoRuler/email', // 修改郵件規則
  set_newEmailRule: prefix + '/autoRuler/email', // 新增郵件規則
  set_updateEmailRuleStatus: prefix + '/autoRuler/email/status', // 修改郵件規則狀態
  get_emailRuleDetails: prefix + '/autoRuler/email/', // 獲取郵件規則詳情
  get_warehouseRulesAllList: prefix + '/autoRuler/warehouse', // 不分页获取仓库列表
  delete_warehouseRules: prefix + '/autoRuler/warehouse', // 删除仓库规则
  set_newWarehouseRules: prefix + '/autoRuler/warehouse', // 新增仓库规则
  set_updateWarehouseRules: prefix + '/autoRuler/warehouse', // 更新仓库规则
  set_updateWarehousePriority: prefix + '/autoRuler/warehouse/priority', // 更新优先级
  set_updateWarehouseStatus: prefix + '/autoRuler/warehouse/status', // 更新仓库规则状态
  get_warehouseRulesDetails: prefix + '/autoRuler/warehouse/', // 查看仓库详情
  set_newCustomerPlatform: prefix + '/customAccount/customInsert', // 新增自定義平台帳號
  set_deleteCustomPlatform: prefix + '/customAccount/deleteCustom', // 刪除自定義平台
  set_updateCustomPlatform: prefix + '/customAccount/updateCustom', // 更新自定義平台
  get_messageList: prefix + '/platformMessage/list', // 站內信列表
  get_messageDetails: prefix + '/platformMessage/detail/', // 站內信詳情
  set_messageUpdate: prefix + '/platformMessage/update', // 站內信更新
  get_userWarehouseList: prefix + '/settings/getUserOwningWarehouse', // 獲取用戶倉庫列表
  set_contacts: prefix + '/contactInfo/insert', // 站内信
  get_settings: prefix + '/setting/', // {settingCode} 获取商户配置
  set_settingCurrency: prefix + '/setting/merchant', // 保存商户设置
  get_smsList: prefix + '/sms/message/getSmsSupportList', // 獲取短信所在地區
  get_invoiceList: prefix + '/invoice/list', // 獲取發票列表
  set_invoicePrint: prefix + '/invoice/print', // 打印發票
  get_invoiceDetails: prefix + '/invoice/', // 發票詳情 {invoiceId}
  set_deleteInvoice: prefix + '/invoice', // 刪除發票
  set_newInvoice: prefix + '/invoice', // 新增發票
  set_updateInvoice: prefix + '/invoice' // 修改發票
}

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import localfcb5665f633c47e5bc974b00db3517a8 from './lang/en-US';
import local4a4319ba62954f1caffb2e3cd4a2cdba from './lang/zh-TW';
import localb102c1aa31134ee8ae540c40f15a276c from './lang/ja';

Vue.use(VueI18n);
let lang = localStorage.getItem('lang') || 'zh-TW';
localStorage.setItem('lang', lang);
Vue.config.lang = lang;
export const messages = {'en-US':{...localfcb5665f633c47e5bc974b00db3517a8},'zh-TW':{...local4a4319ba62954f1caffb2e3cd4a2cdba},'ja':{...localb102c1aa31134ee8ae540c40f15a276c}};
const i18n = new VueI18n({
				  locale: lang,
				  messages
				})
				export default i18n
var localeMap = {"en-US":"English","zh-TW":"繁體中文","ja":"日本語"}